import React, { Component } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { connect } from 'react-redux';

import Header from '../common/Header';
import LeftSidebar from '../common/LeftSidebar';
import RightSidebar from '../common/RightSidebar';
import Notification from '../common/Notification';
import Contribution from '../transactions/Contribution';
import trustFundApiService from '../common/api/ApiService';
import CONSTANTS from '../common/helpers/Constants';
import 'react-datepicker/dist/react-datepicker.css';
import DateRenderField from '../authorization/DatePickerRenderField';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionData: [],
      initialPage: 0,
      startDate: '',
      endDate: '',
      totalRecords: 0,
      buttonText: 'Filter'
    };
  }

  componentDidMount() {
    document.title = "Transactions - If You TRUST Me I'll TRUST You";
    this.getTransactions(
      CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION,
      this.state.initialPage,
      1
    );
  }

  getTransactions = (type, pageNo, action) => {
    if (action === 1) {
      this.setState({
        buttonText: 'Filter',
        startDate: '',
        endDate: ''
      });
      this.props.dispatch(change('transactionForm', 'startDate', ''));
      this.props.dispatch(change('transactionForm', 'endDate', ''));
      let data = {
        trans_type: type,
        offset: pageNo + 1,
        count: CONSTANTS.RECORDS_PER_PAGE,
        trans_from_date: '',
        trans_to_date: '',
        orderby: ''
      };
      trustFundApiService('getTransaction', data)
        .then(response => {
          if (response.data.status === 'success') {
            let data = response.data.data;
            let totalRecords = response.data.total_records;

            this.setState({
              transactionData: data,
              totalRecords
            });
          } else if (response.data.status === 'error') {
            console.log('dfsd');
            this.setState({
              transactionData: []
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (action === 2) {
      let startDate =
        this.state.startDate !== ''
          ? moment(this.state.startDate).format('MM/DD/YYYY')
          : '';
      let endDate =
        this.state.endDate !== ''
          ? moment(this.state.endDate).format('MM/DD/YYYY')
          : '';

      let data = {
        trans_type: type,
        offset: pageNo + 1,
        count: CONSTANTS.RECORDS_PER_PAGE,
        trans_from_date: startDate,
        trans_to_date: endDate,
        orderby: ''
      };
      this.setState({
        buttonText: 'Clear'
      });
      trustFundApiService('getTransaction', data)
        .then(response => {
          if (response.data.status === 'success') {
            let data = response.data.data;
            let totalRecords = response.data.total_records;
            console.log(data);

            this.setState({
              transactionData: data,
              totalRecords
            });
          } else if (response.data.status === 'error') {
            this.setState({
              transactionData: []
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  handlePageClick = (type, page) => {
    this.getTransactions(type, page.selected, 2);
    this.setState({
      initialPage: page.selected
    });
  };

  // handleChangeStart = startDate => {
  //   this.setState({ startDate });
  // };

  // handleChangeEnd = endDate => {
  //   this.setState({
  //     endDate
  //   });
  // };

  handleChangeStart = startDate => {
    this.handleDateChange({ startDate });
  };

  handleChangeEndDate = endDate => {
    this.handleDateChange({ endDate });
  };

  handleDateChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (startDate && endDate) {
      if (startDate.valueOf() > endDate.valueOf()) {
        endDate = startDate;
      }
    }
    this.props.dispatch(change('etcForm', 'startDate', startDate));
    this.props.dispatch(change('etcForm', 'endDate', endDate));
    this.props.dispatch(untouch('etcForm', 'startDate'));
    this.props.dispatch(untouch('etcForm', 'endDate'));
    this.setState({ startDate, endDate });
  };

  viewLoan = loanId => {
    console.log(loanId);
  };

  render() {
    const cur_symbol = this.props.userData.cur_symbol || '';
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body  animated trans-text fadeIn uk-margin-bottom">
                  <h2 className="uk-text-large-theme uk-text-center theme-color uk-margin-large-bottom">
                    Transactions
                    <br />
                  </h2>
                  <ul data-uk-switcher="{connect:'#my-id'}" data-uk-tab>
                    <li>
                      <a
                        onClick={this.getTransactions.bind(
                          this,
                          CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION,
                          this.state.initialPage,
                          1
                        )}
                      >
                        Contributions
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.getTransactions.bind(
                          this,
                          CONSTANTS.TRANSACTION_TYPE.EARNINGS,
                          this.state.initialPage,
                          1
                        )}
                      >
                        Trust Earnings
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.getTransactions.bind(
                          this,
                          CONSTANTS.TRANSACTION_TYPE.LOAN,
                          this.state.initialPage,
                          1
                        )}
                      >
                        Loans
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.getTransactions.bind(
                          this,
                          CONSTANTS.TRANSACTION_TYPE.POP,
                          this.state.initialPage,
                          1
                        )}
                      >
                        Purchase Order Power
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={this.getTransactions.bind(
                          this,
                          CONSTANTS.TRANSACTION_TYPE.ETC,
                          this.state.initialPage,
                          1
                        )}
                      >
                        Emergency Travel Cover
                      </a>
                    </li>
                  </ul>
                  <ul id="my-id" className="uk-switcher uk-margin">
                    {/* Contribution Section Starts */}
                    <li className="uk-margin-right" data-uk-switcher-item="0">
                      <table className="uk-table">
                        <caption className="uk-text-center uk-h3 uk-vis@m">
                          <span className="uk-margin-right">From:</span>
                          <Field
                            className="date-field"
                            name="startDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            endDate={this.state.endDate}
                            startDate={this.state.startDate}
                          />
                          <span className="uk-margin-left uk-margin-right">
                            To:
                          </span>
                          <Field
                            className="date-field"
                            name="endDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {this.state.buttonText === 'Filter' ? (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION,
                                this.state.initialPage,
                                2
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Filter
                            </button>
                          ) : (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION,
                                this.state.initialPage,
                                1
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Clear
                            </button>
                          )}
                        </caption>
                        <thead>
                          <tr>
                            <th className="theme-color">ID</th>
                            <th className="theme-color">DATE</th>
                            <th className="theme-color">
                              AMOUNT({cur_symbol})
                            </th>
                            <th className="theme-color">STATUS</th>
                          </tr>
                        </thead>
                        {this.state.transactionData.length > 0 ? (
                          <Contribution
                            type={CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION}
                            data={this.state.transactionData}
                          />
                        ) : (
                          <tr>
                            <td>No records available</td>
                          </tr>
                        )}
                      </table>

                      <div className="uk-margin flex-center">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            this.state.totalRecords / CONSTANTS.RECORDS_PER_PAGE
                          }
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={this.handlePageClick.bind(
                            this,
                            CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION
                          )}
                          containerClassName={'uk-pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'uk-active'}
                          initialPage={this.state.initialPage}
                        />
                      </div>
                    </li>
                    {/* Contribution Section ends */}

                    {/* Trust Earning Section starts */}
                    <li>
                      <table class="uk-table">
                        <caption className="uk-text-center uk-h3 uk-vis@m">
                          <span className="uk-margin-right">From:</span>
                          <Field
                            name="startDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            endDate={this.state.endDate}
                            startDate={this.state.startDate}
                          />
                          <span className="uk-margin-left uk-margin-right">
                            To:
                          </span>
                          <Field
                            name="endDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {this.state.buttonText === 'Filter' ? (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.EARNINGS,
                                this.state.initialPage,
                                2
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Filter
                            </button>
                          ) : (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.EARNINGS,
                                this.state.initialPage,
                                1
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Clear
                            </button>
                          )}
                          {/* <button
                            className="uk-button uk-button-xsmall button-out"
                            onClick={this.getTransactions.bind(
                              this,
                              CONSTANTS.TRANSACTION_TYPE.EARNINGS,
                              this.state.initialPage
                            )}
                            style={{ fontSize: '12px' }}
                          >
                            Filter
                          </button> */}
                        </caption>
                        <thead>
                          <tr>
                            <th className="theme-color">DATE</th>
                            <th className="theme-color">
                              AMOUNT({cur_symbol})
                            </th>
                            <th className="theme-color">STATUS</th>
                          </tr>
                        </thead>
                        {this.state.transactionData.length > 0 ? (
                          <Contribution
                            type={CONSTANTS.TRANSACTION_TYPE.EARNINGS}
                            data={this.state.transactionData}
                            {...this.props}
                          />
                        ) : (
                          <tr>
                            <td>No records available</td>
                          </tr>
                        )}
                      </table>
                      <div className="uk-margin flex-center">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            this.state.totalRecords / CONSTANTS.RECORDS_PER_PAGE
                          }
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={this.handlePageClick.bind(
                            this,
                            CONSTANTS.TRANSACTION_TYPE.EARNINGS
                          )}
                          containerClassName={'uk-pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'uk-active'}
                          initialPage={this.state.initialPage}
                        />
                      </div>
                    </li>
                    {/* Trust Earning Section ends */}

                    {/* Loan Section Starts */}
                    <li className="uk-margin-right" data-uk-switcher-item="1">
                      <table class="uk-table">
                        <caption className="uk-text-center uk-h3 uk-vis@m">
                          <span className="uk-margin-right">From:</span>
                          <Field
                            name="startDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            endDate={this.state.endDate}
                            startDate={this.state.startDate}
                          />
                          <span className="uk-margin-left uk-margin-right">
                            To:
                          </span>
                          <Field
                            name="endDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {this.state.buttonText === 'Filter' ? (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.LOAN,
                                this.state.initialPage,
                                2
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Filter
                            </button>
                          ) : (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.LOAN,
                                this.state.initialPage,
                                1
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Clear
                            </button>
                          )}
                          {/* <button
                            className="uk-button uk-button-xsmall button-out"
                            onClick={this.getTransactions.bind(
                              this,
                              CONSTANTS.TRANSACTION_TYPE.LOAN,
                              this.state.initialPage
                            )}
                            style={{ fontSize: '12px' }}
                          >
                            Filter
                          </button> */}
                        </caption>
                        <thead>
                          <tr>
                            <th className="theme-color">DATE</th>
                            <th className="theme-color">
                              AMOUNT({cur_symbol})
                            </th>
                            <th className="theme-color">STATUS</th>
                            <th className="theme-color">ACTION</th>
                          </tr>
                        </thead>
                        {this.state.transactionData.length > 0 ? (
                          <Contribution
                            type={CONSTANTS.TRANSACTION_TYPE.LOAN}
                            data={this.state.transactionData}
                            {...this.props}
                          />
                        ) : (
                          <tr>
                            <td>No records available</td>
                          </tr>
                        )}
                      </table>
                      <div className="uk-margin">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            this.state.totalRecords / CONSTANTS.RECORDS_PER_PAGE
                          }
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={this.handlePageClick.bind(
                            this,
                            CONSTANTS.TRANSACTION_TYPE.LOAN
                          )}
                          containerClassName={'uk-pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'uk-active'}
                          initialPage={this.state.initialPage}
                        />
                      </div>
                    </li>
                    {/* Loan Section end */}
                    <li className="uk-margin-right" data-uk-switcher-item="2">
                      <table class="uk-table">
                        <caption className="uk-text-center uk-h3 uk-vis@m">
                          <span className="uk-margin-right">From:</span>
                          <Field
                            name="startDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            endDate={this.state.endDate}
                            startDate={this.state.startDate}
                          />
                          <span className="uk-margin-left uk-margin-right">
                            To:
                          </span>
                          <Field
                            name="endDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {this.state.buttonText === 'Filter' ? (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.POP,
                                this.state.initialPage,
                                2
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Filter
                            </button>
                          ) : (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.POP,
                                this.state.initialPage,
                                1
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Clear
                            </button>
                          )}
                          {/* <button
                            className="uk-button uk-button-xsmall button-out"
                            onClick={this.getTransactions.bind(
                              this,
                              CONSTANTS.TRANSACTION_TYPE.POP,
                              this.state.initialPage
                            )}
                            style={{ fontSize: '12px' }}
                          >
                            Filter
                          </button> */}
                        </caption>
                        <thead>
                          <tr>
                            <th className="theme-color">DATE</th>
                            <th className="theme-color">ITEM</th>
                            <th className="theme-color">
                              AMOUNT({cur_symbol})
                            </th>
                            <th className="theme-color">STATUS</th>
                          </tr>
                        </thead>
                        {this.state.transactionData.length > 0 ? (
                          <Contribution
                            type={CONSTANTS.TRANSACTION_TYPE.POP}
                            data={this.state.transactionData}
                            {...this.props}
                          />
                        ) : (
                          <tr>
                            <td>No records available</td>
                          </tr>
                        )}
                      </table>
                      <div className="uk-margin">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            this.state.totalRecords / CONSTANTS.RECORDS_PER_PAGE
                          }
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={this.handlePageClick.bind(
                            this,
                            CONSTANTS.TRANSACTION_TYPE.POP
                          )}
                          containerClassName={'uk-pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'uk-active'}
                          initialPage={this.state.initialPage}
                        />
                      </div>
                    </li>

                    <li className="uk-margin-right" data-uk-switcher-item="3">
                      <table class="uk-table">
                        <caption className="uk-text-center uk-h3 uk-vis@m">
                          <span className="uk-margin-right">From:</span>
                          <Field
                            name="startDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            endDate={this.state.endDate}
                            startDate={this.state.startDate}
                          />
                          <span className="uk-margin-left uk-margin-right">
                            To:
                          </span>
                          <Field
                            name="endDate"
                            component={DateRenderField}
                            type="text"
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                          />
                          &nbsp;&nbsp;&nbsp;
                          {this.state.buttonText === 'Filter' ? (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.ETC,
                                this.state.initialPage,
                                2
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Filter
                            </button>
                          ) : (
                            <button
                              className="uk-button uk-button-xsmall button-out"
                              onClick={this.getTransactions.bind(
                                this,
                                CONSTANTS.TRANSACTION_TYPE.ETC,
                                this.state.initialPage,
                                1
                              )}
                              style={{ fontSize: '12px' }}
                            >
                              Clear
                            </button>
                          )}
                          {/* <button
                            className="uk-button uk-button-xsmall button-out"
                            onClick={this.getTransactions.bind(
                              this,
                              CONSTANTS.TRANSACTION_TYPE.ETC,
                              this.state.initialPage
                            )}
                            style={{ fontSize: '12px' }}
                          >
                            Filter
                          </button> */}
                        </caption>
                        <thead>
                          <tr>
                            <th className="theme-color">DATE</th>
                            <th className="theme-color">FROM</th>
                            <th className="theme-color">TO</th>
                            <th className="theme-color">TYPE</th>
                            <th className="theme-color">STATUS</th>
                          </tr>
                        </thead>
                        {this.state.transactionData.length > 0 ? (
                          <Contribution
                            type={CONSTANTS.TRANSACTION_TYPE.ETC}
                            data={this.state.transactionData}
                          />
                        ) : (
                          <tr>
                            <td>No records available</td>
                          </tr>
                        )}
                      </table>
                      <div className="uk-margin">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={
                            this.state.totalRecords / CONSTANTS.RECORDS_PER_PAGE
                          }
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={this.handlePageClick.bind(
                            this,
                            CONSTANTS.TRANSACTION_TYPE.ETC
                          )}
                          containerClassName={'uk-pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'uk-active'}
                          initialPage={this.state.initialPage}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

Transactions = connect(mapStateToProps, null)(Transactions);

export default reduxForm({
  form: 'transactionForm',
  touchOnBlur: true,
  destroyOnUnmount: true
})(Transactions);
