import React from "react";
import { PerksContainer, PerkSingle, PerkSpan } from "./Styles";
import "./Styles.css";
import slide1 from "./slide1.svg";
import slide2 from "./slide2.svg";
import slide3 from "./slide3.svg";
import slide4 from "./slide4.svg";
import slide5 from "./slide5.svg";
import slide6 from "./slide6.svg";

export default function Perks() {
  return (
    <>
      {/* <PerksContainer>
        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">Loan Approval</PerkSpan>
            <PerkSpan className="perk-text-3">
              In <span style={{ color: "orange" }}>59 Seconds</span>
            </PerkSpan>
          </div>
        </PerkSingle>
        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>No</span> Interest
            </PerkSpan>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>No</span> Collateral
            </PerkSpan>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>No</span> Paperwork
            </PerkSpan>
          </div>
        </PerkSingle>
        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">Earn Weekly</PerkSpan>
            <PerkSpan className="perk-text-3">
              With Your <span style={{ color: "orange" }}>Trust Link</span>
            </PerkSpan>
          </div>
        </PerkSingle>
        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>Access</span> to
            </PerkSpan>
            <PerkSpan className="perk-text-3">Emergency Travel Cover</PerkSpan>
          </div>
        </PerkSingle>
        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>Access</span> to
            </PerkSpan>
            <PerkSpan className="perk-text-3">Purchase On Credit </PerkSpan>
            <PerkSpan className="perk-text-3">
              <span>(POP)</span>{" "}
            </PerkSpan>
          </div>
        </PerkSingle>

        <PerkSingle>
          <div>
            <PerkSpan className="perk-text-3">
              <span style={{ color: "orange" }}>FREE</span>
            </PerkSpan>
            <PerkSpan className="perk-text-3">. Medical Advisory</PerkSpan>
            <PerkSpan className="perk-text-3">. Legal Advisory</PerkSpan>
            <PerkSpan className="perk-text-3">. Business Advisory</PerkSpan>
          </div>
        </PerkSingle>
      </PerksContainer> */}

      <div class="codebreedslider">
        <div class="codebreedslide-track">
          <div class="codebreedslide">
            <img src={slide1} />
          </div>
          <div class="codebreedslide">
            <img src={slide2} />
          </div>
          <div class="codebreedslide">
            <img src={slide3} />
          </div>
          <div class="codebreedslide">
            <img src={slide4} />
          </div>
          <div class="codebreedslide">
            <img src={slide5} />
          </div>
          <div class="codebreedslide">
            <img src={slide6} />
          </div>
          <div class="codebreedslide">
            <img src={slide3} />
          </div>
          <div class="codebreedslide">
            <img src={slide4} />
          </div>
          <div class="codebreedslide">
            <img src={slide2} />
          </div>
          <div class="codebreedslide">
            <img src={slide2} />
          </div>
        </div>
      </div>
    </>
  );
}
