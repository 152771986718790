import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import SocketContext from "../routes/SocketContext";

import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "../common/helpers/Utils";
import { showErrorToast } from "../common/helpers/Toastr";
// import { actionLogout } from "../common/redux/actions/";
import CONSTANTS from "../common/helpers/Constants";
import People from "../assets/img/pple.png";
import VerifyCard from "../assets/img/bankcard.png";
import SilverPlan from "../assets/img/silver.png";
import GoldPlan from "../assets/img/gold.png";
import PlatinumPlan from "../assets/img/Plat.png";
import FlexiPlan from "../assets/img/Flex.png";
import ComingSoon from "../assets/img/coming-soon.png";
import Verified from "../assets/img/verified.png";
import PlanBlk from "../assets/img/plan-blk.png";
import Close from "../assets/img/close.png";

import trustFundApiService from "../common/api/ApiService";
import Logo from "../assets/img/svglogo.svg";
import * as qs from "query-string";
import notificationSound from "../assets/definite.mp3";
import {
  actionGetPlans,
  actionLogout,
  actionBuyPlan,
} from "../common/redux/actions/";

class LeftSidebar extends Component {
  static contextType = SocketContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      verifyEmail: false,
      buyPlan: false,
      comingSoon: false,
      businessExpertCount: 0,
      legalAdviserCount: 0,
      medicalAdviserCount: 0,
      incomingSocketData: "",

      isChosen5: false,
      isChosen3: false,
      isChosen2: false,
      isChosen1: false,

      planData: [],
      currentPlanName: "",
      selectedPlanId: "",
      flexiFrequencies: [],
      pf_pl_text: "",
      con_amount: "",
      membership_fee: 0.0,
      update_profile_user_info: "",
      ordinary_Id: 0,
    };
    this.logout = this.logout.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.getFlexiFrequencies = this.getFlexiFrequencies.bind(this);
    this.currentPlanInfo = this.currentPlanInfo.bind(this);
    this.getSocketMessage = this.getSocketMessage.bind(this);
    this.processPayment = this.processPayment.bind(this);
    this.getUnreadChatCount = this.getUnreadChatCount.bind(this);
    this.getUnreadChatCount = this.getUnreadChatCount.bind(this);
  }

  componentDidMount() {
    this.getPlans();
    this.getCardDetail();
    this.getSocketMessage();
    this.getUnreadChatCount();
    this.currentPlanInfo();
    this.getFlexiFrequencies();
    // if (this.props.location.pathname !== '/chat') {
    //   if (document.getElementById('purechat-container'))
    //     document.getElementById('purechat-container').style.display = 'none';
    // } else {
    //   if (document.getElementById('purechat-container'))
    //     document.getElementById('purechat-container').style.display = 'block';
    // }

    const { location } = this.props;
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed.reference && parsed.trxref) {
        if (parsed.reference == 2 && location.pathname == "/profile") {
          this.processPayment();
        }
      }
    }
  }

  componentWillReceiveProps(res) {}

  getSocketMessage() {
    const { location } = this.props;
    console.log(location);
    let _this = this;
    this.context.on("send_message", function (data) {
      if (data && location.pathname !== "/chat") {
        _this.getUnreadChatCount();
      }
    });
  }

  processPayment = () => {
    this.setState({
      loader: true,
    });
    let _this = this;
    trustFundApiService("paymentProcess2")
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;
          if (result && result.pr_response) {
            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              let paymentData = {
                payment_data: result.pr_response,
              };
              trustFundApiService("changeCardPaymentProcess2", paymentData)
                .then((response) => {
                  if (response.data.status === "success") {
                    // _this.props.history.push("/profile");
                    _this.setState({
                      loader: false,
                    });
                  } else {
                    _this.setState({
                      loader: false,
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };

  logout = () => {
    const _this = this;
    clearLocalStorage(function () {
      _this.props.history.push("/");
      _this.props.actionLogout();
    });
  };

  toggleModal1 = (name) => {
    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(name);
      console.log(plan.pl_plan_name);
      console.log(name == plan.pl_plan_name);

      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
    });

    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen1: !this.state.isChosen1,
    });
  };
  toggleModal2 = (name) => {
    console.log(name);

    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(name);
      console.log(plan.pl_plan_name);
      console.log(name == plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        isConfirm: false,
        isChosen2: !this.state.isChosen2,
      },
      () => {
        console.log(this.state.selectedPlanId);
      }
    );
  };
  toggleModal3 = (name) => {
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen3: !this.state.isChosen3,
    });
  };

  toggleModal5 = (name) => {
    console.log(name);
    console.log(this.state.planData);
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(name);
      console.log(plan.pl_plan_name);
      console.log(name == plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        con_amount: "",

        isChosen5: !this.state.isChosen5,
        isConfirm: false,
      },
      () => console.log(this.state.selectedPlanId)
    );
  };
  getPlans = () => {
    this.props
      .actionGetPlans()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          let ordinary_Id;
          data.map((plan) => {
            console.log(plan.pl_plan_name);
            if (plan.pl_plan_name == "BASIC") {
              ordinary_Id = plan.pl_plan_id;
            }
            console.log(ordinary_Id);
          });
          this.setState({
            ordinary_Id,
            planData: data.map((plan) => {
              return {
                pl_plan_id: plan.pl_plan_id,
                pl_plan_name: plan.pl_plan_name,
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  selectPlan = (type, val) => {
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "1",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "1",
      };
    }
    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount:
                this.state.pf_pl_text == "Daily" &&
                this.state.con_amount > 10000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Weekly" &&
                    this.state.con_amount > 100000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Monthly" &&
                    this.state.con_amount > 500000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.con_amount,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };
  currentPlanInfo = () => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.setState(
            {
              tfp_pin_status: data.u_tfp_pin_status,
              loan_pop_etc_status: data.loan_pop_etc_status,
              loan_pop_etc_rating: data.rating.loan_pop_etc_rating,
              pl_name: data.plan_info.pl_name,
              pl_id: data.plan_info.pl_id,
              pl_image: data.plan_info.pl_image,
              cashoutStatus: data.cashout_status,
              trustLink: response.data.data.u_trust_link,
            },
            () => {
              console.log(this.state.pl_name);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getFlexiFrequencies() {
    this.setState({
      loader: true,
    });
    trustFundApiService("getFlexiFrequencies").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          loader: false,
          flexiFrequencies: response.data.data,
          pf_pl_text: response.data.data[0].pf_pl_text,
        });
      } else {
        this.setState({
          loader: false,
        });
      }
    });
  }
  handleFlexiChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);

    var isnum =
      e.target.name == "con_amount" ? /^\d+$/.test(e.target.value) : true;

    this.setState(
      {
        [e.target.name]:
          e.target.name == "con_amount"
            ? e.target.value.replace(/[^0-9]/g, "")
            : e.target.value,
      },
      () => {
        // if (isnum && this.state.con_amount > 199) {
        this.selectPlan("subscribeFlexiPlan", 1);
        // }
      }
    );
  };

  verifyModal = () => {
    this.setState({
      verifyEmail: !this.state.verifyEmail,
    });
  };

  planModal = () => {
    this.setState({
      buyPlan: !this.state.buyPlan,
    });
  };

  bankTransfer = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/transfer");
  };

  paymentProcess = () => {
    let _this = this;
    console.log("paymentProcesspaymentProcess22222");

    trustFundApiService("paymentProcess2")
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;

          if (result && result.pr_response) {
            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              let paymentData = {
                planid: getLocalStorage("planId")
                  ? getLocalStorage("planId")
                  : this.state.selectedPlanId,
                payment_data: result.pr_response,
              };

              this.props
                .actionBuyPlan(paymentData)
                .then((response) => {
                  if (response.data.status === "success") {
                    if (getLocalStorage("referalKey")) {
                      console.log("dddd");
                      this.referTFP();
                    } else {
                      _this.setState({
                        loader: false,
                      });
                      _this.props.history.push({
                        pathname: "/trustpartner",
                        state: { fromRegister: _this.state.fromRegister },
                      });
                    }
                  } else {
                    _this.setState({
                      loader: false,
                    });
                    _this.props.history.push({
                      pathname: "/trustpartner",
                      state: { fromRegister: this.state.fromRegister },
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                  console.log(err);
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };

  referTFP = () => {
    let tfp_code = getLocalStorage("referalKey");
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    if (tfp_code && tfp_relationship) {
      let data = {
        u_relationship: tfp_relationship,
        u_tfp_code: "TFP" + tfp_code,
      };

      trustFundApiService("referTrustPartner", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.updateProcess();
            this.setState({
              loader: false,
            });
            setTimeout(
              function () {
                this.props.history.push({
                  pathname: "/dashboard",
                  state: { fromRegister: this.state.fromRegister },
                });
              }.bind(this),
              3000
            );
          } else {
            this.props.history.push({
              pathname: "/trustpartner",
              state: { fromRegister: this.state.fromRegister },
            });
            this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  updateProcess = () => {
    let data = {
      type: "both",
    };

    trustFundApiService("updateRegisterProcess", data);
  };

  comingSoonModal = () => {
    this.setState({
      comingSoon: !this.state.comingSoon,
    });
  };

  checkEligibility = (type) => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let isCardAdded = response.data.data.credit_card_info;
          let update_profile_user_info =
            response.data.data.update_profile_user_info;
          let profile_percentage =
            response.data.data.profile.profile_percentage;
          let plan_id = response.data.data.plan_info.pl_id;
          if (plan_id == this.state.ordinary_Id) {
            //make it dynamic
            this.planModal();
          } else if (isCardAdded === 0) {
            this.verifyModal();
          } else if (
            profile_percentage == "100" ||
            profile_percentage == "75"
          ) {
            this.props.history.push("/" + type);
          } else {
            this.verifyModal();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCardDetail = () => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let isCardAdded = response.data.data.credit_card_info;
          let update_profile_user_info =
            response.data.data.profile.update_profile_user_info;
          this.setState({
            isCardAdded,
            update_profile_user_info,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // let _this = this;
    // trustFundApiService('getCardDetail')
    //   .then(response => {
    //     if (response.data.status === 'success') {
    //       let data = response.data.data;
    //       this.setState({
    //         card_status: data.up_card_number,
    //         card_name: data.up_card_pin
    //       });
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  };

  changeCard = () => {
    trustFundApiService("changeCardPaymentProcess1", {
      page_response: "4",
    })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;
          if (result.authurl) {
            let authURL = result.authurl;
            window.location.href = authURL;
          }
        } else {
          this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log(err);
      });
  };

  getUnreadChatCount = () => {
    trustFundApiService("getUnreadChatCount")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          if (
            data.business_expert > 0 ||
            data.legal_adviser > 0 ||
            data.medical_adviser > 0
          ) {
            var x = document.getElementById("myAudio");
            x.play();
          }

          this.setState({
            businessExpertCount: data.business_expert,
            legalAdviserCount: data.legal_adviser,
            medicalAdviserCount: data.medical_adviser,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { location } = this.props;

    const homeClass = location.pathname === "/" ? "theme-color" : "";
    const dashboardClass = location.pathname.match(/^\/dashboard/)
      ? "theme-color"
      : "";
    const weeklycontributionClass = location.pathname.match(
      /^\/weeklycontribution/
    )
      ? "theme-color"
      : "";
    const upfrontcontributionClass = location.pathname.match(
      /^\/savemore/
    )
      ? "theme-color"
      : "";
    const weeklyearningsClass = location.pathname.match(/^\/transferearnings/)
      ? "theme-color"
      : "";
    const trustmembersClass = location.pathname.match(/^\/trustmembers/)
      ? "theme-color"
      : "";
    const buildnetworkClass = location.pathname.match(/^\/buildnetwork/)
      ? "theme-color"
      : "";
    const transactionsClass = location.pathname.match(/^\/transactions/)
      ? "theme-color"
      : "";
    const loanClass = location.pathname.match(/^\/loan/) ? "theme-color" : "";
    const trustwalletClass = location.pathname.match(/^\/trustwallet/)
      ? "theme-color"
      : "";
    const trustmarketClass = location.pathname.match(/^\/trustmarket/)
      ? "theme-color"
      : "";
    const popClass = location.pathname.match(/^\/pop()/) ? "theme-color" : "";
    const etcClass = location.pathname.match(/^\/etc/) ? "theme-color" : "";
    const expertsClass = location.pathname.match(/^\/experts/)
      ? "theme-color"
      : "";
    const chatClass = location.pathname.match(/^\/chat/) ? "theme-color" : "";
    const trustnetworkClass = location.pathname.match(/^\/trustnetwork/)
      ? "theme-color"
      : "";
    const notificationsClass = location.pathname.match(/^\/notifications/)
      ? "theme-color"
      : "";
    const profileClass = location.pathname.match(/^\/profile/)
      ? "theme-color"
      : "";

    return (
      <>
        <nav className="dash-sidebar uk-background-secondary uk-light uk-padding uk-visible@m">
          <audio id="myAudio">
            <source src={notificationSound} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <Modal
            className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.verifyEmail}
            onRequestClose={this.verifyModal}
          >
            {" "}
            {/* {!this.state.card_name || !this.state.card_status ? ( */}
            {this.state.isCardAdded === 0 ? (
              <div>
                <div className="uk-text-center uk-margin-top">
                  <img src={VerifyCard} width={400} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Manual Account (Add your Bank Card)
                  </h3>
                  <p>Please Add your Card to apply for Loan | POP | ETC</p>
                  <div className="uk-margin-bottom">
                    {/* {this.props.location.pathname === '/changecard' ? (
                      <a
                        onClick={this.verifyModal}
                        className="uk-button dash-button"
                      >
                        Add Card1
                      </a>
                    ) : ( */}
                    <a
                      onClick={this.changeCard}
                      className="uk-button dash-button"
                    >
                      Add Card
                    </a>
                    {/* )} */}
                  </div>
                  <a
                    onClick={this.verifyModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Close
                  </a>
                </div>
              </div>
            ) : (
              // ) : this.state.update_profile_user_info == 0 ? (
              <div>
                <div className="uk-text-center uk-margin-top">
                  <img src={People} width={400} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Welcome to the Trust Community
                  </h3>
                  <p>
                    Please update your profile to apply for Loan | POP | ETC
                  </p>
                  <div className="uk-margin-bottom">
                    <Link className="uk-button dash-button" to="/profile">
                      Update Profile
                    </Link>
                  </div>
                  <a
                    onClick={this.verifyModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Close
                  </a>
                </div>
              </div>
              // ) : (
              //   ""
            )}
          </Modal>

          {/*Buy Plan*/}
          <Modal
            className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.buyPlan}
            onRequestClose={this.planModal}
          >
            {" "}
            <div className="uk-text-center uk-margin-top">
              <h3 className="uk-text-lead theme-color">
                UPGRADE PLAN TO ENJOY THESE BENEFITS
              </h3>

              <div class="uk-column-1-2@s uk-column-1-3@m uk-column-1-4@l">
                <p>
                  <img src={PlanBlk} width={100} alt="" />
                  <br />
                  <span className="theme-color">
                    PLATINUM
                    <br />
                  </span>
                  <Link
                    onClick={() => this.toggleModal3("PLATINUM")}
                    className="uk-button uk-button-small buy-button"
                  >
                    Start Plan
                  </Link>
                </p>
                <p>
                  <img src={PlanBlk} width={100} alt="" />
                  <br />
                  <span className="theme-color">
                    GOLD
                    <br />
                  </span>
                  <Link
                    onClick={() => this.toggleModal2("GOLD")}
                    className="uk-button uk-button-small buy-button"
                  >
                    Start Plan
                  </Link>
                </p>
                <p>
                  <img src={PlanBlk} width={100} alt="" />
                  <br />
                  <span className="theme-color">
                    SILVER
                    <br />
                  </span>
                  <Link
                    onClick={() => this.toggleModal1("SILVER")}
                    className="uk-button uk-button-small buy-button"
                  >
                    Start Plan
                  </Link>
                </p>
                <p>
                  <img src={PlanBlk} width={100} alt="" />
                  <br />
                  <span className="theme-color">
                    FLEXI
                    <br />
                  </span>
                  <Link
                    onClick={() => this.toggleModal5("FLEXI")}
                    className="uk-button uk-button-small buy-button"
                  >
                    Start Plan
                  </Link>
                </p>
              </div>
              <Link
                className="uk-button dash-button uk-margin-top"
                to="/weeklyearnings"
              >
                Upgrade Plan with Earnings
              </Link>
            </div>
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <a
                onClick={this.planModal}
                className="uk-position-relative link uk-position-z-index"
              >
                <img src={Close} className="close-icon" alt="close" />
              </a>
            </div>
          </Modal>

          {/*Buy Plan*/}

          {/*Coming Soon*/}
          <Modal
            className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.comingSoon}
            onRequestClose={this.comingSoonModal}
          >
            {" "}
            <div className="uk-text-center uk-margin-top">
              <h3 className="uk-text-lead theme-color">
                Trust Market
                <p className="uk-text-meta uk-margin-small-top">
                  ...Trust It, Share It
                </p>
              </h3>
              <img src={ComingSoon} width={400} alt="" />
            </div>
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <a
                onClick={this.comingSoonModal}
                className="uk-position-relative link uk-position-z-index"
              >
                Close
              </a>
            </div>
          </Modal>

          {/* Toogle Modal */}

          <Modal
            className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.isChosen5}
            onRequestClose={this.toggleModal5}
          >
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <h3 className="uk-text-lead theme-color">
                Set Flexi Amount & Frequency
              </h3>

              <div data-uk-grid>
                <div class="uk-width-1-2">
                  <div className="uk-margin">
                    <div className="uk-form-controls">
                      <label className="uk-form-label-dash">
                        Contribution Amount (₦)
                      </label>
                      <input
                        className="uk-input"
                        name="con_amount"
                        type="text"
                        value={this.state.con_amount}
                        onChange={(e) => this.handleFlexiChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div className="uk-margin">
                    <div className="uk-form-controls">
                      <label className="uk-form-label-dash">
                        Select Frequency
                      </label>
                      <select
                        className="uk-select"
                        name="pf_pl_text"
                        type="text"
                        onChange={(e) => this.handleFlexiChange(e)}
                      >
                        {this.state.flexiFrequencies &&
                          this.state.flexiFrequencies.map((resp, index) => {
                            return (
                              <option
                                key={index}
                                value={resp.pf_pl_text}
                                id={resp.pf_id}
                              >
                                {resp.pf_pl_text}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <h4>
                Membership Fee: <span className="theme-color"></span>₦
                {this.state.membership_fee}
              </h4>

              <a
                onClick={this.selectPlan.bind(this, "subscribeFlexiPlan", 2)}
                className="uk-button dash-button"
              >
                Pay with Card
              </a>
              <br />
              <span className="modal-text">
                <img width="10" src={Verified} />{" "}
                <i>Your Dashboard will be created in few minutes</i>
              </span>
              <br />
              <br />

              <a
                onClick={this.toggleModal5}
                className="uk-position-relative link uk-position-z-index theme-color"
              >
                Close
              </a>
            </div>
          </Modal>
          <Modal
            className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.isChosen1}
            onRequestClose={this.toggleModal1}
          >
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <h3 className="uk-text-lead theme-color">
                Select Payment Method
              </h3>
              <a
                onClick={this.selectPlan.bind(this, "paymentProcess1")}
                className="uk-button dash-button"
              >
                Pay with Card
              </a>
              <br />
              <span className="modal-text">
                <img width="10" src={Verified} />{" "}
                <i>
                  To access Dashboard instantly and for seamless weekly
                  contributions
                </i>
              </span>
              <br />
              <br />
              OR
              <br />
              <br />
              <a
                onClick={this.bankTransfer.bind(
                  this,
                  // plan.pl_plan_id
                  1
                )}
                className="uk-button button-out"
              >
                Pay via Bank Transfer
              </a>
              <br />
              <span className="modal-text">
                <i>
                  This method may take up to 24hrs to access dashboard
                  <br /> and a proof of paymemt may be required.
                </i>
              </span>
              <br />
              <br />
              <a
                onClick={this.toggleModal1}
                className="uk-position-relative link uk-position-z-index theme-color"
              >
                Close
              </a>
            </div>
          </Modal>
          <Modal
            className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.isChosen2}
            onRequestClose={this.toggleModal2}
          >
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <h3 className="uk-text-lead theme-color">
                Select Payment Method
              </h3>
              <a
                onClick={this.selectPlan.bind(this, "paymentProcess1")}
                className="uk-button dash-button"
              >
                Pay with Card
              </a>
              <br />
              <span className="modal-text">
                <img width="10" src={Verified} />{" "}
                <i>
                  To access Dashboard instantly and for seamless weekly
                  contributions
                </i>
              </span>
              <br />
              <br />
              OR
              <br />
              <br />
              <a
                onClick={this.bankTransfer.bind(
                  this,
                  // plan.pl_plan_id
                  2
                )}
                className="uk-button button-out"
              >
                Pay via Bank Transfer
              </a>
              <br />
              <span className="modal-text">
                <i>
                  This method may take up to 24hrs to access dashboard
                  <br /> and a proof of paymemt may be required.
                </i>
              </span>
              <br />
              <br />
              <a
                onClick={this.toggleModal2}
                className="uk-position-relative link uk-position-z-index theme-color"
              >
                Close
              </a>
            </div>
          </Modal>

          <Modal
            className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
            isOpen={this.state.isChosen3}
            onRequestClose={this.toggleModal3}
          >
            <div className="uk-text-center uk-margin-right uk-margin-left">
              <h3 className="uk-text-lead theme-color">
                Select Payment Method
              </h3>
              <a
                onClick={this.selectPlan.bind(this, "paymentProcess1")}
                className="uk-button dash-button"
              >
                Pay with Card
              </a>
              <br />
              <span className="modal-text">
                <img width="10" src={Verified} />{" "}
                <i>
                  To access Dashboard instantly and for seamless weekly
                  contributions
                </i>
              </span>
              <br />
              <br />
              OR
              <br />
              <br />
              <a
                onClick={this.bankTransfer.bind(
                  this,
                  // plan.pl_plan_id
                  3
                )}
                className="uk-button button-out"
              >
                Pay via Bank Transfer
              </a>
              <br />
              <span className="modal-text">
                <i>
                  This method may take up to 24hrs to access dashboard
                  <br /> and a proof of paymemt may be required.
                </i>
              </span>
              <br />
              <br />
              <a
                onClick={this.toggleModal3}
                className="uk-position-relative link uk-position-z-index theme-color"
              >
                Close
              </a>
            </div>
          </Modal>

          {/*Coming Soon*/}

          <ul className="uk-nav">
            <li className="nav-list">
              <Link
                className={dashboardClass}
                activeClassName="active"
                to="/dashboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 27.507 27.506"
                >
                  <path d="M18.655,27.506a4,4,0,0,1-4-4V14.7a4,4,0,0,1,4-4h4.851a4,4,0,0,1,4,4V23.506a4,4,0,0,1-4,4Zm-15.32-.04a3.294,3.294,0,0,1,0-6.588H9.6a3.294,3.294,0,0,1,0,6.588ZM4,19.81a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H8.852a4,4,0,0,1,4,4V15.81a4,4,0,0,1-4,4ZM17.95,6.588A3.294,3.294,0,1,1,17.95,0h6.263a3.294,3.294,0,1,1,0,6.588Z" />
                </svg>{" "}
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="nav-list">
              <Link
                className={weeklycontributionClass}
                activeClassName="active"
                // to="/weeklycontribution"
                onClick={this.checkEligibility.bind(this, "weeklycontribution")}
              >
                <i className="fa fa-calendar-o" aria-hidden="true" />
                <span>Weekly Contribution</span>
              </Link>
            </li>
            {this.props.type === "adminLogin" ? null : (
              <span>
                <li className="nav-list">
                  <Link
                    className={weeklyearningsClass}
                    activeClassName="active"
                    // onClick={this.checkEligibility.bind(
                    //   this,
                    //   "weeklyearnings"
                    // )}
                    to="/weeklyearnings"
                  >
                    <i className="fa fa-calendar-plus-o" aria-hidden="true" />
                    <span>Weekly Earnings</span>
                  </Link>
                </li>
                <li className="nav-list">
                  <Link
                    className={upfrontcontributionClass}
                    activeClassName="active"
                    // to="/savemore"
                    onClick={this.checkEligibility.bind(
                      this,
                      "savemore"
                    )}
                  >
                    <svg
                      version="1.1"
                      className="contri"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="557.896px"
                      height="322.808px"
                      viewBox="0 0 557.896 322.808"
                      enableBackground="new 0 0 557.896 322.808"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          fill="#F2F2F2"
                          d="M556.148,82.237C547.354,35.423,506.265,0,456.896,0H101C51.632,0,10.543,35.423,1.747,82.237H556.148z"
                        />
                        <path
                          fill="#F2F2F2"
                          d="M0,124.342v97.465c0,55.78,45.22,101,101,101h355.896c55.781,0,101-45.22,101-101v-97.465H0z
                           M196.492,273.465H43.86v-35.088h152.632V273.465z"
                        />
                      </g>
                    </svg>
                    <span> Save More</span>
                  </Link>
                </li>
              </span>
            )}

            <li className="nav-list">
              <Link
                className={buildnetworkClass}
                activeClassName="active"
                to="/buildnetwork"
              >
                <i className="fa fa-user-plus" aria-hidden="true" />
                <span>Build Trust Network</span>
              </Link>
            </li>
            <li className="nav-list">
              <Link
                className={trustmembersClass}
                activeClassName="active"
                to="/trustmembers"
              >
                <i className="fa fa-handshake-o" aria-hidden="true" />
                <span>My Trust Network</span>
              </Link>
            </li>
            <li className="nav-list">
              <Link
                className={transactionsClass}
                activeClassName="active"
                to="/transactions"
              >
                <i className="fa fa-list-ol" aria-hidden="true" />
                <span>Transactions</span>
              </Link>
            </li>
            <li className="nav-list">
              <a
                className={loanClass}
                activeClassName="active"
                onClick={this.checkEligibility.bind(this, "loan")}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="50px"
                  height="50px"
                  viewBox="0 0 256.225 302.968"
                  enableBackground="new 0 0 256.225 302.968"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      fill="#F2F2F2"
                      d="M87.381,75.666h94.737c0,0,43.859-45.614,36.842-63.158s-163.158-15.79-175.438,0
                          S87.381,75.666,87.381,75.666z"
                    />
                    <path
                      fill="#F2F2F2"
                      d="M182.881,87.9h-99.16c0,0-92.724,127.458-83.007,176.48c9.716,49.02,236.333,51.264,253.334,7.145
                          C271.051,227.403,182.881,87.9,182.881,87.9z"
                    />
                  </g>
                </svg>{" "}
                <span>Get a Loan</span>
              </a>
            </li>
            <li className="nav-list">
              <Link
                className={trustwalletClass}
                activeClassName="active"
                to="/trustwallet"
              >
                <i className="fa fa-folder-open" aria-hidden="true" />
                <span>My Trust Wallet</span>
              </Link>
            </li>
            <li className="nav-list">
              <a
                className={trustmarketClass}
                activeClassName="active"
                // to="/trustmarket"
                onClick={this.comingSoonModal}
              >
                <i className="fa fa-line-chart" aria-hidden="true" />
                <span>Trust Market</span>
              </a>
              {/* <Link
                className={trustmarketClass}
                activeClassName="active"
                to="/trustmarket"
                //onClick={this.comingSoonModal}
              >
                <i className="fa fa-line-chart" aria-hidden="true" />
                <span>Trust Market</span>
              </Link> */}
            </li>
            <li className="nav-list">
              <a
                className={popClass}
                activeClassName="active"
                onClick={this.checkEligibility.bind(this, "pop")}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
                <span>Apply for P.O.P</span>
              </a>
            </li>
            <li className="nav-list">
              <a
                className={etcClass}
                activeClassName="active"
                onClick={this.checkEligibility.bind(this, "etc")}
              >
                <i className="fa fa-plane" aria-hidden="true" />
                <span>Apply for E.T.C</span>
              </a>
            </li>
            {/*<li className="nav-list">
              <Link  className={expertsClass} activeClassName="active" to="/experts">
                <i className="fa fa-comments" aria-hidden="true" />Chat With ::
                Expert
              </Link>
            </li>*/}
            
            {/* Old chat links */}
            {/* <li className="nav-list">

            
            <li className="nav-list">
              <Link
                className={chatClass}
                activeClassName="active"
                to={{
                  pathname: "/chat",
                  state: {
                    type: CONSTANTS.CHAT_TYPE.BUSINESS_EXPERT,
                    expertTitle: "Business Expert",
                    //onSocketData: this.state.incomingSocketData
                  },
                }}
              >
                <i className="fa fa-briefcase" aria-hidden="true" />
                <span>
                  Business Expert &nbsp;
                  <span className="uk-badge">
                    {this.state.businessExpertCount || "0"}
                  </span>
                </span>
              </Link>
            </li>
              <Link
                className={chatClass}
                activeClassName="active"
                to={{
                  pathname: "/chat",
                  state: {
                    type: CONSTANTS.CHAT_TYPE.LEGAL_ADVISER,
                    expertTitle: "Legal Advice",
                    // onSocketData: this.state.incomingSocketData
                  },
                }}
              >
                <i className="fa fa-gavel" aria-hidden="true" />
                <span>
                  Legal Advice &nbsp;
                  <span className="uk-badge">
                    {this.state.legalAdviserCount || "0"}
                  </span>
                </span>
              </Link>
            </li>
            <li className="nav-list">
              <Link
                className={chatClass}
                activeClassName="active"
                to={{
                  pathname: "/chat",
                  state: {
                    type: CONSTANTS.CHAT_TYPE.MEDICAL_ADVISER,
                    expertTitle: "Medical Advice",
                    //onSocketData: this.state.incomingSocketData
                  },
                }}
              >
                <i className="fa fa-stethoscope" aria-hidden="true" />
                <span>
                  Medical Advice &nbsp;
                  <span className="uk-badge">
                    {this.state.medicalAdviserCount || "0"}
                  </span>
                </span>
              </Link>
            </li> */}
            {/* End Old chat links */}
            
            {/* Whatsapp chat links */}
            
            <li className="nav-list">
              <a
                className={chatClass}
                activeClassName="active"
                href="https://chat.whatsapp.com/LswwblLAcp6DrZE4AN6Mfg"
              >
                <i className="fa fa-briefcase" aria-hidden="true" />
                <span>
                  Business Expert &nbsp;
                  {/* <span className="uk-badge">
                    {this.state.businessExpertCount || "0"}
                  </span> */}
                </span>
              </a>
            </li>
            <li className="nav-list">
              <a
                className={chatClass}
                activeClassName="active"
                href="https://chat.whatsapp.com/Evl6OA3Zs4K7B7ahdfJRDd"
              >
                <i className="fa fa-gavel" aria-hidden="true" />
                <span>
                  Legal Advice &nbsp;
                  {/* <span className="uk-badge">
                    {this.state.legalAdviserCount || "0"}
                  </span> */}
                </span>
              </a>
            </li>
            <li className="nav-list">
              <a
                className={chatClass}
                activeClassName="active"
                href="https://chat.whatsapp.com/LlK8GwuqVb701Nqy1OylHN"
              >
                <i className="fa fa-stethoscope" aria-hidden="true" />
                <span>
                  Medical Advice &nbsp;
                  {/* <span className="uk-badge">
                    {this.state.medicalAdviserCount || "0"}
                  </span> */}
                </span>
              </a>
            </li>
            {/* End Whatsapp chat links */}
            <li className="nav-list">
              <Link
                className={trustnetworkClass}
                activeClassName="active"
                to="/trustnetwork"
              >
                <i className="fa fa-users" aria-hidden="true" />
                <span>Invite People</span>
              </Link>
            </li>
            <li className="nav-list">
              <Link
                className={notificationsClass}
                activeClassName="active"
                to="/notifications"
              >
                <i className="fa fa-bell" aria-hidden="true" />
                <span>
                  Notifications{" "}
                  <span className="uk-badge">
                    {this.props.userData.notification_count || "0"}
                  </span>
                </span>
              </Link>
            </li>
            {this.props.type === "adminLogin" ? null : (
              <li className="nav-list">
                <Link
                  className={profileClass}
                  activeClassName="active"
                  to="/profile"
                >
                  <i className="fa fa-user" aria-hidden="true" />
                  <span>User Profile</span>
                </Link>
              </li>
            )}
            <li className="nav-list">
              <a onClick={this.logout}>
                <i className="fa fa-cog" aria-hidden="true" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </nav>
        ,
        <div id="offcanvas-menu" uk-offcanvas="overlay: true">
          <div className="uk-offcanvas-bar">
            <a class="uk-offcanvas-close" type="button" data-uk-close></a>
            <div className="">
              <Link to="/dashboard">
                <img src={Logo} className="dash-icon" alt="icon" />
              </Link>
            </div>
            <ul className="uk-nav-mobile">
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link activeClassName="active" to="/dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 27.507 27.506"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: ".a{fill:#e7bc0e;}",
                        }}
                      />
                    </defs>
                    <path
                      className="a"
                      d="M18.655,27.506a4,4,0,0,1-4-4V14.7a4,4,0,0,1,4-4h4.851a4,4,0,0,1,4,4V23.506a4,4,0,0,1-4,4Zm-15.32-.04a3.294,3.294,0,0,1,0-6.588H9.6a3.294,3.294,0,0,1,0,6.588ZM4,19.81a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H8.852a4,4,0,0,1,4,4V15.81a4,4,0,0,1-4,4ZM17.95,6.588A3.294,3.294,0,1,1,17.95,0h6.263a3.294,3.294,0,1,1,0,6.588Z"
                    />
                  </svg>
                  <span className={dashboardClass}>
                    <span className="m-nav-tab">Dashboard</span>
                  </span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={weeklycontributionClass}
                  activeClassName="active"
                  // to="/weeklycontribution"
                  onClick={this.checkEligibility.bind(
                    this,
                    "weeklycontribution"
                  )}
                >
                  <i className="fa fa-calendar-o" aria-hidden="true" />
                  <span className="m-nav-tab">Weekly Contribution</span>
                </Link>
              </li>
              {this.props.type === "adminLogin" ? null : (
                <span>
                  <li
                    className="nav-list-mobile"
                    data-uk-toggle="target: #offcanvas-menu"
                  >
                    <Link
                      className={weeklyearningsClass}
                      activeClassName="active"
                      // onClick={this.checkEligibility.bind(
                      //   this,
                      //   "weeklyearnings"
                      // )}
                      to="/weeklyearnings"
                    >
                      <i className="fa fa-calendar-plus-o" aria-hidden="true" />
                      <span className="m-nav-tab">Weekly Earnings</span>
                    </Link>
                  </li>
                  <li
                    className="nav-list-mobile"
                    data-uk-toggle="target: #offcanvas-menu"
                  >
                    <Link
                      className={upfrontcontributionClass}
                      activeClassName="active"
                      // to="/savemore"
                      onClick={this.checkEligibility.bind(
                        this,
                        "savemore"
                      )}
                    >
                      <svg
                        version="1.1"
                        className="contri"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="557.896px"
                        height="322.808px"
                        viewBox="0 0 557.896 322.808"
                        enableBackground="new 0 0 557.896 322.808"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            fill="#F2F2F2"
                            d="M556.148,82.237C547.354,35.423,506.265,0,456.896,0H101C51.632,0,10.543,35.423,1.747,82.237H556.148z"
                          />
                          <path
                            fill="#F2F2F2"
                            d="M0,124.342v97.465c0,55.78,45.22,101,101,101h355.896c55.781,0,101-45.22,101-101v-97.465H0z
                         M196.492,273.465H43.86v-35.088h152.632V273.465z"
                          />
                        </g>
                      </svg>
                      <span className="m-nav-tab"> Save More</span>
                    </Link>
                  </li>
                </span>
              )}
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={buildnetworkClass}
                  activeClassName="active"
                  to="/buildnetwork"
                >
                  <i className="fa fa-user-plus" aria-hidden="true" />
                  <span className="m-nav-tab">Build Trust Network</span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={trustmembersClass}
                  activeClassName="active"
                  to="/trustmembers"
                >
                  <i className="fa fa-handshake-o" aria-hidden="true" />
                  <span className="m-nav-tab">My Trust Network</span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={transactionsClass}
                  activeClassName="active"
                  to="/transactions"
                >
                  <i className="fa fa-list-ol" aria-hidden="true" />
                  <span className="m-nav-tab">Transactions</span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={loanClass}
                  activeClassName="active"
                  onClick={this.checkEligibility.bind(this, "loan")}
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="50px"
                    height="50px"
                    viewBox="0 0 256.225 302.968"
                    enableBackground="new 0 0 256.225 302.968"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        fill="#F2F2F2"
                        d="M87.381,75.666h94.737c0,0,43.859-45.614,36.842-63.158s-163.158-15.79-175.438,0
                        S87.381,75.666,87.381,75.666z"
                      />
                      <path
                        fill="#F2F2F2"
                        d="M182.881,87.9h-99.16c0,0-92.724,127.458-83.007,176.48c9.716,49.02,236.333,51.264,253.334,7.145
                        C271.051,227.403,182.881,87.9,182.881,87.9z"
                      />
                    </g>
                  </svg>
                  <span className="m-nav-tab">Get a Loan</span>
                </a>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={trustwalletClass}
                  activeClassName="active"
                  to="/trustwallet"
                >
                  <i className="fa fa-folder-open" aria-hidden="true" />
                  <span className="m-nav-tab">My Trust Wallet</span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={trustmarketClass}
                  activeClassName="active"
                  // to="/trustmarket"
                  onClick={this.comingSoonModal}
                >
                  <i className="fa fa-line-chart" aria-hidden="true" />
                  <span className="m-nav-tab">Trust Market</span>
                </a>
                {/* <Link
                  className={trustmarketClass}
                  activeClassName="active"
                  // to="/trustmarket"
                  onClick={this.comingSoonModal}
                >
                  <i className="fa fa-line-chart" aria-hidden="true" />
                  <span className="m-nav-tab">Trust Market</span>
                </Link> */}
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={popClass}
                  activeClassName="active"
                  onClick={this.checkEligibility.bind(this, "pop")}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                  <span className="m-nav-tab">Apply for P.O.P</span>
                </a>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={etcClass}
                  activeClassName="active"
                  onClick={this.checkEligibility.bind(this, "etc")}
                >
                  <i className="fa fa-plane" aria-hidden="true" />
                  <span className="m-nav-tab">Apply for E.T.C</span>
                </a>
              </li>
              
                {/* Mobile chat links */}
              {/* <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={chatClass}
                  activeClassName="active"
                  to={{
                    pathname: "/chat",
                    state: {
                      type: CONSTANTS.CHAT_TYPE.BUSINESS_EXPERT,
                      expertTitle: "Business Expert",
                      //onSocketData: this.state.incomingSocketData
                      // socketConnect: this.socket
                    },
                  }}
                >
                  <i className="fa fa-comments" aria-hidden="true" />
                  <span className="m-nav-tab">Business Expert</span>
                  <span className="uk-badge">
                    {this.state.businessExpertCount || "0"}
                  </span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={chatClass}
                  activeClassName="active"
                  to={{
                    pathname: "/chat",
                    state: {
                      type: CONSTANTS.CHAT_TYPE.LEGAL_ADVISER,
                      expertTitle: "Legal Advice",
                      // onSocketData: this.state.incomingSocketData,
                      // socketConnect: this.socket
                    },
                  }}
                >
                  <i className="fa fa-gavel" aria-hidden="true" />
                  <span className="m-nav-tab">Legal Advice</span>
                  <span className="uk-badge">
                    {this.state.legalAdviserCount || "0"}
                  </span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={chatClass}
                  activeClassName="active"
                  to={{
                    pathname: "/chat",
                    state: {
                      type: CONSTANTS.CHAT_TYPE.MEDICAL_ADVISER,
                      expertTitle: "Medical Advice",
                      // onSocketData: this.state.incomingSocketData,
                      // socketConnect: this.socket
                    },
                  }}
                >
                  <i className="fa fa-stethoscope" aria-hidden="true" />
                  <span className="m-nav-tab">Medical Advice</span>
                  <span className="uk-badge">
                    {this.state.medicalAdviserCount || "0"}
                  </span>
                </Link>
              </li> */}
                {/* End Mobile chat links */}

                {/* Mobile Whatsapp links */}
                <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={chatClass}
                  activeClassName="active"
                  
                href="https://chat.whatsapp.com/LswwblLAcp6DrZE4AN6Mfg"
                >
                  <i className="fa fa-comments" aria-hidden="true" />
                  <span className="m-nav-tab">Business Expert</span>
                  {/* <span className="uk-badge">
                    {this.state.businessExpertCount || "0"}
                  </span> */}
                </a>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={chatClass}
                  activeClassName="active"
                  
                href="https://chat.whatsapp.com/Evl6OA3Zs4K7B7ahdfJRDd"
                >
                  <i className="fa fa-gavel" aria-hidden="true" />
                  <span className="m-nav-tab">Legal Advice</span>
                  {/* <span className="uk-badge">
                    {this.state.legalAdviserCount || "0"}
                  </span> */}
                </a>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <a
                  className={chatClass}
                  activeClassName="active"
                  
                href="https://chat.whatsapp.com/LlK8GwuqVb701Nqy1OylHN"
                >
                  <i className="fa fa-stethoscope" aria-hidden="true" />
                  <span className="m-nav-tab">Medical Advice</span>
                  {/* <span className="uk-badge">
                    {this.state.medicalAdviserCount || "0"}
                  </span> */}
                </a>
              </li>

            {/* End Mobile whatsapp links */}

              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={trustnetworkClass}
                  activeClassName="active"
                  to="/trustnetwork"
                >
                  <i className="fa fa-users" aria-hidden="true" />
                  <span className="m-nav-tab">Invite People</span>
                </Link>
              </li>
              <li
                className="nav-list-mobile"
                data-uk-toggle="target: #offcanvas-menu"
              >
                <Link
                  className={notificationsClass}
                  activeClassName="active"
                  to="/notifications"
                >
                  <i className="fa fa-bell" aria-hidden="true" />
                  <span className="m-nav-tab">Notifications</span>
                  <span className="uk-badge">
                    {this.props.userData.notification_count || "0"}
                  </span>
                </Link>
              </li>
              {this.props.type === "adminLogin" ? null : (
                <li
                  className="nav-list-mobile"
                  data-uk-toggle="target: #offcanvas-menu"
                >
                  <Link
                    className={profileClass}
                    activeClassName="active"
                    to="/profile"
                  >
                    <i className="fa fa-user" aria-hidden="true" />
                    <span className="m-nav-tab">User Profile</span>
                  </Link>
                </li>
              )}
              <li className="nav-list-mobile">
                <a onClick={this.logout}>
                  <i className="fa fa-cog" aria-hidden="true" />
                  <span
                    className="m-nav-tab"
                    data-uk-toggle="target: #offcanvas-menu"
                  >
                    Logout
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

// export default connect(mapStateToProps, { actionLogout })
export default connect(mapStateToProps, {
  actionLogout,
  actionGetPlans,
  actionBuyPlan,
})(LeftSidebar);
