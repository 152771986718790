import React, { Component } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import moment from "moment";
//import Sound from 'react-sound';
import soundfile from "../assets/definite.mp3";
import Verified from "../assets/img/verified.png";
import TrustInvestImg from "../assets/img/trustinvest1.jpeg";
import CONSTANTS from "../common/helpers/Constants";

import "../assets/css/main.css";
import Header from "../common/Header";
import LeftSidebar from "../common/LeftSidebar";
import RightSidebar from "../common/RightSidebar";
import Notification from "../common/Notification";
import Platinum from "../assets/img/Plat.png";
import Silver from "../assets/img/silver.png";
import Gold from "../assets/img/gold.png";
import SilverPlan from "../assets/img/silver.png";
import GoldPlan from "../assets/img/gold.png";
import PlatinumPlan from "../assets/img/Plat.png";
import FlexiPlan from "../assets/img/Flex.png";
import Close from "../assets/img/close.png";
import PlanBlk from "../assets/img/plan-blk.png";
import DashUpgrade from "../assets/img/upgrade-dash.png";
import Smiley from "../assets/img/emoticons/smiley.png";
import People from "../assets/img/pple.png";
import Locked from "../assets/img/locked.png";
import trustFundApiService from "../common/api/ApiService";
import * as qs from "query-string";

import {
  formatNumber,
  capitalizeFirstLetter,
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "../common/helpers/Utils";
import DynaLogo from "../assets/img/aye-loader.gif";
import { showErrorToast } from "../common/helpers/Toastr";
import {
  actionGetPlans,
  actionLogout,
  actionBuyPlan,
} from "../common/redux/actions/";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conAmount: [],
      conDate: [],
      contribution: 0,
      reg_date: "",
      earnings: 0,
      loans: 0,
      currentLoan: 0,
      loader: false,
      //loader1: false,
      // loader2: false,
      isConfirm: false,
      isActive: false,
      verifyEmail: false,
      isRepay: false,
      isWithdraw: false,
      accountLocked: false,
      message: "",
      message2: "",
      title: "",
      memberTitle: "",
      shouldCloseOnOverlayClick: true,
      loanStatus: 0,
      cashoutStatus: 0,
      trustLink: "",
      copySuccess: "",
      buyPlan: false,

      isChosen5: false,
      isChosen3: false,
      isChosen2: false,
      isChosen1: false,

      trustInvest: false,

      planData: [],
      currentPlanName: "",
      selectedPlanId: "",
      flexiFrequencies: [],
      pf_pl_text: "",
      con_amount: "",
      membership_fee: 0.0,
      ordinary_Id: 0,

      cannotCashout: false,
    };
  }

  componentDidMount() {
    document.title = "Dashboard - If You TRUST Me I'll TRUST You";
    if (getLocalStorage("userInfo")) {
      if (this.props.userData.u_tfp_pin && this.props.userData.u_id) {
        this.getContribution();
        this.getTrustWallet();
        this.currentPlanInfo();
        this.getFlexiFrequencies();
        this.getPlans();
        if (
          this.props.userData.u_is_verified === 0 &&
          this.props.userData.u_exceed_verfied_limit === 1
        ) {
          this.setState({
            shouldCloseOnOverlayClick: false,
          });
          this.verifyModal();
        }

        const { location } = this.props;
        if (location) {
          const parsed = qs.parse(location.search);
          if (parsed.reference && parsed.trxref) {
            if (parsed.reference == 2) {
              this.paymentProcess();
            }
          }
        }
      } else {
        this.props.history.push("/trustplans");
      }
    } else {
      this.props.history.push("/login");
    }
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState({
          verifyEmail: true,
        });
      }
    });
    if (this.props.userData.u_status === 3) {
      this.setState({
        accountLocked: true,
      });
      this.lockedModal();
    }
  }

  toggleModal1 = (name) => {
    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
    });

    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen1: !this.state.isChosen1,
    });
  };
  toggleModal2 = (name) => {
    console.log(name);

    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        isConfirm: false,
        isChosen2: !this.state.isChosen2,
      },
      () => {
        console.log(this.state.selectedPlanId);
      }
    );
  };
  toggleModal3 = (name) => {
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen3: !this.state.isChosen3,
    });
  };

  toggleModal5 = (name) => {
    console.log(name);
    console.log(this.state.planData);
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        con_amount: "",

        isChosen5: !this.state.isChosen5,
        isConfirm: false,
      },
      () => console.log(this.state.selectedPlanId)
    );
  };
  getPlans = () => {
    this.props
      .actionGetPlans()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          let ordinary_Id;
          data.map((plan) => {
            console.log(plan.pl_plan_name);
            if (plan.pl_plan_name == "ORDINARY") {
              ordinary_Id = plan.pl_plan_id;
            }
            console.log(ordinary_Id);
          });
          this.setState({
            ordinary_Id,
            planData: data.map((plan) => {
              return {
                pl_plan_id: plan.pl_plan_id,
                pl_plan_name: plan.pl_plan_name,
              };
            }),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  selectPlan = (type, val) => {
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "1",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "1",
      };
    }
    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount:
                this.state.pf_pl_text == "Daily" &&
                this.state.con_amount > 10000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Weekly" &&
                    this.state.con_amount > 100000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Monthly" &&
                    this.state.con_amount > 500000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.con_amount,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };
  getFlexiFrequencies() {
    this.setState({
      loader: true,
    });
    trustFundApiService("getFlexiFrequencies").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          loader: false,
          flexiFrequencies: response.data.data,
          pf_pl_text: response.data.data[0].pf_pl_text,
        });
      } else {
        this.setState({
          loader: false,
        });
      }
    });
  }
  handleFlexiChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);

    var isnum =
      e.target.name == "con_amount" ? /^\d+$/.test(e.target.value) : true;

    this.setState(
      {
        [e.target.name]:
          e.target.name == "con_amount"
            ? e.target.value.replace(/[^0-9]/g, "")
            : e.target.value,
      },
      () => {
        // if (isnum && this.state.con_amount > 199) {
        this.selectPlan("subscribeFlexiPlan", 1);
        // }
      }
    );
  };

  selectPlanReedem = (type, val) => {
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "1",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "1",
      };
    }
    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount:
                this.state.pf_pl_text == "Daily" &&
                this.state.con_amount > 10000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Weekly" &&
                    this.state.con_amount > 100000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Monthly" &&
                    this.state.con_amount > 500000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.con_amount,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  trustModal = () => {
    this.setState({
      trustInvest: !this.state.trustInvest,
    });
  };

  planModal = () => {
    this.setState({
      buyPlan: !this.state.buyPlan,
    });
  };
  bankTransfer = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/transfer");
  };

  paymentProcess = () => {
    let _this = this;
    trustFundApiService("paymentProcess2")
      .then((response) => {
        console.log("before if", response);

        if (response.data.status === "success") {
          let result = response.data.data;
          console.log("if 1");

          if (result && result.pr_response) {
            console.log("if 2");

            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              console.log("if 3");

              let paymentData = {
                planid: getLocalStorage("planId")
                  ? getLocalStorage("planId")
                  : this.state.selectedPlanId,
                payment_data: result.pr_response,
              };

              this.props
                .actionBuyPlan(paymentData)
                .then((response) => {
                  if (response.data.status === "success") {
                    if (getLocalStorage("referalKey")) {
                      console.log("dddd");
                      this.referTFP();
                    } else {
                      _this.setState({
                        loader: false,
                      });
                      _this.props.history.push({
                        pathname: "/trustpartner",
                        state: { fromRegister: _this.state.fromRegister },
                      });
                    }
                  } else {
                    _this.setState({
                      loader: false,
                    });
                    _this.props.history.push({
                      pathname: "/trustpartner",
                      state: { fromRegister: this.state.fromRegister },
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                  console.log(err);
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };

  referTFP = () => {
    let tfp_code = getLocalStorage("referalKey");
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    if (tfp_code && tfp_relationship) {
      let data = {
        u_relationship: tfp_relationship,
        u_tfp_code: "TFP" + tfp_code,
      };

      trustFundApiService("referTrustPartner", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.updateProcess();
            this.setState({
              loader: false,
            });
            setTimeout(
              function () {
                this.props.history.push({
                  pathname: "/dashboard",
                  state: { fromRegister: this.state.fromRegister },
                });
              }.bind(this),
              3000
            );
          } else {
            this.props.history.push({
              pathname: "/trustpartner",
              state: { fromRegister: this.state.fromRegister },
            });
            this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  updateProcess = () => {
    let data = {
      type: "both",
    };
    
    trustFundApiService("updateRegisterProcess", data);
  };

  confirmModal = (type) => {
    if (type === 1) {
      this.setState({
        message: "By clicking Cashout, you are closing your current Trustfund Cycle, your Annual Membership Fee",
        message2: "will be deducted and you will be automatically activated for the next TrustFund Cycle.",
        title: "Cashout Contributions",
        isConfirm: !this.state.isConfirm,
        type: this.cashout,
      });
    }
  };

  confirmModal1 = () => {
    this.setState({
      isConfirm: !this.state.isConfirm,
    });
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
      //verifyEmail: !this.state.isActive
    });
  };

  verifyModal = () => {
    this.setState({
      verifyEmail: !this.state.verifyEmail,
    });
  };

  lockedModal = () => {
    this.setState({
      accountLocked: !this.state.accountLocked,
    });
  };

  withdrawModal = () => {
    this.setState({
      isWithdraw: !this.state.isWithdraw,
    });
  };

  repayModal = () => {
    this.setState({
      isRepay: !this.state.isRepay,
    });
  };

  cashoutModal = () => {
    this.setState({
      cannotCashout: !this.state.cannotCashout,
    });
  }; 

  logout = () => {
    const _this = this;
    clearLocalStorage(function () {
      _this.props.history.push("/");
      _this.props.actionLogout();
    });
  };

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: "Link Copied Successfully!" });
  };

  currentPlanInfo = () => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.setState(
            {
              flexi_duration: data.u_flexi_duration,
              tfp_pin_status: data.u_tfp_pin_status,
              loan_pop_etc_status: data.loan_pop_etc_status,
              loan_pop_etc_rating: data.rating.loan_pop_etc_rating,
              pl_name: data.plan_info.pl_name,
              pl_rgst_fee: data.plan_info.pl_rgst_fee,
              pl_id: data.plan_info.pl_id,
              pl_image: data.plan_info.pl_image,
              cashoutStatus: data.cashout_status,
              trustLink: response.data.data.u_trust_link,
              earnings: data.earnings,
              reg_date: data.u_reg_date.date.split(" ")[0],
            },
            () => {
              console.log(this.state.pl_name);
              console.log(this.state.pl_id);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTrustWallet = () => {
    trustFundApiService("trustWallet")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.setState({
            currentLoan: data.loans.current_loan,
            loan_status: data.loans.loan_status,
            POPStatus: data.pop.pop_status,
            ETCStatus: data.etc.etc_status,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getContribution = () => {
    trustFundApiService("getContribution")
      .then((response) => {
        console.log(
          this.state.cashoutStatus === 1 && this.state.contribution > 0
        );
        if (response.data.status === "success") {
          let data = response.data.data;
          console.log("data");
          console.log(
            this.state.cashoutStatus === 1 && this.state.contribution > 0
          );

          let wc = data.week_contribution;
          let conAmount = [];
          let conDate = [];
          if (wc.length > 0) {
            wc.map((item, index) => {
              conDate.push(moment(item.trans_from_date).format("MM/DD/YYYY"));
              conAmount.push(item.con_amount);
            });
          }

          this.setState(
            {
              memberTitle: data.u_membertitle,
              contribution: data.contribution,
              earnings: data.earnings,
              loans: data.loans,
              loanStatus: data.loan_status,
              lastWCAmount: formatNumber(data.last_contribution_amount),
              lastWCDate: data.last_contribution,
              nextWCAmount: formatNumber(data.next_contribution_amount),
              nextWCDate: data.next_contribution,
              conDate,
              conAmount,
            },
            () => {
              console.log(this.state.contribution);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  redeemEarnings = () => {
    let totalEarnings = this.state.earnings;
    let data = {
      redeem_amount: totalEarnings,
    };
    if (parseInt(totalEarnings) >= 5000) {
      this.setState({
        loader: true,
      });
      trustFundApiService("redeemEarningsDB", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    } else {
      this.setState(
        {
          loader: false,
          message:
            "You can only redeem your earnings if you have N5000 or above",
          title: "Redeem Earnings",
        },
        () => this.toggleModal()
      );
    }
  };

  repayLoan = () => {
    let loan_amount = this.state.loans;
    let data = {
      loan_amount,
    };
    if (loan_amount > 0) {
      this.setState({
        loader: true,
        isRepay: false,
      });
      trustFundApiService("repayLoanDB", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState(
              {
                loader: false,

                message: response.data.message,
                title: "Repay Loan",
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Repay Loan",
              },
              () => this.toggleModal()
            );
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  cashout = () => {
    let cashout_amount = this.state.contribution;
    let data = {
      cashout_amount,
    };
    if (cashout_amount > 0) {
      this.setState({
        loader: true,
        isConfirm: false,
      });
      trustFundApiService("cashoutDB", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Request Cashout",
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Request Cashout",
              },
              () => this.toggleModal()
            );
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  dateCheck = (from, to, check) => {
    var fDate, lDate, cDate;

    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }

    return false;
  };

  verifyEmail = () => {
    let _this = this;
    let email = this.props.userData.u_email;
    if (email) {
      let data = {
        email,
      };
      this.setState({
        loader: true,
      });
      trustFundApiService("resendEmail", data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.verifyModal();
            _this.setState({
              loader: false,
            });
          } else {
            _this.verifyModal();
            _this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          _this.verifyModal();
          _this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  // action - 1 - refund , 2- cancel
  refundCancelLoan = (action) => {
    let data = {
      loan_action: action,
    };
    if (data) {
      this.setState({
        isWithdraw: false,
        loader: true,
      });
      trustFundApiService("refundOrCancelLoan", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState({
              loader: false,
            });
          } else {
            this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          if (action !== 2) {
            this.setState(
              {
                loader: false,
              },
              () => this.withdrawModal()
            );
          } else {
            this.setState({
              loader: false,
            });
          }
          console.log(err);
        });
    }
  };

  planChange = () => {
    let planId = parseInt(this.state.planId);
    let currentPlanId = parseInt(this.state.currentPlanId);

    if (planId && currentPlanId) {
      this.setState({
        loader: true,
        isConfirm: false,
        isActive: false,
      });

      let data = {
        pl_id: planId,
        pl_change: currentPlanId < planId ? "1" : "0", //upgrade/downgrade flag
      };

      let _this = this;
      trustFundApiService("planChange", data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.getPlans();
            _this.setState({
              loader: false,
            });
          } else {
            _this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          _this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  render() {
    const u_tfp_pin = this.props.userData.u_tfp_pin || "";
    const cur_name = this.props.userData.cur_name || "";
    // var cashoutBtnDisabled = true;
    // if (
    //   this.dateCheck(
    //     this.props.userData.u_cashout_from,
    //     this.props.userData.u_cashout_to,
    //     new Date()
    //   )
    // )
    //   cashoutBtnDisabled = false;
    // else cashoutBtnDisabled = true;

    const data = {
      labels: this.state.conDate,
      datasets: [
        {
          label: "Contributions Chart",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "#ea9e2318",
          borderColor: "#ea9e23",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#ea9e23",
          pointBackgroundColor: "#ea9e23",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#ea9e23",
          pointHoverBorderColor: "#ea9e23",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.state.conAmount,
        },
      ],
      legend: {
        display: false,
      },
    };
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />

          <Notification />

          {/* <Sound
            url={soundfile}
            playStatus={Sound.status.PLAYING}
            onLoading={this.handleSongLoading}
            onPlaying={this.handleSongPlaying}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          /> */}

          <div className="content-wrapper">
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={false}
                // isOpen={this.state.verifyEmail}
                onRequestClose={this.verifyModal}
                shouldCloseOnOverlayClick={this.state.shouldCloseOnOverlayClick}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <img src={People} width={400} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Welcome to the Trust Community
                  </h3>
                  <p>
                    A verification link has been sent to your email, check your
                    email to verify your account. <br />
                    You will not be able to log in into your dashboard if
                    account is not verified after 24hours.
                  </p>
                  <br />
                  <a
                    className="uk-position-relative uk-button dash-button"
                    onClick={this.verifyEmail.bind(this)}
                  >
                    {" "}
                    Resend Verification Link{" "}
                  </a>
                  <br />
                  <br />
                  {this.state.shouldCloseOnOverlayClick ? (
                    <a
                      onClick={this.verifyModal}
                      className="uk-position-relative link uk-position-z-index"
                    >
                      Close
                    </a>
                  ) : (
                    <div className="uk-margin-bottom">
                      <a onClick={this.logout} className="uk-button link">
                        Logout
                      </a>
                    </div>
                  )}
                </div>
              </Modal>

              {/* Trust Invest Modal */}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.trustInvest}
                onRequestClose={this.trustModal}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <img src={TrustInvestImg} width={400} alt=""  style={{borderRadius:"30px"}} /><br /><br />
                  <a
                    href="mailto:trustinvest@etrustfund.org"
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Email Us!
                  </a>
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <a
                    onClick={this.trustModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    <img src={Close} className="close-icon" alt="close"/>
                  </a>
                </div>
              </Modal>
              {/* Trust Invest Modal */}

              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <img src={Smiley} width={100} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.title}
                  </h3>
                  <p>{this.state.message} </p>

                  <a
                    onClick={this.toggleModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Close
                  </a>
                </div>
              </Modal>

              {/*REPAY MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isRepay}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Repay Loan of {formatNumber(this.state.loans) || 0}{" "}
                  </h3>
                  <span className="uk-text-center reg-note">
                    <i>(Use any of the two methods provided below)</i>
                  </span>
                  <br />
                  <br />
                  <button
                    onClick={this.repayLoan}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Click to Pay from Bank Card
                  </button>
                  <div className="">
                    <table className="uk-table uk-table-justify uk-table-divider">
                      <thead>
                        <tr>
                          <th className="uk-width-small theme-color">
                            FOR MANUAL PAYMENT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="uk-text-left">Bank Name:</td>
                          <td className="uk-text-left theme-color">
                            Guaranty Trust Bank
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Name:</td>
                          <td className="uk-text-left theme-color">
                            A.Y.E Trust Fund
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Number:</td>
                          <td className="uk-text-left theme-color">
                            0454179544
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">
                            Use your TFP as narration:
                          </td>
                          <td className="uk-text-left theme-color">
                            {u_tfp_pin}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a
                      onClick={this.repayModal}
                      className="uk-position-relative link"
                    >
                      Close
                    </a>
                  </div>
                </div>
              </Modal>
              {/*REPAY MODAL*/}

              {/*WITHDRAW MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isWithdraw}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <br />
                  <button
                    onClick={this.refundCancelLoan.bind(this, 1)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Request Loan Withdrawal
                  </button>
                  <br />
                  <div>
                    <span className="uk-text-lead text-red">OR</span>
                  </div>
                  <br />
                  <button
                    onClick={this.refundCancelLoan.bind(this, 2)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Refund Loan
                  </button>
                  <br />
                  <a
                    onClick={this.withdrawModal}
                    className="uk-position-relative link"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              {/*WITHDRAW MODAL*/}

              {/*CASHOUT MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.cannotCashout}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Attention!!
                  </h3>
                  <span className="uk-text-center reg-note">The system is unable to renew your membership and could not submit your<br/> cashout request as your total contributions is less than your plan membership fee.<br/><br/>Please contact support for more details.
                  </span>
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                  <a
                    onClick={this.cashoutModal}
                    className="uk-position-relative link uk-position-z-index"
                  ><br/><br/>
                    <img src={Close} className="close-icon" alt="close" />
                  </a>
                  </div>
                </div>
              </Modal>
              {/*CASHOUT MODAL*/}

              {/*LOCKED ACCOUNT MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.accountLocked}
              >
                <div className="uk-text-center uk-margin-top">
                  <img src={Locked} width={100} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <br />
                  Your Account is{" "}
                  <span className="theme-color">TEMPORARILY LOCKED</span> due to
                  DEFAULT
                  <br />
                  <br />
                  <span className="theme-color">
                    Contact Admin(<b>support@ayetrustund.org</b>) to Unlock
                    Account
                  </span>
                  <div className="uk-margin-bottom">
                    <br />
                    <a onClick={this.logout} className="uk-button dash-button">
                      Logout
                    </a>
                  </div>
                </div>
              </Modal>
              {/*LOCKED ACCOUNT MODAL*/}

              {/*Buy Plan*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.buyPlan}
                onRequestClose={this.planModal}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <h3 className="uk-text-lead theme-color">
                    UPGRADE PLAN TO ENJOY THESE BENEFITS
                  </h3>

                  <div class="uk-column-1-2@s uk-column-1-3@m uk-column-1-4@l">
                    <p>
                      <img src={PlanBlk} width={100} alt="" />
                      <br />
                      <span className="theme-color">
                        PLATINUM
                        <br />
                      </span>
                      <Link
                        onClick={() => this.toggleModal3("PLATINUM")}
                        className="uk-button uk-button-small buy-button"
                      >
                        Start Plan
                      </Link>
                    </p>
                    <p>
                      <img src={PlanBlk} width={100} alt="" />
                      <br />
                      <span className="theme-color">
                        GOLD
                        <br />
                      </span>
                      <Link
                        onClick={() => this.toggleModal2("GOLD")}
                        className="uk-button uk-button-small buy-button"
                      >
                        Start Plan
                      </Link>
                    </p>
                    <p>
                      <img src={PlanBlk} width={100} alt="" />
                      <br />
                      <span className="theme-color">
                        SILVER
                        <br />
                      </span>
                      <Link
                        onClick={() => this.toggleModal1("SILVER")}
                        className="uk-button uk-button-small buy-button"
                      >
                        Start Plan
                      </Link>
                    </p>
                    <p>
                      <img src={PlanBlk} width={100} alt="" />
                      <br />
                      <span className="theme-color">
                        FLEXI
                        <br />
                      </span>
                      <Link
                        onClick={() => this.toggleModal5("FLEXI")}
                        className="uk-button uk-button-small buy-button"
                      >
                        Start Plan
                      </Link>
                    </p>
                  </div>
                  <Link
                    className="uk-button dash-button uk-margin-top"
                    to="/weeklyearnings"
                  >
                    Upgrade Plan with Earnings
                  </Link>
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <a
                    onClick={this.planModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    <img src={Close} className="close-icon" alt="close" />
                  </a>
                </div>
              </Modal>

              {/*Buy Plan*/}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div
                  className="uk-child-width-1-3@m uk-child-width-1-2@s uk-margin-small-left uk-grid-match uk-dash-margin-left"
                  uk-height-match="target: > div.threes"
                  data-uk-grid
                >
                  <div className="threes threes-left card-margin-right">
                    <div className="overv uk-flex animated fadeIn uk-card-body uk-flex-between uk-light uk-flex-middle uk-flex-wrap">
                      <div id="name-card uk-text-center">
                        <span className="theme-color uk-h4">
                          Welcome{" "}
                          {this.props.userData.u_first_name
                            ? this.props.userData.u_first_name
                                .split(" ")
                                .slice(-1)
                                .join(" ")
                            : ""}
                          ,
                        </span>
                      </div>
                      <div
                        className="plan-grid uk-margin-small-top"
                        data-uk-grid
                      >
                        <div className="uk-width-1-2">
                          <h2 className="txt-h">
                            {" "}
                            {this.state.pl_name == 'ORDINARY TRUST MEMBER' ? 'Basic Trust Member' : capitalizeFirstLetter(this.state.pl_name) ||
                              ""}{" "}
                          </h2>
                        </div>
                        <div className="uk-width-1-2">
                          <img
                            className="plan-img"
                            src={this.state.pl_image}
                            alt="Plan Block"
                          />
                        </div>
                      </div>
                      {this.state.reg_date &&
                      <p className="uk-text-small t-text">
                        REGISTRATION DATE:{" "}{this.state.reg_date.split("-")[2]}-{this.state.reg_date.split("-")[1]}-{this.state.reg_date.split("-")[0]}
                      </p>
                      }

                      {this.state.loan_pop_etc_status === 0 ? (
                        this.state.tfp_pin_status === 0 ||
                        this.state.tfp_pin_status === 2 ? (
                          this.state.pl_name.includes("BASIC") ? (
                            ""
                          ) : (
                            this.props.userData.u_status === 5 ? 

                              <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                              <p className="uk-margin-remove uk-text-lead-small ">
                                ACCOUNT TERMINATED
                              </p>
                              </div> :
                                                    
                                                    
                            <div
                              className={`uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove ${
                                this.state.memberTitle === "Ordinary member"
                                  ? "ordinary"
                                  : this.state.memberTitle ===
                                    "Qualified member"
                                  ? "qualified"
                                  : this.state.memberTitle === "Bonafide member"
                                  ? "bonafide"
                                  : "matured"
                              }`}
                              uk-tooltip="title: This is your membership status; pos: bottom"
                            >
                              {" "}
                              <p className="uk-margin-remove uk-text-lead-small ">
                                {this.state.memberTitle}
                              </p>
                            </div>
                          )
                        ) : (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              TFP IN USE
                            </p>
                          </div>
                        )
                      ) : this.state.loan_pop_etc_status === 1 ? (
                        this.state.loan_status === 1 ||
                        this.state.loan_status === 2 ||
                        this.state.loan_status === 4 ||
                        this.state.loan_status === 5 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              LOAN IN PROCESS
                            </p>
                          </div>
                        ) : this.state.loan_status === 3 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              LOAN BORROWER
                            </p>
                          </div>
                        ) : null
                      ) : this.state.loan_pop_etc_status === 2 ? (
                        this.state.POPStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP IN PROCESS
                            </p>
                          </div>
                        ) : this.state.POPStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP IN USE
                            </p>
                          </div>
                        ) : null
                      ) : this.state.loan_pop_etc_status === 3 ? (
                        this.state.ETCStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC IN PROCESS
                            </p>
                          </div>
                        ) : this.state.ETCStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC IN USE
                            </p>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <div className="threes trst-margin-top trst uk-width-2-3@m uk-child-width-2-3@s">
                    <div className="uk-card uk-card-loan uk-card-body">
                      {/*<div className="tearn-title"></div>*/}
                      <div className="tearn-title">
                        <div className="uk-light uk-flex animated fadeIn uk-flex-wrap uk-padding-small uk-flex-middle uk-flex-between">
                          <div>
                            <span
                              className="uk-text-center trst-title"
                              uk-tooltip="title: All your funds reside here; pos: right"
                            >
                              My Trust Wallet
                            </span>
                          </div>
                          <div className="learn">
                            <a
                              className="uk-link"
                              href="/learn-more#trustwallet"
                              uk-tooltip="title: Click to visit help center; pos: bottom"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="t-margin" data-uk-grid>
                        <div className="uk-width-1-3 uk-text-left border">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            <h2 className="text-green t-amount uk-margin-remove">
                              {" "}
                              {formatNumber(this.state.contribution) || 0}{" "}
                            </h2>
                            <p className="uk-text-small uk-margin-remove t-text">
                              {" "}
                              CONTRIBUTIONS{" "}
                            </p>
                            <p>
                              {" "}
                              {/* {this.state.contribution == 30000 ? ( */}
                              {this.state.contribution <= this.state.pl_rgst_fee ? (
                                <a
                                  className="uk-button uk-button-small dash-button"
                                  onClick={this.cashoutModal}
                                  style={{ fontSize: "12px" }}
                                  uk-tooltip="title: Cashout Closed; pos: bottom"
                                >
                                  { this.state.flexi_duration == 12 || this.state.flexi_duration == 3 || this.state.flexi_duration == 6 ? `Flexi Cashout - ${this.state.flexi_duration}M` : 'Cashout' }
                                </a>
                              ) : this.state.cashoutStatus === 1 &&
                              this.state.contribution > 0 ? (
                                <a
                                  className="uk-button uk-button-small dash-button"
                                  onClick={this.confirmModal.bind(this, 1)}
                                  style={{ fontSize: "12px" }}
                                  uk-tooltip="title: Cashout Closed; pos: bottom"
                                >
                                  { this.state.flexi_duration == 12 || this.state.flexi_duration == 3 || this.state.flexi_duration == 6 ? `Flexi Cashout - ${this.state.flexi_duration}M` : 'Cashout' }
                                </a>
                              ) : this.state.pl_id == this.state.ordinary_Id ? (
                                <Link
                                  className="uk-button uk-button-small dash-button"
                                  type="button"
                                  style={{ fontSize: "12px" }}
                                  onClick={this.planModal}
                                >
                                  Start Plan
                                </Link>
                              ) : (
                                <a
                                  className="uk-button uk-button-small dash-button3"
                                  type="button"
                                  disabled={true}
                                  style={{ fontSize: "12px" }}
                                  uk-tooltip="title: Cashout Closed; pos: bottom"
                                >
                                  { this.state.flexi_duration == 12 || this.state.flexi_duration == 3 || this.state.flexi_duration == 6 ? `Flexi Cashout - ${this.state.flexi_duration}M` : 'Cashout' }
                                </a>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-text-left border">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                              {formatNumber(this.state.earnings) || 0}
                            </h2>
                            <p className="uk-text-small uk-margin-remove t-text">
                              {" "}
                              EARNINGS{" "}
                            </p>
                            <p>
                              {" "}
                              {/* {this.state.pl_id === 14 ? ( */}
                              {this.state.pl_id === this.state.ordinary_Id ? (
                                <Link
                                  className="uk-button uk-button-small dash-button"
                                  type="button"
                                  style={{ fontSize: "12px" }}
                                  onClick={this.planModal}
                                >
                                  Redeem
                                </Link>
                              ) : (
                                <a
                                  className="uk-button uk-button-small dash-button"
                                  onClick={
                                    this.state.loader ? "" : this.redeemEarnings
                                  }
                                  style={{ fontSize: "12px" }}
                                  uk-tooltip="title: Send Redeem Earnings Request; pos: bottom"
                                >
                                  Redeem{" "}
                                </a>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-text-left">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            {this.state.loanStatus == 0 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {" "}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 1 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {" "}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 2 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {" "}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 3 ? (
                              <h2 className="uk-text-larger-theme t-amount text-red uk-margin-remove">
                                {" "}
                                {formatNumber(this.state.loans) || 0}{" "}
                              </h2>
                            ) : (
                              ""
                            )}{" "}
                            <p className="uk-text-small uk-margin-remove t-text">
                              {" "}
                              LOANS{" "}
                            </p>
                            <p>
                              {" "}
                              {/* {this.state.pl_id === 14 ? ( */}
                              {this.state.pl_id === this.state.ordinary_Id ? (
                                <Link
                                  className="uk-button uk-button-small dash-button"
                                  type="button"
                                  style={{ fontSize: "12px" }}
                                  onClick={this.planModal}
                                >
                                  Apply
                                </Link>
                              ) : (
                                <p>
                                  {this.state.loanStatus == 0 ? (
                                    <Link
                                      //This is the button that appears when user has no loan
                                      className="uk-button dash-button  uk-button-small"
                                      to="/loan"
                                      style={{ fontSize: "12px" }}
                                      uk-tooltip="title: Click to Apply for a loan; pos: bottom"
                                    >
                                      Apply
                                    </Link>
                                  ) : this.state.loanStatus == 1 ? (
                                    <a
                                      //This is the button that appears when loan is in progress (i.e waiting for truster confirmations)
                                      className="uk-button dash-button  uk-button-small"
                                      onClick={this.withdrawModal}
                                      // onClick={this.refundCancelLoan.bind(
                                      //   this,
                                      //   2
                                      // )}
                                      style={{ fontSize: "12px" }}
                                      uk-tooltip="title: Click to Withdraw or Refund your loan; pos: bottom"
                                    >
                                      Refund/Withdraw
                                    </a>
                                  ) : this.state.loanStatus == 2 ? (
                                    <a
                                      //This is the button that appears when all trusters have confirmed their trust
                                      className="uk-button dash-button  uk-button-small"
                                      onClick={this.refundCancelLoan.bind(
                                        this,
                                        3
                                      )}
                                      style={{ fontSize: "12px" }}
                                      uk-tooltip="title: Click to terminate loan process; pos: bottom"
                                    >
                                      Cancel
                                    </a>
                                  ) : this.state.loanStatus == 3 ? (
                                    <a
                                      // This is the button that appears when loan has been disbursed
                                      className="uk-button dash-button  uk-button-small"
                                      // onClick={this.repayLoan}
                                      onClick={this.repayModal}
                                      style={{ fontSize: "12px" }}
                                      uk-tooltip="title: Click to Repay your loan; pos: bottom"
                                    >
                                      Repay
                                    </a>
                                  ) : this.state.loanStatus == 4 ? (
                                    "Pending Disbursal"
                                  ) : this.state.loanStatus == 5 ? (
                                    "Pending Disbursal"
                                  ) : null}{" "}
                                </p>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  {/* {this.state.pl_id === 14 ? ( */}
                  {this.state.pl_id === this.state.ordinary_Id ? (
                    <span>
                      <img
                        src={DashUpgrade}
                        alt="Upgrade Image"
                        style={{ borderRadius: "12px" }}
                        onClick={this.planModal}
                      />
                    </span>
                  ) : (
                    <span>
                      <h2
                        className="uk-text-large-theme theme-color uk-text-center"
                        uk-tooltip="title: Representation of your contributions; pos: bottom"
                      >
                        Contributions Chart
                        <br />
                      </h2>
                      <br />
                      <div>
                        {this.state.conAmount.length > 0 &&
                        this.state.conDate.length > 0 ? (
                          <Line ref="chart" data={data} />
                        ) : null}
                      </div>
                    </span>
                  )}
                </div>

                <div className="uk-hidden@m uk-card uk-card-loan uk-margin-top uk-card-body animated fadeIn">
                  <h2
                    className="uk-text-large-theme theme-color uk-text-center"
                    uk-tooltip="title: Representation of your contributions; pos: bottom"
                  >
                    My Trust Link
                    <br />
                  </h2>
                  <br />
                  <div>
                    <p>
                      <span className="theme-color uk-text-center">
                        {this.state.copySuccess}
                      </span>
                      <form>
                        <textarea
                          className="t-link"
                          ref={(textarea) => (this.textArea = textarea)}
                          value={this.state.trustLink}
                        />
                      </form>
                    </p>
                    <div className="uk-text-center">
                      <div>
                        {document.queryCommandSupported("copy") && (
                          <div>
                            <span>
                              <a
                                className="uk-button uk-button-small dash-button"
                                onClick={this.copyToClipboard}
                              >
                                Copy Link
                              </a>
                            </span>
                          </div>
                        )}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>

                <p className="uk-text-center footer-text">
                  © 2019. Entrepreneurs Trust Fund is a platform under the{" "}
                  <a href="https://www.ayeonline.org">
                    Africa's Young Entrepreneurs
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
              <RightSidebar
                {...this.props}
                contribution={this.state.contribution}
              />
            </div>
          </div>
        </section>

        {/* Toogle Modal */}

        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen5}
          onRequestClose={this.toggleModal5}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">
              Set Flexi Amount & Frequency
            </h3>

            <div data-uk-grid>
              <div class="uk-width-1-2">
                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <label className="uk-form-label-dash">
                      Contribution Amount (₦)
                    </label>
                    <input
                      className="uk-input"
                      name="con_amount"
                      type="text"
                      value={this.state.con_amount}
                      onChange={(e) => this.handleFlexiChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <label className="uk-form-label-dash">
                      Select Frequency
                    </label>
                    <select
                      className="uk-select"
                      name="pf_pl_text"
                      type="text"
                      onChange={(e) => this.handleFlexiChange(e)}
                    >
                      {this.state.flexiFrequencies &&
                        this.state.flexiFrequencies.map((resp, index) => {
                          return (
                            <option
                              key={index}
                              value={resp.pf_pl_text}
                              id={resp.pf_id}
                            >
                              {resp.pf_pl_text}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <h4>
              Membership Fee: <span className="theme-color"></span>₦
              {this.state.membership_fee}
            </h4>

            <a
              onClick={this.selectPlan.bind(this, "subscribeFlexiPlan", 2)}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>Your Dashboard will be created in few minutes</i>
            </span>
            <br />
            <br />

            <a
              onClick={this.toggleModal5}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>
        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen1}
          onRequestClose={this.toggleModal1}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                1
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal1}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>
        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen2}
          onRequestClose={this.toggleModal2}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                2
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal2}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>

        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen3}
          onRequestClose={this.toggleModal3}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                3
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal3}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>

        <Modal
          className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isConfirm}
          onRequestClose={this.confirmModal}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">{this.state.title}</h3>
            <p>{this.state.message} </p>
            <p>{this.state.message2} </p>
            <div className="uk-margin-bottom">
              <a className="uk-button dash-button" onClick={this.state.type}>
                Confirm
              </a>
            </div>
            <a
              onClick={this.confirmModal1}
              className="uk-position-relative link uk-position-z-index"
            >
              Cancel
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, {
  actionLogout,
  actionGetPlans,
  actionBuyPlan,
})(Dashboard);
