const CONSTANTS = {
  PLAN_NAME: {
    SILVER_PLAN: 'SILVER TRUST MEMBER',
    GOLD_PLAN: 'GOLD TRUST MEMBER',
    PLATINUM_PLAN: 'PLATINUM TRUST MEMBER',
    SILVER_PREFIX: 'STM',
    GOLD_PREFIX: 'GTM',
    PLATINUM_PREFIX: 'PTM',
    SILVER_MAX: 'N1000000 Max.',
    GOLD_MAX: 'N5000000 Max.',
    PLATINUM_MAX: 'N10000000 Max.',
    SILVER: 'SILVER',
    GOLD: 'GOLD',
    PLATINUM: 'PLATINUM'
  },

  TP_RELATION: {
    NOONE: 'No One',
    FRIEND: 'Friend',
    FAMILY: 'Family',
    COLLEAGUE: 'Colleague',
    OTHERS: 'Others'
  },

  TRANSACTION_TYPE: {
    CONTRIBUTION: 'contribution',
    LOAN: 'loan',
    POP: 'pop',
    ETC: 'etc',
    EARNINGS: 'referal'
  },

  CHAT_TYPE: {
    CUSTOMER_CARE: 'customer_care',
    BUSINESS_EXPERT: 'business_expert',
    LEGAL_ADVISER: 'legal_adviser',
    MEDICAL_ADVISER: 'medical_adviser'
  },

  RECORDS_PER_PAGE: 10,
  CRYPTER_KEY:
    '0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xfffffff8'
};

export default CONSTANTS;
