let isSubscribed = false;
let swRegistration = null;
let applicationKey =
  'BI1Nq4G6GHpWzRip0TbaQSznnOLg8ELI9RMfvUA0L_AQHc_ncHN2gsLuxZN58W-T8nH5RUdrA3Ee9W4DzMEj-M4';

// Url Encription
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Installing service worker
export function subscribeUser() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    // console.log('Service Worker and Push is supported');

    navigator.serviceWorker
      .register('custom-sw.js')
      .then(function(swReg) {
        swRegistration = swReg;
        //   console.log('service worker registered', swRegistration);
        swRegistration.pushManager
          .getSubscription()
          .then(function(subscription) {
            //  console.log('User is subscribedsdasd');
            isSubscribed = !(subscription === null);

            if (isSubscribed) {
              console.log('User is subscribed');
            } else {
              //   console.log('User is not subscribed');
              swRegistration.pushManager
                .subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: urlB64ToUint8Array(applicationKey)
                })
                .then(function(subscription) {
                  //  console.log('subscription', subscription);
                  //  console.log('User is subscribed');

                  saveSubscription(subscription);

                  isSubscribed = true;
                })
                .catch(function(err) {
                  //  console.log('Failed to subscribe user: ', err);
                });
            }
          });
      })
      .catch(function(error) {
        //console.error('Service Worker Error', error);
      });
  } else {
    //console.warn('Push messaging is not supported');
  }
}

// Send request to database for add new subscriber
function saveSubscription(subscription) {
  let xmlHttp = new XMLHttpRequest();
  xmlHttp.open('POST', `${process.env.REACT_APP_NOTIFICATION_URL}/subscribe`);
  //xmlHttp.open('POST', 'http://103.76.253.131:9000/subscribe');
  xmlHttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xmlHttp.onreadystatechange = function() {
    if (xmlHttp.readyState != 4) return;
    if (xmlHttp.status != 200 && xmlHttp.status != 304) {
      console.log('HTTP error ' + xmlHttp.status, null);
    } else {
      console.log('User subscribed to server');
    }
  };

  xmlHttp.send(JSON.stringify(subscription));
}
