import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import * as qs from "query-string";
import Header from "./common/Header";
import LeftSidebar from "./common/LeftSidebar";
import RightSidebar from "./common/RightSidebar";
import Notification from "./common/Notification";
import Validation from "./common/helpers/Validation";
import RenderField from "./authorization/RenderField";
import GoogleAutocomplete from "./common/helpers/GoogleAutocomplete";
import {
  actionGetUserProfile,
  actionUpdateProfile,
  actionGetPlans,
  actionBuyPlan,
} from "./common/redux/actions/";
import {
  formatNumber,
  getLocalStorage,
  setLocalStorage,
  capitalizeFirstLetter,
} from "./common/helpers/Utils";
import CONSTANTS from "./common/helpers/Constants";

import PhoneInput from "./common/helpers/PhoneInput";
import { showErrorToast } from "./common/helpers/Toastr";
import SelectRenderField from "./authorization/SelectRenderField";
import trustFundApiService from "./common/api/ApiService";
import axios from "axios";
import Allen from "./assets/img/allen.png";
import Cam from "./assets/img/cam.png";
import DynaLogo from "./assets/img/aye-loader.gif";
import Verified from "./assets/img/verified.png";

import { stat } from "fs";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      fullname: "",
      email: "",
      tfpPin: "",
      currentPlan: "",
      imageSource: "",
      imageData: "",
      loader: false,
      isConfirm: false,
      isTCActive: false,
      currentPlanId: "",
      address: "",
      remainingAmount: 0,
      planName: "",

      isChosen5: false,
      isChosen3: false,
      isChosen2: false,
      isChosen1: false,

      currentPlanName: "",
      selectedPlanId: "",
      flexiFrequencies: [],
      pf_pl_text: "",
      con_amount: "",
      membership_fee: 0.0,
    };
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  confirmModal = (planId, remainingAmount, planName) => {
    this.setState({
      isConfirm: !this.state.isConfirm,
      planId: planId,
      remainingAmount: remainingAmount,
      planName,
    });
  };

  tcModal = () => {
    this.setState({
      isTCActive: !this.state.isTCActive,
    });
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  componentDidMount() {
    document.title = "User Profile - If You TRUST Me I'll TRUST You";
    const { location } = this.props;
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed.reference && parsed.trxref) {
        console.log(parsed.reference === 3);
        console.log(parsed.reference == 3);
        console.log(parsed.reference);

        if (parsed.reference == 3) {
          this.paymentProcess();
        } else {
          this.processPayment();
        }
      }
    }
    this.getFlexiFrequencies();
    this.getUserData();
    this.getPlans();
  }
  toggleModal1 = (id) => {
    this.setState({
      isConfirm: false,
      selectedPlanId: id,
      isChosen1: !this.state.isChosen1,
    });
  };
  toggleModal2 = (id) => {
    this.setState({
      isConfirm: false,
      selectedPlanId: id,
      isChosen2: !this.state.isChosen2,
    });
  };
  toggleModal3 = (id) => {
    this.setState({
      isConfirm: false,
      selectedPlanId: id,
      isChosen3: !this.state.isChosen3,
    });
  };
  toggleModal4 = (id) => {
    console.log(id);

    this.setState({
      isConfirm: false,
      selectedPlanId: id,
      isChosen4: !this.state.isChosen4,
    });
  };

  toggleModal5 = (id) => {
    this.setState({
      con_amount: '',

      selectedPlanId: id,
      isChosen5: !this.state.isChosen5,
      isConfirm: false,
    });
  };
  selectPlan = (type, val) => {
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "2",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "2",
      };
    }
    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount:
                this.state.pf_pl_text == "Daily" &&
                this.state.con_amount > 10000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Weekly" &&
                    this.state.con_amount > 100000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.pf_pl_text == "Monthly" &&
                    this.state.con_amount > 500000
                  ? Math.floor(this.state.con_amount / 10)
                  : this.state.con_amount,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };
  getFlexiFrequencies() {
    this.setState({
      loader: true,
    });
    trustFundApiService("getFlexiFrequencies").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          loader: false,
          flexiFrequencies: response.data.data,
          pf_pl_text: response.data.data[0].pf_pl_text,
        });
      } else {
        this.setState({
          loader: false,
        });
      }
    });
  }
  handleFlexiChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);

    var isnum =
      e.target.name == "con_amount" ? /^\d+$/.test(e.target.value) : true;

    this.setState(
      {
        [e.target.name]:
          e.target.name == "con_amount"
            ? e.target.value.replace(/[^0-9]/g, "")
            : e.target.value,
      },
      () => {
        // if (isnum && this.state.con_amount > 199) {
        this.selectPlan("subscribeFlexiPlan", 1);
        // }
      }
    );
  };

  processPayment = () => {
    console.log("card");

    this.setState({
      loader: true,
    });
    let _this = this;
    trustFundApiService("paymentProcess2")
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;
          if (result && result.pr_response) {
            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              let paymentData = {
                payment_data: result.pr_response,
              };
              trustFundApiService("changeCardPaymentProcess2", paymentData)
                .then((response) => {
                  console.log("changesssssssssss");

                  if (response.data.status === "success") {
                    _this.setState({
                      loader: false,
                    });
                    _this.props.history.push("/profile");
                  } else {
                    _this.setState({
                      loader: false,
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };
  getUserData = () => {
    this.props
      .actionGetUserProfile()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          this.setState({
            fullname: data.u_first_name,
            email: data.u_email,
            tfpPin: data.u_tfp_pin,
            currentPlan: data.pl_id,
            currentPlanId: data.pl_id,
            currentPlanName: data.pl_name,
            userImage: data.u_image,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getPlans = () => {
    this.props
      .actionGetPlans()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          this.setState({
            planData: data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  planChange = () => {
    let planId = parseInt(this.state.planId);
    let currentPlanId = parseInt(this.state.currentPlanId);

    if (planId && currentPlanId) {
      this.setState({
        loader: true,
        isConfirm: false,
        isActive: false,
      });

      let data = {
        pl_id: planId,
        pl_change: currentPlanId < planId ? "1" : "0", //upgrade/downgrade flag
      };

      let _this = this;
      trustFundApiService("planChange", data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.getPlans();
            _this.getUserData();
            _this.setState({
              loader: false,
            });
          } else {
            _this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          _this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  bankTransfer = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/transfer");
  };

  changeCard = () => {
    this.setState({
      loader: true,
    });
    trustFundApiService("changeCardPaymentProcess1", {
      page_response: "3",
    })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;
          this.setState({
            loader: false,
          });
          if (result.authurl) {
            let authURL = result.authurl;
            window.location.href = authURL;
          }
        } else {
          this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log(err);
      });
  };
  paymentProcess = () => {
    let _this = this;
    console.log("paymentProcess22222");

    trustFundApiService("paymentProcess2")
      .then((response) => {
        console.log(response.data);
        
        if (response.data.status === "success") {
          let result = response.data.data;
          console.log("if 1");

          if (result && result.pr_response) {
            console.log("if 2");

            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              console.log("if 3");

              let paymentData = {
                planid: getLocalStorage("planId")
                  ? getLocalStorage("planId")
                  : this.state.selectedPlanId,
                payment_data: result.pr_response,
              };
              console.log(paymentData);

              this.props
                .actionBuyPlan(paymentData)
                .then((response) => {
                  if (response.data.status === "success") {
                    if (getLocalStorage("referalKey")) {
                      console.log("dddd");
                      this.referTFP();
                    } else {
                      _this.setState({
                        loader: false,
                      });
                      _this.props.history.push({
                        pathname: "/trustpartner",
                        state: { fromRegister: _this.state.fromRegister },
                      });
                    }
                  } else {
                    _this.setState({
                      loader: false,
                    });
                    _this.props.history.push({
                      pathname: "/trustpartner",
                      state: { fromRegister: this.state.fromRegister },
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                  console.log(err);
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };

  referTFP = () => {
    let tfp_code = getLocalStorage("referalKey");
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    if (tfp_code && tfp_relationship) {
      let data = {
        u_relationship: tfp_relationship,
        u_tfp_code: "TFP" + tfp_code,
      };

      trustFundApiService("referTrustPartner", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.updateProcess();
            this.setState({
              loader: false,
            });
            setTimeout(
              function () {
                this.props.history.push({
                  pathname: "/dashboard",
                  state: { fromRegister: this.state.fromRegister },
                });
              }.bind(this),
              3000
            );
          } else {
            this.props.history.push({
              pathname: "/trustpartner",
              state: { fromRegister: this.state.fromRegister },
            });
            this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  updateProcess = () => {
    let data = {
      type: "both",
    };

    trustFundApiService("updateRegisterProcess", data);
  };

  handleSubmit = (formValues) => {
    let formData = new FormData();
    var filePath;
    let _this = this;

    if (this.state.imageData !== "") {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      formData.append("u_image", this.state.imageData);

      axios
        .post(
          "https://www.etrustfund.org/api/userimageupload",
          formData,
          config
        )
        .then(function (response) {
          if (response.data.status === "success") {
            _this.setState({
              imageSource: "",
            });
            filePath = response.data.data.filepath;
            if (filePath) {
              let data = {
                email: formValues.email,
                u_first_name: formValues.firstname,
                u_phone: formValues.phonenumber,
                u_business_sector: formValues.business,
                u_address: formValues.address,
                u_tfp_pin: formValues.tfppin,
                u_image: filePath,
                up_bank_name: formValues.bankname,
                up_account_number: formValues.accountnumber,
                up_bank_holder_name: formValues.accountholdername,
                gender: formValues.sex,
                u_hear_about_us: formValues.hearaboutus,
                u_kin_name: formValues.nxtkinname,
                u_kin_contact: formValues.nxtkinphonenumber,
                u_kin_email: formValues.nxtkinemail,
                dob: formValues.dob,
              };

              _this.props
                .actionUpdateProfile(data)
                .then((response) => {
                  if (response.data.status === "success") {
                    _this.getUserData();
                    setTimeout(function () {
                      _this.props.history.push("/dashboard");
                    }, 3000);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    } else {
      console.log("no image");
      let data = {
        email: formValues.email,
        u_first_name: formValues.firstname,
        u_phone: formValues.phonenumber,
        u_business_sector: formValues.business,
        u_address: formValues.address,
        u_tfp_pin: formValues.tfppin,
        u_image: this.state.userImage,
        up_bank_name: formValues.bankname,
        up_account_number: formValues.accountnumber,
        up_bank_holder_name: formValues.accountholdername,
        gender: formValues.sex,
        u_hear_about_us: formValues.hearaboutus,
        u_kin_name: formValues.nxtkinname,
        u_kin_contact: formValues.nxtkinphonenumber,
        u_kin_email: formValues.nxtkinemail,
        dob: formValues.dob,
      };

      _this.props
        .actionUpdateProfile(data)
        .then((response) => {
          if (response.data.status === "success") {
            setTimeout(function () {
              _this.props.history.push("/dashboard");
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  uploadPhoto = (e) => {
    this.photoUpload.click();
  };

  handleImageChange = (event) => {
    this.setState({ imageSource: "", imageData: "" });
    const file = event.target.files[0];
    console.log(file);

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.setState({
          imageSource: event.target.result,
          imageData: file,
        });
      };
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    const { planData } = this.state;

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />
          {this.state.loader ? (
            <div className="spinner-wrapper">
              <div className="spinner">
                <img src={DynaLogo} alt="" />
              </div>
            </div>
          ) : null}

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Downgrade / Upgrade Account
                  </h3>
                  <p>
                    It is strongly advised that you fully understand the
                    modalities for <br />
                    change of account plan in the{" "}
                    <Link to="/learn-more#plandownup" className="link">
                      TERMS & CONDITIONS
                    </Link>
                    , before you proceed
                    <br /> with any of these options.
                  </p>
                  <div className="">
                    <table className="uk-table uk-table-justify uk-table-divider">
                      <thead>
                        <tr>
                          <th className="uk-width-small theme-color">
                            Plan Name
                          </th>
                          <th className="theme-color">Membership Fee</th>
                          <th className="theme-color">Weekly Contribution</th>
                          <th className="theme-color">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {planData && planData.length > 0
                          ? planData.map((plan, index) => (
                              <tr>
                                <td className="uk-text-left">
                                  {" "}
                                  {capitalizeFirstLetter(
                                    plan.pl_plan_name
                                  )}{" "}
                                  Trust Membership{" "}
                                </td>
                                <td className="uk-text-left">
                                  {" "}
                                  N
                                  {plan.pl_registration
                                    ? formatNumber(plan.pl_registration)
                                    : 0}
                                </td>
                                <td className="uk-text-left">
                                  {" "}
                                  N
                                  {plan.pl_weekly_saving
                                    ? formatNumber(plan.pl_weekly_saving)
                                    : 0}{" "}
                                </td>
                                {this.state.currentPlan === plan.pl_plan_id ? (
                                  <td>
                                    <a
                                      href=""
                                      className="theme-color"
                                      onClick={this.toggleModal}
                                    >
                                      <span uk-icon="icon: star" /> Active Plan
                                    </a>
                                    <br />
                                    <Link to="/trustplans" className="link">
                                      <i>...learn more</i>
                                    </Link>
                                  </td>
                                ) : (
                                  <td>
                                    <a
                                      className="uk-button uk-button-small button-out"
                                      onClick={this.confirmModal.bind(
                                        this,
                                        plan.pl_plan_id,
                                        plan.plan_change_amount,
                                        plan.pl_plan_name
                                      )}
                                    >
                                      <span uk-icon="icon: check" /> Select
                                    </a>
                                    <br />
                                    <Link to="/trustplans" className="link">
                                      <i>...learn more</i>
                                    </Link>
                                  </td>
                                )}
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <a
                      onClick={this.toggleModal}
                      className="uk-position-relative link"
                    >
                      Close
                    </a>
                  </div>
                </div>
              </Modal>

              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isConfirm}
                onRequestClose={this.confirmModal}
              >
                {/* condition 1 - select button on  ordinary */}
                {this.state.planName == "BASIC" ? (
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <p>
                      <i style={{ color: "red" }}>
                        You cannot change your plan to {this.state.planName}.
                      </i>
                    </p>
                  </div>
                ) : //condition 1 - select button on  Flexi
                this.state.planName == "FLEXI" ? (
                  //condition 1.1 - only ordinary can change to flexi
                  this.state.currentPlanName.includes("BASIC") ? (
                    <div className="uk-text-center uk-margin-right uk-margin-left">
                      <h3 className="uk-text-lead theme-color">
                        Confirm Plan Change
                      </h3>
                      <p>
                        {this.state.remainingAmount > 0
                          ? `You will have to pay ${formatNumber(
                              this.state.remainingAmount
                            )}`
                          : "Are you sure you want to change this plan ?"}
                        <br />{" "}
                        <i style={{ color: "red" }}>
                          Click Confirm to Continue or Cancel to abort.
                        </i>
                      </p>
                      <div className="uk-margin-bottom">
                        <a
                          className="uk-button dash-button"
                          onClick={() => this.toggleModal5(this.state.planId)}
                        >
                          Confirm
                        </a>
                      </div>
                      <a
                        onClick={this.confirmModal}
                        className="uk-position-relative link uk-position-z-index"
                      >
                        Cancel
                      </a>
                    </div>
                  ) : (
                    //condition 1.2 - all other then ordinary can not change to flexi

                    <div className="uk-text-center uk-margin-right uk-margin-left">
                      <p>
                        <i style={{ color: "red" }}>
                          You cannot change your plan to {this.state.planName}.
                        </i>
                      </p>
                    </div>
                  )
                ) : //condition 2 - select button of other plan
                //condition 2.1 - ordinary is the active plan,so new payment

                this.state.currentPlanName.includes("BASIC") ? (
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <h3 className="uk-text-lead theme-color">
                      Confirm Plan Change
                    </h3>
                    <p>
                      {this.state.remainingAmount > 0
                        ? `You will have to pay ${formatNumber(
                            this.state.remainingAmount
                          )}`
                        : "Are you sure you want to change this plan ?"}
                      <br />{" "}
                      <i style={{ color: "red" }}>
                        Click Confirm to Continue or Cancel to abort.
                      </i>
                    </p>
                    <div className="uk-margin-bottom">
                      {this.state.planName == "SILVER" ? (
                        <a
                          onClick={() => this.toggleModal1(this.state.planId)}
                          className="uk-button dash-button"
                        >
                          Choose Plan
                        </a>
                      ) : this.state.planName == "GOLD" ? (
                        <a
                          onClick={() => this.toggleModal2(this.state.planId)}
                          className="uk-button dash-button"
                        >
                          Choose Plan
                        </a>
                      ) : this.state.planName == "PLATINUM" ? (
                        <a
                          onClick={() => this.toggleModal3(this.state.planId)}
                          className="uk-button dash-button"
                        >
                          Choose Plan
                        </a>
                      ) : null}
                    </div>
                    <a
                      onClick={this.confirmModal}
                      className="uk-position-relative link uk-position-z-index"
                    >
                      Cancel
                    </a>
                  </div>
                ) : //condition 2.2 - Flexi is the active plan,so no payment or change
                this.state.currentPlanName.includes("FLEXI") ? (
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <p>
                      <i style={{ color: "red" }}>
                        You cannot change your plan to {this.state.planName}.
                      </i>
                    </p>
                  </div>
                ) : (
                  //condition 2.3 - Any other active plan can change

                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <h3 className="uk-text-lead theme-color">
                      Confirm Plan Change
                    </h3>
                    <p>
                      {this.state.remainingAmount > 0
                        ? `You will have to pay ${formatNumber(
                            this.state.remainingAmount
                          )}`
                        : "Are you sure you want to change this plan ?"}
                      <br />{" "}
                      <i style={{ color: "red" }}>
                        Click Confirm to Continue or Cancel to abort.
                      </i>
                    </p>
                    <div className="uk-margin-bottom">
                      <a
                        className="uk-button dash-button"
                        onClick={this.planChange}
                      >
                        Confirm
                      </a>
                    </div>
                    <a
                      onClick={this.confirmModal}
                      className="uk-position-relative link uk-position-z-index"
                    >
                      Cancel
                    </a>
                  </div>
                )}
              </Modal>
              <Modal
                className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isChosen5}
                onRequestClose={this.toggleModal5}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Set Flexi Amount & Frequency
                  </h3>

                  <div data-uk-grid>
                    <div class="uk-width-1-2">
                      <div className="uk-margin">
                        <div className="uk-form-controls">
                          <label className="uk-form-label-dash">
                            Contribution Amount (₦)
                          </label>
                          <input
                            className="uk-input"
                            name="con_amount"
                            type="text"
                            value={this.state.con_amount}
                            onChange={(e) => this.handleFlexiChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <div className="uk-margin">
                        <div className="uk-form-controls">
                          <label className="uk-form-label-dash">
                            Select Frequency
                          </label>
                          <select
                            className="uk-select"
                            name="pf_pl_text"
                            type="text"
                            onChange={(e) => this.handleFlexiChange(e)}
                          >
                            {this.state.flexiFrequencies &&
                              this.state.flexiFrequencies.map((resp, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={resp.pf_pl_text}
                                    id={resp.pf_id}
                                  >
                                    {resp.pf_pl_text}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h4>
                    Membership Fee: <span className="theme-color"></span>₦
                    {this.state.membership_fee}
                  </h4>

                  <a
                    onClick={this.selectPlan.bind(
                      this,
                      "subscribeFlexiPlan",
                      2
                    )}
                    className="uk-button dash-button"
                  >
                    Pay with Card
                  </a>
                  <br />
                  <span className="modal-text">
                    <img width="10" src={Verified} />{" "}
                    <i>Your Dashboard will be created in few minutes</i>
                  </span>
                  <br />
                  <br />

                  <a
                    onClick={this.toggleModal5}
                    className="uk-position-relative link uk-position-z-index theme-color"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              <Modal
                className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isChosen1}
                onRequestClose={this.toggleModal1}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Select Payment Method
                  </h3>
                  <a
                    onClick={this.selectPlan.bind(this, "paymentProcess1")}
                    className="uk-button dash-button"
                  >
                    Pay with Card
                  </a>
                  <br />
                  <span className="modal-text">
                    <img width="10" src={Verified} />{" "}
                    <i>
                      To access Dashboard instantly and for seamless weekly
                      contributions
                    </i>
                  </span>
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                  <a
                    onClick={this.bankTransfer.bind(
                      this,
                      // plan.pl_plan_id
                      1
                    )}
                    className="uk-button button-out"
                  >
                    Pay via Bank Transfer
                  </a>
                  <br />
                  <span className="modal-text">
                    <i>
                      This method may take up to 24hrs to access dashboard
                      <br /> and a proof of paymemt may be required.
                    </i>
                  </span>
                  <br />
                  <br />
                  <a
                    onClick={this.toggleModal1}
                    className="uk-position-relative link uk-position-z-index theme-color"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              <Modal
                className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isChosen2}
                onRequestClose={this.toggleModal2}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Select Payment Method
                  </h3>
                  <a
                    onClick={this.selectPlan.bind(this, "paymentProcess1")}
                    className="uk-button dash-button"
                  >
                    Pay with Card
                  </a>
                  <br />
                  <span className="modal-text">
                    <img width="10" src={Verified} />{" "}
                    <i>
                      To access Dashboard instantly and for seamless weekly
                      contributions
                    </i>
                  </span>
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                  <a
                    onClick={this.bankTransfer.bind(
                      this,
                      // plan.pl_plan_id
                      2
                    )}
                    className="uk-button button-out"
                  >
                    Pay via Bank Transfer
                  </a>
                  <br />
                  <span className="modal-text">
                    <i>
                      This method may take up to 24hrs to access dashboard
                      <br /> and a proof of paymemt may be required.
                    </i>
                  </span>
                  <br />
                  <br />
                  <a
                    onClick={this.toggleModal2}
                    className="uk-position-relative link uk-position-z-index theme-color"
                  >
                    Close
                  </a>
                </div>
              </Modal>

              <Modal
                className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isChosen3}
                onRequestClose={this.toggleModal3}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Select Payment Method
                  </h3>
                  <a
                    onClick={this.selectPlan.bind(this, "paymentProcess1")}
                    className="uk-button dash-button"
                  >
                    Pay with Card
                  </a>
                  <br />
                  <span className="modal-text">
                    <img width="10" src={Verified} />{" "}
                    <i>
                      To access Dashboard instantly and for seamless weekly
                      contributions
                    </i>
                  </span>
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                  <a
                    onClick={this.bankTransfer.bind(
                      this,
                      // plan.pl_plan_id
                      3
                    )}
                    className="uk-button button-out"
                  >
                    Pay via Bank Transfer
                  </a>
                  <br />
                  <span className="modal-text">
                    <i>
                      This method may take up to 24hrs to access dashboard
                      <br /> and a proof of paymemt may be required.
                    </i>
                  </span>
                  <br />
                  <br />
                  <a
                    onClick={this.toggleModal3}
                    className="uk-position-relative link uk-position-z-index theme-color"
                  >
                    Close
                  </a>
                </div>
              </Modal>

              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <div class="uk-text-center" data-uk-grid>
                    <div class="uk-width-1-2">
                      <div className="uk-text-center">
                        <input
                          type="file"
                          onChange={this.handleImageChange.bind(this)}
                          accept="image/*"
                          value=""
                          ref={(input) => (this.photoUpload = input)}
                          hidden
                        />
                      </div>
                      <div onClick={this.uploadPhoto} className="uk-inline">
                        <img
                          className="photo-frame uk-margin-top user-photo"
                          src={
                            this.state.imageSource
                              ? this.state.imageSource
                              : this.state.userImage
                              ? this.state.userImage
                              : Allen
                          }
                          alt={Allen}
                          uk-tooltip="title: Click to upload Photo; pos: bottom"
                        />
                        {/* <div className="uk-position-center">
                          <img
                            onClick={this.uploadPhoto}
                            src={Cam}
                            uk-tooltip="title: Click to upload Photo; pos: bottom"
                            alt="upload"
                            className="uk-box-shadow-large uk-box-shadow-hover-xlarge photo-icon"
                          />
                        </div> */}
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <br />
                      <div className="uk-text-center">
                        <h3 className="uk-card-title theme-color uk-margin-remove">
                          {this.state.fullname || ""}
                        </h3>
                        <h5 className="uk-text-default theme-color uk-margin-top">
                          {this.state.email || ""}
                        </h5>
                        <h2 className="uk-card-title theme-color uk-margin-remove">
                          {this.state.tfpPin || ""}
                        </h2>
                        <a
                          onClick={this.toggleModal}
                          className="uk-button uk-visible@m uk-margin-top uk-button-small button-out"
                        >
                          Downgrade / Upgrade
                        </a>
                        <a
                          onClick={this.toggleModal}
                          className="uk-button uk-button-xsmall uk-hidden@m uk-margin-top button-out"
                        >
                          Downgrade / Upgrade
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                      <div className="uk-flex-center  uk-margin">
                        <h2 className="uk-text-large-theme theme-color uk-text-center">
                          Profile
                          <br />
                        </h2>
                        <br />
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Full Name{" "}
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="firstname"
                              id="reg-firstname"
                              type="text"
                              placeholder="Firstname and Lastname"
                              autoComplete="off"
                              maxLength="50"
                              validate={[
                                Validation.firstNameRequired,
                                Validation.fullNameAlphabetOnly,
                              ]}
                            />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">Email</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="email"
                              id="reg-email"
                              type="text"
                              autoComplete="username"
                              readOnly
                              // validate={[
                              //   Validation.emailRequired,
                              //   Validation.isEmail
                              // ]}
                            />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Trust Fund Pin (TFP)
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="tfppin"
                              type="text"
                              autoComplete="username"
                              readOnly
                            />
                          </div>
                        </div>

                        <div data-uk-grid>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Sex
                                </label>
                                <Field
                                  component={SelectRenderField}
                                  className="uk-select"
                                  name="sex"
                                  type="text"
                                  validate={[Validation.genderRequired]}
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Phone No.{" "}
                                </label>
                                <Field
                                  component={PhoneInput}
                                  name="phonenumber"
                                  autoComplete="off"
                                  placeholder="Start with country code e.g 234"
                                  maxLength="18"
                                  validate={[
                                    Validation.phoneNumberRequired,
                                    Validation.phoneNumberLength,
                                  ]}
                                  normalize={Validation.normalizeNumber}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div data-uk-grid>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Birthday
                                </label>
                                <Field
                                  component={RenderField}
                                  className="uk-input"
                                  name="dob"
                                  id="reg-dob"
                                  type="text"
                                  placeholder="Birthday (e.g: 05-05)"
                                  autoComplete="off"
                                  maxLength="50"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Phone No.
                            </label>
                            <Field
                              component={PhoneInput}
                              name="phonenumber"
                              autoComplete="off"
                              maxLength="15"
                              validate={[
                                Validation.phoneNumberRequired,
                                Validation.phoneNumberLength
                              ]}
                              normalize={Validation.normalizeNumber}
                            />

                            {/* <Field
                              component={RenderField}
                              className="uk-input"
                              name="phonenumber"
                              type="text"
                              autoComplete="off"
                              maxLength="10"
                              validate={[
                                Validation.phoneNumberRequired,
                                Validation.phoneNumberLength
                              ]}
                              normalize={Validation.normalizeNumber}
                            /> 
                          </div>
                        </div> */}

                        <div data-uk-grid>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Business Sector
                                </label>
                                <Field
                                  component={SelectRenderField}
                                  className="uk-select"
                                  name="business"
                                  type="text"
                                  validate={[Validation.businessSectorRequired]}
                                >
                                  <option value="">Select</option>
                                  <option value="Accounting/Finance">
                                    Accounting/Finance
                                  </option>
                                  <option value="Advertising/Public Relations">
                                    Advertising/Public Relations
                                  </option>
                                  <option value="Aerospace/Aviation">
                                    Aerospace/Aviation
                                  </option>
                                  <option value="Agriculture">
                                    Agriculture
                                  </option>
                                  <option value="Arts/Entertainment/Publishing">
                                    Arts/Entertainment/Publishing
                                  </option>
                                  <option value="Automotive">Automotive</option>
                                  <option value="Banking/Mortgage<">
                                    Banking/Mortgage
                                  </option>
                                  <option value="Business Development">
                                    Business Development
                                  </option>
                                  <option value="Business Opportunity">
                                    Business Opportunity
                                  </option>
                                  <option value="Clerical/Administrative">
                                    Clerical/Administrative
                                  </option>
                                  <option value="Construction/Facilities">
                                    Construction/Facilities
                                  </option>
                                  <option value="Consumer Goods">
                                    Consumer Goods
                                  </option>
                                  <option value="Corper (NYSC)">
                                    Corper (NYSC)
                                  </option>
                                  <option value="Customer Service">
                                    Customer Service
                                  </option>
                                  <option value="Education/Training">
                                    Education/Training
                                  </option>
                                  <option value="Energy/Utilities">
                                    Energy/Utilities
                                  </option>
                                  <option value="Engineering">
                                    Engineering
                                  </option>
                                  <option value="Government/Military">
                                    Government/Military
                                  </option>
                                  <option value="Green">Green</option>
                                  <option value="Healthcare">Healthcare</option>
                                  <option value="Hospitality/Travel">
                                    Hospitality/Travel
                                  </option>
                                  <option value="Human Resources">
                                    Human Resources
                                  </option>
                                  <option value="Installation/Maintenance">
                                    Installation/Maintenance
                                  </option>
                                  <option value="Insurance">Insurance</option>
                                  <option value="Internet">Internet</option>
                                  <option value="Job Search Aids">
                                    Job Search Aids
                                  </option>
                                  <option value="Law Enforcement/Security">
                                    Law Enforcement/Security
                                  </option>
                                  <option value="Legal">Legal</option>
                                  <option value="Management/Executive">
                                    Management/Executive
                                  </option>
                                  <option value="Manufacturing/Operations">
                                    Manufacturing/Operations
                                  </option>
                                  <option value="Marketing">Marketing</option>
                                  <option value="Non-Profit/Volunteer">
                                    Non-Profit/Volunteer
                                  </option>
                                  <option value="Pharmaceutical/Biotech">
                                    Pharmaceutical/Biotech
                                  </option>
                                  <option value="Professional Services">
                                    Professional Services
                                  </option>
                                  <option value="QA/Quality Control">
                                    QA/Quality Control
                                  </option>
                                  <option value="Real Estate">
                                    Real Estate
                                  </option>
                                  <option value="Restaurant/Food Service">
                                    Restaurant/Food Service
                                  </option>
                                  <option value="Retail">Retail</option>
                                  <option value="Sales">Sales</option>
                                  <option value="Science/Research">
                                    Science/Research
                                  </option>
                                  <option value="Skilled Labour">
                                    Skilled Labour
                                  </option>
                                  <option value="Student">Student</option>
                                  <option value="Technology">Technology</option>
                                  <option value="Telecommunications">
                                    Telecommunications
                                  </option>
                                  <option value="Transportation/Logistics">
                                    Transportation/Logistics
                                  </option>
                                  <option value="Others">Others</option>
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  How did you hear about us?
                                </label>
                                <Field
                                  component={SelectRenderField}
                                  className="uk-select"
                                  name="hearaboutus"
                                  type="text"
                                >
                                  <option value="Trust Partner">
                                    Trust Partner
                                  </option>
                                  <option value="Television">Television</option>
                                  <option value="Internet">Internet</option>
                                  <option value="Friend">Friend</option>
                                  <option value="Family">Family</option>
                                  <option value="Colleague">Colleague</option>
                                  <option value="Social Media">
                                    Social Media
                                  </option>
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="uk-margin higher-index">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Address
                            </label>
                            <Field
                              component={GoogleAutocomplete}
                              className="uk-input"
                              name="address"
                              type="text"
                              placeholder="Enter Address"
                              autoComplete="off"
                              validate={[Validation.addressRequired]}
                              maxLength="100"
                            />
                          </div>
                        </div>

                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Next of Kin Fullname{" "}
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="nxtkinname"
                              id="reg-nxtkin"
                              type="text"
                              placeholder="Enter Firstname and Lastname"
                              autoComplete="off"
                              maxLength="50"
                              validate={[
                                Validation.firstNameRequired,
                                Validation.fullNameAlphabetOnly,
                              ]}
                            />
                          </div>
                        </div>
                        <div data-uk-grid>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Next of Kin (Email){" "}
                                </label>
                                <Field
                                  component={RenderField}
                                  className="uk-input"
                                  name="nxtkinemail"
                                  id="nxtkin-email"
                                  placeholder="Enter a valid email"
                                  type="text"
                                  autoComplete="email"
                                  validate={[
                                    Validation.emailRequired,
                                    Validation.isEmail,
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="uk-width-1-2">
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                <label className="uk-form-label-dash">
                                  Next of Kin (Phone No.){" "}
                                </label>
                                <Field
                                  component={PhoneInput}
                                  name="nxtkinphonenumber"
                                  autoComplete="off"
                                  placeholder="Enter a valid phone number"
                                  maxLength="18"
                                  validate={[
                                    Validation.phoneNumberRequired,
                                    Validation.phoneNumberLength,
                                  ]}
                                  normalize={Validation.normalizeNumber}
                                />
                                <Link to="/changepassword">
                                  <span className="uk-button item-right">
                                    Change Password{" "}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                    <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                      <div className="uk-flex-center  uk-margin">
                        <h2 className="uk-text-large-theme theme-color uk-text-center">
                          Account Settings
                          <br />
                        </h2>
                        <br />
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Bank Name{" "}
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="bankname"
                              type="text"
                              placeholder="Enter correct bank name (no acronyms)"
                              autoComplete="off"
                              maxLength="50"
                              validate={[
                                Validation.bankNameRequired,
                                Validation.bankNamealphaOnly,
                              ]}
                            />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Account Holder's Name{" "}
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="accountholdername"
                              type="text"
                              placeholder="Enter name as registered with bank"
                              autoComplete="off"
                              validate={[
                                Validation.accountHolderNameRequired,
                                Validation.accountHolderNamealphaOnly,
                              ]}
                            />
                          </div>
                        </div>
                        <div className="uk-margin uk-margin-bottom">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">
                              Account Number{" "}
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="accountnumber"
                              type="text"
                              autoComplete="off"
                              placeholder="Enter correct account number"
                              maxLength="16"
                              validate={[
                                Validation.accountNumberRequired,
                                Validation.accNumberLength,
                              ]}
                              normalize={Validation.normalizeNumber}
                            />

                            <span
                              className="uk-button item-right"
                              onClick={() => this.changeCard()}
                            >
                              Change Bank Card{" "}
                            </span>
                          </div>
                        </div>
                        <br />
                        <p className="small-text item">
                          *Please note that you will be redirected to the
                          payment gateway and <b>debited 100</b> to verify your
                          card, please wait to be redirected back here after
                          payment. <b>Do not interrupt the process.</b>
                        </p>
                        <div className="uk-text-center uk-margin-bottom">
                          <button
                            className="uk-button dash-button"
                            type="submit"
                            disabled={submitting}
                          >
                            Update Profile
                          </button>
                          <br />
                        </div>
                        <div className="uk-text-center uk-margin-bottom">
                          <Link to="/dashboard" className="uk-text-lead link">
                            Cancel
                          </Link>
                          <br />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

Profile = reduxForm({
  form: "updateProfileForm",
  enableReinitialize: true,
  touchOnBlur: false,
  destroyOnUnmount: true,
})(Profile);

export default connect(
  (state) => ({
    mapStateToProps,
    initialValues: {
      firstname: state.user.userData.u_first_name || "",
      email: state.user.userData.u_email || "",
      tfppin: state.user.userData.u_tfp_pin || "",
      phonenumber: state.user.userData.u_phone || "",
      business: state.user.userData.u_business_sector || "",
      address: state.user.userData.u_address || "",
      bankname: state.user.userData.up_bank_name || "",
      accountnumber: state.user.userData.up_account_number || "",
      accountholdername: state.user.userData.up_bank_holder_name || "",
      sex: state.user.userData.u_gender || "",
      dob: state.user.userData.u_dob || "",
      hearaboutus: state.user.userData.u_hear_about_us || "",
      nxtkinname: state.user.userData.u_kin_name || "",
      nxtkinphonenumber: state.user.userData.u_kin_contact || "",
      nxtkinemail: state.user.userData.u_kin_email || "",
      userImage: state.user.userData.u_image || "",
    },
  }),

  { actionGetUserProfile, actionUpdateProfile, actionGetPlans, actionBuyPlan }
)(Profile);
