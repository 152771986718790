import React from "react";

export default function FAQ({
  faqData,
  viewMoreQuestions,
  viewLessQuestions,
  viewFAQData,
}) {
  return (
    <>
      <section id="faq">
        <div className="container container-main home-margin-top3">
          <div className="grid center-xs">
            <h3 className="uk-text-center">FAQs</h3>
          </div>
          <div className="faq-container">
            <ul uk-accordion="targets:> *" className="uk-text-center">
              {faqData && faqData.length > 0
                ? faqData.slice(0, viewFAQData).map((item, index) => (
                    <li className="uk-text-left" key={item.id}>
                      <a className="uk-accordion-title faq-q" href="#">
                        {item.fq_questions}
                      </a>
                      <div className="uk-accordion-content">
                        <p className="faq-ans">{item.fq_answers} </p>
                      </div>
                    </li>
                  ))
                : null}

              {viewFAQData > 4 ? (
                <a
                  className="link uk-margin uk-text-center"
                  onClick={viewLessQuestions}
                >
                  <span uk-icon="icon: chevron-up" /> Show less
                </a>
              ) : null}

              {faqData && faqData.length > viewFAQData ? (
                <a
                  className="link uk-margin uk-margin-left uk-text-center"
                  onClick={viewMoreQuestions}
                >
                  Show more <span uk-icon="icon: chevron-down" />
                </a>
              ) : null}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
