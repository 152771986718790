import React, { Component } from "react";
import { Field, reduxForm, reset, change, untouch } from "redux-form";
// import { Link } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import * as qs from "query-string";

import DynaLogo from "../assets/img/aye-loader.gif";
import { actionLogin } from "../common/redux/actions/";
import "../assets/css/main.css";
import Sidebar from "./Sidebar";
import RenderField from "./RenderField";
import Validation from "../common/helpers/Validation";
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
  base64_decode,
  clearLocalStorage,
} from "../common/helpers/Utils";
import trustFundApiService from "../common/api/ApiService";
import { userInfo } from "os";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termschecked: "0",
      loader: false,
    };
  }

  componentDidMount() {
    document.title = "Log In - If You TRUST Me I'll TRUST You";
    this.props.dispatch(reset("loginForm"));
    this.setLoginData();
    const { location } = this.props;
    if (location.search) {
      const parsed = qs.parse(location.search);
      if (parsed.type === "emailverification") {
        if (getLocalStorage("userInfo")) {
          clearLocalStorage();
        }

        let email = parsed.email;
        let type = parsed.type;
        let authCode = parsed.authcode;
        this.verifyEmail(email, type, authCode);
      }
      // if accept or reject from trustmembers email
      else if (parsed.type === "trustmembers") {
        let trustMemberData = {
          trustrequest: parsed.trustrequest,
          byuserid: parsed.byuserid,
          touserid: parsed.touserid,
          type: "trustmembers",
          email: base64_decode(parsed.email),
          pass: parsed.pass,
        };
        if (this.props.userData.u_id === trustMemberData.touserid) {
          this.props.history.push({
            pathname: "/trustmembers",
            state: {
              trustMemberData,
            },
          });
        } else {
          this.autoLogin(trustMemberData);
        }
      } else if (parsed.type == "admin_login") {
        console.log("Dsf");
        let loginData = {
          email: parsed.email,
          pass: parsed.password,
        };
        console.log(loginData);
        this.adminLoginToUserAccount(loginData);
      }
      // if accept or reject from notification email
      else {
        let notificationData = {
          loanid: parsed.loanid,
          uid: parsed.uid,
          trustresponse: parsed.trustresponse,
          email: base64_decode(parsed.email),
          pass: parsed.pass,
          type: "notifications",
        };

        if (this.props.userData.u_id === notificationData.uid) {
          this.props.history.push({
            pathname: "/notifications",
            state: {
              loanid: notificationData.loanid,
              trustresponse: notificationData.trustresponse,
              uid: notificationData.uid,
            },
          });
        } else {
          this.autoLogin(notificationData);
        }
      }
    } else {
      if (
        this.props.userData &&
        this.props.userData.u_id &&
        this.props.userData.u_tfp_pin
      ) {
        this.props.history.push("/dashboard");
      }
    }
  }

  verifyEmail = (email, type, authcode) => {
    if (email && type) {
      let data = {
        email,
        type,
        authcode,
      };
      let _this = this;
      //  console.log(data);
      trustFundApiService("emailVerification", data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.props.history.push("/login");
          } else {
            _this.props.history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  autoLogin = (data) => {
    let _this = this;
    if (data && data.email && data.pass) {
      let _data = {
        email: data.email,
        password: data.pass,
        type: "admin",
      };
      if (data) {
        this.props
          .actionLogin(_data)
          .then((result) => {
            if (result && result.data && result.data.status === "success") {
              if (data.type === "notifications") {
                _this.props.history.push({
                  pathname: "/notifications",
                  state: {
                    loanid: data.loanid,
                    trustresponse: data.trustresponse,
                    uid: data.uid,
                  },
                });
              } else if (data.type === "trustmembers") {
                this.props.history.push({
                  pathname: "/trustmembers",
                  state: {
                    trustMemberData: data,
                  },
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  adminLoginToUserAccount = (data) => {
    let _this = this;
    if (data && data.email && data.pass) {
      let _data = {
        email: data.email,
        password: data.pass,
        type: "admin_login",
      };
      if (data) {
        this.props
          .actionLogin(_data)
          .then((result) => {
            if (result && result.data && result.data.status === "success") {
              _this.props.history.push({
                pathname: "/userdashboard",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  // if remember checkbox is checked
  setLoginData = () => {
    if (getLocalStorage("remember")) {
      let loginInfo = getLocalStorage("remember");
      this.props.initialize({
        email: loginInfo.email,
        password: loginInfo.password,
        rememberme: true,
      });
    }
  };

  handleSubmit = (formValues) => {
    let data = formValues;
    const _this = this;
    _this.setState({ loader: true });
    this.props
      .actionLogin(data)
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          setLocalStorage("userInfo", result.data.data);
          _this.setState({ loader: false });
          if (data.rememberme === true) {
            let loginInfo = {
              email: data.email,
              password: data.password,
            };
            setLocalStorage("remember", loginInfo);
          } else {
            removeLocalStorage("remember");
          }
          if (
            result.data.data.u_plan_id === "" &&
            result.data.data.u_plan_name === ""
          ) {
            setLocalStorage("fromRegister", 1);
            _this.props.history.push("/viewplans");
          } else {
            this.props.history.push("/dashboard");
          }
        } else {
          _this.setState({ loader: false });
          this.props.dispatch(change("loginForm", "password", ""));
          this.props.dispatch(untouch("loginForm", "password", ""));
        }
      })
      .catch((error) => {
        _this.setState({ loader: false });
        console.log(error);
      });
  };

  // trusted or declined loan request
  getTrustPartnerResponseOnLoan = (loanid, uid, trustresponse) => {
    let _this = this;
    if (loanid && uid) {
      let data = {
        trustresponse,
        loanid,
        uid,
        termschecked: _this.state.termschecked,
      };

      trustFundApiService("getTrustPartnerResponseOnLoan", data)
        .then((response) => {
          if (response.data.status === "success") {
            _this.props.history.push({
              pathname: "/notifications",
              state: { loanid: loanid, trustresponse: trustresponse, uid: uid },
            });
          } else {
            _this.props.history.push({
              pathname: "/notifications",
              state: { loanid: loanid, trustresponse: trustresponse, uid: uid },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <section id="bg">
        <div
          id="register-view"
          className="uk-width-1-1@m uk-child-width-1-2@m"
          data-uk-grid
        >
          <div
            id="signin"
            className="uk-flex-middle uk-flex-center uk-flex-last@m uk-margin-remove"
            data-uk-grid
          >
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className=" uk-width-4-5 uk-padding-remove-left">
              <div className="uk-height-1-1 loginReg">
                <h2 className="uk-h3 theme-color">Account Login</h2>
                <form
                  className="uk-form-stacked"
                  id="loginForm"
                  onSubmit={handleSubmit((values) =>
                    this.handleSubmit({
                      ...values,
                      email:
                        values && values.email
                          ? values.email.toLowerCase()
                          : "",
                    })
                  )}
                >
                  <div className="uk-margin-medium-top">
                    <div className="uk-form-controls">
                      <Field
                        component={RenderField}
                        className="uk-input"
                        name="email"
                        id="reg-email"
                        type="text"
                        placeholder="Enter your email"
                        autoComplete="username"
                        validate={[
                          Validation.emailRequired,
                          Validation.isEmail,
                        ]}
                      />
                      <label className="uk-form-label" htmlFor="usname">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="uk-margin-medium-top">
                    <div className="uk-form-controls">
                      <Field
                        component={RenderField}
                        className="uk-input"
                        name="password"
                        id="reg-password"
                        type="password"
                        placeholder="Enter your password"
                        autoComplete="new-password"
                        maxLength="40"
                        validate={[
                          Validation.passwordRequired,
                          Validation.noSpace,
                        ]}
                      />
                      <label className="uk-form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                  </div>
                  <label className="uk-margin-top uk-display-inline-block">
                    <Field
                      name="rememberme"
                      id="reg-rememberme"
                      component={RenderField}
                      type="checkbox"
                      className="uk-checkbox"
                    />{" "}
                    Save Password
                  </label>
                  <label className="uk-margin-top uk-text-right uk-display-inline-block uk-align-right">
                    <Link to="/forgot">Forgot password ?</Link>
                  </label>
                  <div className="uk-margin-medium-top">
                    <button
                      className="uk-button prog-button"
                      type="submit"
                      disabled={submitting}
                    >
                      <span>Login</span>
                    </button>
                    <Link
                      to="/register"
                      className="uk-link uk-link-reset uk-margin-left"
                    >
                      <span className="uk-position-relative uk-position-z-index">
                        No Account?{" "}
                        <span
                          className="theme-color"
                          onClick={() => this.props.history.push("/register")}
                        >
                          Click to Join Now
                        </span>
                      </span>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <Link
              className="uk-link uk-hidden@m uk-link-reset uk-margin-right"
              to="/"
            >
              <br />
              <span className="theme-color">Go Home</span>
            </Link>
          </div>
          <Sidebar pageName="Login" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  const { userData } = state.user;
  return { userData };
};

Login = connect(mapStateToProps, { actionLogin })(Login);

export default reduxForm({
  form: "loginForm",
  touchOnBlur: false,
  destroyOnUnmount: true,
})(withRouter(Login));
