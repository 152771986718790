import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

class GoogleAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
    this.onAddressChange = this.onAddressChange.bind(this);
  }

  componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        address: this.props.input.value
      });
    }
  }

  onAddressChange(address) {
    const { input } = this.props;
    const { onChange } = input;
    this.setState({ address });
    onChange(address);
  }

  render() {
    const inputProps = {
      value: this.state.address,
      type: 'search',
      onChange: this.onAddressChange
    };

    const cssClasses = {
      root: 'input-group mb-1',
      input: 'uk-input'
    };

    const defaultStyles = {
      root: {
        position: 'relative',
        paddingBottom: '0px'
      },
      input: {
        display: 'inline-block',
        width: '100%',
        padding: '10px'
      },
      autocompleteContainer: {
        position: 'absolute',
        top: '100%',
        backgroundColor: 'white',
        border: '1px solid #555555',
        width: '100%'
      },
      autocompleteItem: {
        backgroundColor: '#ffffff',
        padding: '10px',
        color: '#555555',
        cursor: 'pointer'
      },
      autocompleteItemActive: {
        backgroundColor: '#fafafa'
      }
    };

    return (
      <PlacesAutocomplete
        inputProps={inputProps}
        classNames={cssClasses}
        styles={defaultStyles}
      />
    );
  }
}

GoogleAutocomplete.propTypes = {
  input: PropTypes.object,
  classes: PropTypes.object
};

export default GoogleAutocomplete;
