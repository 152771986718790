import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from '../Home/Home';

// Registration Pages
import Register from '../authorization/registration/Register';
import PlanView from '../authorization/registration/PlanView';
import TrustPlans from '../authorization/registration/TrustPlans';
import TrustPartner from '../authorization/registration/TrustPartner';
import TrustNetwork from '../authorization/registration/TrustNetwork';
import Login from '../authorization/Login';
import ForgotPassword from '../authorization/ForgotPassword';
import ResetPassword from '../authorization/ResetPassword';
import OTP from '../authorization/registration/OTP';

import Dashboard from '../dashboard/Dashboard';
import AdminDashboard from '../dashboard/adminDashboard';
import Success from '../authorization/Success';
import AddCard from '../authorization/registration/AddCard';
import BankTransfer from '../authorization/registration/BankTransfer';
import WeeklyContribution from '../WeeklyContribution';
import UpFrontContribution from '../UpFrontContribution';
import WeeklyEarnings from '../TransferEarnings';
import Transactions from '../transactions/Transactions';
import Profile from '../Profile';
import Loan from '../Loan';
import AddTrusters from '../AddTrusters';
import ConfirmLoan from '../ConfirmLoan';
import LoanView from '../LoanView';
import TrustMembers from '../TrustMembers';
import BuildNetwork from '../BuildNetwork';
import TrustWallet from '../TrustWallet';
import ChangeCard from '../ChangeCard';
import ChangePassword from '../ChangePassword';
import Terms from '../Terms';
import ErrorPage from '../ErrorPage';
import Privacy from '../Privacy';
import More from '../More';
import Notifications from '../Notifications';
import Etc from '../Etc';
import Pop from '../Pop';
import Chat from '../Chat';
import UserChat from '../UserChat';
import Bissi from '../Bissi';
import TrustMarket from '../TrustMarket';
import ChatCustomerCare from '../ChatCustomerCare';
import ChatWithBissi from '../ChatWithBissi';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Jobs from '../Jobs';
import Loans from '../Loans';

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer
          autoClose={10000}
          className="custom-toaster-main-cls"
          toastClassName="custom-toaster-bg"
        />{' '}
        <Switch>
          <Route exact path="/" component={Home} />

          <PublicRoute path="/register" component={Register} />
           <PublicRoute path="/loans" component={Loans} />
          <Route path="/login" component={Login} />
          <PublicRoute path="/forgot" component={ForgotPassword} />
          <PublicRoute path="/reset" component={ResetPassword} />

          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/learn-more" component={More} />
          <Route path="/otp" component={OTP} />
          <Route path="/carddetails" component={AddCard} />
          <Route path="/transfer" component={BankTransfer} />
          <Route path="/support" component={ChatWithBissi} />
          <Route path="/chat-support" component={Bissi} />
          <Route path="/trustnetwork" component={TrustNetwork} />
          <Route path="/trustpartner" component={TrustPartner} />
          <Route exact path="/success" component={Success} />

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/trustplans" component={TrustPlans} />
          <Route path="/viewplans" component={PlanView} />

          <Route path="/jobs" component={Jobs} />

          {/* <PrivateRoute path="/trustplans" component={TrustPlans} />
          <PrivateRoute path="/viewplans" component={PlanView} /> */}

          <PrivateRoute
            path="/weeklycontribution"
            component={WeeklyContribution}
          />
          <PrivateRoute
            path="/savemore"
            component={UpFrontContribution}
          />
          <PrivateRoute
            path="/weeklyearnings"
            component={WeeklyEarnings}
          />

          <PrivateRoute path="/userchat" component={UserChat} />
          <PrivateRoute path="/notifications" component={Notifications} />
          <PrivateRoute path="/pop" component={Pop} />
          <PrivateRoute path="/etc" component={Etc} />
          <PrivateRoute path="/chat" component={Chat} />
          <PrivateRoute path="/profile" component={Profile} />
          {/* <Route path="/profile" component={Profile} /> */}
          <PrivateRoute path="/loan" component={Loan} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/addtrusters" component={AddTrusters} />
          <PrivateRoute path="/confirmloan" component={ConfirmLoan} />
          <PrivateRoute path="/viewloan" component={LoanView} />
          <PrivateRoute path="/trustmembers" component={TrustMembers} />
          <PrivateRoute path="/buildnetwork" component={BuildNetwork} />
          <PrivateRoute path="/trustwallet" component={TrustWallet} />
          <PrivateRoute path="/changecard" component={ChangeCard} />
          <PrivateRoute path="/changepassword" component={ChangePassword} />
          <PrivateRoute path="/userdashboard" component={AdminDashboard} />
          <PrivateRoute path="/trustmarket" component={TrustMarket} />
          <PrivateRoute path="/customercare" component={ChatCustomerCare} />

          <Route path="*" component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default Routes;
