import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { formatNumber } from './common/helpers/Utils';
import { showErrorToast } from './common/helpers/Toastr';
import DynaLogo from './assets/img/aye-loader.gif';

class TrustWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEarnings: 0,
      redeemedEarnings: 0,
      currentLoan: 0,
      outstandingLoan: 0,
      currentETC: 0,
      outstandingETC: 0,
      currentPOP: 0,
      outstandingPOP: 0,
      loader: false,
      type: '',
      loanStatus: 0,
      isRepay: false,
      isWithdraw: false
    };
  }

  componentDidMount() {
    document.title = "Trust Wallet - If You TRUST Me I'll TRUST You";
    this.getTrustWallet();
  }

  redeemEarnings = () => {
    let totalEarnings = this.state.earnings;
    let data = {
      redeem_amount: totalEarnings,
    };
    if (parseInt(totalEarnings) >= 5000) {
      this.setState({
        loader: true,
      });
      trustFundApiService("redeemEarningsDB", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    } else {
      this.setState(
        {
          loader: false,
          message:
            "You can only redeem your earnings if you have N5000 or above",
          title: "Redeem Earnings",
        },
        () => this.toggleModal()
      );
    }
  };

  toggleModal = type => {
    // if (type === 'Redeem') {
    //   this.setState({
    //     message: 'Are you sure you want to redeem this earnings ?',
    //     title: 'Redeem Earnings',
    //     isActive: !this.state.isActive,
    //     type: this.redeemEarnings
    //   });
    // } else if (type === 'Loan') {
    if (type === 'Loan') {
      this.setState({
        message: 'Are you sure you want to repay loan ?',
        title: 'Repay Loan',
        isActive: !this.state.isActive,
        type: this.repayLoan
      });
    } else if (type === 'POP') {
      this.setState({
        message: 'Are you sure you want to repay POP ?',
        title: 'Repay POP',
        isActive: !this.state.isActive,
        type: this.repayPOP
      });
    } else if (type === 'ETC') {
      this.setState({
        message: 'Are you sure you want to repay ETC ?',
        title: 'Repay ETC',
        isActive: !this.state.isActive,
        type: this.repayETC
      });
    } else if (type === 'cancelPOP') {
      this.setState({
        message: 'Are you sure you want to cancel POP ?',
        title: 'Cancel POP',
        isActive: !this.state.isActive,
        type: this.cancelPOP
      });
    } else if (type === 'cancelETC') {
      this.setState({
        message: 'Are you sure you want to cancel ETC ?',
        title: 'Cancel ETC',
        isActive: !this.state.isActive,
        type: this.cancelETC
      });
    }
  };

  toggleModal1 = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  withdrawModal = () => {
    this.setState({
      isWithdraw: !this.state.isWithdraw
    });
  };

  repayModal = type => {
    if (type === 'Loan') {
      this.setState({
        repaytitle: 'Repay Loan',
        isRepay: !this.state.isRepay,
        repaytype: this.repayLoan
      });
    } else if (type === 'POP') {
      this.setState({
        repaytitle: 'Repay POP',
        isRepay: !this.state.isRepay,
        repaytype: this.repayPOP
      });
    } else if (type === 'ETC') {
      this.setState({
        repaytitle: 'Repay ETC',
        isRepay: !this.state.isRepay,
        repaytype: this.repayETC
      });
    } else if (type === 'close') {
      this.setState({
        isRepay: !this.state.isRepay
      });
    }
  };

  getTrustWallet = () => {
    trustFundApiService('trustWallet')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          this.setState({
            currentEarnings: data.earnings.tot_earned,
            redeemedEarnings: data.earnings.redeem_earned,
            currentLoan: data.loans.current_loan,
            outstandingLoan: data.loans.outstanding_loan,
            loanStatus: data.loans.loan_status,
            currentETC: data.etc.current_etc,
            outstandingETC: data.etc.outstanding_etc,
            ETCStatus: data.etc.etc_status,
            currentPOP: data.pop.current_pop,
            outstandingPOP: data.pop.outstanding_pop,
            POPStatus: data.pop.pop_status
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  cancelPOP = () => {
    this.setState({
      isActive: !this.state.isActive,
      loader: true
    });
    trustFundApiService('cancelPOP')
      .then(response => {
        if (response.data.status === 'success') {
          this.getTrustWallet();
          this.setState({
            loader: false
          });
        } else {
          this.setState({
            loader: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loader: false
        });
        console.log(err);
      });
  };

  cancelETC = () => {
    this.setState({
      isActive: !this.state.isActive,
      loader: true
    });
    trustFundApiService('cancelETC')
      .then(response => {
        if (response.data.status === 'success') {
          this.getTrustWallet();
          this.setState({
            loader: false
          });
        } else {
          this.setState({
            loader: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loader: false
        });
        console.log(err);
      });
  };

  redeemEarnings = () => {
    let totalEarnings = this.state.currentEarnings;
    let data = {
      redeem_amount: totalEarnings
    };
    if (parseInt(totalEarnings) >= 5000) {
      this.setState({
        loader: true
      });
      trustFundApiService('redeemEarnings', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getTrustWallet();
            this.setState(
              {
                loader: false
              },
              () => this.toggleModal1()
            );
          } else {
            this.setState(
              {
                loader: false
              },
              () => this.toggleModal1()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    } else {
      this.setState({
        isActive: false
      });
      showErrorToast(
        'You can only redeem your earnings if you have N5000 or above'
      );
    }
  };

  repayLoan = () => {
    let loan_amount = this.state.outstandingLoan;
    let data = {
      loan_amount
    };
    if (loan_amount > 0) {
      this.setState({
        loader: true,
        isRepay: false
      });
      trustFundApiService('repayLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getTrustWallet();
            this.setState(
              {
                loader: false
              }
              // () => this.toggleModal1()
            );
          } else {
            this.setState(
              {
                loader: false
              }
              // () => this.toggleModal1()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    } else {
      showErrorToast('Loan amount should be greater than 0');
    }
  };

  repayETC = () => {
    let etc_amount = this.state.outstandingETC;
    let data = {
      etc_amount
    };
    if (etc_amount > 0) {
      this.setState({
        loader: true,
        isRepay: false
      });
      trustFundApiService('repayETC', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getTrustWallet();
            this.setState(
              {
                loader: false
              }
              // () => this.toggleModal1()
            );
          } else {
            this.setState(
              {
                loader: false
              }
              // () => this.toggleModal1()
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              loader: false
            }
            //() => this.toggleModal1()
          );
          console.log(err);
        });
    }
  };

  repayPOP = () => {
    let pop_amount = parseInt(this.state.outstandingPOP);
    let data = {
      pop_amount
    };
    if (pop_amount > 0) {
      this.setState({
        loader: true,
        isRepay: false
      });
      trustFundApiService('repayPOP', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getTrustWallet();
            this.setState(
              {
                loader: false
              }
              //() => this.toggleModal1()
            );
          } else {
            this.setState(
              {
                loader: false
              }
              //() => this.toggleModal1()
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              loader: false
            }
            //  () => this.toggleModal1()
          );
          console.log(err);
        });
    }
  };

  refundCancelLoan = action => {
    let data = {
      loan_action: action
    };
    if (data) {
      this.setState({
        isWithdraw: false,
        loader: true
      });
      trustFundApiService('refundOrCancelLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getTrustWallet();
            this.setState({
              loader: false
            });
          } else {
            this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          if (action !== 2) {
            this.setState(
              {
                loader: false
              },
              () => this.withdrawModal()
            );
          } else {
            this.setState({
              loader: false
            });
          }
          console.log(err);
        });
    }
  };

  render() {
    const cur_name = this.props.userData.cur_name || '';
    const u_tfp_pin = this.props.userData.u_tfp_pin || '';
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.title}
                  </h3>
                  <p>{this.state.message} </p>
                  <div className="uk-margin-bottom">
                    <a
                      className="uk-button dash-button"
                      onClick={this.state.type}
                    >
                      Confirm
                    </a>
                  </div>
                  <a
                    onClick={this.toggleModal1}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Cancel
                  </a>
                </div>
              </Modal>

              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isRepay}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.repaytitle}{' '}
                    {this.state.repaytitle === 'Repay POP' ? (
                      <span>
                        : {cur_name}
                        {formatNumber(this.state.outstandingPOP) || 0}
                      </span>
                    ) : this.state.repaytitle === 'Repay ETC' ? (
                      <span>
                        : {cur_name}
                        {formatNumber(this.state.outstandingETC) || 0}
                      </span>
                    ) : this.state.repaytitle === 'Repay Loan' ? (
                      <span>
                        : {cur_name}
                        {formatNumber(this.state.outstandingLoan) || 0}
                      </span>
                    ) : null}
                  </h3>
                  <button
                    onClick={this.state.repaytype}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Click to Pay from Bank Card
                  </button>
                  <div className="">
                    <table className="uk-table uk-table-justify uk-table-divider">
                      <thead>
                        <tr>
                          <th className="uk-width-small theme-color">
                            FOR MANUAL PAYMENT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="uk-text-left">Bank Name:</td>
                          <td className="uk-text-left">Guarantee Trust Bank</td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Name:</td>
                          <td className="uk-text-left">A.Y.E Trust Fund</td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Number:</td>
                          <td className="uk-text-left">0454179544</td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Narration:</td>
                          <td className="uk-text-left">{u_tfp_pin}</td>
                        </tr>
                      </tbody>
                    </table>
                    <a
                      onClick={this.repayModal.bind(this, 'close')}
                      className="uk-position-relative link"
                    >
                      Close
                    </a>
                  </div>
                </div>
              </Modal>
              {/*REPAY MODAL*/}

              {/*WITHDRAW MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isWithdraw}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <br />
                  <button
                    onClick={this.refundCancelLoan.bind(this, 1)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Request Loan Withdrawal
                  </button>
                  <br />
                  <div>
                    <span className="uk-text-lead text-red">OR</span>
                  </div>
                  <br />
                  <button
                    onClick={this.refundCancelLoan.bind(this, 2)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Refund Loan
                  </button>
                  <br />
                  <a
                    onClick={this.withdrawModal}
                    className="uk-position-relative link"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              {/*WITHDRAW MODAL*/}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom uk-flex-wrap uk-padding-small">
                  <h2 className="theme-color uk-text-center">
                    Trust Earnings
                    <br />
                  </h2>
                  <div className="uk-flex uk-flex-middle uk-flex-between">
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove theme-color">
                        {formatNumber(this.state.currentEarnings) || 0}
                      </h2>
                      <span>CURRENT</span>
                    </div>
                    {/*<div className="border">
                      <br />
                      <br />
                      <br />
                    </div>*/}
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove text-red">
                        {formatNumber(this.state.redeemedEarnings) || 0}
                      </h2>
                      <span>REDEEMED</span>
                    </div>
                  </div>
                  <div className="uk-text-center">
                    <a
                      className="uk-button button-out uk-button-small uk-margin-top"
                      // onClick={this.toggleModal.bind(this, 'Redeem')}
                      onClick={this.state.loader ? '' : this.redeemEarnings}
                      style={{ fontSize: '12px' }}
                    >
                      Redeem
                    </a>
                  </div>
                </div>

                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom uk-flex-wrap uk-padding-small">
                  <h2 className="theme-color uk-text-center">
                    Loan
                    <br />
                  </h2>
                  <div className="uk-flex uk-flex-middle uk-flex-between">
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove theme-color">
                        {formatNumber(this.state.currentLoan) || 0}
                      </h2>
                      <span>CURRENT</span>
                    </div>
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove text-red">
                        {formatNumber(this.state.outstandingLoan) || 0}
                      </h2>
                      <span>OUTSTANDING</span>
                    </div>
                  </div>
                  {this.state.loanStatus == 0 ? (
                    <div className="uk-text-center">
                      <Link
                        className="uk-button button-out uk-button-small uk-margin-top"
                        to="/loan"
                        style={{ fontSize: '12px' }}
                      >
                        Apply
                      </Link>
                    </div>
                  ) : this.state.loanStatus === 1 ? (
                    <div className="uk-text-center">
                      <Link
                        //This is the button that appears when all trusters have confirmed their trust
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.withdrawModal}
                        style={{ fontSize: '12px' }}
                      >
                        Refund/Withdraw
                      </Link>
                    </div>
                  ) : this.state.loanStatus === 2 ? (
                    <div className="uk-text-center">
                      <Link
                        //This is the button that appears when loan is in progress (i.e waiting for truster confirmations)
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.refundCancelLoan.bind(this, 3)}
                        style={{ fontSize: '12px' }}
                      >
                        Cancel Loan
                      </Link>
                    </div>
                  ) : this.state.loanStatus === 3 ? (
                    <div className="uk-text-center">
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.repayModal.bind(this, 'Loan')}
                        //  onClick={this.repayLoan}
                        style={{ fontSize: '12px' }}
                      >
                        Repay Loan
                      </a>
                    </div>
                  ) : this.state.loanStatus == 4 ? (
                    'Pending for admin approval'
                  ) : this.state.loanStatus == 5 ? (
                    'In Process'
                  ) : null}{' '}
                  {/*<a
                      // This is the button that appears when loan has been disbursed
                      className="uk-button button-out uk-button-small uk-margin-top"
                      onClick={this.toggleModal.bind(this, 'Loan')}
                      //  onClick={this.repayLoan}
                      style={{ fontSize: '12px' }}
                    >
                    <Link
                      //This is the button that appears when user has no loan
                      className="uk-button button-out uk-button-small uk-margin-top"
                      onClick={this.toggleModal.bind(this, 'Loan')}
                      style={{ fontSize: '12px' }}
                    >
                      Apply
                    </Link>

                    <Link
                    //This is the button that appears when all trusters have confirmed their trust
                      className="uk-button button-out uk-button-small uk-margin-top"
                      onClick={this.toggleModal.bind(this, 'Loan')}
                      style={{ fontSize: '12px' }}
                    >
                      Refund/Withdraw
                    </Link>*/}
                </div>

                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom uk-flex-wrap uk-padding-small">
                  <h2 className="theme-color uk-text-center">
                    Purchase Order Power
                    <br />
                  </h2>
                  <div className="uk-flex uk-flex-middle uk-flex-between">
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove theme-color">
                        {formatNumber(this.state.currentPOP) || 0}
                      </h2>
                      <span>CURRENT</span>
                    </div>
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove text-red">
                        {formatNumber(this.state.outstandingPOP) || 0}
                      </h2>
                      <span>OUTSTANDING</span>
                    </div>
                  </div>
                  <div className="uk-text-center">
                    {this.state.POPStatus === 1 ? (
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.toggleModal.bind(this, 'cancelPOP')}
                        //  onClick={this.repayPOP}
                        style={{ fontSize: '12px' }}
                      >
                        Cancel
                      </a>
                    ) : this.state.POPStatus === 2 ? (
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.repayModal.bind(this, 'POP')}
                        // onClick={this.toggleModal.bind(this, 'POP')}
                        //  onClick={this.repayPOP}
                        style={{ fontSize: '12px' }}
                      >
                        Repay
                      </a>
                    ) : this.state.POPStatus === 3 ? (
                      <Link
                        className="uk-button button-out uk-button-small uk-margin-top"
                        to="/pop"
                        style={{ fontSize: '12px' }}
                      >
                        Apply
                      </Link>
                    ) : null}
                  </div>
                </div>

                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom uk-flex-wrap uk-padding-small">
                  <h2 className="theme-color uk-text-center">
                    Emergency Travel Cover
                    <br />
                  </h2>
                  <div className="uk-flex uk-flex-middle uk-flex-between">
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove theme-color">
                        {formatNumber(this.state.currentETC) || 0}
                      </h2>
                      <span>CURRENT</span>
                    </div>
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove text-red">
                        {formatNumber(this.state.outstandingETC) || 0}
                      </h2>
                      <span>OUTSTANDING</span>
                    </div>
                  </div>
                  <div className="uk-text-center">
                    {this.state.ETCStatus === 1 ? (
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.toggleModal.bind(this, 'cancelETC')}
                        //  onClick={this.repayPOP}
                        style={{ fontSize: '12px' }}
                      >
                        Cancel
                      </a>
                    ) : this.state.ETCStatus === 2 ? (
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.repayModal.bind(this, 'ETC')}
                        // onClick={this.toggleModal.bind(this, 'POP')}
                        //  onClick={this.repayPOP}
                        style={{ fontSize: '12px' }}
                      >
                        Repay
                      </a>
                    ) : this.state.ETCStatus === 3 ? (
                      <Link
                        className="uk-button button-out uk-button-small uk-margin-top"
                        to="/etc"
                        style={{ fontSize: '12px' }}
                      >
                        Apply
                      </Link>
                    ) : null}
                    {/* {this.state.outstandingETC === 0 ? (
                      <Link
                        className="uk-button button-out uk-button-small uk-margin-top"
                        to="/etc"
                        style={{ fontSize: '12px' }}
                      >
                        Apply
                      </Link>
                    ) : (
                      <a
                        className="uk-button button-out uk-button-small uk-margin-top"
                        onClick={this.toggleModal.bind(this, 'ETC')}
                        //onClick={this.repayETC}
                        style={{ fontSize: '12px' }}
                      >
                        Repay
                      </a>
                    )} */}
                  </div>
                </div>
                {/*<div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom uk-flex-wrap uk-padding-small">
                  <h2 className="theme-color uk-text-center">
                    Trust Deal
                    <br />
                  </h2>
                  <div className="uk-flex uk-flex-middle uk-flex-between">
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove theme-color">
                        0
                      </h2>
                      <span>CURRENT</span>
                    </div>
                    <div class="">
                      <span className="currency">{cur_name}</span>
                      <h2 className="uk-text-larger-theme uk-margin-remove text-red">
                        0
                      </h2>
                      <span>OUTSTANDING</span>
                    </div>
                  </div>
                  <div className="uk-text-center">
                    <a
                      className="uk-button button-out uk-button-small uk-margin-top"
                      style={{ fontSize: '12px' }}
                    >
                      Repay
                    </a>
                  </div>
                </div>
              */}
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(TrustWallet);
