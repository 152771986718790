import React, { Component, PropTypes } from 'react';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import { TwitterButton, FacebookShareButton } from 'react-social-buttons';

import DynaLogo from '../assets/img/aye-loader.gif';
import trustFundApiService from '../common/api/ApiService';
import Call_Center from '../assets/img/call-center.png';
import Smiley from '../assets/img/emoticons/smiley.png';
import Update from "../assets/img/south_africa.png";
import TrustInvest from "../assets/img/trustinvest2.jpeg";
import { showErrorToast } from '../common/helpers/Toastr';
import { relative } from 'path';

class RightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingList: [],
      loader: false,
      cashoutWeeks: 0,
      is_email_verified: 0,
      is_bank_info: 0,
      is_trust_network: 0,
      is_profile_info: 0,
      rating: 0,
      profile_percentage: 0,
      trustLink: '',
      copySuccess: '',
      isConfirm: false
    };
  }

  componentDidMount() {
    this.getMeetingList();
    this.isProfileComplete();
    this.getContribution();
    var date1 = new Date();
    var date2 = Date.parse(this.props.userData.u_cashout_from);
    var dif = Math.round(date2 - date1);
    var cashoutWeeks = Math.round(dif / 1000 / 60 / 60 / 24 / 7);
    if (cashoutWeeks > 0) {
      this.setState({
        cashoutWeeks
      });
    }
  }

  getContribution = () => {
    trustFundApiService('getContribution')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;

          this.setState({
            contribution: data.contribution
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getMeetingList = () => {
    trustFundApiService('TNMMeetingList')
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            meetingList: response.data.data
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  joinMeeting = eventId => {
    if (eventId) {
      let data = {
        event_id: eventId,
        event_status: 1
      };
      this.setState({ loader: true });
      trustFundApiService('joinMeeting', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
          }
        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err);
        });
    }
  };

  cashout = () => {
    let cashout_amount = this.state.contribution;
    let data = {
      cashout_amount
    };
    if (cashout_amount > 0) {
      this.setState({
        loader: true,
        isConfirm: false
      });
      trustFundApiService('cashoutDB', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Request Cashout'
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Request Cashout'
              },
              () => this.toggleModal()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  dateCheck = (from, to, check) => {
    var fDate, lDate, cDate;

    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }

    return false;
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
      verifyEmail: !this.state.isActive
    });
  };

  confirmModal = type => {
    if (type === 1) {
      this.setState({
        message: "By clicking Cashout, you are closing your current Trustfund Cycle, your Annual Membership Fee",
        message2: "will be deducted and you will be automatically activated for the next TrustFund Cycle.",
        title: "Cashout Contributions",
        isConfirm: !this.state.isConfirm,
        type: this.cashout,
      });
    }
  };

  confirmModal1 = () => {
    this.setState({
      isConfirm: !this.state.isConfirm
    });
  };

  isProfileComplete = () => {
    trustFundApiService('profileCompleteness')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          let profileData = response.data.data.profile;

          this.setState({
            trustLink: response.data.data.u_trust_link,
            is_email_verified: profileData.email_verified,
            is_bank_info: profileData.bank_info,
            is_trust_network: profileData.trust_rating_network,
            is_profile_info: profileData.update_profile_user_info,
            rating: response.data.data.rating.rating_count,
            profile_percentage: profileData.profile_percentage,
            cashoutStatus: data.cashout_status
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  verifyEmail = () => {
    let _this = this;
    let email = this.props.userData.u_email;
    if (email) {
      let data = {
        email
      };
      this.setState({
        loader: true
      });
      trustFundApiService('resendEmail', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.setState({
              loader: false
            });
          } else {
            _this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  displayRating = () => {
    var indents = [];
    for (var i = 1; i <= this.state.rating; i++) {
      indents.push(<span uk-icon="star" className="theme-color"></span>);
    }
    return indents;
  };

  copyToClipboard = e => {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: 'Link Copied!' });
  };

  render() {
    var cashoutBtnDisabled = true;
    if (
      this.dateCheck(
        this.props.userData.u_cashout_from,
        this.props.userData.u_cashout_to,
        new Date()
      )
    )
      cashoutBtnDisabled = false;
    else cashoutBtnDisabled = true;

    return (
      <div
        id="right"
        className="uk-width-1-4@m offers uk-child-width-1-1@m uk-child-width-1-2@s"
        data-uk-grid
      >
        <Modal
          className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isActive}
          onRequestClose={this.toggleModal}
        >
          {' '}
          <div className="uk-text-center uk-margin-top">
            <img src={Smiley} width={100} alt="" />
          </div>
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">{this.state.title}</h3>
            <p>{this.state.message} </p>

            <a
              onClick={this.toggleModal}
              className="uk-position-relative link uk-position-z-index"
            >
              Cancel
            </a>
          </div>
        </Modal>
        <div className="right-sidebar">
          <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
            <div className="uk-card-body uk-padding-small">
              <div>
                <span className="theme-color">My Trust Link</span>
              </div>
              <p>
                {/*<i className="text-width">{this.state.trustLink}</i><br/>*/}
                <span className="theme-color">{this.state.copySuccess}</span>
                <form>
                  <textarea
                    className="t-link"
                    ref={textarea => (this.textArea = textarea)}
                    value={this.state.trustLink}
                  />
                </form>
              </p>
              <div className="uk-text-center">
                <div>
                  {/* Logical shortcut for only displaying the 
                        button if the copy command exists */
                  document.queryCommandSupported('copy') && (
                    <div>
                      <span>
                        <a
                          className="uk-button dash-button"
                          onClick={this.copyToClipboard}
                        >
                          Copy Link
                        </a>
                      </span>
                    </div>
                  )}
                </div>
                <br />
                {/*<FacebookShareButton
                  url={this.state.trustLink}
                  text={'Join the largest community of entrepreneurs'}
                />
                <br />
                <TwitterButton
                  url={this.state.trustLink}
                  text={'Join the largest community of entrepreneurs'}
                />*/}
              </div>
            </div>
          </div>
          <div className="dtsud uk-margin-remove-top uk-margin-bottom">
            <div className="uk-card-body uk-padding-small">
              <div data-uk-grid>
                <div>
                  <span className="theme-color">Profile</span>
                </div>
                <div>
                  <span className="uk-text-right">
                    {this.state.profile_percentage}% Complete
                  </span>
                </div>
              </div>
              <div className="uk-margin-bottom uk-margin-small-top">
                <span>
                  {this.state.is_email_verified === 1 ? (
                    <a
                      href="javascript:void(0);"
                      className={
                        this.state.is_email_verified === 1
                          ? 'complete'
                          : 'not-complete'
                      }
                    >
                      Verify Account Email
                    </a>
                  ) : (
                    <a
                      onClick={this.verifyEmail.bind(this)}
                      className={
                        this.state.is_email_verified === 1
                          ? 'complete'
                          : 'not-complete'
                      }
                    >
                      Verify Account Email
                    </a>
                  )}
                </span>
                {this.state.is_email_verified === 1 ? (
                  <span className="completed"> (Done)</span>
                ) : (
                  ''
                )}
                <br />
                <span>
                  <Link
                    to="/profile"
                    className={
                      this.state.is_profile_info === 1
                        ? 'complete'
                        : 'not-complete'
                    }
                  >
                    Update User Information
                  </Link>
                </span>
                {this.state.is_profile_info === 1 ? (
                  <span className="completed"> (Done)</span>
                ) : (
                  ''
                )}
                <br />
                <span>
                  <Link
                    to="/trustnetwork"
                    className={
                      this.state.is_trust_network === 1
                        ? 'complete'
                        : 'not-complete'
                    }
                  >
                    Build Trust Network
                  </Link>
                </span>
                {this.state.is_trust_network === 1 ? (
                  <span className="completed"> (Done)</span>
                ) : (
                  ''
                )}
                <br />
                <span>
                  <Link
                    to="/profile"
                    className={
                      this.state.is_bank_info === 1
                        ? 'complete'
                        : 'not-complete'
                    }
                  >
                    Add Bank Account details
                  </Link>
                </span>
                {this.state.is_bank_info === 1 ? (
                  <span className="completed"> (Done)</span>
                ) : (
                  ''
                )}
                <br />
              </div>
              <div className="uk-margin-remove-top" data-uk-grid>
                <div>
                  <span className="theme-color">Rating</span>
                </div>
                <div>{this.displayRating()}</div>
              </div>
            </div>
          </div>

          <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
            {this.state.cashoutStatus === 1 ? (
            <div className="uk-card-body uk-padding-small">
              <Confetti width="auto" height="300px" />
              <h4 className="uk-h2 theme-color">CASHOUT SEASON</h4>
              <p className="uk-text-small">YOU CAN REQUEST CASHOUT NOW</p>
              <button
                className="uk-button uk-button-small dash-button"
                onClick={this.confirmModal.bind(this, 1)}
                style={{
                  fontSize: '12px',
                  position: relative,
                  zIndex: 99999,
                  cursor: 'pointer'
                }}
              >
                Cashout
              </button><br/>
            </div>
            ) : (
            <div className="uk-card-body uk-padding-small">
              <h2 className="uk-heading-primary">
                {this.state.cashoutWeeks} <br />{' '}
                {this.state.cashoutWeeks > '1' ? 'Weeks' : 'Week'}
                <br />
              </h2>
              <p className="uk-text-small theme-color">
                &gt;&gt;TO CASHOUT DAY&lt;&lt;
              </p>
              <Link className="link uk-margin-top" to="/learn-more#cashout" uk-toggle>
                Learn More
              </Link>
            </div>
            )}
          </div>
          {/* <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
          <div className="uk-card-body uk-padding-small uk-text-center">
            
          <span className="theme-color">Cashout Schedule!</span><br/>
            <span>
            Cashout request window for this year will be between 1st -7th December,2020. During this period you will be able to request cashout of your contributions. Disbursements is from Dec 14 - Dec 31.
            </span>
          </div>
          </div> */}
          <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
          <div className="uk-card-body uk-padding-small uk-text-center">
            <img src={TrustInvest} style={{borderRadius:"5px"}} />
          </div>
          </div>
          <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
            <div className="uk-card-body uk-padding-small theme-background">
              <a href="tel:090-8349-0000">
                <img src={Call_Center} />
              </a>
            </div>
          </div>
          {/* <div className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom">
          <div className="uk-card-body uk-padding-small uk-text-center">
            <p className="uk-text-small theme-color">
              ANTICIPATE!!!
            </p>
            <img src={Update} />
            <Link className="link uk-margin-top" to="/learn-more#cashout" uk-toggle>
              ETF is coming to SA!!!
            </Link>
          </div>
          </div> */}
          {this.state.meetingList && this.state.meetingList.length > 0
            ? this.state.meetingList.map((meeting, index) => (
                <div
                  className="dtsud uk-margin-remove-top  uk-text-center uk-margin-bottom"
                  key={index}
                >
                  {this.state.loader ? (
                    <div className="spinner-wrapper">
                      <div className="spinner">
                        <img src={DynaLogo} alt="" />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="uk-card-body uk-padding-small">
                    <p className="uk-text-small">
                      <span uk-icon="icon: users" /> {meeting.ev_name}
                      <span uk-icon="icon: users" />
                    </p>
                    <p className="uk-text-small tnm">{meeting.ev_about}</p>
                    <p className="uk-text-small">
                      <span
                        uk-icon="icon: location"
                        className="theme-color icon-small"
                      />
                      <br />
                      {meeting.ev_address} &nbsp;&nbsp; <br />
                      <span
                        uk-icon="icon: calendar"
                        className="theme-color icon-small"
                      />
                      &nbsp; {moment(meeting.event_date).format('MM/DD/YYYY')}{' '}
                      <span
                        uk-icon="icon: clock"
                        className="theme-color icon-small uk-margin-small-left"
                      />
                      &nbsp; {meeting.event_time}{' '}
                    </p>
                    <a
                      className="uk-button dash-button uk-margin-small-top"
                      onClick={this.joinMeeting.bind(this, meeting.ev_id)}
                      uk-toggle
                    >
                      <span className="uk-position-relative uk-position-z-index">
                        Attend
                      </span>
                    </a>
                    {/* ATTEND MODAL SHOULD FIT HERE*/}
                  </div>
                </div>
              ))
            : null}
        </div>
        <Modal
          className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isConfirm}
          onRequestClose={this.confirmModal}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">{this.state.title}</h3>
            <p>{this.state.message} </p>
            <p>{this.state.message2} </p>
            <div className="uk-margin-bottom">
              <a className="uk-button dash-button" onClick={this.state.type}>
                Confirm
              </a>
            </div>
            <a
              onClick={this.confirmModal1}
              className="uk-position-relative link uk-position-z-index"
            >
              Cancel
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(RightSidebar);
