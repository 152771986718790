import React from "react";
import { AboutContainer, AboutTitle, Tagline, TitleContainer } from "./Styles";

export default function About() {
  return (
    <div>
      <AboutContainer>
        <TitleContainer>
          <AboutTitle>What is E-TrustFund?</AboutTitle>
          <Tagline>Watch the video to know more</Tagline>
        </TitleContainer>

        <div id="about" className="" data-uk-grid>
          <div className="uk-container uk-flex-middle" data-uk-grid>
            <div className="uk-width-1-2 uk-width-expand">
              <a
                className="uk-margin-bottom"
                uk-toggle="target: .hideme; animation: uk-animation-fade; queued: true; duration: 300"
              >
                <img
                  className="uk-width-1-1@m hideme"
                  src={require("../../../assets/img/video.png")}
                  alt=""
                />
              </a>
              <video
                src="../../../videos/explainer.mp4"
                controls
                playsInline
                hidden
                uk-video="autoplay: inview"
                uk-responsive
                className="hideme"
              ></video>
            </div>
            <div className="uk-width-1-2 uk-width-expand uk-margin-large-top aboutText uk-text-justify">
              <p className="how-c">
                Entrepreneurs Trust Fund is the First Entreprenuers Co-operative
                in the World that encourages members to consistently save.
                Members can enjoy being part of a community that promotes
                Savings and Trust. They also have access to weekly earnings,
                loans with no interest, collateral or paperwork, Free Business,
                Legal and Medical Advisory, Emergency Travel Cover and many
                other benefits.
              </p>
            </div>
          </div>
        </div>
      </AboutContainer>
    </div>
  );
}
