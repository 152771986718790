export default {
  emailRequired: value => (value ? undefined : 'Enter email address'),
  confirmEmailRequired: value =>
    value ? undefined : 'Enter confirm email address',
  firstNameRequired: value => (value ? undefined : 'Enter full name'),
  isEmail: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Enter valid email address'
      : undefined,

  messageRequired: value => (value ? undefined : 'Enter Message'),

  passwordRequired: value => (value ? undefined : 'Enter password'),
  addressRequired: value => (value ? undefined : 'Enter address'),
  genderRequired: value => (value ? undefined : 'Select gender'),
  businessSectorRequired: value =>
    value ? undefined : 'Select business sector',
  phoneNumberRequired: value => (value ? undefined : 'Enter phone number'),
  bankNameRequired: value => (value ? undefined : 'Enter bank name'),
  accountNumberRequired: value => (value ? undefined : 'Enter account number'),
  cardDetailRequired: value => (value ? undefined : 'Enter Card Detail'),

  oldPasswordRequired: value => (value ? undefined : 'Enter old password'),
  newPasswordRequired: value => (value ? undefined : 'Enter new password'),
  reasonRequired: value => (value ? undefined : 'Enter travel reason'),
  typeRequired: value => (value ? undefined : 'Select a type'),
  dateReuired: value => (value ? undefined : 'Select a date'),
  reasonPurchaseRequired: value =>
    value ? undefined : 'Enter reason for purchase',

  locationRequired: value => (value ? undefined : 'Enter location'),

  itemRequired: value => (value ? undefined : 'Enter Item'),

  confirmPasswordRequired: value =>
    value ? undefined : 'Enter confirm password',

  passwordsMatch: (value, allValues) =>
    value !== allValues.password
      ? 'Mismatch password and confirm password'
      : undefined,

  emailMatch: (value, allValues) =>
    value.toLowerCase() !== allValues.email.toLowerCase()
      ? 'Mismatch email and confirm email'
      : undefined,

  changePasswordsMatch: (value, allValues) =>
    value !== allValues.newpassword
      ? 'Mismatch new password and confirm new password'
      : undefined,

  passwordsReset: (value, allValues) =>
    value !== allValues.password
      ? 'Mismatch new and confirm password'
      : undefined,

  minLength: (min, field) => value =>
    value && value.length < min
      ? `${field} must be ${min} characters long`
      : undefined,

  maxLength: max => value =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined,

  maxLength1: (max, field) => value =>
    value && value.length > max
      ? `${field} must be ${max} characters`
      : undefined,

  termsRequired: value =>
    value
      ? undefined
      : 'Select checkbox to agree to the terms and conditions to continue',
  CVVRequired: value => (value ? undefined : 'Enter CVV'),
  OTPRequired: value => (value ? undefined : 'Enter OTP'),
  cardPinRequired: value => (value ? undefined : 'Enter card pin'),
  cardExpiryRequired: value => (value ? undefined : 'Enter card expiry'),

  cardNumberRequired: value => (value ? undefined : 'Enter card number'),
  cardNameRequired: value => (value ? undefined : 'Enter card holder name'),
  TFPRequired: value => (value ? undefined : 'Enter TFP pin'),
  cityRequired: value => (value ? undefined : 'Select city'),
  accountHolderNameRequired: value =>
    value ? undefined : "Enter account holder's name ",

  cardNumberLength: value =>
    value.length < 16 ? 'Enter your 16 digit card numbers' : undefined,

  // accNumberLength: value =>
  //   value.length < 16 ? 'Enter your 16 digit Account numbers' : undefined,

  accNumberLength: value =>
    value && value.length < 10
      ? 'Account must be at least 10 numbers'
      : undefined,

  phoneNumberLength: value =>
    value.length < 10 ? 'Enter your 10 digit Phone numbers' : undefined,

  vaildExpDate: value => (value == '00/00' ? 'Invalid Date' : undefined),

  noSpace: value =>
    value && /^[ ]+|[ ]+$/i.test(value)
      ? "Password can't start or end with a blank space"
      : undefined,

  accNumNoSpace: value =>
    value && value.replace(/[^\d]/g, '')
      ? 'Blank space not allowed'
      : undefined,

  passwordPolicy: value =>
    value &&
    !/^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z])(?=.*[a-zA-Z])(^[a-zA-Z0-9@\$=!:.#%]+$)/.test(
      value
    )
      ? 'Password must contain at least 8 characters, at least one letter and one number'
      : undefined,

  alphaOnly: field => value =>
    value && /[^a-zA-Z ]/i.test(value)
      ? `${field} could only consist alphabets`
      : undefined,

  bankNamealphaOnly: value =>
    value && /[^a-zA-Z ]/i.test(value)
      ? 'Bank name could only consist alphabets'
      : undefined,

  accountHolderNamealphaOnly: value =>
    value && /[^a-zA-Z ]/i.test(value)
      ? "Account holde's name could only consist alphabets"
      : undefined,

  fullNameAlphabetOnly: value =>
    value && /[^a-zA-Z ]/i.test(value)
      ? 'Name could only consist alphabets'
      : undefined,

  number: value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,

  alphaNumeric: value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'Trust Partner TFP could only consist alphanumeric characters'
      : undefined,

  accountNumAlphaNumeric: value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'Account Number could only consist alphanumeric characters'
      : undefined,

  expiryMonth: value =>
    value && value < 13 ? 'Expiry month must be between 01 and 12' : undefined,

  isTFPValid: value =>
    value && !/[A-Z]{3}\d{10}/.test(value)
      ? 'TFP pin must start with 3 alpha characters followed by 10 digit number'
      : undefined,

  loanAmountReuired: value => (value ? undefined : 'Enter loan amount'),
  repaydateReuired: value => (value ? undefined : 'Enter repayment date'),

  restrictSpecialCharacter: value =>
    value && /[^\w\s]/gi.test(value)
      ? 'Trust Partner TFP could only consist alphanumeric characters'
      : undefined,

  minLoanAmount: value =>
    value && value < 10000
      ? 'Minimum loan amount should be N10,000'
      : undefined,

  lessThanStartDate: (value, allValues) =>
    value > allValues.repaydate
      ? 'Travel date should be less than repayment date'
      : undefined,

  lessThanEndDate: (value, allValues) =>
    value > allValues.repaydate
      ? 'Return date should be less than repayment date'
      : undefined,

  equalTo: (value, allValues) =>
    value == allValues.cityFrom
      ? "Source and destination can't be same"
      : undefined,

  // Normalization of fields
  upper: value => value && value.toUpperCase(),
  lower: value => value && value.toLowerCase(),
  trim: value => value && value.toLowerCase(),
  normalizeNumber: value => value && value.replace(/[^\d]/g, ''),
  normalizeSpecialCharacter: value => value && value.replace(/[^\w\s]/gi, '')
};
