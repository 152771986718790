import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import * as qs from 'query-string';

import DynaLogo from '../assets/img/aye-loader.gif';
import '../assets/css/main.css';
import Sidebar from './Sidebar';
import RenderField from './RenderField';
import Validation from '../common/helpers/Validation';
import trustFundApiService from '../common/api/ApiService';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed) {
        this.setState({
          userid: parsed.userid
        });
      }
    }
  }

  handleSubmit = formValues => {
    if (formValues) {
      let userid = this.state.userid;
      let password = formValues.password;

      let data = {
        userid,
        password
      };

      if (data) {
        this.setState({ loader: true });
        trustFundApiService('resetPassword', data)
          .then(response => {
            if (response.data.status === 'success') {
              this.setState({ loader: false });
              setTimeout(
                function() {
                  this.props.history.push('/login');
                }.bind(this),
                3000
              );
              this.props.initialize({ password: '', confirmpassword: '' });
            } else {
              this.setState({ loader: false });
              this.props.initialize({ password: '', confirmpassword: '' });
            }
          })
          .catch(err => {
            this.setState({ loader: false });
            console.log(err);
          });
      }
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <section id="bg">
        <div
          id="register-view"
          className="uk-width-1-1@m uk-child-width-1-2@m"
          data-uk-grid
        >
          <div
            id="signin"
            className="uk-flex-middle uk-flex-center uk-flex-last@m uk-margin-remove"
            data-uk-grid
          >
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="uk-width-1-1 uk-padding-remove">
                <div className="uk-height-1-1 loginReg uk-padding">
                  <div
                    id="register"
                    className="uk-child-width-1-1@m uk-flex-center"
                    data-uk-grid
                  >
                    <h2 className="uk-h3 theme-color">Reset Password</h2>
                    <div id="signup1">
                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="password"
                            id="new-password"
                            placeholder="Enter new Password"
                            type="password"
                            maxLength="40"
                            validate={[
                              Validation.passwordRequired,
                              Validation.passwordPolicy,
                              Validation.noSpace
                            ]}
                          />
                          <label className="uk-form-label" htmlFor="reg-email">
                            New Password
                          </label>
                        </div>
                      </div>
                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="confirmpassword"
                            id="confirm-password"
                            type="password"
                            placeholder="Confirm new Password"
                            maxLength="40"
                            validate={[
                              Validation.confirmPasswordRequired,
                              Validation.passwordsReset,
                              Validation.noSpace
                            ]}
                          />
                          <label className="uk-form-label" htmlFor="reg-email">
                            Confirm Password
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin uk-width-1-1@m uk-flex uk-flex-center uk-padding-remove-left">
                      <div className="padding-right subby">
                        <button
                          className="uk-button prog-button"
                          type="submit"
                          disabled={submitting}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                      <div className="padding-left uk-hidden@m uk-flex uk-flex-middle">
                        <Link className="uk-link uk-link-reset" to="/login">
                          <span className="uk-position-relative uk-position-z-index">
                            Login
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Sidebar pageName="Signup" />
        </div>
      </section>
    );
  }
}

export default reduxForm({
  form: 'resetPasswordForm',
  touchOnBlur: false,
  destroyOnUnmount: true
})(ResetPassword);
