import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { actionPurchasePlanOTP } from '../../common/redux/actions';
import trustFundApiService from '../../common/api/ApiService';

import '../../assets/css/main.css';
import RenderField from '../RenderField';
import Validation from '../../common/helpers/Validation';
import DynaLogo from '../../assets/img/aye-loader.gif';
import OtpPhone from '../../assets/img/otp.png';
import { getLocalStorage } from '../../common/helpers/Utils';
import CONSTANTS from '../../common/helpers/Constants';

class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      flwRef: '',
      chargeResponseMessage: '',
      loader: false,
      fromRegister: 0,
      type: ''
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      let flwRef = location.state.flwRef;
      let chargeResponseMessage = location.state.chargeResponseMessage;
      let type = location.state.type;

      if (type === 'changecard') {
        let cardData = location.state.cardData;
        this.setState({
          flwRef,
          chargeResponseMessage,
          cardData,
          type
        });
      } else {
        let fromRegister = location.state.fromRegister;
        this.setState({
          flwRef,
          chargeResponseMessage,
          fromRegister,
          type
        });
      }
    }
  }

  handleSubmit = formValues => {
    let _this = this;
    let planid = getLocalStorage('planId');
    let cardData = this.state.cardData;

    if (this.state.type === 'addcard') {
      if (planid) {
        let payment_type = '2';
        let data = {
          planid,
          payment_type,
          flwRef: this.state.flwRef,
          otp: formValues.otp
        };
        this.setState({
          loader: true
        });

        this.props
          .actionPurchasePlanOTP(data)
          .then(response => {
            if (response.data.status === 'success') {
              if (getLocalStorage('referalKey')) {
                this.referTFP();
              } else {
                _this.setState({
                  loader: false
                });
                _this.props.history.push({
                  pathname: '/trustpartner',
                  state: { fromRegister: this.state.fromRegister }
                });
              }
            } else {
              _this.props.history.push({
                pathname: '/carddetails',
                state: { fromRegister: this.state.fromRegister }
              });
            }
          })
          .catch(err => {
            _this.setState({
              loader: false
            });
            console.log(err);
          });
      }
    } else if (this.state.type === 'changecard') {
      this.setState({
        loader: true
      });
      let data = {
        card_number: cardData.card_number,
        card_exp: cardData.card_exp,
        card_cvv: cardData.card_cvv,
        card_pin: cardData.card_pin,
        card_name: cardData.card_name,
        card_process: 2,
        payment_type: 2,
        flwRef: this.state.flwRef,
        otp: formValues.otp
      };
      trustFundApiService('changeCardByOTP', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.setState({
              loader: false
            });
            _this.props.history.push('/profile');
          } else {
            _this.props.history.push('/profile');
          }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  referTFP = () => {
    let tfp_code = getLocalStorage('referalKey');
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    let data = {
      u_relationship: tfp_relationship,
      u_tfp_code: 'TFP' + tfp_code
    };

    trustFundApiService('referTrustPartner', data)
      .then(response => {
        if (response.data.status === 'success') {
          this.updateProcess();
          this.setState({
            dynoLoader: false
          });
          setTimeout(
            function() {
              this.props.history.push({
                pathname: '/dashboard',
                state: { fromRegister: this.state.fromRegister }
              });
            }.bind(this),
            3000
          );
        } else {
          this.setState({
            dynoLoader: false
          });
        }
      })
      .catch(err => {
        this.setState({
          dynoLoader: false
        });
        console.log(err);
      });
  };

  updateProcess = () => {
    let data = {
      type: 'both'
    };

    trustFundApiService('updateRegisterProcess', data);
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <section id="bg">
        <div
          id="register-view"
          className="uk-width-1-1@m uk-child-width-1-2@m"
          data-uk-grid
        >
          {this.state.loader ? (
            <div className="spinner-wrapper">
              <div className="spinner">
                <img src={DynaLogo} alt="" />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="uk-width-1-1 uk-padding-remove">
            <div className="uk-height-1-1 loginReg uk-padding">
              <div
                className="uk-child-width-1-2@s uk-grid-collapse uk-text-center"
                data-uk-grid
              >
                <div className="uk-visible@m uk-margin-large-top">
                  <img src={OtpPhone} alt="otp" />
                </div>
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <div>
                    <div className="uk-tile uk-tile-default">
                      <h2 className="uk-text-large theme-color">
                        Enter OTP
                        <br />
                      </h2>
                      <p>{this.state.chargeResponseMessage}</p>
                      <div>
                        <div className="uk-tile uk-tile-default">
                          <div className="uk-form-controls">
                            <label className="uk-form-label val-f">OTP</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="otp"
                              id="reg-otp"
                              type="text"
                              autoComplete="off"
                              maxLength="7"
                              validate={[
                                Validation.OTPRequired,
                                Validation.minLength(6, 'OTP')
                              ]}
                              normalize={Validation.normalizeNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="uk-button prog-button uk-margin-top"
                        disabled={submitting}
                        type="submit"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                    {this.state.type === 'addcard' ? (
                      <Link to="/carddetails" className="link">
                        Go Back
                      </Link>
                    ) : (
                      <Link to="/changecard" className="link">
                        Go Back
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

OTP = connect(null, { actionPurchasePlanOTP })(OTP);

export default reduxForm({
  form: 'addCardForm',
  touchOnBlur: true,
  destroyOnUnmount: false
})(OTP);
