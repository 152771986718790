import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getLocalStorage } from '../common/helpers/Utils';

let userInfo = getLocalStorage('userInfo');

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        userInfo && userInfo.u_id && userInfo.u_tfp_pin ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
