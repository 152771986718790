import styled from "styled-components";
import Colors from "../../../Shared/utils/Colors";

export const PerksContainer = styled.div`
  width: 98%;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 5rem;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  @media (min-width: 1020px) {
    width: 85%;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 14rem;
  }
`;

export const PerkSingle = styled.div`
  width: 100%;
  padding: 2.6rem 1.4rem;
  background-color: #f5f6f8;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  color: #20242e;

  @media (min-width: 1020px) {
    width: 48%;
    padding: 2.6rem 6rem;
  }
`;

export const PerkSpan = styled.span`
  display: block;
  font-size: 1.8rem;
  font-weight: 900;
  margin-left: 0;
  text-align: left;
`;
