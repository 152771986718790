import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
//import Sound from 'react-sound';
import soundfile from '../assets/definite.mp3';

import '../assets/css/main.css';
import Header from '../common/Header';
import LeftSidebar from '../common/LeftSidebar';
import RightSidebar from '../common/RightSidebar';
import Notification from '../common/Notification';
import Platinum from '../assets/img/Plat.png';
import Silver from '../assets/img/silver.png';
import Gold from '../assets/img/gold.png';
import Smiley from '../assets/img/emoticons/smiley.png';
import People from '../assets/img/pple.png';
import trustFundApiService from '../common/api/ApiService';
import {
  formatNumber,
  capitalizeFirstLetter,
  clearLocalStorage
} from '../common/helpers/Utils';
import DynaLogo from '../assets/img/aye-loader.gif';
import { showErrorToast } from '../common/helpers/Toastr';
import { actionLogout } from '../common/redux/actions';

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conAmount: [],
      conDate: [],
      contribution: 0,
      earnings: 0,
      loans: 0,
      currentLoan: 0,
      loader: false,
      //loader1: false,
      // loader2: false,
      cashoutBtnDisabled: true,
      isActive: false,
      verifyEmail: false,
      isRepay: false,
      isWithdraw: false,
      message: '',
      title: '',
      memberTitle: '',
      shouldCloseOnOverlayClick: true,
      loanStatus: 0
    };
  }

  componentDidMount() {
    document.title = "Dashboard - If You TRUST Me I'll TRUST You";

    this.getContribution();
    this.getTrustWallet();
    this.currentPlanInfo();
    this.getCardDetail();
    // if (
    //   this.props.userData.u_is_verified === 0 &&
    //   this.props.userData.u_exceed_verfied_limit === 0
    // ) {
    //   this.verifyModal();
    // } else if (
    //   this.props.userData.u_is_verified === 0 &&
    //   this.props.userData.u_exceed_verfied_limit === 1
    // ) {
    //   this.setState({
    //     shouldCloseOnOverlayClick: false
    //   });
    //   this.verifyModal();
    // }
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
      //verifyEmail: !this.state.isActive
    });
  };

  verifyModal = () => {
    this.setState({
      verifyEmail: !this.state.verifyEmail
    });
  };

  withdrawModal = () => {
    this.setState({
      isWithdraw: !this.state.isWithdraw
    });
  };

  repayModal = () => {
    this.setState({
      isRepay: !this.state.isRepay
    });
  };

  logout = () => {
    const _this = this;
    clearLocalStorage(function() {
      _this.props.history.push('/');
      _this.props.actionLogout();
    });
  };

  currentPlanInfo = () => {
    trustFundApiService('profileCompleteness')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          this.setState({
            tfp_pin_status: data.u_tfp_pin_status,
            loan_pop_etc_status: data.loan_pop_etc_status,
            loan_pop_etc_rating: data.rating.loan_pop_etc_rating,

            pl_name: data.plan_info.pl_name,
            pl_image: data.plan_info.pl_image
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getTrustWallet = () => {
    trustFundApiService('trustWallet')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          this.setState({
            currentLoan: data.loans.current_loan,
            loan_status: data.loans.loan_status,
            POPStatus: data.pop.pop_status,
            ETCStatus: data.etc.etc_status
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getContribution = () => {
    trustFundApiService('getContribution')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          let wc = data.week_contribution;
          let conAmount = [];
          let conDate = [];
          if (wc.length > 0) {
            wc.map((item, index) => {
              conDate.push(moment(item.trans_from_date).format('MM/DD/YYYY'));
              conAmount.push(item.con_amount);
            });
          }

          this.setState({
            memberTitle: data.u_membertitle,
            contribution: data.contribution,
            earnings: data.earnings,
            loans: data.loans,
            loanStatus: data.loan_status,
            lastWCAmount: formatNumber(data.last_contribution_amount),
            lastWCDate: data.last_contribution,
            nextWCAmount: formatNumber(data.next_contribution_amount),
            nextWCDate: data.next_contribution,
            conDate,
            conAmount
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getCardDetail = () => {
    let _this = this;
    trustFundApiService('getCardDetail')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          this.setState({
            card_status: data.up_card_number,
            card_name: data.up_card_pin
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  redeemEarnings = () => {
    let totalEarnings = this.state.earnings;
    let data = {
      redeem_amount: totalEarnings
    };
    if (parseInt(totalEarnings) >= 5000) {
      this.setState({
        loader: true
      });
      trustFundApiService('redeemEarningsDB', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Redeem Earnings'
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Redeem Earnings'
              },
              () => this.toggleModal()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    } else {
      this.setState(
        {
          loader: false,
          message:
            'You can only redeem your earnings if you have N5000 or above',
          title: 'Redeem Earnings'
        },
        () => this.toggleModal()
      );
    }
  };

  repayLoan = () => {
    let loan_amount = this.state.loans;
    let data = {
      loan_amount
    };
    if (loan_amount > 0) {
      this.setState({
        loader: true,
        isRepay: false
      });
      trustFundApiService('repayLoanDB', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState(
              {
                loader: false,

                message: response.data.message,
                title: 'Repay Loan'
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Repay Loan'
              },
              () => this.toggleModal()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  cashout = () => {
    let cashout_amount = this.state.contribution;
    let data = {
      cashout_amount
    };
    if (cashout_amount > 0) {
      this.setState({
        loader: true
      });
      trustFundApiService('cashoutDB', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Request Cashout'
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: 'Request Cashout'
              },
              () => this.toggleModal()
            );
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  dateCheck = (from, to, check) => {
    var fDate, lDate, cDate;

    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }

    return false;
  };

  verifyEmail = () => {
    let _this = this;
    let email = this.props.userData.u_email;
    if (email) {
      let data = {
        email
      };
      this.setState({
        loader: true
      });
      trustFundApiService('resendEmail', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.setState({
              loader: false
            });
          } else {
            _this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  // action - 1 - refund , 2- cancel
  refundCancelLoan = action => {
    let data = {
      loan_action: action
    };
    if (data) {
      this.setState({
        isWithdraw: false,
        loader: true
      });
      trustFundApiService('refundOrCancelLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState({
              loader: false
            });
          } else {
            this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          if (action !== 2) {
            this.setState(
              {
                loader: false
              },
              () => this.withdrawModal()
            );
          } else {
            this.setState({
              loader: false
            });
          }
          console.log(err);
        });
    }
  };

  render() {
    console.log(this.state.loanStatus);
    const u_tfp_pin = this.props.userData.u_tfp_pin || '';
    const cur_name = this.props.userData.cur_name || '';
    var cashoutBtnDisabled = true;
    if (
      this.dateCheck(
        this.props.userData.u_cashout_from,
        this.props.userData.u_cashout_to,
        new Date()
      )
    )
      cashoutBtnDisabled = false;
    else cashoutBtnDisabled = true;

    const data = {
      labels: this.state.conDate,
      datasets: [
        {
          label: 'Contributions Chart',
          fill: true,
          lineTension: 0.1,
          backgroundColor: '#ea9e2318',
          borderColor: '#ea9e23',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#ea9e23',
          pointBackgroundColor: '#ea9e23',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#ea9e23',
          pointHoverBorderColor: '#ea9e23',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.state.conAmount
        }
      ],
      legend: {
        display: false
      }
    };
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} type="adminLogin" />

          <Notification />

          {/* <Sound
            url={soundfile}
            playStatus={Sound.status.PLAYING}
            onLoading={this.handleSongLoading}
            onPlaying={this.handleSongPlaying}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          /> */}

          <div className="content-wrapper">
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.verifyEmail}
                onRequestClose={this.verifyModal}
                shouldCloseOnOverlayClick={this.state.shouldCloseOnOverlayClick}
              >
                {' '}
                <div className="uk-text-center uk-margin-top">
                  <img src={People} width={400} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Welcome to the Trust Community
                  </h3>
                  <p>
                    A verification link has been sent to your email, check your
                    email to verify your account. <br />
                    You will not be able to log in into your dashboard if
                    account is not verified after 24hours.
                  </p>
                  <br />
                  <a
                    className="uk-position-relative uk-button dash-button"
                    //onClick={this.verifyEmail.bind(this)}
                  >
                    {' '}
                    Resend Verification Link{' '}
                  </a>
                  <br />
                  <br />
                  {this.state.shouldCloseOnOverlayClick ? (
                    <a
                      //onClick={this.verifyModal}
                      className="uk-position-relative link uk-position-z-index"
                    >
                      Close
                    </a>
                  ) : (
                    <div className="uk-margin-bottom">
                      <a onClick={this.logout} className="uk-button link">
                        Logout
                      </a>
                    </div>
                  )}
                </div>
              </Modal>

              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                {' '}
                <div className="uk-text-center uk-margin-top">
                  <img src={Smiley} width={100} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.title}
                  </h3>
                  <p>{this.state.message} </p>

                  <a
                    // onClick={this.toggleModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Cancel
                  </a>
                </div>
              </Modal>

              {/*REPAY MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isRepay}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Repay Loan of {formatNumber(this.state.loans) || 0}{' '}
                  </h3>
                  <span className="uk-text-center reg-note">
                    <i>(Use any of the two methods provided below)</i>
                  </span>
                  <br />
                  <br />
                  <button
                    //   onClick={this.repayLoan}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Click to Pay from Bank Card
                  </button>
                  <div className="">
                    <table className="uk-table uk-table-justify uk-table-divider">
                      <thead>
                        <tr>
                          <th className="uk-width-small theme-color">
                            FOR MANUAL PAYMENT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="uk-text-left">Bank Name:</td>
                          <td className="uk-text-left theme-color">
                            Guaranty Trust Bank
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Name:</td>
                          <td className="uk-text-left theme-color">
                            A.Y.E Trust Fund
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">Account Number:</td>
                          <td className="uk-text-left theme-color">
                            0454179544
                          </td>
                        </tr>
                        <tr>
                          <td className="uk-text-left">
                            Use your TFP as narration:
                          </td>
                          <td className="uk-text-left theme-color">
                            {u_tfp_pin}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a
                      // onClick={this.repayModal}
                      className="uk-position-relative link"
                    >
                      Close
                    </a>
                  </div>
                </div>
              </Modal>
              {/*REPAY MODAL*/}

              {/*WITHDRAW MODAL*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isWithdraw}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <br />
                  <button
                    //  onClick={this.refundCancelLoan.bind(this, 1)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Request Loan Withdrawal
                  </button>
                  <br />
                  <div>
                    <span className="uk-text-lead text-red">OR</span>
                  </div>
                  <br />
                  <button
                    // onClick={this.refundCancelLoan.bind(this, 2)}
                    className="uk-button dash-button uk-margin-bottom"
                  >
                    Refund Loan
                  </button>
                  <br />
                  <a
                    // onClick={this.withdrawModal}
                    className="uk-position-relative link"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              {/*WITHDRAW MODAL*/}

              <div id="left" className="uk-width-3-4@m dash-margin">
                <div
                  className="uk-child-width-1-3@m uk-child-width-1-2@s uk-margin-small-left uk-grid-match"
                  uk-height-match="target: > div.threes"
                  data-uk-grid
                >
                  <div className="threes card-margin-right">
                    <div className="overv uk-flex animated fadeIn uk-card-body uk-flex-between uk-light uk-flex-middle uk-flex-wrap">
                      <div id="name-card uk-text-center">
                        <span className="theme-color uk-h4">
                          Welcome{' '}
                          {this.props.userData.u_first_name
                            ? this.props.userData.u_first_name
                                .split(' ')
                                .slice(-1)
                                .join(' ')
                            : ''}{' '}
                          ,
                        </span>
                      </div>
                      <div
                        className="plan-grid uk-margin-small-top"
                        data-uk-grid
                      >
                        <div className="uk-width-1-2">
                          <h2 className="txt-h">
                            {' '}
                            {capitalizeFirstLetter(this.state.pl_name) ||
                              ''}{' '}
                          </h2>
                        </div>
                        <div className="uk-width-1-2">
                          <img
                            className="plan-img"
                            src={this.state.pl_image}
                            alt="Plan Block"
                          />
                        </div>
                      </div>
                      {this.state.loan_pop_etc_status === 0 ? (
                        this.state.tfp_pin_status === 0 ||
                        this.state.tfp_pin_status === 2 ? (
                          <div
                            className={`uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove ${
                              this.state.memberTitle === 'Ordinary member'
                                ? 'ordinary'
                                : this.state.memberTitle === 'Qualified member'
                                ? 'qualified'
                                : this.state.memberTitle === 'Bonafide member'
                                ? 'bonafide'
                                : 'matured'
                            }`}
                            uk-tooltip="title: This is your membership status; pos: bottom"
                          >
                            {' '}
                            <p className="uk-margin-remove uk-text-lead-small ">
                              {this.state.memberTitle}
                            </p>
                          </div>
                        ) : (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              TFP IN USE
                            </p>
                          </div>
                        )
                      ) : this.state.loan_pop_etc_status === 1 ? (
                        this.state.loan_status === 1 ||
                        this.state.loan_status === 2 ||
                        this.state.loan_status === 4 ||
                        this.state.loan_status === 5 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              LOAN IN PROCESS
                            </p>
                          </div>
                        ) : this.state.loan_status === 3 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              LOAN BORROWER
                            </p>
                          </div>
                        ) : null
                      ) : this.state.loan_pop_etc_status === 2 ? (
                        this.state.POPStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP IN PROCESS
                            </p>
                          </div>
                        ) : this.state.POPStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP IN USE
                            </p>
                          </div>
                        ) : null
                      ) : this.state.loan_pop_etc_status === 3 ? (
                        this.state.ETCStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC IN PROCESS
                            </p>
                          </div>
                        ) : this.state.ETCStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC IN USE
                            </p>
                          </div>
                        ) : null
                      ) : null}

                      {/* {this.state.tfp_status === 0 &&
                      this.state.loan_pop_etc_rating === 0 ? (
                        <div
                          className={`uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove ${
                            this.state.memberTitle === 'Ordinary member'
                              ? 'ordinary'
                              : this.state.memberTitle === 'Qualified member'
                              ? 'qualified'
                              : this.state.memberTitle === 'Bonafide member'
                              ? 'bonafide'
                              : 'matured'
                          }`}
                          uk-tooltip="title: This is your membership status; pos: bottom"
                        >
                          <p className="uk-margin-remove uk-text-lead-small ">
                            {this.state.memberTitle}
                          </p>
                        </div>
                      ) : this.state.tfp_status === 1 &&
                        this.state.loan_pop_etc_rating === 0 ? (
                        <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                          <p className="uk-margin-remove uk-text-lead-small ">
                            TFP IN USE
                          </p>
                        </div>
                      ) : this.state.tfp_status === 1 &&
                        this.state.loan_pop_etc_rating === 1 ? (
                        this.state.POPStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP IN USE
                            </p>
                          </div>
                        ) : this.state.ETCStatus === 2 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC IN USE
                            </p>
                          </div>
                        ) : (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove borrower">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              LOAN BORROWER
                            </p>
                          </div>
                        )
                      ) : this.state.tfp_status == 0 &&
                        this.state.loan_pop_etc_rating == 1 ? (
                        this.state.POPStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              POP In Process
                            </p>
                          </div>
                        ) : this.state.ETCStatus === 1 ? (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              ETC In Process
                            </p>
                          </div>
                        ) : (
                          <div className="uk-width-1-1 uk-flex animated fadeIn uk-flex-middle uk-flex-center uk-flex-wrap uk-light uk-margin-remove requested">
                            <p className="uk-margin-remove uk-text-lead-small ">
                              Loan In Process
                            </p>
                          </div>
                        )
                      ) : null} */}

                      {/* <span>
                        Active Since:{' '}
                        <span className="theme-color">23-09-2019</span>
                      </span> */}
                      {this.state.card_name == null ? (
                        <span className="uk-text-center theme-color">
                          MANUAL ACCOUNT
                        </span>
                      ) : (
                        <span className="uk-text-center theme-color">
                          CARD IS LINKED
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="threes trst uk-width-2-3@m uk-child-width-2-3@s">
                    <div className="uk-card uk-card-loan uk-card-body">
                      {/*<div className="tearn-title"></div>*/}
                      <div className="tearn-title">
                        <div className="uk-light uk-flex animated fadeIn uk-flex-wrap uk-padding-small uk-flex-middle uk-flex-between">
                          <div>
                            <span
                              className="uk-text-center trst-title"
                              uk-tooltip="title: All your funds reside here; pos: right"
                            >
                              My Trust Wallet
                            </span>
                          </div>
                          <div className="learn">
                            <a
                              className="uk-link"
                              href="/learn-more#trustwallet"
                              uk-tooltip="title: Click to visit help center; pos: bottom"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="t-margin" data-uk-grid>
                        <div className="uk-width-1-3 uk-text-left border">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            <h2 className="text-green t-amount uk-margin-remove">
                              {' '}
                              {formatNumber(this.state.contribution) || 0}{' '}
                            </h2>
                            <p className="uk-text-small uk-margin-remove t-text">
                              {' '}
                              CONTRIBUTIONS{' '}
                            </p>
                            <p>
                              {' '}
                              {!cashoutBtnDisabled ? (
                                <a
                                  className="uk-button uk-button-small dash-button"
                                  onClick={this.cashout}
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Send Cashout Request; pos: bottom"
                                >
                                  Cashout
                                </a>
                              ) : (
                                <a
                                  className="uk-button dash-button3  uk-button-small"
                                  onClick={() =>
                                    showErrorToast("you can't cashout now")
                                  }
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Send Cashout Request; pos: bottom"
                                >
                                  Cashout
                                </a>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-text-left border">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                              {formatNumber(this.state.earnings) || 0}
                            </h2>
                            <p className="uk-text-small uk-margin-remove t-text">
                              {' '}
                              EARNINGS{' '}
                            </p>
                            <p>
                              {' '}
                              <a
                                className="uk-button uk-button-small dash-button"
                                onClick={
                                  this.state.loader ? '' : this.redeemEarnings
                                }
                                style={{ fontSize: '12px' }}
                                uk-tooltip="title: Send Redeem Earnings Request; pos: bottom"
                              >
                                Redeem{' '}
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="uk-width-1-3 uk-text-left">
                          <div className="uk-margin-remove">
                            <span className="currency">{cur_name}</span>
                            {this.state.loanStatus == 0 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {' '}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 1 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {' '}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 2 ? (
                              <h2 className="uk-text-larger-theme t-amount theme-color uk-margin-remove">
                                {' '}
                                {formatNumber(this.state.currentLoan) || 0}
                              </h2>
                            ) : this.state.loanStatus == 3 ? (
                              <h2 className="uk-text-larger-theme t-amount text-red uk-margin-remove">
                                {' '}
                                {formatNumber(this.state.loans) || 0}{' '}
                              </h2>
                            ) : (
                              ''
                            )}{' '}
                            <p className="uk-text-small uk-margin-remove t-text">
                              {' '}
                              LOANS{' '}
                            </p>
                            <p>
                              {this.state.loanStatus == 0 ? (
                                <Link
                                  //This is the button that appears when user has no loan
                                  className="uk-button dash-button  uk-button-small"
                                  to="/loan"
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Click to Apply for a loan; pos: bottom"
                                >
                                  Apply
                                </Link>
                              ) : this.state.loanStatus == 1 ? (
                                <a
                                  //This is the button that appears when loan is in progress (i.e waiting for truster confirmations)
                                  className="uk-button dash-button  uk-button-small"
                                  onClick={this.withdrawModal}
                                  // onClick={this.refundCancelLoan.bind(
                                  //   this,
                                  //   2
                                  // )}
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Click to Withdraw or Refund your loan; pos: bottom"
                                >
                                  Refund/Withdraw
                                </a>
                              ) : this.state.loanStatus == 2 ? (
                                <a
                                  //This is the button that appears when all trusters have confirmed their trust
                                  className="uk-button dash-button  uk-button-small"
                                  onClick={this.refundCancelLoan.bind(this, 3)}
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Click to terminate loan process; pos: bottom"
                                >
                                  Cancel Loan
                                </a>
                              ) : this.state.loanStatus == 3 ? (
                                <a
                                  // This is the button that appears when loan has been disbursed
                                  className="uk-button dash-button  uk-button-small"
                                  // onClick={this.repayLoan}
                                  onClick={this.repayModal}
                                  style={{ fontSize: '12px' }}
                                  uk-tooltip="title: Click to Repay your loan; pos: bottom"
                                >
                                  Repay Loan
                                </a>
                              ) : this.state.loanStatus == 4 ? (
                                'Pending Disbursal'
                              ) : this.state.loanStatus == 5 ? (
                                'Pending Disbursal'
                              ) : null}{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  <h2
                    className="uk-text-large-theme theme-color uk-text-center"
                    uk-tooltip="title: Representation of your contributions; pos: bottom"
                  >
                    Contributions Chart
                    <br />
                  </h2>
                  <br />
                  <div>
                    {this.state.conAmount.length > 0 &&
                    this.state.conDate.length > 0 ? (
                      <Line ref="chart" data={data} />
                    ) : null}
                  </div>
                </div>
                <p className="uk-text-center footer-text">
                  © 2019. A.Y.E Trust Fund is a platform under the{' '}
                  <a href="https://www.ayeonline.org">
                    Africa's Young Entrepreneurs
                  </a>
                  . All Rights Reserved.
                </p>
              </div>
              <RightSidebar
                {...this.props}
                contribution={this.state.contribution}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  console.log('data', user);
  return { userData: user.userData };
};

export default connect(mapStateToProps, { actionLogout })(AdminDashboard);
