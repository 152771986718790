import React from "react";
import { Link } from "react-router-dom";
import AppDash from "../../../assets/img/dash.png";
import Icon from "../../../assets/img/icon.png";
import Float from "../../../assets/img/wad2.svg";
import {
  EtfText,
  HeroButton,
  HeroButtonOutline,
  HeroSpan,
  ImgSlide,
  MobileSection,
  SpanContainer,
} from "./Styles";

export default function Hero() {
  return (
    <>
      <section className="head-container container-main home-margin-top">
        <div className="uk-vis@m">
          <div style={{ marginTop: "12rem", paddingBottom: "5rem" }}>
            <div className="uk-grid" data-uk-grid>
              <div className="uk-width-1-2 slid-text uk-margin">
                <h1 className="slid-note uk-margin-left txt-margin">
                  The First <br />
                  Entrepreneurs <br />
                  Co-operative In the <br />
                  World.
                </h1>
                <br />
                <p className="uk-margin-left lead-margin txt-margin">
                  Transactions based on TRUST.
                </p>

                <SpanContainer>
                  <HeroSpan> Save</HeroSpan>
                  <HeroSpan> Earn</HeroSpan>
                  <HeroSpan> Loan</HeroSpan>
                  <HeroSpan>Many more</HeroSpan>
                </SpanContainer>

                <div className="uk-margin-left txt-margin mob-align-center">
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      width: "100%",
                      justifyContent: "start",
                    }}
                  >
                    <Link to="/login">
                      <HeroButtonOutline>Login</HeroButtonOutline>
                    </Link>
                    <Link to="/register">
                      <HeroButton>Join For Free</HeroButton>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ position: "relative" }}
                className="uk-width-1-2 uk-margin"
              >
                <img
                  style={{ postion: "relative" }}
                  className="wad"
                  src={Float}
                  alt="floating"
                />

                <ImgSlide src={AppDash} alt="app" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =========== mobile ======================= */}

      <>
        <MobileSection>
          <section className="uk-hidden@m mb-top-margin">
            <div className="uk-text-center uk-margin-top">
              <img src={Icon} width={60} alt="etf-icon" className="etf-icon" />
              <p className="theme-color">
                <b>Entreprenuers Trust Fund</b>
              </p>
              {/* <h5 className="home-etf-text"></h5> */}
              <EtfText>
                The First <br />
                Entrepreneurs <br />
                Co-operative
                <br />
                In the World.
              </EtfText>
              <br />
              <p className="uk-margin-top">Transactions based on TRUST</p>
              <SpanContainer>
                <HeroSpan> Save</HeroSpan>
                <HeroSpan> Earn</HeroSpan>
                <HeroSpan> Loan</HeroSpan>
                <HeroSpan>Many more</HeroSpan>
              </SpanContainer>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Link to="/login">
                  <HeroButtonOutline>Login</HeroButtonOutline>
                </Link>
                <Link to="/register">
                  <HeroButton>Join For Free</HeroButton>
                </Link>
              </div>
            </div>
            <br />
            <br />
          </section>
        </MobileSection>
      </>
    </>
  );
}
