import styled from "styled-components";

export const ContactContainer = styled.div`
  width: 85%;
  margin: auto;
  padding: 2.5rem;
  margin-top: 10rem;
  background: hsla(39, 100%, 77%, 1);

  background: linear-gradient(
    40deg,
    hsla(39, 100%, 77%, 1) 0%,
    hsla(22, 90%, 57%, 1) 100%
  );

  background: -moz-linear-gradient(
    40deg,
    hsla(39, 100%, 77%, 1) 0%,
    hsla(22, 90%, 57%, 1) 100%
  );

  background: -webkit-linear-gradient(
    40deg,
    hsla(39, 100%, 77%, 1) 0%,
    hsla(22, 90%, 57%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FFD78A", endColorstr="#F4762D", GradientType=1 );
  border-radius: 1rem;
`;

export const Content = styled.div`
  text-align: center;
  width: 98%;
  margin: auto;
  font-size: 0.8rem;
  color: #000;

  @media (min-width: 1020px) {
    text-align: center;
    width: 40%;
    margin: auto;
    font-size: 1.5rem;
    color: #000;
  }
`;
