import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { connect } from "react-redux";
import Slider from "react-slick";
import CookieBanner from "react-cookie-banner";
import Footer from "../common/Footer";
import trustFundApiService from "../common/api/ApiService";
import { actionLogout, actionGetPlans } from "../common/redux/actions/";
import { setLocalStorage, formatNumber } from "../common/helpers/Utils";
// import Logo from "../assets/img/svglogo.svg";
// import Icon from "../assets/img/icon.png";
import WhatsApp from "../assets/img/whatsapp.png";
import Android from "../assets/img/d-app1.svg";
import Apple from "../assets/img/d-app2.svg";
import Update from "../assets/img/south_africa.png";
import Close from "../assets/img/close.png";
import Float from "../assets/img/wad2.svg";
import App from "../assets/img/app.png";
import AppDash from "../assets/img/dash.png";
// import yemipic from "../assets/Yemi.jpeg";
// import drchris from "../assets/drchris.jpeg";
// import Folorunsho from "../assets/Folorunsho.jpeg";

import "../assets/css/style.css";

import Trustplans from "./Sections/Trustplans/Trustplans";

import TopBar from "./Sections/TopBar";
import Hero from "./Sections/Hero";
import About from "./Sections/About";
import Howitworks from "./Sections/Howitworks/Howitworks";
import Perks from "./Sections/Perks";
import FAQ from "./Sections/Faq";
import VideoSection from "./Sections/Video";
import Testimony from "./Sections/Testimony";

class Home extends Component {
  constructor(props) {
    super(props);
    // for endorsement slider
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.state = {
      isActive: false,
      isUpdate: false,
      workData: [],
      endorsementData: [],
      faqData: [],
      viewFAQData: 0,
      isLoggedIn: false,
    };
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  componentWillMount() {
    Modal.setAppElement("body");
  }

  componentDidMount() {
    document.title = "The First Entrepreneurship Cooperative in the World";
    this.getHowItWorksData();
    this.getEndorsementData();
    this.getFaqData();
    this.props.actionGetPlans();

    if (
      this.props.userData &&
      this.props.userData.u_id &&
      this.props.userData.u_tfp_pin
    ) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
    // let _this = this;
    // window.addEventListener('scroll', function(e) {
    //   _this.stopVideo();
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData.u_id && nextProps.u_tfp_pin) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  }

  getHowItWorksData = () => {
    let _this = this;

    trustFundApiService("howItWorks")
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          let data = response.data.data;
          _this.setState({
            workData: data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getEndorsementData = () => {
    let _this = this;
    trustFundApiService("endorsement")
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          let data = response.data.data;

          _this.setState({
            endorsementData: data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFaqData = () => {
    let _this = this;
    trustFundApiService("faq")
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          let data = response.data.data;
          _this.setState({
            faqData: data,
          });
          _this.viewMoreQuestions();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  toggleUpdate = (id) => {
    console.log(id);

    this.setState({
      selectedPlanId: id,
      isUpdate: !this.state.isUpdate,
    });
  };

  logout = () => {
    const _this = this;
    if (_this.props.userData && _this.props.userData.u_id) {
      _this.props
        .actionLogout()
        .then((result) => {
          if (result === "test") {
            _this.props.history.push("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      _this.props.history.push("/login");
    }
  };

  viewMoreQuestions = () => {
    let remainingLength = this.state.faqData.length - this.state.viewFAQData;
    if (remainingLength >= 4) {
      this.setState({
        viewFAQData: this.state.viewFAQData + 4,
      });
    } else {
      this.setState({
        viewFAQData: this.state.viewFAQData + remainingLength,
      });
    }
  };

  viewLessQuestions = () => {
    let remainingLength = this.state.viewFAQData - 4;
    if (remainingLength > 4) {
      this.setState({
        viewFAQData: this.state.viewFAQData - 4,
      });
    } else {
      this.setState({
        viewFAQData: this.state.viewFAQData - remainingLength,
      });
    }
  };

  showAllFAQ = () => {
    this.setState({
      viewFAQData: this.state.faqData.length,
    });
  };

  stopVideo = (element) => {
    console.log(element);
    var iframe = element.querySelector("iframe");
    var video = element.querySelector("video");
    if (iframe) {
      var iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
    if (video) {
      video.pause();
    }
  };

  moreBenefits = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/trustplans");
  };

  render() {
    const { isLoggedIn } = this.state;

    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        {/* Topbar */}
        <CookieBanner
          message="A.Y.E Trust Fund uses cookies to guarantee users a better experience of its site features. By continuing to browse the site you're agreeing to our use of cookies."
          onAccept={() => {}}
          buttonMessage="Accept"
          cookie="user-has-accepted-cookies"
        />
        {/* Topbar */}
        <TopBar isLoggedIn={isLoggedIn} />
        {/* END Topbar */}
        {/* Home Section */}
        <br />
        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isUpdate}
          onRequestClose={this.toggleUpdate}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">
              Yayyy!! ETF is Coming to SA
            </h3>
            <img src={Update} alt="" className="animated flash" />
            {/* <Link
              to="/register"
              className="uk-button dash-button"
            >
              Join Now
            </Link> */}
            <br />
            {/* <span>Introducing <b>Two New Membership Plans</b>! <br/> Now you can <b>Make Contributions</b> with your Earnings...</span> */}
            <span>
              Get Ready!... Entrepreneurs TrustFund is expanding throughout the
              Continent.
            </span>
            <br />
            <a
              onClick={this.toggleUpdate}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              <img src={Close} className="close-icon" alt="close" />
            </a>
          </div>
        </Modal>
        {/*=================Hero Section ============= */}
        <Hero />
        {/*=========== End Hero Section =========== */}
        {/* ========= About============*/}
        <About />
        {/*========End About sectiomn ============*/}
        {/*========== How it Works ======= */}
        <Howitworks />
        {/*======== End How it Works ========*/}
        {/*=========== Try it out =========== */}
        {/* Try It Out */}
        <section className="uk-vis@m global-card container-main">
          <div className="footer__cta">
            <div uk-slideshow="animation: fade; autoplay: true uk-animation-fast">
              <ul className="uk-slideshow-items">
                <li className="animated bounceInDown uk-animation-fast">
                  <h3 className="card-margin txt-white">
                    Your Trust Network is Your Net Worth
                  </h3>
                  <p className="txt-white">
                    Become a part of the trusted community today
                  </p>
                  <Link
                    to="/register"
                    className="uk-button prog-button try-btn"
                  >
                    Join Us
                  </Link>
                </li>

                <li className="animated bounceInDown uk-animation-fast">
                  <h3 className="card-margin txt-white">Free Membership</h3>
                  <p className="txt-white">
                    Become a part of the trusted community today
                  </p>
                  <Link
                    to="/register"
                    className="uk-button prog-button try-btn"
                  >
                    Join Us
                  </Link>
                </li>

                <li className="animated bounceInDown">
                  <h3 className="card-margin txt-white">
                    Loan Approval in 59seconds
                  </h3>
                  <p className="txt-white">
                    No Interest, No Collateral, No Paper Work, No Credit Check
                  </p>
                  <a href="/register" className="uk-button prog-button try-btn">
                    Try it Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Try It Out */}
        {/* ==================================*/}
        {/* Mobile Try It Out */}
        <section className="uk-hidden@m global-card container-main">
          <div className="footer__cta">
            <div uk-slideshow="animation: fade; autoplay: true">
              <ul className="uk-slideshow-items">
                <li className="animated bounceInDown">
                  <h4 className="card-margin txt-white">
                    Your Trust Network is Your Net Worth
                  </h4>
                  <p className="txt-white">
                    Become a part of the trusted community today
                  </p>
                  <Link
                    to="/register"
                    className="uk-button prog-button try-btn"
                  >
                    Join Us
                  </Link>
                </li>
                <li className="animated bounceInDown">
                  <h4 className="card-margin txt-white">
                    Loan Approval in 59seconds
                  </h4>
                  <p className="txt-white">
                    No Interest, No Collateral, No Paper Work, No collateral
                  </p>
                  <a href="/register" className="uk-button prog-button try-btn">
                    Try it Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Mobile Try It Out */}
        {/* Plan Section */}
        {/* =================== Trust plans section @CoodeBreed ============= */}
        <>
          {this.props.planData && this.props.planData.length > 0 && (
            <Trustplans
              planData={this.props.planData}
              action={this.moreBenefits}
            />
          )}
        </>
        {/* ========================================================= */}
        {/* ============== Perks Section =========================== */}
        <Perks />
        {/* ============== End Perks Section ==========================*/}
        {/* ===================Videos===================================*/}
        <section
          id="videos"
          className="uk-margin-large-top container-main home-margin-top3"
        >
          <h3 className="uk-text-center">Videos</h3>
          <br />
          <br />

          <div>
            <Slider
              ref={(c) => (this.slider = c)}
              {...settings}
              className="slick-custm endorsement"
            >
              {this.state.endorsementData &&
              this.state.endorsementData.length > 0
                ? this.state.endorsementData.map((item, index) => (
                    <div className="vid">
                      <div
                        key={item.id}
                        className="uk-height-1-1 uk-inline-clip uk-transition-toggle videos-padding endorsement-img"
                      >
                        <a
                          className="uk-margin-bottom uk-height-1-1"
                          uk-toggle={`target: .test_${index}; animation: uk-animation-fade; queued: true; duration: 300`}
                        >
                          <img
                            className={`uk-width-1-1@m endorsement-img test_${index}`}
                            src={item.es_thumbnail}
                            alt
                          />
                        </a>
                        <video
                          src={item.es_video_url}
                          controls
                          playsinline
                          hidden
                          uk-video="autoplay: inview"
                          uk-responsive
                          className={`hideme-endorse test_${index}`}
                        ></video>
                        <div className="uk-width-1-1@m uk-position-bottom-left uk-overlay uk-overlay-primary">
                          <p className="video-txt uk-margin-remove">
                            {item.es_name}
                          </p>
                          <p className="video-txt theme-uline uk-margin-remove">
                            {item.es_businessname}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </Slider>
            <a
              onClick={this.previous}
              data-uk-slidenav-previous=""
              uk-slideshow-item="previous"
              class="uk-slidenav-previous uk-icon uk-slidenav uk-position-center-left uk-position-small uk-hidden-hover"
            ></a>
            <a
              onClick={this.next}
              data-uk-slidenav-next=""
              uk-slideshow-item="next"
              class="uk-slidenav-next uk-icon uk-slidenav uk-position-center-right uk-position-small uk-hidden-hover"
            ></a>
          </div>
        </section>

        {/* ================== Videos ==============================*/}
        {/* =================FAQ Section======================= */}
        <FAQ
          faqData={this.state.faqData}
          viewFAQData={this.state.viewFAQData}
          viewLessQuestions={this.viewLessQuestions}
          viewMoreQuestions={this.viewMoreQuestions}
        />
        {/* ================End FAQ Section ======================*/}
        {/* =========================Testimonies ============================== */}
        <Testimony />
        {/* =================================================================== */}
        {/* Download App Section */}
        <section>
          <div className="app-container container-main home-margin-top2">
            <div>
              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-2 uk-margin uk-hidden@l">
                  <div className="app"></div>
                </div>
                <div className="uk-width-1-2 slid-text uk-margin">
                  <div className="text-left">
                    <h2 className="uk-margin-left app-note">
                      Download The App
                      <br />
                      On Google Play or
                      <br />
                      The Apple Store
                    </h2>
                    <p className="uk-margin-left lead-margin">
                      Place the entrepreneurial power on your palm today!
                    </p>
                    <div className="uk-margin-left mob-align-center">
                      <a
                        className="uk-button prog-button d-btn"
                        href="https://play.google.com/store/apps/details?id=com.app.ayetrust"
                      >
                        <img className="btn-img" src={Android} alt="" />
                      </a>
                      <a
                        className="uk-button prog-button d-btn"
                        href="https://apps.apple.com/us/app/entrepreneurs-trust-fund/id1490114677"
                      >
                        <img className="btn-img" src={Apple} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2 uk-margin uk-visible@l">
                  <div className="app"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Download App Section */}
        {/* Footer Section */}
        <section>
          <a
            href="https://api.whatsapp.com/send?phone=2348156328284"
            className="float rotateInUpRight"
          >
            <img
              className="uk-hidden@l"
              src={WhatsApp}
              width={60}
              style={{ marginLeft: 30 }}
            />
            <img className="uk-visible@l" src={WhatsApp} width={80} />
            {/* <img src={Bissi} /> */}
          </a>
        </section>
        <Footer />
        {/* End Footer Section */}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userData: user.userData,
    planData: user.planData,
  };
};

export default connect(mapStateToProps, { actionLogout, actionGetPlans })(Home);
