import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { actionGetPlans } from '../../common/redux/actions';
import _ from 'lodash';
import * as qs from 'query-string';
import OTP from './OTP';

import '../../assets/css/main.css';
import SilverPlan from '../../assets/img/silver.png';
import GoldPlan from '../../assets/img/gold.png';
import PlatinumPlan from '../../assets/img/Plat.png';
import Lock from '../../assets/img/padlock.png';
import {
  capitalizeFirstLetter,
  formatExpirationDate
} from '../../common/helpers/Utils';
import CONSTANTS from '../../common/helpers/Constants';
import RenderField from '../RenderField';
import Validation from '../../common/helpers/Validation';
import trustFundApiService from '../../common/api/ApiService';
import { actionPurchasePlan } from '../../common/redux/actions';
import { showErrorToast } from '../../common/helpers/Toastr';
import DynaLogo from '../../assets/img/aye-loader.gif';
import { getLocalStorage } from '../../common/helpers/Utils';

class AddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planData: [],
      loader: false,
      fromRegister: 0,
      flwRef: '',
      chargeResponseMessage: '',
      isActive: false
    };
    this.props.initialize({
      cardName: '',
      cardNumber: '',
      cardExpiry: '',
      cardCVV: ''
    });
  }

  componentDidMount() {
    const { location } = this.props;
    this.toggleModal();
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed && parsed.response) {
        var paymentData = JSON.parse(parsed.response);
        if (paymentData) {
          if (paymentData.status == 'successful') {
            this.paymentSuccess(paymentData);
          } else if (paymentData.status == 'failed') {
            showErrorToast(
              'your transaction failed, please try again later'
            );
            this.props.history.push('/carddetails');
          }
        }
      }
    }

    let planId = getLocalStorage('planId');
    if (planId) {
      this.getPlans(planId);
    }

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    if (getLocalStorage('fromRegister') === 1) {
      this.setState({
        fromRegister: 1
      });
    } else if (
      this.props.userData &&
      this.props.userData.u_id &&
      this.props.userData.u_plan_id &&
      this.props.userData.u_plan_name
    ) {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/carddetails');
    }
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  getPlans = planId => {
    this.props
      .actionGetPlans()
      .then(result => {
        if (result && result.data && result.data.status === 'success') {
          let data = result.data.data;
          let planData = _.filter(data, { pl_plan_id: planId });
          this.setState({
            planData: planData[0]
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSubmit = formValues => {
    let currentYear = (new Date().getFullYear() + '').slice(-2);
    let cardMonth = formValues.cardExpiry.split('/')[0];
    let cardYear = formValues.cardExpiry.split('/')[1];

    if (
      cardYear >= currentYear &&
      parseInt(cardMonth) !== 0 &&
      cardMonth <= 12
    ) {
      if (formValues) {
        let _this = this;
        _this.setState({
          loader: true
        });
        let data = {
          card_name: formValues.cardName,
          card_number: formValues.cardNumber,
          card_exp: formValues.cardExpiry,
          card_cvv: formValues.cardCVV,
          card_pin: formValues.cardPin,
          card_process: '1',
          payment_type: '0'
        };

        trustFundApiService('addCard1', data)
          .then(response => {
            if (response.data.status === 'success') {
              _this.subscribePlan();
            } else {
              _this.setState({
                loader: false
              });
            }
          })
          .catch(err => {
            _this.setState({
              loader: false
            });
            console.log(err);
          });
      }
    } else {
      showErrorToast('Please Enter a valid expiry date');
    }
  };

  subscribePlan = () => {
    let _this = this;
    let planid = this.state.planData.pl_plan_id;
    let payment_type = '0';
    let data = {
      planid,
      payment_type,
      fromWeb: '1'
    };

    if (planid) {
      this.props
        .actionPurchasePlan(data)
        .then(response => {
          if (response.data.status === 'success') {
            let result = response.data.data;
            if (result.authurl) {
              let authURL = result.authurl;
              window.location.href = authURL;
            } else if (result.flwRef) {
              let flwRef = result.flwRef;
              let chargeResponseMessage = result.chargeResponseMessage;
              this.props.history.push({
                pathname: '/otp',
                state: {
                  flwRef: flwRef,
                  chargeResponseMessage: chargeResponseMessage,
                  fromRegister: this.state.fromRegister,
                  type: 'addcard'
                }
              });
            }

            _this.setState({
              loader: false
            });
          }
          //   if (getLocalStorage('referalKey')) {
          //     this.referTFP();
          //   }
          //   // } else {
          //   //   _this.props.history.push({
          //   //     pathname: '/trustpartner',
          //   //     state: { fromRegister: this.state.fromRegister }
          //   //   });
          //   // }
          // } else {
          //   _this.setState({
          //     loader: false
          //   });
          //   _this.props.history.push({
          //     pathname: '/trustpartner',
          //     state: { fromRegister: this.state.fromRegister }
          //   });
          // }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  referTFP = () => {
    let tfp_code = getLocalStorage('referalKey');
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    let data = {
      u_relationship: tfp_relationship,
      u_tfp_code: 'TFP' + tfp_code
    };

    trustFundApiService('referTrustPartner', data)
      .then(response => {
        if (response.data.status === 'success') {
          this.updateProcess();
          this.setState({
            dynoLoader: false
          });
          setTimeout(
            function() {
              this.props.history.push({
                pathname: '/dashboard',
                state: { fromRegister: this.state.fromRegister }
              });
            }.bind(this),
            3000
          );
        } else {
          this.setState({
            dynoLoader: false
          });
          //this.props.dispatch(change('trustPartnerForm', 'u_tfp_code', ''));
        }
      })
      .catch(err => {
        this.setState({
          dynoLoader: false
        });
        console.log(err);
      });
  };

  updateProcess = () => {
    let data = {
      type: 'both'
    };

    trustFundApiService('updateRegisterProcess', data);
  };

  paymentSuccess = payment_data => {
    let _this = this;
    let planid = getLocalStorage('planId');
    let payment_type = '1';

    let data = {
      planid,
      payment_type,
      payment_data
    };

    _this.setState({
      loader: true
    });

    if (planid) {
      this.props
        .actionPurchasePlan(data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.props.initialize({
              cardName: '',
              cardNumber: '',
              cardExpiry: '',
              cardCVV: '',
              cardPin: ''
            });
            if (getLocalStorage('referalKey')) {
              this.referTFP();
            } else {
              _this.setState({
                loader: false
              });
              _this.props.history.push({
                pathname: '/trustpartner',
                state: { fromRegister: this.state.fromRegister }
              });
            }
          } else {
            _this.props.history.push({
              pathname: '/trustpartner',
              state: { fromRegister: this.state.fromRegister }
            });
          }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    let { planData } = this.state;
    let planImage = '';
    if (planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.SILVER_PREFIX) {
      planImage = SilverPlan;
    } else if (planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.GOLD_PREFIX) {
      planImage = GoldPlan;
    } else if (
      planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.PLATINUM_PREFIX
    ) {
      planImage = PlatinumPlan;
    } else {
      planImage = SilverPlan;
    }
    return (
      <section id="bg">
        <div
          id="register-view"
          className="uk-width-1-1@m uk-child-width-1-2@m"
          data-uk-grid
        >
          {this.state.loader ? (
            <div className="spinner-wrapper">
              <div className="spinner">
                <img src={DynaLogo} alt="" />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="uk-width-1-1 uk-padding-remove">
            <div className="uk-height-1-1 loginReg uk-padding">
              <div
                className="uk-child-width-1-2@s uk-grid-collapse uk-text-center"
                data-uk-grid
              >
                {planData ? (
                  <div>
                    <div className="uk-tile uk-tile-muted">
                      <h2 className="theme-color uk-margin-remove-top">
                        Preferred Plan
                      </h2>
                      <img
                        src={planImage}
                        style={{ width: '100px' }}
                        alt="Platinum"
                      />
                      <div className="uk-inline-clip">
                        <p className="uk-text-lead">
                          {capitalizeFirstLetter(planData.pl_plan_name) || ''} (
                          {planData.pl_plan_prefix})
                        </p>
                        <p>
                          You will be debited a your <b>Membership Fee</b> and
                          your <b>First Weekly Contribution</b> will be
                          automatically debited.{' '}
                          <b>
                            If your auto-debit doesn't go through you may not be
                            able to access your dashboard
                          </b>
                          , contact <b>support@ayetrustund.org</b> for
                          assistance.
                        </p>
                        <ul style={{ textAlign: 'left' }}>
                          {planData &&
                          planData.planbenefits &&
                          planData.planbenefits.length > 0
                            ? planData.planbenefits.map(
                                (benefit, i) => (
                                  // i <= 12 ? (
                                  <li>
                                    {benefit.pb_value === 'No'
                                      ? benefit.pb_value
                                      : ''}{' '}
                                    {benefit.pb_text}
                                  </li>
                                )
                                // ) : null
                              )
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <div>
                    <div className="uk-tile uk-tile-default">
                      <h2 className="uk-text-large">
                        Add Payment Card
                        <br />
                      </h2>
                      <br />
                      <div>
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label">
                              Name on Card
                            </label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="cardName"
                              id="reg-cardname"
                              type="text"
                              autoComplete="off"
                              //   placeholder="Card Name"
                              maxLength="50"
                              validate={[
                                Validation.cardNameRequired,
                                Validation.alphaOnly('Card name')
                              ]}
                            />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label">Card Number</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="cardNumber"
                              id="reg-cardnumber"
                              type="text"
                              autoComplete="off"
                              // placeholder="Card Number"
                              maxLength="20"
                              validate={[
                                Validation.cardNumberRequired,
                                Validation.minLength(16, 'Card number')
                              ]}
                              normalize={Validation.normalizeNumber}
                            />
                          </div>
                        </div>

                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label">Card Pin</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="cardPin"
                              type="password"
                              autoComplete="off"
                              //   placeholder="Card Number"
                              maxLength="4"
                              validate={[
                                Validation.cardPinRequired,
                                Validation.minLength(4, 'Card Pin')
                              ]}
                              normalize={Validation.normalizeNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="uk-child-width-1-2 uk-grid-collapse val-w"
                        data-uk-grid
                      >
                        <div class="uk-tile uk-tile-default">
                          <div className="uk-form-controls val-f">
                            <label className="uk-form-label">Expiration</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="cardExpiry"
                              id="reg-cardExpiry"
                              type="text"
                              autoComplete="off"
                              // placeholder="MM/YY"
                              maxLength="5"
                              validate={[
                                Validation.cardExpiryRequired,
                                Validation.vaildExpDate,
                                Validation.expiryMonth
                              ]}
                              format={formatExpirationDate}
                            />
                          </div>
                        </div>
                        <div class="uk-tile uk-tile-default">
                          <div className="uk-form-controls">
                            <label className="uk-form-label val-f">CVV</label>
                            <Field
                              component={RenderField}
                              className="uk-input"
                              name="cardCVV"
                              id="reg-cardCVV"
                              type="password"
                              autoComplete="off"
                              maxLength="3"
                              validate={[
                                Validation.CVVRequired,
                                Validation.minLength(3, 'CVV number')
                              ]}
                              normalize={Validation.normalizeNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <br className="uk-hidden@m" />
                      <button
                        className="uk-button prog-button uk-margin-top"
                        disabled={submitting}
                        type="submit"
                      >
                        <span>Complete</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="uk-vis@m uk-alert uk-alert-success pci-note">
                      <img src={Lock} width={20} alt="lock" />
                      Please note that your card details are safe and secured as
                      they are domiciled in PCI compliant payment gateway.
                    </p>
                    <p className="uk-hidden@m uk-alert uk-alert-success pci-note">
                      <img src={Lock} width={20} alt="lock" />
                      Your card details are safe and secured.
                    </p>
                  </div>
                  <br />
                  <Link to="/trustplans" className="link">
                    Go Back
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { planData, userData } = state.user;
  return { planData, userData };
};

AddCard = connect(mapStateToProps, { actionPurchasePlan, actionGetPlans })(
  AddCard
);

export default reduxForm({
  form: 'addCardForm',
  touchOnBlur: true,
  destroyOnUnmount: false
})(AddCard);
