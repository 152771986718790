import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "../assets/css/main.css";
import Sandwich from "../assets/img/sandwich.png";
import Logo from '../assets/img/nav.png';
// import Logo from "../assets/img/nav-dash.svg";
import Icon from "../assets/img/icon.png";
import Customer_care from "../assets/img/customer-care.png";
import WhatsApp from "../assets/img/whatsapp.png";
import Tearn from "../assets/img/tearn.png";
import { clearLocalStorage } from "../common/helpers/Utils";
import { actionLogout } from "../common/redux/actions/";
import trustFundApiService from "../common/api/ApiService";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tfp_status: "",
      loan_pop_etc_rating: "",
    };
    this.logout = this.logout.bind(this);
    this.currentPlanInfo = this.currentPlanInfo.bind(this);
  }

  componentDidMount() {
    this.currentPlanInfo();

    if (this.props.userData && this.props.userData.u_id) {
      console.log(this.props.userData);
      
      if(this.props.userData.u_tfp_pin === '' || this.props.userData.u_tfp_pin === null)  {
      this.props.history.push('/trustplans');
      console.log('here is the line');
      
      }
    } else if ( this.props.userData && this.props.userData.u_id && this.props.userData.u_tfp_pin !== "") {
    } else {
      this.props.history.push("/");
    }
  }

  logout = () => {
    const _this = this;
    clearLocalStorage(function () {
      _this.props.history.push("/");
      _this.props.actionLogout();
    });
  };

  currentPlanInfo = () => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;

          this.setState({
            tfp_status: data.u_tfp_pin_status,
            loan_pop_etc_status: data.loan_pop_etc_status,
            loan_pop_etc_rating: data.rating.loan_pop_etc_rating,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const u_image = this.props.userData.u_image || "";
    const u_tfp_pin = this.props.userData.u_tfp_pin || "";
    const cur_name = this.props.userData.cur_name || "";

    return (
      <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar">
        <nav
          className="uk-navbar-container"
          data-uk-navbar
          style={{ position: "relative", zIndex: 980 }}
        >
          <div className="uk-navbar-left">
            <div className="uk-hidden@l uk-navbar-item">
              <a href="#">
                <img
                  className="nav-toggle"
                  src={Sandwich}
                  alt="Sandwitch"
                  data-uk-toggle="target: #offcanvas-menu"
                />
              </a>
            </div>

            <div className="uk-visible@m uk-navbar-item">
              <Link to="/dashboard">
                <img
                  src={Logo}
                  className="dash-logo"
                  style={{ height: "50px", margin: "5px" }}
                  alt="logo"
                />
              </Link>
            </div>

            <div>
              {this.state.loan_pop_etc_status === 0 ? (
                <div id="tfp-card" className="uk-hidden@m">
                  <span className="tfp-title">Trust Fund Pin: </span>
                  <span
                    className={`tfp tfp-title ${
                      this.props.userData.u_membertitle === "Ordinary member"
                        ? "theme-color"
                        : this.props.userData.u_membertitle ===
                          "Qualified member"
                        ? "text-green"
                        : this.props.userData.u_membertitle ===
                          "Bonafide member"
                        ? "text-blue"
                        : "theme-color"
                    }`}
                  >
                    {u_tfp_pin}
                  </span>
                </div>
              ) : this.state.loan_pop_etc_status === 1 ? (
                <div id="tfp-card" className="uk-hidden@m">
                  <span className="tfp-title">Trust Fund Pin: </span>
                  <span className="text-red">{u_tfp_pin}</span>
                </div>
              ) : this.state.loan_pop_etc_status === 2 ? (
                <div id="tfp-card" className="uk-hidden@m">
                  <span className="tfp-title">Trust Fund Pin: </span>
                  <span className="text-process">{u_tfp_pin}</span>
                </div>
              ) : null}

                <div className="uk-hidden@m">
                <a href="https://api.whatsapp.com/send?phone=+2348156328284">
                <img
                  className="w-icon-m"
                    src={WhatsApp}
                    uk-tooltip="title: Talk to us on WhatsApp; pos: left"
                  />
                </a>
              </div>

              <div id="usr-det" className="uk-visible@m uk-navbar-item">
                <button
                  className="nav-dropdown uk-light"
                  uk-tooltip="title: Your account TFP; pos: right"
                >
                  {this.state.loan_pop_etc_status === 0 ? (
                    <span className="name uk-margin-small-right">
                      Trust Fund Pin:{" "}
                      <span
                        className={`${
                          this.props.userData.u_membertitle ===
                          "Ordinary member"
                            ? "theme-color"
                            : this.props.userData.u_membertitle ===
                              "Qualified member"
                            ? "text-green"
                            : this.props.userData.u_membertitle ===
                              "Bonafide member"
                            ? "text-blue"
                            : "theme-color"
                        }`}
                      >
                        {u_tfp_pin}
                      </span>
                    </span>
                  ) : this.state.loan_pop_etc_status === 1 ? (
                    <span className="name uk-margin-small-right">
                      Trust Fund Pin:{" "}
                      <span className="text-red"> {u_tfp_pin}</span>
                    </span>
                  ) : this.state.loan_pop_etc_status === 2 ? (
                    <span className="name uk-margin-small-right">
                      Trust Fund Pin:{" "}
                      <span className="text-red"> {u_tfp_pin}</span>
                    </span>
                  ) : (
                    <span className="name uk-margin-small-right">
                      Trust Fund Pin:{" "}
                      <span className="text-process"> {u_tfp_pin}</span>
                    </span>
                  )}
                  {/* <span className="uk-icon" uk-icon="chevron-down" /> */}
                </button>
              </div>
            </div>
          </div>

          {/*<div className="uk-navbar-right uk-hidden@m">
              <Link to="/dashboard">
                <img
                  src={Icon}
                  className="dash-icon"
                  style={{ height: '30px', margin: '5px' }}
                  alt="icon"
                />
              </Link>
            </div>*/}

          <div className="uk-navbar-right">
            <div className="uk-visible@m uk-navbar-item">
              <Link to="/customercare">
                <img
                  src={Customer_care}
                  uk-tooltip="title: Click to chat with Customer Care; pos: left"
                />
              </Link>
            </div>
            <div className="uk-visible@m uk-navbar-item">
              <a href="https://api.whatsapp.com/send?phone=+2348156328284">
              <img
                className="w-icon"
                  src={WhatsApp}
                  uk-tooltip="title: Talk to us on WhatsApp; pos: left"
                />
              </a>
            </div>
            <div id="notif" className="uk-visible@m uk-navbar-item">
              <Link to="/notifications">
                <i
                  className="fa fa-bell fa-2x"
                  aria-hidden="true"
                  uk-tooltip="title: Click to see your notifications; pos: bottom"
                />
                <span className="uk-badge">
                  {this.props.userData.notification_count || "0"}
                </span>
              </Link>
            </div>
            <div id="usr-det" className="uk-visible@m uk-navbar-item">
              <button className="nav-dropdown uk-light nav-dropdown-transparent">
                <img
                  className="userimg uk-border-circle"
                  src={u_image ? u_image : Tearn}
                  alt="userImage"
                />
                {/*<span className="name uk-margin-small-right">
                  {u_first_name}
                </span>*/}
                <span
                  className="uk-margin-small-right uk-icon"
                  uk-icon="chevron-down"
                />
              </button>
              <div uk-dropdown="mode: click">
                <ul className="uk-dropdown-nav">
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="/learn-more">Learn More</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms and Conditions</Link>
                  </li>
                  <li>
                    <a onClick={this.logout}>Logout</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="usr-det" className="uk-visible@m uk-navbar-item">
              <button className="nav-dropdown uk-light">
                <span className="name uk-margin-small-right">
                  Currency: <span className="theme-color">{cur_name}</span>
                </span>
                {/*<span
                  className="uk-margin-small-right uk-icon"
                  uk-icon="chevron-down"
                />*/}
              </button>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, { actionLogout })(Header);
