import React, { Component } from 'react';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { Link } from 'react-router-dom';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import Validation from './common/helpers/Validation';
import RenderField from './authorization/RenderField';
import trustFundApiService from './common/api/ApiService';
import Allen from './assets/img/allen.png';
import DynaLogo from './assets/img/aye-loader.gif';
import Add from './assets/img/add.png';

class BuildNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      TFPName: '',
      TFPImage: '',
      trustMembers: [],
      disabled: true
    };
  }

  componentDidMount() {
    document.title = "Trust Members - If You TRUST Me I'll TRUST You";
    const { location } = this.props;

    if (location) {
      let data = location.state;
      console.log(data);
      if (data && data.trustMemberData) {
        let parsed = data.trustMemberData;
        let trustrequest = parsed.trustrequest;
        let byuserid = parsed.byuserid;
        let touserid = parsed.touserid;
        this.acceptRejectTrustMember(trustrequest, byuserid, touserid);
      }
    }
    this.getTFPMembers();
  }

  acceptRejectTrustMember = (trustrequest, byuserid, touserid) => {
    if (trustrequest && byuserid && touserid) {
      this.setState({ loader: true });
      let data = {
        trustrequest,
        byuserid,
        touserid
      };
      trustFundApiService('acceptRejectTrustMember', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.props.history.push('/trustmembers');
            this.setState({ loader: false });
            this.getTFPMembers();
          } else {
            this.props.history.push('/trustmembers');
            this.setState({ loader: false });
          }
        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err);
        });
    }
  };

  getTrustMemberDetail = event => {
    let _this = this;
    let TFP = event.target.value;

    if (TFP.length === 13) {
      let data = {
        u_mem_tfpcode: TFP
      };

      _this.setState({ loader: true });
      trustFundApiService('getReferredTFPName', data)
        .then(response => {
          if (response.data.status === 'success') {
            let TFPName = response.data.data.t_mem_name;
            let TFPImage = response.data.data.t_mem_image;
            _this.setState({
              loader: false,
              TFPName,
              TFPImage,
              disabled: false
            });
          } else if (response.data.status === 'error') {
            //_this.props.dispatch(change('trustMemberForm', 'u_tfp_code', ''));
            //this.props.dispatch(untouch('trustMemberForm', 'u_tfp_code'));
            _this.setState({ loader: false, disabled: true });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      _this.setState({
        loader: false,
        disabled: true
      });
    }
  };

  handleSubmit = formValues => {
    let _this = this;
    let u_tfp_code = formValues.u_tfp_code;
    if (u_tfp_code) {
      let data = {
        u_mem_tfpcode: u_tfp_code
      };

      this.setState({
        loader: true
      });

      trustFundApiService('AddTrustMember', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.getTFPMembers();
            _this.setState({
              TFPImage: '',
              TFPName: '',
              loader: false
            });
            _this.props.dispatch(change('trustMemberForm', 'u_tfp_code', ''));
            _this.props.dispatch(untouch('trustMemberForm', 'u_tfp_code'));
          } else {
            _this.setState({
              TFPImage: '',
              TFPName: '',
              loader: false
            });
            _this.props.dispatch(change('trustMemberForm', 'u_tfp_code', ''));
            // _this.props.dispatch(untouch('trustMemberForm', 'u_tfp_code'));
          }
        })
        .catch(err => {
          _this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  getTFPMembers = () => {
    let data = {
      offset: 1,
      count: 10
    };
    trustFundApiService('getTrustMember', data)
      .then(response => {
        if (response.data.status === 'success') {
          let trustMembers = response.data.data.t_mem_list;
          this.setState({
            trustMembers
          });
        } else {
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <div id="left" className="uk-width-3-4@m dash-margin">
                {this.state.loader ? (
                  <div className="spinner-wrapper">
                    <div className="spinner">
                      <img src={DynaLogo} alt="" />
                    </div>
                  </div>
                ) : null}
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="uk-card uk-card-loan uk-card-body animated fadeIn single uk-margin-bottom">
                      <h2 className="uk-text-large-theme theme-color uk-text-center">
                        Add Trust Member
                        <br />
                      </h2>
                    <div class="uk-text-center">
                      <div class="">
                        <div className="uk-text-center">
                          <img
                            className="uk-border-pill"
                            src={
                              this.state.TFPImage ? this.state.TFPImage : Add
                            }
                            width={150}
                            height={150}
                            alt="User"
                          />
                          <h3 className="uk-card-title theme-color">
                            {this.state.TFPName ? this.state.TFPName : ' '}
                          </h3>
                        </div>
                      </div>
                      <div class="uk-margin-top">
                        <br />
                        <div className="uk-text-center field">
                          <label className="uk-form-label-dash">
                            Enter Member TFP:
                          </label>

                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="u_tfp_code"
                            id="reg-trustPartnerTFP"
                            type="text"
                            autoComplete="off"
                            validate={[
                              Validation.TFPRequired,
                              Validation.isTFPValid
                            ]}
                            normalize={Validation.upper}
                            maxLength="13"
                            // onBlur={this.getTrustMemberDetail}
                            onChange={this.getTrustMemberDetail}
                          />

                          <br />

                          <button
                            className="uk-button uk-button button-out uk-margin-top"
                            disabled={this.state.disabled}
                          >
                            Add Member
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default reduxForm({
  form: 'trustMemberForm',
  touchOnBlur: false,
  destroyOnUnmount: true
})(BuildNetwork);
