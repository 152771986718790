import styled from "styled-components";

export const SvgWrapper = styled.div`
  justify-content: right;
  margin-right: 1.4rem;
  margin-bottom: 0.2rem;
  background-color: #000;
  color: white;
  width: 1.4rem;
  height: 1.4rem;
  padding: 0.1rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RegisterButton = styled.div`
  padding: 0.9rem 0;
  width: 90%;
  text-align: center;
  color: #e99e24;
  font-weight: 900;
  background-color: #fff;
  border: 1px solid #e99e24;
  border-radius: 0.4rem;
  cursor: pointer;
`;

export const TrustplanHeaderBox = styled.div`
  border: 1px solid orange;
  padding: 0.6rem 5rem;
  color: #000;
  text-align: center;
  width: 90%;
  margin: auto;
  border-radius: 0.4rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;

  @media (min-width: 1020px) {
    border: 1px solid orange;
    padding: 0.6rem 5rem;
    color: #000;
    text-align: center;
    width: 30%;
    margin: auto;
    border-radius: 0.4rem;
    margin-top: 4rem;
    font-size: 2.8rem;
  }
`;

export const NoMembership = styled.span`
  display: block;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-weight: 800;

  @media (min-width: 1020px) {
    display: block;
    text-align: center;
    width: 30%;
    margin: auto;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-weight: 800;
  }
`;
