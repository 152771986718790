import React, { Component } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import Modal from 'react-modal';
import moment from 'moment';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import CONSTANTS from './common/helpers/Constants';
import Avatar from './assets/img/ccicon.png';

class ChatCustomerCare extends Component {
  socket = {};
  constructor(props) {
    super(props);
    this.state = {
      onlineExpertId: '',
      expertIds: [],
      message: '',
      allMessages: []
    };
    this.socket = io.connect('https://etrustfund.org:5000');

    this.socket.on('connect', function() {
      console.log('inside connect socket');
    });

    this.socket.on('disconnect', function() {
      console.log('inside disconnect socket');
    });
  }

  // Solved the scroll to bottom issue
  componentDidUpdate() {
    const objDiv = document.getElementById('scroller');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentDidMount() {
    document.title = "Chat with Customer Care - If You TRUST Me I'll TRUST You";
    let _this = this;

    _this.getOnlineExperts();

    _this.socket.on('send_message', function(data) {
      if (
        (data &&
          parseInt(data.receiver) === parseInt(_this.props.userData.u_id)) ||
        (data.firstMessage == true &&
          data.receiver.findIndex(
            todo => todo == parseInt(_this.props.userData.u_id)
          ) !== -1)
      ) {
        _this.updateChat(data);
      }

      if (data && data.sendexpertId) {
        _this.setState({
          onlineExpertId: data.sendexpertId
        });
      }
    });
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  getOnlineExperts = () => {
    let expertIds = [];
    let expertType = CONSTANTS.CHAT_TYPE.CUSTOMER_CARE;

    let data = {
      chat_expert: CONSTANTS.CHAT_TYPE.CUSTOMER_CARE
    };

    if (expertType != '') {
      trustFundApiService('getOnlineExperts', data)
        .then(response => {
          if (response.data.status === 'success') {
            let data = response.data.data;
            if (data.length > 0) {
              data.map((expert, index) => {
                expertIds.push(expert.u_id);
              });
              this.setState({ expertIds });
            }
          } else {
            this.setState(
              {
                title: 'Chat With Customer Care',
                message: response.data.message
              },
              () => this.toggleModal()
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addMessage = e => {
    if (e.keyCode === 13 && e.shiftKey == false) {
      this.handleSendMessage();
      this.setState({
        message: ''
      });
    }
  };

  handleSendMessage = () => {
    let message = this.state.message ? this.state.message.trim() : '';
    if (message) {
      let senderId = this.props.userData.u_id;
      let expertIds =
        this.state.expertIds.length > 0 ? this.state.expertIds : [];

      let receiverId = '';
      let firstMessage = false;

      if (this.state.onlineExpertId !== '') {
        firstMessage = false;
        receiverId = this.state.onlineExpertId;
      } else {
        firstMessage = true;
        receiverId = expertIds;
      }

      let data = {
        sender: parseInt(senderId),
        receiver: receiverId.length > 0 ? receiverId : parseInt(receiverId),
        message: message,
        firstMessage: firstMessage,
        datetime: new Date()
      };
      console.log('Emit', data);
      this.socket.emit('send_message', data);
      this.updateChat(data);
      this.setState({ message: '' });
    }
  };

  updateChat(data) {
    const allMessages = this.state.allMessages;
    allMessages.push(data);
    console.log(allMessages);
    this.setState({ allMessages });
  }

  render() {
    const { allMessages } = this.state;

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Chat with customer care
                  </h3>
                  <p>{this.state.message} </p>

                  <a
                    onClick={() => this.props.history.push('/dashboard')}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Cancel
                  </a>
                </div>
              </Modal>
              <div
                id="left"
                className="uk-width-3-4@m uk-child-width-1-1@m uk-child-width-1-3@s uk-margin-remove"
                data-uk-grid
              >
                <div className="uk-padding-remove-left uk-margin-remove-top single uk-width-1-1@m uk-width-1-2@s">
                  <div className="uk-text-center  uk-margin">
                    <h2 className="uk-text-large-theme theme-color uk-text-center">
                      Chat With Customer Care
                    </h2>
                  </div>

                  <div className="chat-area" id="scroller">
                    <div className="">
                      {/* Sender div */}
                      {allMessages && allMessages.length > 0
                        ? allMessages.map((message, i) =>
                            message.sender === this.props.userData.u_id ? (
                              <div
                                className="me data-uk-grid-small uk-flex-bottom uk-flex-right uk-text-right"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small theme-bg uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {moment(message.datetime).format('HH:mm')}
                                  </p>
                                </div>

                                <div className="chat-img uk-width-auto">
                                  <img
                                    className="uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={
                                      this.props.userData.u_image
                                        ? this.props.userData.u_image
                                        : ''
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="guest data-uk-grid-small uk-flex-bottom uk-flex-left"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <img
                                    className="uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={Avatar}
                                  />
                                </div>
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small uk-card-default uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {' '}
                                    {moment(message.datetime).format('HH:mm')}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>

                  <div className="chat-input uk-card-footer uk-padding-remove">
                    <div
                      className="data-uk-grid-small uk-flex-middle"
                      data-uk-grid
                    >
                      <div className="uk-width-expand">
                        <div className="uk-padding-small uk-padding-remove-horizontal">
                          <textarea
                            className="uk-textarea uk-padding-remove uk-border-remove"
                            rows={1}
                            placeholder="Type Here..."
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={this.addMessage}
                          />
                        </div>
                      </div>
                      <div className="uk-width-auto">
                        <a
                          onClick={this.handleSendMessage}
                          className="uk-button dash-button"
                        >
                          Send
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(ChatCustomerCare);
