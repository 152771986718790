import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import Modal from 'react-modal';
import trustFundApiService from './common/api/ApiService';
import DynaLogo from './assets/img/aye-loader.gif';
import { showErrorToast } from './common/helpers/Toastr';

class UpFrontContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      counter: 0,
      wc: 1000,
      loader: false
    };
  }
  componentWillMount() {
    Modal.setAppElement('body');
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  componentDidMount() {
    document.title = "Save More - If You TRUST Me I'll TRUST You";
    this.getContribution();
  }

  getContribution = () => {
    trustFundApiService('getContribution')
      .then(response => {
        if (response.data.status === 'success') {
          let wc = response.data.data.base_unit;
          this.setState({
            wc
          });
        } else {
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  incrementWeek = () => {
    this.setState({
      counter: this.state.counter + 1
    });
  };

  decrementWeek = () => {
    if (this.state.counter >= 1) {
      this.setState({
        counter: this.state.counter - 1
      });
    }
  };

  makePayment = () => {
    let con_week = this.state.counter;
    let con_amount = this.state.wc * con_week;
    let con_currency = '';

    let data = {
      con_week,
      con_amount,
      con_currency
    };

    if (con_week > 0 && con_amount > 0) {
      this.setState({
        loader: true,
        isActive: false
      });
      trustFundApiService('makeContribution', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              counter: 0,
              isActive: false,
              loader: false
            });
            setTimeout(
              function() {
                this.props.history.push('/dashboard');
              }.bind(this),
              3000
            );
          } else {
            this.setState({
              loader: false,
              isActive: false
            });
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    } else {
      showErrorToast('Contribution amount should be greater than zero');
    }
  };

  render() {
    const cur_symbol = this.props.userData.cur_symbol || '';
    const cur_name = this.props.userData.cur_name || '';
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />
          {this.state.loader ? (
            <div className="spinner-wrapper">
              <div className="spinner">
                <img src={DynaLogo} alt="" />
              </div>
            </div>
          ) : null}

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Confirm Save More
                  </h3>
                  <p>
                    You are about to make a total contribution of{' '}
                    <span className="theme-color">
                      ({cur_symbol})
                      {this.state.counter >= 1
                        ? this.state.counter * this.state.wc
                        : this.state.counter}
                    </span>{' '}
                    for{' '}
                    <span className="theme-color">
                      {this.state.counter} Weeks
                    </span>
                    ,
                    <br />{' '}
                    <i style={{ color: 'red' }}>
                      Click Confirm to Continue or Cancel to abort.
                    </i>
                  </p>
                  <div className="uk-margin-bottom">
                    <a
                      className="uk-button dash-button"
                      onClick={this.state.loader ? '' : this.makePayment}
                    >
                      Confirm Contributions
                    </a>
                  </div>
                  <a
                    onClick={() => this.props.history.push('/dashboard')}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Cancel
                  </a>
                </div>
              </Modal>
              <div id="left" className="uk-width-3-4@m  dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  <div className="uk-width-2-1 uk-text-center">
                    <h2 className="uk-text-large-theme theme-color uk-text-center">
                      Save More
                      <br />
                    </h2>
                    <br />
                    {/* COUNTER */}

                    <div id="week" className="uk-text-center up-text">
                      <span className="uk-text-center uk-heading-secondary theme-color uk-margin-right">
                        Week(s):
                      </span>
                      {this.state.counter}
                    </div>
                    <br />
                    <div
                      id="counter"
                      className={`uk-text-center up-text ${
                        this.state.counter * this.state.wc >= '900000'
                          ? 'text-red'
                          : ''
                      }`}
                    >
                      <span className="uk-text-center uk-heading-secondary theme-color uk-margin-right">
                        Amount({cur_symbol}):
                      </span>
                      {this.state.counter >= 1
                        ? this.state.counter * this.state.wc
                        : this.state.counter}
                    </div>
                    <div className="uk-text-center uk-margin-top">
                      <br />
                      <button
                        className="uk-button uk-button-small button-out"
                        id="subtract"
                        onClick={this.decrementWeek}
                      >
                        <span uk-icon="icon: minus" />
                      </button>
                      &nbsp;
                      <button
                        className={`uk-button uk-button-small button-out ${
                          this.state.counter * this.state.wc >= '1000000'
                            ? 'hide'
                            : ''
                        }`}
                        id="add"
                        onClick={this.incrementWeek}
                      >
                        <span uk-icon="icon: plus" />
                      </button>
                    </div>
                    <br />
                    <br />
                    <div className="uk-text-center">
                      <button
                        className="uk-button button-out"
                        onClick={this.toggleModal}
                      >
                        <span>Make Payment</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(UpFrontContribution);
