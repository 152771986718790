import React from "react";
import { Link } from "react-router-dom";

export default function Howitworks() {
  return (
    <>
      <section
        id="howitworks"
        className="container-main etf-section bg-link-water"
      >
        <div className="container-how uk-margin-large-bottom">
          <div className="grid center-xs">
            <h3 className="uk-text-center">How it Works</h3>
            <br />
            <br />
          </div>
          <div className="uk-grid" uk-grid>
            <div className="uk-width-1-2 hwt-width uk-margin">
              <p className="how-n">01</p>
              <p className="how-t">Register</p>
              <p className="how-c">
                Register to join the Community of Entrepreneurs in the World.
              </p>
            </div>
            <div className="uk-width-1-2 hwt-width uk-margin">
              <p className="how-n">02</p>
              <p className="how-t">Save</p>
              <p className="how-c">
                Based on your choice of plan, your savings is autodebited either
                daily or weekly depending on your savings frequency.
              </p>
            </div>
          </div>
          <div className="uk-grid" uk-grid>
            <div className="uk-width-1-2 hwt-width uk-margin ">
              <p className="how-n">03</p>
              <p className="how-t">Earn</p>
              <p className="how-c">
                Earn always by building your Trust Network and referring others.
              </p>
            </div>
            <div className="uk-width-1-2 hwt-width uk-margin">
              <p className="how-n">04</p>
              <p className="how-t">Cashout</p>
              <p className="how-c">
                Get all your savings paid back to you during your stipulated
                Cashout window.
              </p>
            </div>
          </div>
        </div>
        <div className="uk-text-center">
          <Link to="/learn-more">
            <span className="uk-button button-out">Learn More</span>
          </Link>
          <Link to="/register">
            <span className="uk-button button-out">Join Now</span>
          </Link>
        </div>
        <br />
        <br />
      </section>
    </>
  );
}
