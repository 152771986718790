import { toast } from 'react-toastify';
import swal from 'sweetalert';

let toastId = '';

{/*export const showErrorToast = (errorMessage, event) => {
  if (!toast.isActive(toastId)) {
    toastId = toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }
};

// toastr messages for success
export const showSuccessToast = message => {
  if (!toast.isActive(toastId)) {
    toastId = toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }
};

// toastr messages for warning
export const showWarningToast = message => {
  if (!toast.isActive(toastId)) {
    toastId = toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });
  }
};*/}


export const showErrorToast = (errorMessage, event) => {
  if (!toast.isActive(toastId)) {
    toastId = swal("Error!", errorMessage, "error");
  }
};

// toastr messages for success
export const showSuccessToast = message => {
  if (!toast.isActive(toastId)) {
    toastId = swal("Success!", message, "success");
  }
};

export const showWarningToast = message => {
  if (!toast.isActive(toastId)) {
    toastId = swal("Oops!", message, "warning");
  }
};
