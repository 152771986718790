import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import trustFundApiService from "./api/ApiService";
// import ContactUs from "../Home/Sections/ContactUs/ContactUs";
import ContactUs from "../Home/Sections/ContactUs/ContactUs";

import "../assets/css/main.css";
import Logo from "../assets/img/svglogo.svg";
import Bissi from "../assets/img/bissi.png";

class Footer extends Component {
  render() {
    return (
      <div>
        {/* Contact */}
        <section id="contact">
          <ContactUs />
        </section>
        {/* Contact */}
        {/* Footer */}
        <footer className="site-footer">
          <div className="ui vertical footer segment">
            <div className="">
              <div className="uk-grid">
                <div className="uk-width-1-3">
                  <div className="logo">
                    <img className="foot-img" src={Logo} href="/" />
                  </div>
                </div>
                <div className="uk-width-1-3 footer-mobile">
                  <h4 className="ui inverted foot-header"></h4>
                  <div className="uk-grid" data-uk-grid>
                    <div className="ui inverted link list">
                      <li className="uk-text-bold theme-color">Useful Links</li>
                      <li>
                        <a href="/" className="foot-item">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="/jobs" className="foot-item">
                          Jobs
                        </a>
                      </li>
                      <li>
                        <Link to="/privacy" className="foot-item">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms" className="foot-item">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://ayeorganization.com/about/"
                          className="foot-item"
                        >
                          About A.Y.E
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ayeonline.org/pages/our-programs/"
                          className="foot-item"
                        >
                          Our Programs
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://tv.ayeonline.org/"
                          className="foot-item"
                        >
                          Reality TV Show
                        </a>
                      </li>
                    </div>
                    {/* <div className="ui inverted foot-adr">
                      <li className="ui inverted link list uk-text-bold theme-color">
                        Address
                      </li>
                      <li>
                        A107 Building 
                        <br />
                        16,Adeola Hopewell street 
                        <br />
                        Victoria island, Lagos
                        <br />
                        <br />
                       
                      </li>
                    </div> */}
                  </div>
                </div>
                <div className="uk-width-1-3 footer-mobile">
                  <h4 className="ui inverted foot-header"></h4>
                  <div className="uk-grid" data-uk-grid>
                    <div className="ui inverted foot-adr">
                      <br />
                      <li>
                        THE ENTREPRENEURSHIP VILLAGE,
                        <br />
                        Parliament Road beside Shoprite,
                        <br /> opposite International Conference Centre Akure,
                        <br />
                        Ondo State-Nigeria.
                        <br />
                        <br />
                        <span className="uk-text-bold">Tel:</span> +234 (90)
                        8349-0000
                        <br />
                        <span className="uk-text-bold">WhatsApp:</span> +234
                        (81) 5632-8284
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="uk-margin uk-text-center theme-color">
              © 2022 Entrepreneurs Trust Fund | A Division of{" "}
              <b>Africa's Young Entrepreneurs Organization</b>. All Rights
              Reserved
            </p>
          </div>
        </footer>

        {/*<section>
                  <a href="#" className="float animated rotateInUpRight">
                    <img src={Bissi} />
                  </a>
                </section>*/}
      </div>
    );
  }
}
export default Footer;
