import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import Header from "./common/Header";
import LeftSidebar from "./common/LeftSidebar";
import RightSidebar from "./common/RightSidebar";
import Notification from "./common/Notification";
import trustFundApiService from "./common/api/ApiService";
import RenderField from "./authorization/RenderField";
import Validation from "./common/helpers/Validation";
import {
  formatExpirationDate,
  formatNumber,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "./common/helpers/Utils";
import { showErrorToast } from "./common/helpers/Toastr";
import DynaLogo from "./assets/img/aye-loader.gif";
import * as qs from "query-string";

class ChangeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      pl_name: "",
      pl_image: "",
      pl_weekly_saving: "",
      pl_rgst_fee: "",
    };
  }

  componentDidMount() {
    document.title = "Change Card - If You TRUST Me I'll TRUST You";
    this.currentPlanInfo();
    this.getCardDetail();
    const { location } = this.props;
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed && parsed.response) {
        var paymentData = JSON.parse(parsed.response);
        if (paymentData) {
          if (paymentData.status == "successful") {
            this.paymentSuccess(paymentData);
          } else if (paymentData.status == "failed") {
            showErrorToast("your transaction failed, please try again later");
            this.props.history.push("/changecard");
          }
        }
      }
    }
  }

  paymentSuccess = (payment_data) => {
    let _this = this;
    if (payment_data) {
      trustFundApiService("getupdatedchangecard")
        .then((response) => {
          if (response.data.status === "success") {
            let cardData = response.data.data;
            if (cardData) {
              let data = {
                card_name: cardData.cl_cr_name,
                card_number: cardData.cl_cr_number,
                card_exp: cardData.cl_cr_expiry,
                card_cvv: cardData.cl_cr_cvv,
                card_pin: cardData.cl_cr_pin,
                card_process: "2",
                payment_type: "1",
                payment_data,
              };
              trustFundApiService("addCard", data)
                .then((response) => {
                  if (response.data.status === "success") {
                    _this.setState({ loader: false });
                  } else {
                    _this.setState({ loader: false });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);

          _this.setState({ loader: false });
        });
    }
  };

  currentPlanInfo = () => {
    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          if (data.plan_info)
            this.setState({
              pl_name: data.plan_info.pl_name,
              pl_image: data.plan_info.pl_image,
              pl_weekly_saving: data.plan_info.pl_weekly_saving,
              pl_rgst_fee: data.plan_info.pl_rgst_fee,
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCardDetail = () => {
    let _this = this;
    trustFundApiService("getCardDetail")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          _this.props.dispatch(
            change("cardForm", "cardName", data.up_card_name)
          );
          _this.props.dispatch(
            change("cardForm", "cardNumber", data.up_card_number)
          );
          _this.props.dispatch(change("cardForm", "cardPin", data.up_card_pin));
          _this.props.dispatch(change("cardForm", "cardCVV", data.up_card_cvv));
          _this.props.dispatch(
            change("cardForm", "cardExpiry", data.up_card_exp)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = (formValues) => {
    let currentYear = (new Date().getFullYear() + "").slice(-2);
    let cardMonth = formValues.cardExpiry.split("/")[0];
    let cardYear = formValues.cardExpiry.split("/")[1];
    this.setState({ loader: true });
    if (
      cardYear >= currentYear &&
      parseInt(cardMonth) !== 0 &&
      cardMonth <= 12
    ) {
      if (formValues) {
        let _this = this;
        let data = {
          card_name: formValues.cardName,
          card_number: formValues.cardNumber,
          card_exp: formValues.cardExpiry,
          card_cvv: formValues.cardCVV,
          card_pin: formValues.cardPin,
          card_process: "2",
          payment_type: "0",
          fromWeb: "1",
        };

        trustFundApiService("addCard", data)
          .then((response) => {
            this.setState({ loader: false });

            if (response.data.status === "success") {
              this.setState({ loader: false });
              console.log("result", response);

              let result = response.data.data;

              if (result && result.authurl) {
                console.log("result.authurl", result.authurl);

                let authURL = result.authurl;
                window.location.href = authURL;
              } else if (result && result.flwRef) {
                console.log("result.flwRef", result.flwRef);

                let flwRef = result.flwRef;
                let chargeResponseMessage = result.chargeResponseMessage;
                this.props.history.push({
                  pathname: "/otp",
                  state: {
                    flwRef: flwRef,
                    chargeResponseMessage: chargeResponseMessage,
                    type: "changecard",
                    cardData: data,
                  },
                });
              }
              setTimeout(function () {
                _this.props.history.push("/dashboard");
              }, 3000);
              this.setState({ loader: false });
            } else {
              this.setState({ loader: false });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      showErrorToast("Please Enter a valid expiry date");
    }
  };
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <div class="uk-text-center" data-uk-grid>
                    <div class="uk-width-1-2">
                      <div className="uk-text-center">
                        <img
                          className="uk-margin-top"
                          src={this.state.pl_image}
                          width={250}
                          height={150}
                          alt="Plan Image"
                        />
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <br />
                      <br />
                      <div className="uk-text-center">
                        <h2 className="uk-card-title text-green uk-margin-remove">
                          Subscribed Plan
                        </h2>
                        <h3 className="uk-card-title theme-color uk-margin-remove">
                          {this.props.userData &&
                          this.props.userData.u_plan_name
                            ? this.state.pl_name
                            : ""}{" "}
                        </h3>
                        <h5 className="uk-text-default text-white uk-margin-remove">
                          {this.props.userData &&
                          this.props.userData.u_pl_rgst_fee
                            ? formatNumber(this.state.pl_rgst_fee)
                            : 0}{" "}
                          Membership Fee (Renewal)
                        </h5>
                        <h5 className="uk-text-default text-white uk-margin-remove">
                          {this.props.userData &&
                          this.props.userData.u_pl_weekly_saving
                            ? formatNumber(this.state.pl_weekly_saving)
                            : 0}{" "}
                          Weekly Contribution
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <div className="uk-flex-center  uk-margin">
                    <h2 className="uk-text-large-theme theme-color uk-text-center  uk-margin-top">
                      Change Bank Card
                      <br />
                    </h2>
                    <br />
                    <form
                      onSubmit={handleSubmit(this.handleSubmit)}
                      name="changeCardForm"
                    >
                      <div className="uk-margin uk-margin-top uk-form-controls">
                        <label className="uk-form-label-dash">
                          Name on Card:
                        </label>
                        {/* <input
                          className="uk-input"
                          type="text"
                          name="name"
                          id="name"
                        /> */}
                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="cardName"
                          id="reg-cardname"
                          type="text"
                          autoComplete="off"
                          maxLength="40"
                          validate={[
                            Validation.cardNameRequired,
                            Validation.alphaOnly("Card name"),
                          ]}
                        />
                      </div>
                      <div className="uk-margin uk-margin-top uk-form-controls">
                        <label className="uk-form-label-dash uk-margin-top">
                          Card Number:
                        </label>

                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="cardNumber"
                          id="reg-cardnumber"
                          type="text"
                          autoComplete="off"
                          maxLength="20"
                          validate={[
                            Validation.cardNumberRequired,
                            Validation.cardNumberLength,
                          ]}
                          normalize={Validation.normalizeNumber}
                        />
                      </div>

                      <div className="uk-margin uk-margin-top uk-form-controls">
                        <label className="uk-form-label-dash uk-margin-top">
                          Card Pin:
                        </label>

                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="cardPin"
                          type="password"
                          autoComplete="off"
                          maxLength="4"
                          validate={[
                            Validation.cardPinRequired,
                            Validation.minLength(4, "Card Pin"),
                          ]}
                          normalize={Validation.normalizeNumber}
                        />
                      </div>
                      <div
                        className="uk-margin uk-margin-top uk-form-controls"
                        data-uk-grid
                      >
                        <div className="uk-width-1-2">
                          <label className="uk-form-label-dash">
                            Card Expiry (MM/YY):
                          </label>

                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="cardExpiry"
                            id="reg-cardExpiry"
                            type="text"
                            autoComplete="off"
                            maxLength="5"
                            validate={[
                              Validation.cardExpiryRequired,
                              Validation.vaildExpDate,
                              Validation.expiryMonth,
                            ]}
                            format={formatExpirationDate}
                          />
                        </div>
                        <div class="uk-width-1-2">
                          <label className="uk-form-label-dash">
                            Card CVV:
                          </label>
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="cardCVV"
                            id="reg-cardCVV"
                            type="password"
                            autoComplete="off"
                            maxLength="3"
                            validate={Validation.CVVRequired}
                            normalize={Validation.normalizeNumber}
                          />
                        </div>
                      </div>
                      <div className="uk-text-center ">
                        <button
                          href="/dashboard"
                          className="uk-button dash-button uk-margin-bottom uk-margin-top"
                          disabled={submitting}
                        >
                          Update Card Details
                        </button>
                        <br />
                        <Link to="/profile" className="uk-text-lead link">
                          Cancel
                        </Link>
                        <br />
                      </div>
                    </form>
                  </div>
                </div>
                {this.state.loader ? (
                  <div className="spinner-wrapper">
                    <div className="spinner">
                      <img src={DynaLogo} alt="" />
                    </div>
                  </div>
                ) : null}
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

ChangeCard = reduxForm({
  form: "cardForm",
  touchOnBlur: true,
  destroyOnUnmount: false,
})(ChangeCard);

export default connect(mapStateToProps, null)(ChangeCard);
