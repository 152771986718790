import React, { Component } from 'react';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import {
  getLocalStorage,
  removeLocalStorage
} from '../../common/helpers/Utils';

import DynaLogo from '../../assets/img/aye-loader.gif';
import trustFundApiService from '../../common/api/ApiService';
import { showErrorToast } from '../../common/helpers/Toastr';

class TrustNetwork extends Component {
  constructor(props) {
    super(props);
    this.inviteObj = { email: '', status: 0, error: '', errorStatus: 1 };
    this.errorMessage = {
      registered: 'Email already registered',
      invalid: 'Enter valid email address',
      require: 'Enter email address'
    };
    this.state = {
      responseArray: [],
      fields: [Object.assign({}, this.inviteObj)],
      errorCounter: 0,
      disabled: false,
      loader: false,
      fromRegister: 0
    };
    this.handleAddMore = this.handleAddMore.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Solved the scroll to bottom issue
  componentDidUpdate() {
    const objDiv = document.getElementById('scroller');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentDidMount() {
    //const { location } = this.props;
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    if (getLocalStorage('fromRegister') === 1) {
      this.setState({
        fromRegister: 1
      });
    } else if (
      this.props.userData &&
      this.props.userData.u_id &&
      this.props.userData.u_plan_id &&
      this.props.userData.u_plan_name
    ) {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/trustnetwork');
    }
  }

  handleAddMore() {
    this.setState((state, props) => {
      let fields = state.fields;
      fields.push(Object.assign({}, this.inviteObj));
      return { fields: fields };
    });
  }

  handleRemoveInvite(index) {
    this.setState((state, props) => {
      let fields = state.fields;
      fields.splice(index, 1);
      return { fields: fields };
    });
  }

  setErrorCounter(value) {
    this.setState((state, props) => {
      let errorCounter =
        state.errorCounter + value > -1 ? state.errorCounter - value : 0;
      return { errorCounter };
    });
  }

  onChangeHandler(e, index) {
    const { value } = e.target;
    this.setState(({ fields }, props) => {
      fields[index].email = String(value).trim();
      if (!fields[index].email) {
        fields[index].error = this.errorMessage.require;
        fields[index].errorStatus = 1;
      }
      //Email validation code and show error message
      else if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        fields[index].error = this.errorMessage.invalid;
        fields[index].errorStatus = 1;
      } else {
        fields[index].error = '';
        fields[index].errorStatus = 0;
      }
      return { fields: fields };
    });
  }

  find_duplicate_in_array(arra1) {
    const object = {};
    const result = [];

    arra1.forEach(item => {
      if (!object[item]) object[item] = 0;
      object[item] += 1;
    });

    for (const prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }

    return result;
  }

  skipPage = () => {
    removeLocalStorage('fromRegister');
    this.props.history.push('/success');
  };

  handleSubmit() {
    let validationCounter = 0;
    this.setState(
      (state, props) => {
        let fields = state.fields;
        let _fields = fields.map((item, index) => {
          if (!item.email && !item.error) {
            item.error = 'Email ID required';
            validationCounter += 1;
          } else if (item.errorStatus) {
            validationCounter += 1;
          }
          return item;
        });
        return { fields: _fields };
      },
      () => {
        if (validationCounter === 0) {
          let trustnetwork = [];
          let checkEmails = [];
          let _this = this;

          this.state.fields.map((item, index) => {
            trustnetwork.push({
              email: item.email,
              index: index
            });
            checkEmails.push(item.email);
          });

          let duplicateEmails = this.find_duplicate_in_array(checkEmails);
          if (duplicateEmails.length === 0) {
            let data = {
              trustnetwork
            };
            this.setState({
              disabled: true,
              loader: true
            });
            trustFundApiService('inviteTrustMembers', data)
              .then(response => {
                if (response.data.status === 'success') {
                  removeLocalStorage('fromRegister');
                  _this.setState({
                    disabled: false,
                    loader: false
                  });
                  if (_this.state.fromRegister === 1) {
                    _this.props.history.push('/success');
                  } else {
                    _this.props.history.push('/dashboard');
                  }
                } else if (response.data.status === 'error') {
                  _this.setState({
                    disabled: false,
                    loader: false
                  });
                  let errorData = response.data.data;
                  this.setState((state, props) => {
                    let fields = state.fields;
                    errorData &&
                      errorData.map((resItem, resIndex) => {
                        if (resItem.status === 1) {
                          fields[resIndex].error = this.errorMessage.registered;
                          fields[resIndex].errorStatus = 1;
                        }
                      });
                    return { fields };
                  });
                }
              })
              .catch(err => {
                this.setState({
                  loader: false
                });
                console.log(err);
              });
          } else {
            showErrorToast("Please don't enter same email multiple time");
          }
        } else {
          console.log(' this.state.errorCounter', this.state.errorCounter);
        }
      }
    );
  }

  render() {
    const fields = this.state.fields;

    return (
      <div>
        <section id="bg">
          <div
            id="register-view"
            className="uk-width-1-1@m uk-child-width-1-2@m"
            data-uk-grid
          >
            <div
              id="signin"
              className="uk-flex-middle uk-flex-center uk-flex-last@m uk-margin-remove"
              data-uk-grid
            >
              <div className="uk-width-1-1 uk-padding-remove">
                <div className="uk-height-1-1 loginReg uk-padding">
                  <form>
                    {this.state.loader ? (
                      <div className="spinner-wrapper">
                        <div className="spinner">
                          <img src={DynaLogo} alt="" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      id="register"
                      className="uk-child-width-1-1@m uk-flex-center"
                      data-uk-grid
                    >
                      <div>
                        <h2 className="uk-text-large">
                          {' '}
                          Invite Friends & Family
                          <br />
                        </h2>
                        <div className="uk-text-center">
                          <button
                            className="uk-button"
                            onClick={() => this.handleAddMore()}
                            type="button"
                          >
                            <span className="uk-position-relative uk-position-z-index">
                              <span uk-icon="icon: plus-circle" /> Add more
                            </span>
                          </button>
                        </div>
                        <div className="invite-container" id="scroller">
                          {fields.map((email, index) => (
                            <div
                              className="uk-form-controls uk-margin-top"
                              key={index}
                            >
                              <label
                                className="uk-form-label"
                                htmlFor="reg-email"
                              >
                                {index + 1}.{''}
                              </label>
                              {index >= 1 ? (
                                <span
                                  className="uk-button tn-remove"
                                  uk-icon="minus-circle"
                                  onClick={() => this.handleRemoveInvite(index)}
                                  style={{ cursor: 'pointer' }}
                                />
                              ) : null}
                              <input
                                type="text"
                                id="anchor"
                                name={`trustMembers[${index}]`}
                                onChange={e => this.onChangeHandler(e, index)}
                                className="uk-input"
                                value={this.state.fields[index]['email']}
                              />
                              <span>{this.state.fields[index]['error']}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="uk-margin uk-width-1-1@m uk-flex uk-flex-center uk-padding-remove-left">
                        <div className="padding-right subby">
                          <button
                            className="uk-button prog-button"
                            type="button"
                            disabled={this.state.disabled}
                            onClick={() => {
                              this.handleSubmit();
                            }}
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="uk-margin-medium-top  bottom-right">
                {this.state.fromRegister === 1 ? (
                  <a onClick={this.skipPage}>
                    <span className="uk-position-relative uk-position-z-index theme-color">
                      <span uk-icon="icon: chevron-double-right" />
                      I'll do it later
                    </span>
                  </a>
                ) : (
                  <Link to="/dashboard">
                    <span className="uk-position-relative uk-position-z-index theme-color">
                      <span uk-icon="icon: chevron-double-right" />
                      I'll do it later
                    </span>
                  </Link>
                )}
              </div>
            </div>
            <div
              id="bgWrite"
              className="uk-visible@m uk-flex-first@m uk-flex-wrap uk-flex-middle"
            >
              <div>
                <h2 className="uk-text-huge sidebar-margin-top">
                  Your NETWORK, <br />
                  is your NETWORTH! <br />
                </h2>
                <Link className="uk-button uk-button-white" to="/dashboard">
                  Go to Dashboard
                  <span
                    className="uk-margin-small-left uk-icon"
                    uk-icon="chevron-right"
                  />
                </Link>
              </div>
              <div id="links" className="uk-child-width-1-4@m uk-light">
                <Link to="/">Home</Link>
                <Link to="/terms">T & C</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/learn-more">Help Center</Link>
                <Link to="/#faq">FAQs</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TrustNetwork;
