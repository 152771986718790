import styled from "styled-components";
import { keyframes } from "styled-components";

import Colors from "../../../Shared/utils/Colors";

export const HeroButton = styled.button`
  padding: 0.5rem 2.4rem;
  color: "#000";
  border: 1px solid ${Colors.primary};
  background-color: ${Colors.primary};

  @media (max-width: 1020px) {
    padding: 0.5rem 1.4rem;
  }
`;

export const HeroButtonOutline = styled.button`
  padding: 0.5rem 2.8rem;
  color: ${Colors.primary};
  border: 1px solid ${Colors.primary};
  background-color: transparent;
`;

const WingAnimDesktop = keyframes`
  /* 0%{ transform:rotate(-15deg)} */
  1%, 2% {
    opacity: 0;
   
  }
  0%{ margin-top: 600px;}
  30%{ margin-top: -50px; opacity:0.8}
  60%{ margin-top: -50px; opacity:1}
  85%{ margin-top: -50px; opacity:0.8}
  90%{ margin-top: -50px; opacity:0.2}
  95%{ margin-top: -50px; opacity:0}
  100%{ margin-top: -50px; opacity:0}
  /* 100%{ transform:rotate(15deg)} */

  
  
`;

const WingAnim = keyframes`
  /* 0%{ transform:rotate(-15deg)} */

  0%{ margin-top: 400px; opacity:0}
  30%{ margin-top: 10px; opacity:0.8}
  60%{ margin-top: 10px; opacity:1}
  85%{ margin-top: 10px; opacity:0.8}
  90%{ margin-top: 10px; opacity:0.2}
  95%{ margin-top: 10px; opacity:0}
  100%{ margin-top: 10px; opacity:0}
  /* 100%{ transform:rotate(15deg)} */

  
  
`;

export const ImgSlide = styled.img`
  position: absolute;
  margin-top: 240px;
  width: 38%;
  left: 114px;
  animation-name: ${WingAnim};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  transition: all ease-out;
  /* animation-direction: alternate; */

  @media (min-width: 1020px) {
    animation-name: ${WingAnimDesktop};
    margin-top: -140px;
    width: 40%;
    left: 200px;
  }
`;
export const SpanContainer = styled.p`
  margin-left: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 0.4rem;
`;
export const HeroSpan = styled.span`
  padding: 0.1rem 0.6rem;
  display: inline-block;
  background-color: #fce4bd;
  border-radius: 0rem;
  color: #000;
  font-size: 14px;
`;

export const EtfText = styled.h2`
  font-size: 2rem;
`;

export const MobileSection = styled.section`
  /* margin-bottom: 24rem; */

  @media (min-width: 1000px) {
    margin-bottom: 2rem;
  }
`;
