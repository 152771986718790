import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { getLocalStorage } from "../../common/helpers/Utils";
import * as qs from "query-string";

import CONSTANTS from "../../common/helpers/Constants";
import RenderField from "../RenderField";
import Validation from "../../common/helpers/Validation";
import trustFundApiService from "../../common/api/ApiService";
import DynaLogo from "../../assets/img/aye-loader.gif";

class TrustPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TFPField: true,
      TFPName: false,
      disabled: true,
      loader: false,
      dynoLoader: false,
      fromRegister: 0,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    console.log("Dsfdfsd", location);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    if (getLocalStorage("fromRegister") === 1) {
      this.setState({
        fromRegister: 1,
      });
      console.log("here");
      if (getLocalStorage("referalKey")) {
        this.props.history.push("/dashboard");
      }
    } else if (
      this.props.userData &&
      this.props.userData.u_id &&
      this.props.userData.u_tfp_pin
      // this.props.userData.u_plan_id &&
      // this.props.userData.u_plan_name
    ) {
      console.log(
        this.props.userData,
        this.props.userData.u_id,
        this.props.userData.u_tfp_pin
      );

      this.props.history.push("/dashboard");
    } else {
      console.log(
        this.props.userData,
        this.props.userData.u_id,
        this.props.userData.u_tfp_pin
      );
      this.props.history.push("/trustpartner");
    }
    // if (location && location.state) {
    //   let fromRegister = location.state.fromRegister;
    //   if (fromRegister === 1) {
    //     this.setState({
    //       fromRegister: 1
    //     });
    //   }
    // } else if (this.props.userData && this.props.userData.u_id) {
    //   this.props.history.push('/dashboard');
    // }
    this.props.initialize({
      u_relationship: CONSTANTS.TP_RELATION.FRIEND,
      u_tfp_code: "TFP",
    });
  }

  handleSelect = (event) => {
    if (event && event.target.value === CONSTANTS.TP_RELATION.NOONE) {
      this.setState({ TFPField: false, TFPName: false, disabled: false });
    } else {
      this.setState({ TFPField: true, disabled: true });
    }
  };

  getTFPName = (event) => {
    let _this = this;
    let TFP = event.target.value;

    if (TFP.length === 13) {
      let data = {
        u_mem_tfpcode: TFP,
      };
      this.setState({ loader: true });
      trustFundApiService("getReferredTFPName", data)
        .then((response) => {
          if (response.data.status === "success") {
            let TFPName = response.data.data.t_mem_name;
            _this.props.dispatch(
              change("trustPartnerForm", "u_mem_name", TFPName)
            );
            _this.setState({ TFPName: true, disabled: false, loader: false });
          } else {
            //_this.props.dispatch(change("trustPartnerForm", "u_tfp_code", ""));
            _this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      _this.setState({
        TFPName: false,
        disabled: true,
        loader: false,
      });
      _this.props.dispatch(change("trustPartnerForm", "u_mem_name", ""));
    }
  };

  handleSubmit = (formValues) => {
    if (
      formValues &&
      formValues.u_relationship === CONSTANTS.TP_RELATION.NOONE
    ) {
      this.props.history.push("/trustnetwork");
    } else {
      let tfp_code = formValues.u_tfp_code;
      let tfp_relationship = formValues.u_relationship;

      let data = {
        u_relationship: tfp_relationship,
        u_tfp_code: tfp_code,
      };

      this.setState({
        dynoLoader: true,
      });

      trustFundApiService("referTrustPartner", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              dynoLoader: false,
            });
            setTimeout(
              function () {
                this.props.history.push({
                  pathname: "/trustnetwork",
                  state: { fromRegister: this.state.fromRegister },
                });
              }.bind(this),
              3000
            );
          } else {
            this.setState({
              dynoLoader: false,
            });
            //this.props.dispatch(change('trustPartnerForm', 'u_tfp_code', ''));
          }
        })
        .catch((err) => {
          this.setState({
            dynoLoader: false,
          });
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <section id="bg">
          <div
            id="register-view"
            className="uk-width-1-1@m uk-child-width-1-2@m"
            data-uk-grid
          >
            <div
              id="signin"
              className="uk-flex-middle uk-flex-center uk-flex-last@m uk-margin-remove"
              data-uk-grid
            >
              {this.state.dynoLoader ? (
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <img src={DynaLogo} alt="" />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="uk-width-1-1 uk-padding-remove">
                <div className="uk-height-1-1 loginReg uk-padding">
                  <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <div
                      id="register"
                      className="uk-child-width-1-1@m uk-flex-center"
                      data-uk-grid
                    >
                      <div>
                        <h2 className="uk-text-large">
                          {" "}
                          Who is your Trust Partner ?<br />
                        </h2>
                        <div className="uk-margin">
                          <label
                            className="uk-form-label"
                            htmlFor="reg-occupation"
                          >
                            Relationship{" "}
                          </label>
                          <Field
                            name="u_relationship"
                            id="reg-occupation"
                            component="select"
                            className="uk-select"
                            onChange={this.handleSelect.bind(this)}
                          >
                            <option>Select</option>
                            <option value={CONSTANTS.TP_RELATION.NOONE}>
                              {" "}
                              {CONSTANTS.TP_RELATION.NOONE}{" "}
                            </option>
                            <option value={CONSTANTS.TP_RELATION.FRIEND}>
                              {" "}
                              {CONSTANTS.TP_RELATION.FRIEND}{" "}
                            </option>
                            <option value={CONSTANTS.TP_RELATION.FAMILY}>
                              {" "}
                              {CONSTANTS.TP_RELATION.FAMILY}{" "}
                            </option>
                            <option value={CONSTANTS.TP_RELATION.COLLEAGUE}>
                              {" "}
                              {CONSTANTS.TP_RELATION.COLLEAGUE}{" "}
                            </option>
                            <option value={CONSTANTS.TP_RELATION.OTHERS}>
                              {" "}
                              {CONSTANTS.TP_RELATION.OTHERS}{" "}
                            </option>
                            ))}
                          </Field>
                        </div>
                        {this.state.TFPField ? (
                          <React.Fragment>
                            <div className="uk-margin">
                              <div className="uk-form-controls">
                                {this.state.loader ? (
                                  <span class="uk-form-icon uk-form-icon-flip loader" />
                                ) : null}
                                <Field
                                  component={RenderField}
                                  className="uk-input"
                                  name="u_tfp_code"
                                  id="reg-trustPartnerTFP"
                                  type="text"
                                  autoComplete="off"
                                  validate={[
                                    Validation.TFPRequired,
                                    Validation.isTFPValid,
                                  ]}
                                  normalize={
                                    Validation.normalizeSpecialCharacter
                                  }
                                  maxLength="13"
                                  onBlur={this.getTFPName}
                                />
                                <label
                                  className="uk-form-label"
                                  htmlFor="reg-name"
                                >
                                  Trust Partner TFP{" "}
                                </label>
                              </div>
                            </div>

                            {this.state.TFPName ? (
                              <div className="uk-margin">
                                <div className="uk-form-controls">
                                  <Field
                                    component={RenderField}
                                    className="t-partner"
                                    name="u_mem_name"
                                    id="reg-trustPartnerName"
                                    type="text"
                                    autoComplete="off"
                                    readOnly={true}
                                    style={{ marginTop: "12px" }}
                                  />
                                  <label
                                    className="uk-form-label"
                                    htmlFor="reg-name"
                                  >
                                    {" "}
                                    Trust Partner Name{" "}
                                  </label>
                                </div>
                              </div>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className="uk-margin uk-width-1-1@m uk-flex uk-flex-center uk-padding-remove-left">
                        <div className="padding-right subby">
                          <button
                            className="uk-button prog-button"
                            type="submit"
                            disabled={submitting}
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="uk-margin-medium-top  bottom-right">
                <Link to="/trustnetwork">
                  <span className="uk-position-relative uk-position-z-index theme-color">
                    <span uk-icon="icon: chevron-double-right" />
                    Skip
                  </span>
                </Link>
              </div>
            </div>
            <div
              id="bgWrite"
              className="uk-visible@m uk-flex-first@m uk-flex-wrap uk-flex-middle"
            >
              <div>
                <h2 className="uk-text-huge sidebar-margin-top">
                  Account Activated, <br />
                  Welcome on board! <br />
                </h2>
                <Link className="uk-button uk-button-white" to="/dashboard">
                  Go to Dashboard
                  <span
                    className="uk-margin-small-left uk-icon"
                    uk-icon="chevron-right"
                  />
                </Link>
              </div>
              <div id="links" className="uk-child-width-1-4@m uk-light">
                <Link to="/">Home</Link>
                <Link to="/terms">T & C</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/learn-more">Help Center</Link>
                <Link to="/#faq">FAQs</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userData } = state.user;
  return { userData };
};

TrustPartner = connect(mapStateToProps, null)(TrustPartner);

export default reduxForm({
  form: "trustPartnerForm",
  touchOnBlur: true,
  destroyOnUnmount: true,
})(TrustPartner);
