import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import Header2 from "../../common/Header2";
import { clearLocalStorage } from "../../common/helpers/Utils";
import { actionLogout } from "../../common/redux/actions/";
import DynaLogo from "../../assets/img/aye-loader.gif";
import * as qs from "query-string";

import "../../assets/css/main.css";
import Logo from "../../assets/img/nav.svg";
import SilverPlan from "../../assets/img/silver.png";
import GoldPlan from "../../assets/img/gold.png";
import PlatinumPlan from "../../assets/img/Plat.png";
import FlexiPlan from "../../assets/img/Flex.png";
import PlanBlk from "../../assets/img/plan-blk.png";
import freeImg from "../../assets/img/free.png";
import newImg from "../../assets/img/new.png";
import mostImg from "../../assets/img/most.png";
import hoverImg from "../../assets/img/hover.png";
import OrdinaryPlan from "../../assets/img/ordinary.png";
import Verified from "../../assets/img/verified.png";
import CONSTANTS from "../../common/helpers/Constants";
import {
  actionGetPlans,
  actionBuyPlan,
  actionOrdinaryPlan,
} from "../../common/redux/actions";
import {
  formatNumber,
  getLocalStorage,
  setLocalStorage,
} from "../../common/helpers/Utils";
import { showErrorToast } from "../../common/helpers/Toastr";
import trustFundApiService from "../../common/api/ApiService";

class PlanView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromRegister: 0,
      loader: false,
      isChosen1: false,
      isChosen2: false,
      isChosen3: false,
      isChosen4: false,
      isChosen5: false,

      selectedPlanId: "",
      flexiFrequencies: [],
      pf_pl_text: "",
      con_amount: "",
      membership_fee: 0.0,
    };
    this.logout = this.logout.bind(this);
    this.getFlexiFrequencies = this.getFlexiFrequencies.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.getPlans = this.getPlans.bind(this);
  }

  logout = () => {
    const _this = this;
    clearLocalStorage(function () {
      _this.props.history.push("/");
      _this.props.actionLogout();
    });
  };

  componentDidMount() {
    this.getFlexiFrequencies();
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    const { location } = this.props;
    if (location) {
      const parsed = qs.parse(location.search);
      if (parsed.reference && parsed.trxref) {
        this.setState(
          {
            loader: true,
          },
          () => this.paymentProcess()
        );
      }
    }

    if (getLocalStorage("fromRegister") === 1) {
      this.setState({
        fromRegister: 1,
      });
    } else if (
      this.props.userData &&
      this.props.userData.u_id &&
      this.props.userData.u_tfp_pin &&
      this.props.userData.u_id
      // this.props.userData.u_plan_id &&
      // this.props.userData.u_plan_name
    ) {
      this.props.history.push("/dashboard");
    } else {
      this.props.history.push("/trustplans");
    }

    // if (location && location.state) {
    //   let fromRegister = location.state.fromRegister;
    //   if (fromRegister === 1) {
    //     this.setState({
    //       fromRegister: 1
    //     });
    //   }
    // } else if (
    //   this.props.userData &&
    //   this.props.userData.u_id &&
    //   this.props.userData.u_plan_id &&
    //   this.props.userData.u_plan_name
    // ) {
    //   this.props.history.push('/dashboard');
    // } else {
    //   this.props.history.push('/trustplans');
    // }
    this.getPlans();
  }

  getPlans = () => {
    this.props.actionGetPlans();
  };

  selectPlan = (type, val) => {
    console.log(this.state.selectedPlanId);
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "0",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "0",
      };
    }

    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          console.log("hhhhhhhhhhhhhhh", getLocalStorage("planId"));

          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
              if (result.u_tfp_pin) {
                console.log("yes");
                this.props.actionOrdinaryPlan(result).then((resp) => {
                  this.props.history.push("/dashboard");
                });
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount: Math.floor(this.state.con_amount / 10),
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
    // this.props.history.push('/carddetails');
  };
  getFlexiFrequencies() {
    this.setState({
      loader: true,
    });
    trustFundApiService("getFlexiFrequencies").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          loader: false,
          flexiFrequencies: response.data.data,
          pf_pl_text: response.data.data[0].pf_pl_text,
        });
      } else {
        this.setState({
          loader: false,
        });
      }
    });
  }
  handleFlexiChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);

    var isnum =
      e.target.name == "con_amount" ? /^\d+$/.test(e.target.value) : true;

    this.setState(
      {
        [e.target.name]:
          e.target.name == "con_amount"
            ? e.target.value.replace(/[^0-9]/g, "")
            : e.target.value,
      },
      () => {
        if (isnum && this.state.con_amount > 199) {
          this.selectPlan("subscribeFlexiPlan", 1);
        }
      }
    );
  };
  toggleModal1 = (id) => {
    this.setState({
      selectedPlanId: id,
      isChosen1: !this.state.isChosen1,
    });
  };
  toggleModal2 = (id) => {
    this.setState({
      selectedPlanId: id,
      isChosen2: !this.state.isChosen2,
    });
  };
  toggleModal3 = (id) => {
    this.setState({
      selectedPlanId: id,
      isChosen3: !this.state.isChosen3,
    });
  };
  toggleModal4 = (id) => {
    console.log(id);

    this.setState({
      selectedPlanId: id,
      isChosen4: !this.state.isChosen4,
    });
  };
  toggleModal5 = (id) => {
    this.setState({
      con_amount: "",
      selectedPlanId: id,
      isChosen5: !this.state.isChosen5,
    });
  };

  bankTransfer = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/transfer");
  };

  paymentProcess = () => {
    let _this = this;
    console.log("paymentProcesspaymentProcess22222");

    trustFundApiService("paymentProcess2")
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.data;

          if (result && result.pr_response) {
            let isSuccess = result.pr_response.status;
            if (isSuccess === true) {
              let paymentData = {
                planid: getLocalStorage("planId")
                  ? getLocalStorage("planId")
                  : this.state.selectedPlanId,
                payment_data: result.pr_response,
              };
              _this.setState({
                loader: true,
              });
              console.log("Loader Loader");

              this.props
                .actionBuyPlan(paymentData)
                .then((response) => {
                  if (response.data.status === "success") {
                    if (getLocalStorage("referalKey")) {
                      console.log("dddd");
                      this.referTFP();
                    } else {
                      _this.setState({
                        loader: false,
                      });
                      _this.props.history.push({
                        pathname: "/trustpartner",
                        state: { fromRegister: _this.state.fromRegister },
                      });
                    }
                  } else {
                    _this.setState({
                      loader: false,
                    });
                    _this.props.history.push({
                      pathname: "/trustpartner",
                      state: { fromRegister: this.state.fromRegister },
                    });
                  }
                })
                .catch((err) => {
                  _this.setState({
                    loader: false,
                  });
                  console.log(err);
                });
            } else {
              showErrorToast("your transaction failed, please try again later");
            }
          } else {
            console.log("payment response not caught");
          }
        } else {
          _this.setState({
            loader: false,
          });
        }
      })
      .catch((err) => {
        _this.setState({
          loader: false,
        });
      });
  };

  referTFP = () => {
    let tfp_code = getLocalStorage("referalKey");
    let tfp_relationship = CONSTANTS.TP_RELATION.FRIEND;

    if (tfp_code && tfp_relationship) {
      let data = {
        u_relationship: tfp_relationship,
        u_tfp_code: "TFP" + tfp_code,
      };

      trustFundApiService("referTrustPartner", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.updateProcess();
            this.setState({
              loader: false,
            });
            setTimeout(
              function () {
                this.props.history.push({
                  pathname: "/dashboard",
                  state: { fromRegister: this.state.fromRegister },
                });
              }.bind(this),
              3000
            );
          } else {
            this.props.history.push({
              pathname: "/trustpartner",
              state: { fromRegister: this.state.fromRegister },
            });
            this.setState({
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  updateProcess = () => {
    let data = {
      type: "both",
    };

    trustFundApiService("updateRegisterProcess", data);
  };

  render() {
    const { planData, userData, flexiFrequencies } = this.props;
    //console.log(userData);

    return (
      <div>
        <section
          id="planTop"
          className="uk-width-1-1@m"
          data-uk-height-viewport
        >
          <Header2 />
          <div className="container-main">
            {/*{this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}*/}
            <div className="uk-text-center">
              <div className="grid center-xs">
                <h3 className="uk-text-center">Trust Plans</h3>
                <p className="uk-text-center text-flash animated flash theme-color">
                  Click on any Plan to See More...
                </p>
                <br />
              </div>
              <div className="plan-select">
                <ul>
                  {this.props.planData && this.props.planData.length > 0
                    ? this.props.planData.map((plan, index) => (
                        <li key={plan.pl_plan_id}>
                          <Link
                            class=""
                            to={{
                              pathname: "/trustplans",
                              state: {
                                id: plan.pl_plan_id,
                              },
                            }}
                          >
                            {/*<Link class="uk-accordion-title">*/}
                            <div
                              className="card-trst plan-card pln-select-card"
                              key={plan.id}
                            >
                              <div>
                                {plan.pl_plan_name == "BASIC" ? (
                                  <img
                                    src={freeImg}
                                    className="tag-img"
                                    alt=""
                                  />
                                ) : plan.pl_plan_name == "FLEXI" ? (
                                  <img
                                    src={newImg}
                                    className="tag-img"
                                    alt=""
                                  />
                                ) : plan.pl_plan_name == "PLATINUM" ? (
                                  <img
                                    src={mostImg}
                                    className="tag-img"
                                    alt=""
                                  />
                                ) : null}
                              </div>
                              <div className="uk-text-center">
                                {plan.pl_plan_name == "BASIC" ? (
                                  <img
                                    src={OrdinaryPlan}
                                    className="pln-img"
                                    alt=""
                                  />
                                ) : plan.pl_plan_name != "BASIC" ? (
                                  <img
                                    src={PlanBlk}
                                    className="pln-img"
                                    alt=""
                                  />
                                ) : null}
                                <h6 className="pln-name">
                                {plan.pl_plan_name == 'ORDINARY' ? 'BASIC':  plan.pl_plan_name } <br />
                                  MEMBERSHIP
                                </h6>
                              </div>
                              <img
                                src={hoverImg}
                                className="hover-plan"
                                alt=""
                              />
                            </div>
                          </Link>
                          <div class="uk-accordion-content"></div>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ Section */}
        <section id="faq" className="trst-plan-bg">
          {/*<div className="uk-text-center uk-margin-large-top">
            <Link to="/learn-more">
              <span className="uk-button button-out">Learn More</span>
            </Link>
            <Link onClick={this.logout}>
              <span className="uk-button button-out">Return Home</span>
            </Link>
          </div>*/}
          <br />
        </section>
        {/* End FAQ Section */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { planData, userData } = state.user;
  return { planData, userData };
};

PlanView = connect(mapStateToProps, {
  actionGetPlans,
  actionBuyPlan,
  actionOrdinaryPlan,
})(PlanView);

export default PlanView;
