import React, { useState } from "react";
import styles from "./Tplan.module.css";
import { formatNumber } from "../../../common/helpers/Utils";
import { Link } from "react-router-dom";
import { setLocalStorage } from "../../../common/helpers/Utils";
import { RegisterButton, SvgWrapper } from "./Styles";

export default function Plan({
  planName,
  weeklySaving,
  loan,
  action,
  planId,
  planData,
  goto,
}) {
  const [flipit, setflipit] = useState(false);
  const gettrustbonus = (planName) => {
    if (planName === "PLATINUM") {
      return "1.5%";
    } else if (planName === "GOLD") {
      return "1%";
    } else if (planName === "SILVER") {
      return "0.5";
    } else {
      return "0.5% - 1.5%";
    }
  };

  const registerPlan = (plan) => {
    setLocalStorage("planId", plan.pl_plan_id);
    window.location.href = "/register";
  };

  return (
    <div className={styles.plan}>
      <input type="checkbox" checked={flipit} id={styles.flipcheck} />
      <div className={styles.flipcontainer}>
        <div className={styles.flipper}>
          <div className={styles.front}>
            <div className={styles.frontbg}>
              <div className={styles.planHeader}>
                <h4>{planName} MEMBER</h4>
              </div>
              <div className={styles.planPrice}>
                <p>Weekly Savings</p>
                <h2>₦{formatNumber(weeklySaving)}</h2>
              </div>
              <div className={styles.planBenefit}>
                <p>Weekly Earnings</p>
              </div>
              <div className={styles.planBenefit}>
                <p>
                  Loan up to{" "}
                  <span className="planBenefitcta">₦{formatNumber(loan)}</span>
                </p>
              </div>

              <div className={styles.planBenefit}>
                <p>
                  Trust bonus{" "}
                  <span className="planBenefitcta">
                    ({gettrustbonus(planName)} On Total Savings)
                  </span>
                </p>
              </div>
              <div className={styles.planBenefit}>
                <p>
                  Trust Investment opportunities{" "}
                  <span className="planBenefitcta">(Up To 35% ROI)</span>
                </p>
              </div>

              <div className={styles.moreBenefitBtnContainer}>
                <RegisterButton onClick={() => registerPlan(planData)}>
                  Register Now
                </RegisterButton>

                <button onClick={() => setflipit(!flipit)}>
                  See more benefits
                </button>
              </div>
            </div>
          </div>

          {/* ======== back ======= */}
          <div className={styles.back} onClick={() => setflipit(!flipit)}>
            <div className={styles.backbg}>
              <div
                style={{
                  padding: "0.1rem 0",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <SvgWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clipRule="evenodd"
                    />
                  </svg>
                </SvgWrapper>
              </div>

              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[2].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[3].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[4].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[5].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[6].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[7].pb_text}</p>
              </div>
              <div className={styles.planBenefit}>
                <p>{planData.planbenefits[8].pb_text}</p>
              </div>

              <div className={styles.moreBenefitBtnContainer}>
                <button onClick={() => registerPlan(planData)}>Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
