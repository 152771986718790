import React from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

const Sidebar = props => {
  return (
    <div
      id="bgWrite"
      className="uk-visible@m uk-flex-first@m uk-flex-wrap uk-flex-middle"
    >
      {props.pageName === 'Login' ? (
        <div>
          <h2 className="uk-text-huge sidebar-margin-top">
            Get Loan
            <br />
            Approval In 59Seconds.
            <br />
            No Interest, No Collateral
          </h2>
          <Link className="uk-button uk-button-white" to="/register">
            CREATE ACCOUNT
            <span
              className="uk-margin-small-left uk-icon"
              uk-icon="chevron-right"
            />
          </Link>
        </div>
      ) : (
        <div>
          <h2 className="uk-text-huge sidebar-margin-top">
            Create an account
            <br />
            now and become
            <br />
            a member of the first
            <br />
            Entrepreneurs Cooperative
            <br />
            in the World
          </h2>
          <Link className="uk-button uk-button-white" to="/login">
            LOGIN
            <span
              className="uk-margin-small-left uk-icon"
              uk-icon="chevron-right"
            />
          </Link>
        </div>
      )}

      <div id="links" className="uk-light">
        <Link to="/">Home</Link>
        <Link to="/terms">T & C</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/learn-more">Learn More</Link>
        <Link to="/#faq">FAQs</Link>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  pageName: PropTypes.string
};

export default Sidebar;
