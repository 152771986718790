import styled from "styled-components";

export const TestimonyContainer = styled.div`
  background-color: #fce4bd;
  height: 20rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.4rem;
`;

export const SingleTestimony = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Testimony = styled.div`
  width: 80%;
  margin: auto;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  border-radius: 4.4rem;
  @media (min-width: 1020px) {
    width: 50%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 6rem;
  height: 6rem;
`;

export const MemberImage = styled.img`
  width: auto !important;
  height: 6rem;
  border-radius: 50rem;
  object-fit: cover;
`;

export const MemberName = styled.span`
  margin-top: 1rem;
  display: block;
  color: #000;
  font-weight: 900;
`;

export const MemberText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000;

  q {
    font-size: 0.8rem;

    @media (min-width: 1020px) {
      font-size: 1rem;
    }
  }
`;
