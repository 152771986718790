import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import member1 from "./assets/member1.jpg";
import mem1 from "./assets/mem1.jpg";
import mem2 from "./assets/mem2.jpg";
import mem3 from "./assets/mem3.jpg";
import mem4 from "./assets/mem4.jpg";
import mem5 from "./assets/mem5.jpg";
import mem6 from "./assets/mem6.jpg";
import mem7 from "./assets/mem7.jpg";
import mem8 from "./assets/mem8.jpg";
import mem9 from "./assets/mem9.jpg";
import mem10 from "./assets/mem10.jpg";
import {
  TestimonyContainer,
  Testimony as SingleTestimony,
  MemberImage,
  MemberName,
  MemberText,
  ImageContainer,
} from "./Styles";
// import AwesomeSlider from "react-awesome-slider";

export default function Testimony() {
  return (
    <>
      <div
        style={{
          width: "85%",
          margin: "auto",
          marginTop: "12rem",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Testimonials</h3>

        <p style={{ textAlign: "center" }}>
          What our members are saying.
          {/* Helping our members succed through savings culture */}
        </p>
        <br />
        <br />
        <Carousel
          autoPlay={true}
          interval={3000}
          showIndicators={false}
          infiniteLoop={true}
          width="100%"
        >
          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={member1} alt="" />
                <MemberName>Babatunde Jikunu</MemberName>
                <MemberText>
                  <q>
                    I have been able to save much and only have access to my
                    savings at the end of each trust cycle. I believe the
                    E-TrustFund has really helped me alot.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem1} alt="" />

                <MemberName>Jeremiah Hinmikaye</MemberName>
                <MemberText>
                  <q>
                    The Entrepreneurs Trust Fund is an Innovative Solution that
                    can help entrepreneurs to save money easily and increase the
                    level of trust for doing business. It is a platform for
                    every entrepreneur to be a member of.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem2} alt="" />

                <MemberName>Ridwan Agboluaje</MemberName>
                <MemberText>
                  <q>
                    The E-Trustfund platform is a very exciting platform and it
                    is something that is going to link Africa together through
                    Trust.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem3} alt="" />

                <MemberName>Solomon Adebayo</MemberName>
                <MemberText>
                  <q>
                    It is a platform that helps you earn as you save and this
                    kind of platform i can say that the sky is the limit because
                    we’re going place and going together as a Trust Network.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem4} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    The E-TrustFund Is a destructive innnovation i have seen so
                    far which is about we saving and i believe this will be one
                    of the most potential idea in Africa
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem5} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    For the fact that A.Y.E has a platform that you can even use
                    to save is a whole lot for me because seriously the banks
                    are not an option due to the ridiculous charges from
                    withdrawals to credits.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem6} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    The Entrepreneurs Trust Fund it’s really great and it is
                    like support system that has been created for young
                    entrepreneurs where we can get Trust, get backing...
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem7} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    The E-TrustFund is wonderful idea if we can leverage on it
                    especially as student entrepreneur, it will really help
                    young aspiring entrepreneurs to get things done.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem8} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    The E-TrustFund will help me save, gain trust and build
                    network of like minds and this will help in collaborations
                    well.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem9} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    The E-TrustFund Is a network build on Trust, i will advice
                    every entrepreneur to join this platform because it is
                    really good and saves you a lot of stress and enable you
                    save and cashout big at the end of your saving cycle.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>

          <TestimonyContainer>
            <SingleTestimony>
              <div>
                <MemberImage src={mem10} alt="" />

                <MemberName>E-Trustfund Member</MemberName>
                <MemberText>
                  <q>
                    My savings culture is too poor, i find it hard to save every
                    time i have little money in my pocket but with the
                    E-Trustfund, i am able to save and watch my savings and also
                    have enough money to support my business at the end of the
                    year.
                  </q>
                </MemberText>
              </div>
            </SingleTestimony>
          </TestimonyContainer>
        </Carousel>
      </div>

      <br />
      <br />
    </>
  );
}
