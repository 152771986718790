export default {
  register: {
    url: "/register",
    method: "POST",
    data: {
      email: "",
      password: "",
      u_first_name: "",
      u_phone: "",
      device_token: "",
      device_type: "",
      u_trust_link: "",
      u_associate_tfp: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  login: {
    url: "/login",
    method: "POST",
    data: {
      email: "",
      password: "",
      device_token: "",
      device_type: "",
      type: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  emailVerification: {
    url: "/emailverification",
    method: "POST",
    data: {
      email: "",
      type: "",
      authcode: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  forgotPassword: {
    url: "/forgotpassword?email=:email",
    method: "GET",
    data: {},
    showResultMessage: true,
    showErrorMessage: true,
  },

  resetPassword: {
    url: "/reset",
    method: "POST",
    data: {
      userid: "",
      password: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getPlans: {
    url: "/getsubscriptionplans",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  addCard: {
    url: "/submituserpaymentcard",
    method: "POST",
    data: {
      card_name: "",
      card_number: "",
      card_exp: "",
      card_cvv: "",
      card_pin: "",
      card_process: "",
      payment_type: "",
      payment_data: "",
      fromWeb: "1",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  addCard1: {
    url: "/submituserpaymentcard",
    method: "POST",
    data: {
      card_name: "",
      card_number: "",
      card_exp: "",
      card_cvv: "",
      card_pin: "",
      card_process: "",
      payment_type: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  changeCardByOTP: {
    url: "/submituserpaymentcard",
    method: "POST",
    data: {
      card_name: "",
      card_number: "",
      card_exp: "",
      card_cvv: "",
      card_pin: "",
      card_process: "",
      payment_type: "",
      flwRef: "",
      otp: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  subscribePlan: {
    url: "/usersubscribedplan",
    method: "POST",
    data: {
      planid: "",
      payment_type: "",
      payment_data: "",
      fromWeb: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  subscribePlanByOTP: {
    url: "/usersubscribedplan",
    method: "POST",
    data: {
      planid: "",
      payment_type: "",
      flwRef: "",
      otp: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  referTrustPartner: {
    url: "/addreferedtrustpartner",
    method: "POST",
    data: {
      u_relationship: "",
      u_tfp_code: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  getReferredTFPName: {
    url: "/gettrustmemberbytfp",
    method: "POST",
    data: {
      u_mem_tfpcode: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  inviteTrustMembers: {
    url: "/inviteusers",
    method: "POST",
    data: {
      trustnetwork: [],
    },
    showResultMessage: true,
    showErrorMessage: false,
  },

  getContribution: {
    url: "/getusercontribution",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  makeContribution: {
    url: "/usermakeupfrontcontribution",
    method: "POST",
    data: {
      con_week: "",
      con_amount: "",
      con_currency: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getUserProfile: {
    url: "/user",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  updateProfile: {
    url: "/updateuserprofile",
    method: "POST",
    data: {
      email: "",
      u_first_name: "",
      u_phone: "",
      u_business_sector: "",
      u_address: "",
      u_tfp_pin: "",
      u_image: "",
      up_bank_name: "",
      up_account_number: "",
      up_card_number: "",
      up_bank_holder_name: "",
      u_hear_about_us: "",
      u_kin_name: "",
      u_kin_email: "",
      u_kin_contact: "",
      gender: "",
      dob: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getTransaction: {
    url: "/transactions",
    method: "POST",
    data: {
      trans_type: "",
      offset: "",
      count: "",
      trans_from_date: "",
      trans_to_date: "",
      orderby: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  AddTrustMember: {
    url: "/addtrustmember",
    method: "POST",
    data: {
      u_mem_tfpcode: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  acceptRejectTrustMember: {
    url: "/addtrustmemberresponse",
    method: "POST",
    data: {
      byuserid: "",
      touserid: "",
      trustrequest: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getTrustMember: {
    url: "/trustmemberslisting",
    method: "POST",
    data: {
      offset: "",
      count: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  getLoanDetail: {
    url: "/getuserloandetail",
    method: "POST",
    data: {
      loan_id: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  checkLoanRequirement: {
    url: "/checkloanrequirement",
    method: "POST",
    data: {
      loan_amount: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  trustMemberByType: {
    url: "/trustmembers",
    method: "POST",
    data: {
      offset: "",
      count: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  applyForLoan: {
    url: "/userloanrequest",
    method: "POST",
    data: {
      loan_process: "",
      loan_amount: "",
      loan_repayment_date: "",
      req_tfps: {},
      trustmembers: [],
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getTrustPartnerResponseOnLoan: {
    url: "/usertrustpartnerresponse",
    method: "POST",
    data: {
      trustresponse: "",
      loanid: "",
      uid: "",
      termschecked: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  changePassword: {
    url: "/changepassword",
    method: "POST",
    data: {
      old_password: "",
      new_password: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getUserSavedLoanrequest: {
    url: "/getusersavedloanrequest",
    method: "POST",
    data: {
      loan_id: "",
      offset: "",
      count: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  redeemEarnings: {
    url: "/referalreedem",
    method: "POST",
    data: {
      redeem_amount: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  redeemEarningsDB: {
    url: "/referalreedem",
    method: "POST",
    data: {
      redeem_amount: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  emergencyTravelCover: {
    url: "/emergencytravelcover",
    method: "POST",
    data: {
      ec_from: "",
      ec_to: "",
      ec_type: "",
      ec_amount: "",
      ec_reason: "",
      ec_from_travel_date: "",
      ec_to_travel_date: "",
      ec_repayment_date: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  ETCCityList: {
    url: "/emergencytravelcoverlist",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  purchaseOrderPower: {
    url: "/purchaseorderpower",
    method: "POST",
    data: {
      pr_item_id: "",
      pr_item_name: "",
      pr_item_amount: "",
      ec_amount: "",
      pr_repayment_date: "",
      pr_reason_item_purchase: "",
      pr_delivery_location: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  trustWallet: {
    url: "/usertrustwallet",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  TNMMeetingList: {
    url: "/tnmmeetinglist",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  joinMeeting: {
    url: "/tnmmeetingjoin",
    method: "POST",
    data: {
      event_id: "",
      event_status: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  repayLoan: {
    url: "/loanrepayement",
    method: "POST",
    data: {
      loan_amount: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  repayLoanDB: {
    url: "/loanrepayement",
    method: "POST",
    data: {
      loan_amount: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  repayETC: {
    url: "/etcrepayement",
    method: "POST",
    data: {
      etc_amount: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  repayPOP: {
    url: "/poprepayement",
    method: "POST",
    data: {
      pop_amount: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  cashout: {
    url: "/cashout",
    method: "POST",
    data: {
      cashout_amount: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  cashoutDB: {
    url: "/cashout",
    method: "POST",
    data: {
      cashout_amount: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  contactUs: {
    url: "/ayecontactus",
    method: "POST",
    data: {
      c_name: "",
      c_number: "",
      c_email: "",
      c_message: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  howItWorks: {
    url: "/ayehowitworks",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  endorsement: {
    url: "/ayeendorsement",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  faq: {
    url: "/ayefaq",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  uploadProfileImage: {
    url: "/userimageupload",
    method: "POST",
    data: {
      u_image: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  getNotifications: {
    url: "/usenotificationlist",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  getCardDetail: {
    url: "/getuserpaymentcard",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  getOnlineExperts: {
    url: "/getchatusers",
    method: "POST",
    data: {
      chat_expert: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  customerCareChat: {
    url: "/getchatcustomercare",
    method: "POST",
    data: {
      chat_expert: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  profileCompleteness: {
    url: "/userdashboard",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  planChangeList: {
    url: "/userplanchangelist",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  planChange: {
    url: "/userplanchange",
    method: "POST",
    data: {
      pl_id: "",
      pl_change: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  resendEmail: {
    url: "/resendemailverification",
    method: "POST",
    data: {
      email: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getPlanDetail: {
    url: "/userplanchangelist",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  refundOrCancelLoan: {
    url: "/userloanaction",
    method: "POST",
    data: {
      loan_action: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  readNotification: {
    url: "/notificationread",
    method: "POST",
    data: {
      notification_id: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  cancelPOP: {
    url: "/cancelpurchaseorderpower",
    method: "POST",
    data: {},
    showResultMessage: true,
    showErrorMessage: true,
  },

  cancelETC: {
    url: "/cancelemergencytravelcover",
    method: "POST",
    data: {},
    showResultMessage: true,
    showErrorMessage: true,
  },

  updateRegisterProcess: {
    url: "/updateinviteuserstatus",
    method: "POST",
    data: {
      type: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  postChangeCard: {
    url: "/updatechangecard",
    method: "POST",
    data: {
      change_card: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  getChangeCardStatus: {
    url: "/getchangecard",
    method: "POST",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  getupdatedchangecard: {
    url: "/getupdatedchangecard",
    method: "POST",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },

  paymentProcess1: {
    url: "/userplanpayment",
    method: "POST",
    data: {
      planid: "",
      page_response: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },

  paymentProcess2: {
    url: "/getuserpaymentresponse",
    method: "GET",
    showResultMessage: false,
    showErrorMessage: false,
  },

  paymentProcess3: {
    url: "/userplanpaymentcallback",
    method: "POST",
    data: {
      planid: "",
      payment_data: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  changeCardPaymentProcess1: {
    url: "/changecardauthrequest",
    method: "POST",
    data: {
      page_response: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  changeCardPaymentProcess2: {
    url: "/changecardpaymentcallback",
    method: "POST",
    data: {
      payment_data: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },

  getChatHistory: {
    url: "/getuserschatlisting",
    method: "POST",
    data: {
      role_id: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  saveChatMessage: {
    url: "/storemessage",
    method: "POST",
    data: {
      to_id: "",
      role_id: "",
      msg: "",
    },
    showResultMessage: false,
    showErrorMessage: false,
  },

  getUnreadChatCount: {
    url: "/getuserschatcounts",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },
  subscribeOrdinaryPlan: {
    url: "/userordinaryplansubscribe",
    method: "POST",
    data: { planid: "" },
    showResultMessage: true,
    showErrorMessage: false,
  },
  subscribeFlexiPlan: {
    url: "/userflexiplanpayment",
    method: "POST",
    data: {
      flexi_type: "",
      planid: "",
      con_amount: "",
      pf_pl_text: "",
      pf_pl_duration: "",
      page_response: "",
    },
    showResultMessage: false,
    showErrorMessage: true,
  },
  getFlexiFrequencies: {
    url: "/getflexiplanfrequency",
    method: "GET",
    data: {},
    showResultMessage: false,
    showErrorMessage: false,
  },
  usernonftmplanpaymentbyearning: {
    url: "/usernonftmplanpaymentbyearning",
    method: "POST",
    data: { planid: "", page_response: "" },
    showResultMessage: true,
    showErrorMessage: true,
  },
  userftmplanpaymentbyearning: {
    url: "/userftmplanpaymentbyearning",
    method: "POST",
    data: {
      flexi_type: "",
      planid: "",
      con_amount: "",
      pf_pl_text: "",
      page_response: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  usermakeupfrontcontributionbyearning: {
    url: "/usermakeupfrontcontributionbyearning",
    method: "GET",
    data: {},
    showResultMessage: true,
    showErrorMessage: true,
  },
};
