import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import trustFundApiService from "./common/api/ApiService";
import Footer from "./common/Footer";
import Header2 from "./common/Header2";

import Reg from "./assets/img/l-m/reg.svg";
import Contributions from "./assets/img/l-m/Contributions.svg";
import Cashout from "./assets/img/l-m/Cashout.svg";
import Advisory from "./assets/img/l-m/advisory.svg";
import Earnings from "./assets/img/l-m/earnings.svg";
import ETC from "./assets/img/l-m/ETC.svg";
import Loan from "./assets/img/l-m/Loan.svg";
import Network from "./assets/img/l-m/Network.svg";
import Plan from "./assets/img/l-m/plan.svg";
import POP from "./assets/img/l-m/POP.svg";
import UP from "./assets/img/l-m/Upgrade_Downgrade.svg";
import Wallet from "./assets/img/l-m/wallet.svg";
import Lvl1 from "./assets/img/lvl1.png";
import Lvl2 from "./assets/img/lvl2.png";
import Lvl3 from "./assets/img/lvl3.png";

class More extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Learn More - If You TRUST Me I'll TRUST You";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header2 />
        <div className="container-main">
          <div className="uk-margin-left uk-margin-right">
            <h2 className="uk-h4 uk-text-center theme-color uk-margin-top">
              Learn More about the Entrepreneurs Trust Fund
            </h2>
          </div>
          <div
            id="registration"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Reg} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Registration
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                Registration is the first step in becoming a member. Filling the
                bio-data form is easy and seamless as seen on the{" "}
                <i>
                  <a href="/register" className="theme-color">
                    Registration Page
                  </a>
                </i>
                <br />
                <br />
                All you need to do is to fill the registration form with your
                basic details such as your Full Name (First Name and Last Name),
                valid email address, and your desired account password to create
                your account.
                <br />
                <br />
                <i>
                  - Once you create your account, you are expected to select
                  your preferred choice of Trust Plan/ Membership.
                </i>
              </div>
            </div>
          </div>
          <div
            id="trustplns"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Plan} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Plans
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  Please note that you need to have created an account from the{" "}
                  <i>
                    <a href="/register" className="theme-color">
                      Registration Page
                    </a>
                  </i>{" "}
                  before you can proceed with this step where you select a
                  choice of Trust Plan/ Membership.
                  <br />
                  <br /> There are four (4) awesome Trust Membership categories
                  designed to accommodate entrepreneurs at different levels. We
                  have the;
                  {/* <br/><b>Ordinary Trust Membership</b> */}
                  <br />
                  <b>Flexi Trust Membership</b>
                  <br />
                  <b>Silver Trust Membership</b>
                  <br />
                  <b>Gold Trust Membership</b>
                  <br />
                  <b>Platinum Trust Membership</b>
                  <br />
                  Click to read more about them:{" "}
                  <i>
                    <a href="/viewplans" className="theme-color">
                      See Plans
                    </a>
                  </i>
                  <br />
                  <br />
                  <i>
                    - To complete this step, please note that you will be
                    required to provide and input your bank card details after
                    selecting your preferred plan.
                    <br />
                    <br />
                    Use your bank card to make payment for your weekly
                    contributions (Savings) on the platform. Your contribution
                    will automatically be deducted from your bank card.
                    <br />
                    Your contribution will automatically be deducted from your
                    card every seven (7) days.
                    <br />
                    <br />
                    Your contributions can also be paid via bank transfer if
                    your bank card is not functional or if it is unacceptable by
                    the payment gateway. It is however, advisable to initially
                    try all your bank cards as a form of payment before opting
                    for the bank transfer option because the card payment is
                    faster, more reliable and takes you directly to your
                    dashboard in real time, offering you a more seamless way to
                    save.
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div
            id="tfp"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Reg} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">TFP</h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                TFP Stands for Trust Fund Pin, it is a unique identity number
                given to all members on the platform. The TFP is used to process
                loans on the platform and other financially related benefits.
                When a loan is requested on the platform, the E-TrustFund system
                automatically ask you to provide the TFP of other Trust members
                within your network. Once provided, a notification will be sent
                to your Trust Network to ask if they Trust you or not. The TFP
                is usually located at the top page of your dashboard.
              </div>
            </div>
          </div>
          <div
            id="contributions"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Contributions} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Weekly Contributions
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  As a member of the E-TrustFund platform, you are encouraged to
                  imbibe and build a savings culture. To access the full
                  benefits of being a member, you are expected to make your
                  weekly contributions which you can monitor from your
                  dashboard.
                  <br />
                  <br /> Your weekly contributions will be automatically debited
                  from the bank card you registered on the platform. You can
                  also make upfront contributions by going on your dashboard.
                  The transaction records are found in the contribution section
                  on your dashboard and can be referred to at anytime.
                  <br />
                  <br />
                  <i>
                    - In cases of failed contributions, the system will also
                    notify you to fund your associated bank card. The process
                    will be retried again after a while.
                  </i>
                  <br />
                  <br />
                  <i>
                    - If contributions fail after four (4) consecutive weeks,
                    your account will be temporarily locked. You will be
                    required to send an email to support@etrustfund.org
                    requesting for the account to be unlocked. Once unlocked,
                    proceed to make your contributions up to date by clearing
                    the backlogs, this is to ensure that members have a good
                    savings discipline.
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div
            id="earnings"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Earnings} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Earnings
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  We believe that in today’s society TRUST is a scarce and very
                  valuable asset so we reward our members for being Trustworthy.
                  When you build your trust network by encouraging family and
                  friends to be part of the Trust Community, you are appreciated
                  and rewarded with TRUST EARNINGS which is credited to your
                  E-TrustFund account and seen on your dashboard.
                  <br />
                  <br />
                  This can only be redeemed once your earning is up to N5,000 at
                  any given time. There is no limit to what you can earn as you
                  can earn as much as 500,000 or even more depending on your
                  Trust Network.
                  <br />
                  <br />
                  If you sign up a new member, there is a section after his/ her
                  membership payment that will ask “Who is your Trust Partner”
                  endeavor to have the new member put your TFP in this section.
                  This is the only way your earning will automatically reflect
                  on your dashboard (If you skip the registration process where
                  you input your TFP that identifies you as the Trust Partner,
                  you miss out on the trust earning and once skipped cannot be
                  retracted).
                  <br />
                  <br />
                  <b>There are three levels of earning as seen below;</b>
                  <br />
                  <b>- First Level Earning:</b>
                  This is the financial reward you get for directly registering
                  a new member who inputs your TFP as their Trust Partner during
                  the registration process. The reward is immediately credited
                  to your E-TrustFund account and displayed on your dashboard.
                  The first level earning is seen below;
                  <br />
                  <br />
                  Platinum: N1,500
                  <br />
                  Gold: 750
                  <br />
                  Silver: N150
                  <br />
                  Flexi: This depends on the Flexi Member contribution
                  <br />
                  <br />
                  <div className="uk-text-center">
                    <img src={Lvl1} alt="Earnings" />
                  </div>
                  <br />
                  <br />
                  <b>- Second Level Earning:</b> This is the financial reward
                  you get when the new member who inputs your TFP as their Trust
                  Partner during the registration process registers a new
                  member. The reward is immediately credited to your E-TrustFund
                  account and displayed on your dashboard. The second level
                  earning is seen below;
                  <br />
                  <br />
                  Platinum: N600
                  <br />
                  Gold: N300
                  <br />
                  Silver: N60
                  <br />
                  Flexi: This depends on the Flexi Member contribution
                  <br />
                  <br />
                  <div className="uk-text-center">
                    <img src={Lvl2} alt="Earnings" />
                  </div>
                  <br />
                  <br />
                  <b>- Third Level Earning:</b> This is the financial reward you
                  get when the new member who inputs the TFP of the new member
                  who inputs your TFP as their Trust Partner during the
                  registration process registers a new member. The reward is
                  immediately credited to your E-TrustFund account and displayed
                  on your dashboard. The third level earning is seen below;
                  <br />
                  <br />
                  Platinum: N200
                  <br />
                  Gold: N100
                  <br />
                  Silver: N20
                  <br />
                  Flexi: This depends on the Flexi Member contribution
                  <br />
                  <br />
                  <div className="uk-text-center">
                    <img src={Lvl3} alt="Earnings" />
                  </div>
                  <br />
                  <br />
                  The higher the category of the new member the higher your
                  earnings, for example if you sign up a Platinum member you
                  earn more than when you sign up a Gold member. Same applies if
                  you sign up a Gold member, the earning is more than signing up
                  a Silver member.
                </p>
              </div>
            </div>
          </div>
          <div
            id="bonus"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Earnings} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Bonus
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  The Trust Bonus is a form of interest given to members who
                  save consecutively without default for 12 Calendar Month or a
                  complete Trust Cycle. The interest rates vary depending on
                  your membership Plan. See below;
                  <br />
                  Silver membership trust bonus: 0.5%
                  <br />
                  Gold membership trust bonus: 1%
                  <br />
                  Platinum membership trust bonus: 1.5%
                  <br />
                  Flexi Membership trust bonus: Between 0.5% to 1.5%
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div
            id="loans"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Loan} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">Loans</h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  In other to ensure entrepreneurs have access to funds with no
                  interest, collateral, paperwork or credit check, the
                  ENTREPRENEURS TRUST FUND offers Qualified Members i.e. members
                  who have successfully contributed for 12 weeks (This
                  contribution could be done in 3 months or in one day] the
                  opportunity to apply for a loan. When you need a loan on the
                  platform, make sure you are a Qualified Member.
                  <br />
                  <br />
                  Members can request for a loan not exceeding the amount
                  available for each Trust Plan/ Membership Category. For
                  example, a Platinum member can access a maximum of 10million
                  Naira loan, a Gold member can access a maximum of 5million
                  Naira loan while a Silver member can access a maximum of
                  1million Naira loan.
                  <br />
                  <br />
                  To process a loan, get the TFP of other Qualified Trust
                  members who are part of your Trust Network ready as you will
                  need them to Trust you when applying for your loan, hence the
                  importance of building your Trust Network among your family
                  and friends. You can also build your network by attending
                  Trust Network Meetings where you can meet and interact with
                  other Trust members on the platform.
                  <br />
                  <br />
                  Members are required to provide the TFP of Trusters who can
                  TRUST you when applying for your loan. The TRUSTERS must have
                  been previously added from your Trust Network to make them
                  available for selection.
                  <br />
                  <br />
                  Once you have people to Trust you, go to the dash board and
                  click on “Get a Loan”. Input the loan amount while the system
                  calculates the number of TFPs needed to Trust you for the Loan
                  application. The higher the loan amount, the higher the number
                  of TFPs needed.
                  <br />
                  <br />
                  You will not be able to submit an application for a loan until
                  the number of required TRUSTERS TFPs are provided. If you are
                  unable to complete the application, you can save the loan
                  application and continue later when you have the required
                  number of TFPs to complete the loan application.
                  <br />
                  <br />
                  You can input the TFPs of more TRUSTERS than the TFPs required
                  for a loan, to hasten your loan application process. Once the
                  required number of TRUSTERS respond that they TRUST you, the
                  loan process will be fulfilled for final verification.
                  <br />
                  <br />
                  Also note that after the loan application has been submitted,
                  the system will automatically restrict all TRUSTERS from other
                  activities on the platform for forty-eight (48) hours while
                  awaiting their responses. Once the 48hrs mark is reached and
                  the loan process is not completed, all TRUSTERS will no longer
                  be restricted and members will have to re-apply. The member
                  can re-apply for the loan with the same set of TRUSTERS by
                  checking on the Loan Section of the Transaction page.
                  <br />
                  <br />
                  Once the loan request is submitted, all TRUSTERS whose TFP
                  were provided will receive emails and notifications requesting
                  them to TRUST or DECLINE while stating the loan request. If
                  all your TRUSTERS click on TRUST, the loan will be approved
                  within 59 seconds and the loan amount will be displayed on
                  your dashboard for your withdrawal.
                  <br />
                  <br />
                  Go to your dashboard and click on “withdraw” as the loan will
                  be in your Trust Wallet. Once you click Withdraw the money is
                  sent directly to the Bank Account you provided in your
                  profile. The disbursal may take some time depending on the
                  bank network, incorrect account details or any unforeseen
                  circumstance as related to the bank processes.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div
            id="trustnetwork"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Network} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Network
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  The Trust Fund is also a social community where you can build
                  a Network of Trust Members that really matters.
                  <br />
                  <br />
                  Your loan application and approval relies on your Trust
                  Network and their willingness to Trust your loan. It is very
                  important to always build your Trust Network by signing up new
                  members and connecting to other Trust members.
                  <br />
                  <br />
                  You can add as many Trust Members as you want to your Trust
                  Network by going to “My Trust Network” on your dashboard. Your
                  Trust Network is only made up of existing Trust Members who
                  know and Trust you on the platform.
                  <br />
                  <br />
                  If you want to invite non-members of the community, you can do
                  so from the “Build Trust Network” section on the dashboard
                  sidebar. You will be required to provide their email address
                  while the notification containing a registration link as well
                  as your TFP is sent to them.
                  <br />
                  If your link is used by a new member for registration, you are
                  rewarded with Trust Earnings for being Trustworthy.
                  <br />
                  <br />
                  <b>Remember, your Trust Network is Your Networth</b>
                </p>
              </div>
            </div>
          </div>
          <div
            id="etc"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={ETC} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Emergency Travel Cover (ETC)
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  This is available to members who have successfully contributed
                  for 24 Weeks (6 months) on the platform.
                  <br />
                  <br />
                  Here, members can request for a purchase of Bus or Flight
                  Tickets for a One-Way Trip or Round-Trip depending on their
                  membership category as seen below;
                  <br />
                  <br />
                  Silver Trust Member: Bus Tickets within the Country up to
                  N20,000
                  <br />
                  Gold Trust Member: Bus or Airline Tickets within the Country
                  up to N100,000
                  <br />
                  Platinum Trust Member: Bus or Airline Tickets within the
                  Country and other African Countries up to N200,000
                  <br />
                  <i>
                    - Please note that this does not require you to provide the
                    TFP of your Trust Network, however the repayment is
                    applicable on or before the chosen repayment date, otherwise
                    member will be blacklisted and will not be able to CASHOUT
                    the total contributions overtime.
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div
            id="pop"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={POP} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Purchase Order Power (POP)
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  This is available to members who have successfully contributed
                  for 24 Weeks (6 months) on the platform.
                  <br />
                  <br />
                  Here, members can request for the procurement of any business
                  asset depending on their membership category of up to N20,000
                  for silver members, N100,000 for Gold members and N200,000 for
                  Platinum members.
                  <br />
                  <br />
                  <i>
                    - Please note that this does not require you to provide the
                    TFP of your Trust Network, however the repayment is
                    applicable on or before the chosen repayment date, otherwise
                    member will be blacklisted and will not be able to CASHOUT
                    the total contributions overtime.
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div
            id="advisory"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Advisory} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Free Business, Medical & Legal Advisory
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                We have a dedicated team of certified professionals in business,
                medicine and law who are always available to respond to the
                queries of members at all times. The free advisory is to ensure
                that our members have the required support to keep their health
                and that of their businesses relevant and in a good state.
                Members can chat directly with them at no cost via their
                dashboard.
              </div>
            </div>
          </div>
          <div id="trustwallet" class="uk-width-1-1 uk-margin-left uk-padding">
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                <img src={Wallet} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Wallet
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                This is your Trust Fund Wallet where all your funds are
                domiciled. The wallet includes the following: <br />
                <br />
                <b>Trust Earnings</b> This displays your CURRENT earnings and
                your TOTAL REDEEMED earnings
                <br />
                <br />
                <b>Loans</b> This shows your CURRENT loan balance (if you have
                accessed any). If you haven’t, it will have no value.
                <br />
                <br />
                <b>Purchase Order Power</b> This displays your CURRENT P.O.P
                balance (if you have accessed any).
                <br />
                <br />
                <b>Emergency Travel Cover</b> This displays your CURRENT E.T.C
                balance (if you have accessed any).
                <br />
              </div>
            </div>
          </div>
          <div
            id="plandownup"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={UP} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Plan Downgrade/Upgrade
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  A member is at liberty to move between plans if and only if
                  your account status is not <b>"Loan Borrower"</b> or{" "}
                  <b>"TFP In Use"</b>
                  <br /> Terms and Conditions apply.
                  <br />
                  <br />
                  <i>
                    - <b>Plan Upgrade:</b> You can decide to upgrade from your
                    current plan to the next available plan and to be able to do
                    this, you will be required to
                    <ol>
                      <li>
                        Pay the remaining balance for membership fee of new
                        plan, and
                      </li>
                      <li>
                        Top-up your total contributions to with the extras that
                        makes up that of the new plan.
                      </li>
                    </ol>
                  </i>
                  <br />
                  <i>
                    - <b>Plan Downgrade:</b> You can decide to downgrade from
                    your current plan to the next available plan without any
                    extra cost.
                  </i>
                  <br />
                  <i>
                    Once either of these is done, a receipt and email is sent to
                    confirm changes and all subsequent transactions will be in
                    respect of new plan.
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div id="transactions" class="uk-width-1-1 uk-margin-left uk-padding">
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                {/*<img src={Cashout} alt="Reg"/>*/}
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Transactions
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                This is where all the transactions you have done on the platform
                is recorded, from your weekly contributions, earnings, loan to
                every other thing.
              </div>
            </div>
          </div>
          <div
            id="notifications"
            class="uk-width-1-1 uk-margin-left uk-padding"
          >
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                {/*<img src={Cashout} alt="Reg"/>*/}
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Notifications
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match">
              <div class="full-width  uk-background-default">
                You will find all your messages and notifications here, remember
                to check it regularly.
                <br />
              </div>
            </div>
          </div>
          <div
            id="membershiplevel"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                {/*<img src={Cashout} alt="Reg"/>*/}
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Levels of Membership
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  There are 3 levels a member can attain on the platform;
                  <br />
                  <ul>
                    <li>
                      Every member starts as an <b>Ordinary Member</b>
                    </li>
                    <li>
                      The member becomes a <b>Qualified Member</b> after 12
                      weeks’ contribution
                    </li>
                    <li>
                      The Qualified Member becomes a <b>Bonafide Member</b>{" "}
                      after being on the platform for 6 Months’ and consistently
                      makes the weekly contribution
                    </li>
                    <li>
                      The <b>Matured Member</b> is a member who has been on the
                      platform for a minimum of 11 months.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          {/* <div id="cashout" class="uk-width-1-1 uk-margin-left uk-margin-top" data-uk-grid>
                <div className="uk-grid-item-match" data-uk-grid>
                    <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                      <img src={Cashout} alt="Reg"/>
                      <h4 className="uk-text-bold uk-margin-remove-top">Cashout</h4>
                    </div>
                </div>
                <div className="uk-grid-item-match" data-uk-grid>
                    <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                    <p className="uk-text-left">This is the window usually in December when Trust Members are allowed to Cash-Out their savings.<br/><br/>
                      You can only Cash-Out your total contributions (savings) during the Cash-Out period. Once the Cash-Out window is open, members are notified and expected to request for a Cash-Out that initiates the payment process from the TrustFund account to their Bank account.<br/><br/>
                      To ensure that you Cash-Out during this window, it is expected that the profile on the TrustFund platform is duly updated with your accurate bank account details upon becoming a Trust Member.<br/><br/>
                      Whoever does not request Cash-Out from their TrustFund dashboard would not have their contributions remitted into the Bank account provided in their profile and the funds contributed will be rolled over into the next Trust-Cycle.<br/><br/>
                      Cash out window icon on your dashboard will be active from December 1st to 7th of every Trust cycle.
                      <br/><br/>
                      Cash-out disbursement commences in batches from 14th to 31st December of every Trust Cycle  (extended cases may apply due to poor bank networks or reversed transactions)
                      <br/><br/>
                      Remember, the Cash-Out window is only in December so once missed, Cash-Out will be at the next window which will be the December of the following year.
                      <br/><br/>
                      <i>- Please, note that defaulting members will not be eligible for Cash-Out until default is cleared. (This refers to members (Borrowers & Trusters with Outstanding Loan, P.O.P, E.T.C)</i>
                      </p>
                    </div>
                </div>
            </div> */}

          <div
            id="cashout"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <img src={Cashout} alt="Reg" />
                <h4 className="uk-text-bold uk-margin-remove-top">Cashout</h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  This is the window during a Trust Cycle when Trust Members are
                  allowed to withdraw (Cash-Out) all their savings.
                  <br />
                  <br />
                  You can only Cash-Out your total contributions (savings) less
                  the Cooperative dues during your Cash-Out period which is at
                  the end of the 12 calendar months. This applies only to STM,
                  GTM and PTM plan. The Flexi plan is different because you have
                  the option to choose your cash-out window, and your savings
                  plan which ranges from 3 months to 24 months.
                  <br />
                  <br />
                  Once the Cash-Out window for your account is open (usually for
                  about 7 days), you are notified and expected to request for
                  your Cash-Out that initiates the payment process from your
                  E-TrustFund account to your Bank account.
                  <br />
                  <br />
                  To ensure that you Cash-Out during this window, it is expected
                  that you update your profile on the E-TrustFund platform with
                  your accurate bank account details upon becoming a Trust
                  Member. If your account details is not provided in your
                  profile, the platform will automatically roll over your
                  savings to the next Trust Cycle
                  <br />
                  <br />
                  If you do not request Cash-Out from your E-TrustFund dashboard
                  within the 7 days window, you will not have your contributions
                  (savings) remitted into the Bank account provided in your
                  profile and your savings will be rolled over into the next
                  Trust Cycle.
                  <br />
                  <br />
                  Cash-Out window icon on your dashboard will be active on the
                  last week of your Trust Cycle. Please, note that defaulting
                  members will not be eligible for Cash-Out until default is
                  cleared. (Defaulters are members (Borrowers & Trusters with
                  Outstanding Loan, P.O.P, E.T.C).
                </p>

                <br />
                <br />

                Cash-out disbursement is usually done in <strong>batches</strong>, but extended cases may apply due to the following;
              <p>
                <b>Incorrect Bank Account Detail:</b> When a member updates an incorrect bank account detail, the transaction would bounce back on the system, however upon reversal and settlement by the bank, the member will be asked for the “Correct Bank Account Detail”. 
                The transaction will then be Re-processed and payable into the new account provided by the member within 7 working days.
              </p>
              <p>
                <b>No Bank Account Updated in Profile:</b> If a member fails to provide their bank account detail in their profile, the system will be unable to recognise the account and the payment would be declined. If this may be the case you might be required to update your profile and apply during the next cash-out window. 
              </p>
              <p>
                <b>Poor Bank Network:</b>  When the bank experiences poor network or a down-time, it impedes banking transactions across electronic banking platforms. In this case, payments may lag however, be rest assured that it will be re-processed within 24hrs once the network is stable.
              </p>

              <p>
                <b>Reversed Payment Transactions:</b> Members are advised to wait for the bank to reverse the funds whenever payment fails or lags. This is common with online banking and electronic fund transfers. Please note that this process may take up to 21 working days which is subject to when the bank reverses the transaction. Be rest assured that the transaction will be reprocessed immediately the reversal is done.
              </p>
              </div>
            </div>
          </div>

          <div
            id="tinvest"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Invest
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  Members now have the opportunity to enjoy a safe and secured
                  investment either with their savings at the end of the Trust
                  Cycle or through direct deposit of funds where they have the
                  opportunity to earn as much as 35% Return on Investment.
                  <br />
                  <br />
                  Trust Invest gives you the opportunity to access different
                  zero risk investment with Interest. The investment
                  opportunities as agreed are carried out by the cooperative on
                  behalf of the members, the cooperative in this case guarantees
                  and insures the Trust investment.
                  <br />
                  <br />
                  Trust members who invest their Funds will be issued a Trust
                  Investment Certificate (TIC) and Investment Letter which
                  serves as your evidence of investment in the Entrepreneurs
                  Trust Fund “Trust Invest” stating the amount invested,
                  interest rate and maturity date.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div
            id="ttermination"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Trust Invest Termination
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  The investment made in the Trust Invest upon maturity will be
                  paid into the bank account you provide as stated in the
                  Investment Letter. In the event of termination before the
                  investment maturity date, only the principal amount{" "}
                  <span style={{ color: "#e99e24" }}>
                    will be deposited into your bank account 30 days from
                    termination date. Termination of Trust Invest implies that
                    you are no longer interested in the investment and have
                    agreed to forfeit the interest as stipulated in your
                    Investment Letter.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            id="termination"
            class="uk-width-1-1 uk-margin-left uk-margin-top"
            data-uk-grid
          >
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <h4 className="uk-text-bold uk-margin-remove-top">
                  Account Termination
                </h4>
              </div>
            </div>
            <div className="uk-grid-item-match" data-uk-grid>
              <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                <p className="uk-text-left">
                  Trust Members can terminate their Entrepreneurs Trust Fund
                  account at any time during the Trust Cycle, however the total
                  contribution will only be remitted during the cash out period
                  into the bank account provided in the profile. <br />
                  <br />
                  To ensure that you Cash-Out during this window, it is expected
                  that your profile on the E-TrustFund platform is duly updated
                  with your accurate bank account details upon becoming a Trust
                  Member or before Cash-Out.
                  <br />
                  <br />
                  <span style={{ color: "#e99e24" }}>
                    Please, note that defaulters will not be eligible for
                    Cash-Out until default is cleared. (This refers to members
                    (Borrowers & Trusters with Outstanding Loan, P.O.P, E.T.C).
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default More;
