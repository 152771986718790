import React, { Component } from 'react';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { limitCharacter } from './common/helpers/Utils';
import Validation from './common/helpers/Validation';
import RenderField from './authorization/RenderField';
import DynaLogo from './assets/img/aye-loader.gif';
import { actionUpdateNotificationCount } from './common/redux/actions/';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isActive2: false,
      checked: true,
      loanid: '',
      uid: '',
      trustresponse: '',
      loader: false,
      notificationList: [],
      fromURL: false
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    document.title = "Notifications - If You TRUST Me I'll TRUST You";
    const { location } = this.props;
    if (location) {
      let data = location.state;
      if (data && data.trustresponse == 0) {
        this.declineLoanRequest(data.loanid, data.uid, data.trustresponse);
      } else {
        if (data) {
          this.toggleModal();
          let loanid = data.loanid;
          let uid = data.uid;
          let trustresponse = data.trustresponse;
          let fromURL = true;
          this.setState({
            loanid,
            uid,
            trustresponse,
            fromURL
          });
        }
      }
    }
    this.getNotificationList();
  }

  toggleModal = (byId, toId, eventId, key, event) => {
    if (event && event.keyCode === 27) {
    } else {
      this.setState({
        isActive: !this.state.isActive,
        loanid: eventId,
        uid: toId,
        touserid: toId,
        key: key
      });
    }
  };

  toggleModal2 = event => {
    if (event && event.keyCode === 27) {
    } else {
      this.setState({
        isActive2: !this.state.isActive2
      });
    }
  };

  submitLoanConsent = () => {
    this.getTrustPartnerResponseOnLoan();
  };

  declineLoanRequest = (loanid, uid, trustresponse) => {
    let _this = this;

    if (loanid && uid) {
      let data = {
        trustresponse,
        loanid,
        uid,
        termschecked: 0
      };

      trustFundApiService('getTrustPartnerResponseOnLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.toggleModal2();
            _this.setState({
              loanid: '',
              uid: '',
              trustresponse: ''
            });
            this.props.history.push('/notifications');
          } else {
            _this.setState({
              loanid: '',
              uid: '',
              trustresponse: ''
            });
            this.props.history.push('/notifications');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  getTrustPartnerResponseOnLoan = () => {
    let loanid = parseInt(this.state.loanid);
    let uid = parseInt(this.state.uid, 10);
    let trustresponse = 1;
    let termschecked = 1;

    let data = {
      loanid,
      uid,
      trustresponse,
      termschecked
    };

    let _this = this;
    if (loanid && uid) {
      this.setState({ loader: true });
      trustFundApiService('getTrustPartnerResponseOnLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            _this.props.history.push('/notifications');
            _this.setState({
              isActive: !this.state.isActive,
              loader: false,
              fromURL: false
            });
          } else {
            _this.props.history.push('/notifications');
            _this.setState({
              isActive: !this.state.isActive,
              loader: false,
              fromURL: false
            });
          }
        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err);
        });
    } else {
      _this.setState({
        isActive: !this.state.isActive
      });
    }
  };

  getNotificationList = () => {
    let _this = this;
    trustFundApiService('getNotifications')
      .then(response => {
        if (response.data.status === 'success') {
          _this.setState({
            notificationList: response.data.data
          });
        }
      })
      .catch(err => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  acceptRejectTrustMember = (trustrequest, byuserid, touserid) => {
    this.setState({ loader: true });

    let data = {
      trustrequest,
      byuserid,
      touserid
    };
    trustFundApiService('acceptRejectTrustMember', data)
      .then(response => {
        if (response.data.status === 'success') {
          this.props.history.push('/trustmembers');
          this.setState({ loader: false });
          this.getTFPMembers();
        } else {
          // this.props.history.push('/trustmembers');
          this.setState({ loader: false });
        }
      })
      .catch(err => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  handleSubmit = () => {
    if (
      this.state.key === 'aye_noty_loan_request_to_trusters' ||
      this.state.fromURL === true
    ) {
      this.getTrustPartnerResponseOnLoan();
    } else {
    }
  };

  readNotification = notificationId => {
    if (notificationId) {
      let data = {
        notification_id: notificationId
      };
      trustFundApiService('readNotification', data)
        .then(response => {
          if (response.data.status === 'success') {
            let notification_count = response.data.data.notification_count;
            this.props.actionUpdateNotificationCount({ notification_count });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    const { notificationList } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
                shouldCloseOnOverlayClick={false}
              >
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <h3 className="uk-text-lead theme-color uk-margin-top">
                      Trust Advisory Agreement
                    </h3>
                    <ol className="uk-text-left">
                      <li>
                        <p>
                          I hereby confirm my TRUST in favour of the borrowing
                          trust member
                        </p>
                      </li>
                      <li>
                        <p>
                          I certify that I have full knowledge of the borrowing
                          trust member
                          <br /> and I voluntarily and in good faith give my
                          TRUST for the benefit
                          <br /> of the borrowing trust member.
                        </p>
                      </li>
                      <li>
                        <p>
                          I unconditionally agree that in the event that the
                          borrowing trust
                          <br /> member fails to fully pay back the loan within
                          the repayment date,
                          <br /> my account will be locked and be rendered
                          inaccessible.
                        </p>
                      </li>
                      <li>
                        <p>
                          I reaffirm that I understand and agree to the full
                          operation of the
                          <br /> Terms and Conditions governing the E-TrustFund.
                        </p>
                      </li>
                      <li>
                        <p>
                          I hereby agree to fully abide by the terms
                          aforestated.
                        </p>
                      </li>
                    </ol>
                    <p className="theme-color">
                      <Field
                        name="tc"
                        id="terms"
                        component={RenderField}
                        type="checkbox"
                        className="uk-checkbox"
                        validate={[Validation.termsRequired]}
                      />{' '}
                      Please check to Agree
                    </p>
                    <div className="uk-margin-bottom">
                      <button className="uk-button dash-button" type="submit">
                        <span uk-icon="icon: check" /> Submit
                      </button>
                      <br />
                      <br />
                      <a
                        onClick={this.toggleModal}
                        className="uk-position-relative link uk-position-z-index"
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </form>
              </Modal>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive2}
                onRequestClose={this.toggleModal2}
                shouldCloseOnOverlayClick={false}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color uk-margin-top">
                    Declined
                  </h3>
                  <p>Thank you, Your Response has been recieved.</p>
                  <a
                    onClick={this.toggleModal2}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Close
                  </a>
                </div>
              </Modal>
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  <div className="uk-width-2-1 uk-text-center">
                    <h2 className="uk-text-large-theme uk-text-center theme-color uk-margin-remove-bottom">
                      Notifications
                      <br />
                    </h2>
                    <table className="uk-table uk-table-justify uk-table-divider">
                      <thead>
                        <tr>
                          <th className="theme-color">Date</th>
                          <th className="uk-width-small uk-vis@m theme-color">
                            Subject
                          </th>
                          <th className="theme-color">Content</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationList && notificationList.length > 0
                          ? notificationList.map((data, index) => (
                              <tr className="notifications-text">
                                <td className="uk-align-left">
                                  {data.date || ''}
                                </td>
                                <td className="uk-vis@m uk-text-left">
                                  {data.subject || ''}
                                </td>
                                <td>
                                  <ul data-uk-accordion>
                                    <li>
                                      <a
                                        class={`uk-accordion-title uk-text-left ${
                                          data.notification_status == '0'
                                            ? 'theme-color'
                                            : 'text-grey'
                                        }`}
                                        onClick={this.readNotification.bind(
                                          this,
                                          data.notification_id
                                        )}
                                      >
                                        {data && data.message
                                          ? limitCharacter(data.message, 40)
                                          : ''}
                                      </a>
                                      <div class="uk-accordion-content uk-text-left">
                                        <p>{data.message}</p>
                                        {data.key ===
                                        'aye_noty_loan_request_to_trusters' ? (
                                          <React.Fragment>
                                            <a
                                              className="uk-button uk-button-small dash-button"
                                              onClick={this.toggleModal.bind(
                                                this,
                                                data.byuserid,
                                                data.touserid,
                                                data.eventid,
                                                data.key
                                              )}
                                            >
                                              {' '}
                                              <span uk-icon="icon: check" />{' '}
                                              TRUST{' '}
                                            </a>
                                            &nbsp;&nbsp;
                                            <a
                                              className="uk-button uk-button-small dash-button3"
                                              onClick={this.declineLoanRequest.bind(
                                                this,
                                                data.eventid,
                                                data.touserid,
                                                0
                                              )}
                                            >
                                              {' '}
                                              <span uk-icon="icon: close" />{' '}
                                              DECLINE{' '}
                                            </a>
                                          </React.Fragment>
                                        ) : null}

                                        {data.key ===
                                        'aye_noty_trust_request' ? (
                                          <React.Fragment>
                                            <a
                                              className="uk-button uk-button-small dash-button"
                                              onClick={this.acceptRejectTrustMember.bind(
                                                this,
                                                '1',
                                                data.byuserid,
                                                data.touserid
                                              )}
                                            >
                                              {' '}
                                              <span uk-icon="icon: check" />{' '}
                                              ACCEPT{' '}
                                            </a>
                                            &nbsp;&nbsp;
                                            <a
                                              className="uk-button uk-button-small dash-button3"
                                              onClick={this.acceptRejectTrustMember.bind(
                                                this,
                                                '0',
                                                data.byuserid,
                                                data.touserid
                                              )}
                                            >
                                              {' '}
                                              <span uk-icon="icon: close" />{' '}
                                              REJECT{' '}
                                            </a>
                                          </React.Fragment>
                                        ) : null}
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Notifications = reduxForm({
  form: 'loginForm',
  touchOnBlur: false,
  destroyOnUnmount: true
})(Notifications);

export default connect(null, { actionUpdateNotificationCount })(Notifications);
