import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

class CountryCode extends Component {
  state = {
    phone: ''
  };
  state = {
    selectedCode: null
  };

  componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        selectedCode: this.props.input.value
      });
    }
  }

  handleChange = phone => {
    this.props.input.onChange(phone);
  };

  render() {
    const {
      autoComplete,
      maxLength,
      placeholder,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <PhoneInput
          displayInitialValueAsLocalNumber={true}
          placeholder={placeholder}
          maxLength={maxLength}
          autoComplete={autoComplete}
          value={this.state.selectedCode ? `+${this.state.selectedCode}` : ''}
          onChange={phone => this.handleChange(phone)}
        />
        {touched && error && <span className="">{error}</span>}
      </div>
    );
  }
}

export default CountryCode;
