import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';
import trustFundApiService from './common/api/ApiService';
import Footer from './common/Footer';
import Header2 from './common/Header2';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "Privacy Policy - If You TRUST Me I'll TRUST You";
  }

  render() {
    return (
      <div>
          <Header2 />

          <div className="container-main">
            <div className="uk-margin-left uk-margin-right">
              <h2 className="uk-h4 uk-text-center theme-color uk-margin-top">
                Entrepreneurs TrustFund Privacy Policy
              </h2>
              <p>
                This Privacy Policy describes your privacy rights regarding how
                and when we collect, use, store, share and protect your
                information across our website, APIs, applications, email
                notifications and tools regardless of how you use or access
                them.
              </p>

              <p>
                When this policy mentions “we,” “us,” or “our,” it refers to the
                controller of your information under this policy.{' '}
                {/*If you live in Nigeria, the data controller responsible for your information is Flutterwave Technology Solutions Limited with its registered office at, 19, Olubunmi Rotimi Street, Lekki Phase One, Lagos, Nigeria and if you live outside Nigeria, the data controller responsible for your information is Flutterwave Inc. with its registered office at 1323 Columbus Avenue, San Francisco, CA 94133, USA.*/}
              </p>

              <p>
                Despite this, you alone control and are responsible for any and
                every information provided to us through the use of our services
                as provided in the <a href="/terms">Terms of Service</a>.
              </p>

              <p>
                Notwithstanding which country you live in, when using any of our
                services, you consent to the collection, transfer, storage,
                disclosure, and use of your information in Nigeria and any other
                country we operate as described in this privacy policy. This
                includes any information you choose to provide that is deemed
                sensitive under applicable law.
              </p>

              <p>
                We respect the privacy of our online visitors and registered
                users and we will take reasonable steps to protect your
                information.
              </p>
              <p>
                It is our policy to respect your privacy regarding any
                information we may collect while operating our website.
              </p>

              <p>
                Accordingly, we have developed this privacy policy in order for
                you to understand how we collect, use, communicate, disclose and
                otherwise make use of personal information. We have outlined our
                privacy policy below.
              </p>

              <h2>What We Do to Information Collected</h2>

              <p>
                <strong>Personal Information</strong>
              </p>
              <p>
                Upon registration, we collect personal information which you
                voluntarily provided by lawful means. We collect and use
                personal information solely for fulfilling the purposes
                specified by us and for other ancillary purposes.
              </p>

              <p>
                This refers to the personal information you submit when you
                register (e.g. email address, name, password, address, bank
                account number, next of kin details etc.) it may also include
                anonymous information that is linked to you, for example, your
                IP Address. It should be relevant to the purposes for which it
                is to be used, and, to the extent necessary for those purposes,
                should be accurate, complete, and up-to-date.
              </p>

              <p>
                The following lists what we use your personal information for.
                These may be updated from time to time.
              </p>

              <p>
                i. To provide you with access to our service.
                <br />
                ii. To verify the information provided.
                <br />
                iii. To prevent, detect and manage any illegal and fraudulent
                activity.
                <br />
                iv. To improve our services to you.
                <br />
                v. To address any inappropriate use of our services.
                <br />
                vi. To update our website layout, database and content.
                <br />
                vii. To target advertisements, newsletter and service updates
              </p>
              <p>viii. Resolve disputes that may occur.</p>

              <p>
                Additional personal information may be gotten from third party
                applications and other identification/verification services. For
                example, from your financial institution.
              </p>

              <p>
                Once you begin to use our services, we keep all records of your
                transactions and we will not share or disclose your personal
                information with a third party without your consent.
              </p>

              <p>
                <strong>Web Visitors</strong>
              </p>
              <p>
                We do not collect information to web visitors. We may however
                collect non-personally-identifiable-information to enable us
                target advertisements to you, monitor and improve our website
                and services. This too, will not be shared or disclosed to third
                parties without your consent.
              </p>

              <p>
                <strong>Protecting Your Information</strong>
              </p>
              <p>
                We will protect your personal information by using global
                security safeguards against loss or theft, as well as against
                any unauthorized access, risk of loss, disclosure, copying,
                misuse or modification.
              </p>

              <p>
                Other security measures include but not limited to, firewall,
                data encryption and granting access only to employees in order
                to fulfil their job responsibilities.
              </p>

              <p>
                We are committed to conducting our business in accordance with
                these principles in order to ensure that the confidentiality of
                personal information is protected and maintained.
              </p>

              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                Like many other websites, we use cookies to identify you as a
                user and to customize and improve our services. A cookie is a
                small data file that is transferred to your computer or mobile
                device. It enables us to remember your account log-in
                information, IP addresses, web traffic, number of times you
                visit, date and time of visits.
              </p>

              <p>
                Some browsers may automatically accept cookies while some can be
                modified to decline cookies or alert you when a website wants to
                place a cookie on your computer. If you do choose to disable
                cookies, it may limit your ability to use our website.
              </p>

              <p>
                <strong>Data Storage</strong>
              </p>
              <p>
                We will only retain personal information on our servers for as
                long as an account is active. Once an account has been
                deactivated, your information is stored on our servers for as
                long as necessarily obligated by law.
              </p>

              <p>
                This policy may change from time to time at our sole discretion.
              </p>

              <p>
                <i>Last updated: 30th September, 2019</i>
              </p>
            </div>
          </div>
        <Footer />
      </div>
    );
  }
}

export default Privacy;
