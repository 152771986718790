import Cryptr from 'cryptr';
import _ from 'lodash';
import CONSTANTS from '../helpers/Constants';

const cryptr = new Cryptr(CONSTANTS.CRYPTER_KEY);

// Encrypt local storage data
export const encryptLSData = data => {
  return cryptr.encrypt(data);
};

// Decrypt local storage data
export const decryptLSData = data => {
  return cryptr.decrypt(data);
};

// To set local storage data
export const setLocalStorage = (key, value) => {
  value = JSON.stringify(value);
  const encodedData = encryptLSData(value);
  localStorage.setItem(key, encodedData);
};

// To get local storage data
export const getLocalStorage = key => {
  if (key) {
    let data = localStorage.getItem(key);
    if (data) {
      data = JSON.parse(decryptLSData(data));
      return data;
    }
  }
  return null;
};

// To remove local storage key
export const removeLocalStorage = key => {
  localStorage.removeItem(key);
};

// To clear localstorage
export const clearLocalStorage = cb => {
  if (getLocalStorage('remember')) {
    var rememberme = localStorage.getItem('remember');
    localStorage.clear();
    if (cb) cb();
    localStorage.setItem('remember', rememberme);
  } else {
    localStorage.clear();
    if (cb) cb();
  }
};

export const formatNumber = num => {
  if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  else return null;
};

export const capitalizeFirstLetter = string => {
  if (string) return _.startCase(_.toLower(string));
  else return null;
};

export const clearNumber = (value = '') => {
  return value.replace(/\D+/g, '');
};

export const formatExpirationDate = value => {
  if (value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  }
};

export const base64_encode = value => {
  if (value) {
    return window.btoa(value);
  }
  return null;
};

export const base64_decode = value => {
  if (value) {
    return window.atob(value);
  }
  return null;
};

export const limitCharacter = (text, count) => {
  if (text && count) {
    return text.slice(0, count) + (text.length > count ? '...' : '');
  } else {
    return false;
  }
};
