import React from 'react';
import Routes from './routes/Routes';
import Offline from './assets/img/offline.png';
import ClearCache from 'react-clear-cache';
import * as io from 'socket.io-client';

import SocketContext from './routes/SocketContext';

const socket = io.connect('https://etrustfund.org:5000');

const InternetCheck: React.FC<{}> = ({ message, style }) => {
  // Create a variable called internetConnection and assign it the value of navigator.online,
  // you can also do it in componentDidMount by using class component
  var internetConnection = navigator.onLine;
  return (
    <div>
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => <div></div>}
      </ClearCache>
      {!internetConnection ? (
        <div>
          <p
            style={
              style || {
                backgroundColor: 'red',
                color: '#fff',
                height: '30px',
                textAlign: 'center',
                fontWeight: 'bold'
              }
            }
          >
            {message || 'You are Offline! Check Your Internet Connection'}
          </p>
          <div className="uk-text-center uk-margin-large-top">
            <img src={Offline} width={300} alt="offline" />
          </div>
        </div>
      ) : (
        <SocketContext.Provider value={socket}>
          <Routes />
        </SocketContext.Provider>
      )}
    </div>
  );
};
export default InternetCheck;
