import React, { Component } from 'react';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';

import trustFundApiService from '../common/api/ApiService';

class MarketSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <div
        id="right"
        className="uk-width-1-4@m offers uk-child-width-1-1@m uk-child-width-1-2@s"
        data-uk-grid
      >
        <div className="right-sidebar">
          <h4 className="theme-color uk-text-center trend-title">
            <a href="" className="uk-button dash-button uk-margin-bottom" uk-totop>Post a Deal</a> 
            Trending Deals
          </h4><br/><br/><br/><br/><br/>

            <div className="dtsud uk-margin-remove-top uk-text-center uk-margin-bottom">
              <div className="uk-card-body uk-padding-small">
                <a href="#"><span className="theme-color mkt-title">
                  Nigerian Beweries Oil Supply
                  <span className="mkt-pos">#1</span>
                </span></a>
              </div>
            </div>

        </div>
      </div>
    );
  }
}

export default MarketSidebar;
