import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { connect } from 'react-redux';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { formatNumber } from './common/helpers/Utils';

class WeeklyContribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastWCAmount: '',
      lastWCDate: '',
      nextWCAmount: '',
      nextWCDate: '',
      conAmount: [],
      conDate: []
    };
  }

  componentDidMount() {
    document.title = "Weekly Contributions - If You TRUST Me I'll TRUST You";
    this.getContribution();
  }

  getContribution = () => {
    trustFundApiService('getContribution')
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          let wc = data.week_contribution;
          let conAmount = [];
          let conDate = [];
          if (wc.length > 0) {
            wc.map((item, index) => {
              conDate.push(moment(item.trans_from_date).format('MM/DD/YYYY'));
              conAmount.push(item.con_amount);
            });
          }

          this.setState({
            lastWCAmount: formatNumber(data.last_contribution_amount),
            lastWCDate: data.last_contribution,
            nextWCAmount: formatNumber(data.next_contribution_amount),
            nextWCDate: data.next_contribution,
            conDate,
            conAmount
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const cur_name = this.props.userData.cur_name || '';
    const { lastWCAmount, lastWCDate, nextWCAmount, nextWCDate } = this.state;
    const data = {
      labels: this.state.conDate,
      legend: {
        display: false
      },
      datasets: [
        {
          label: 'Contributions Chart',
          fill: true,
          lineTension: 0.1,
          backgroundColor: '#ea9e2318',
          borderColor: '#ea9e23',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#ea9e23',
          pointBackgroundColor: '#ea9e23',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#ea9e23',
          pointHoverBorderColor: '#ea9e23',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.state.conAmount
        }
      ],
      options: {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              console.log(tooltipItem);
              return tooltipItem.yLabel;
            }
          }
        }
      }
    };

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-padding-remove-left uk-margin-remove-top uk-width-1-1@m uk-width-1-2@s">
                  <div className="uk-flex-center  uk-margin">
                    <h2 className="uk-text-large-theme uk-text-center theme-color uk-margin-large-bottom">
                      Contributions
                    </h2>
                    <div className="tearn uk-light uk-flex animated fadeIn uk-flex-wrap uk-padding-small uk-flex-middle uk-flex-between">
                      <div>
                        <span className="currency">{cur_name}</span>
                        <h2 className="uk-text-larger theme-color uk-margin-remove">
                          {lastWCAmount || 0}
                        </h2>
                        <p className="uk-text-small uk-margin-remove">
                          LAST:: {lastWCDate}
                        </p>
                      </div>
                      <div>
                        <span className="currency">{cur_name}</span>
                        <h2 className="uk-text-larger-green theme-color uk-margin-remove">
                          {nextWCAmount || 0}
                        </h2>
                        <p className="uk-text-small uk-margin-remove">
                          NEXT:: {nextWCDate}
                        </p>
                      </div>
                    </div>
                    <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin">
                      <h2 className="uk-text-large-theme theme-color uk-text-center">
                        Contributions Chart
                        <br />
                      </h2>
                      <br />
                      <div className="uk-padding-remove-left uk-margin-remove-top uk-width-1-1@m uk-width-1-2@s">
                        {this.state.conAmount.length > 0 &&
                        this.state.conDate.length > 0 ? (
                          <Line ref="chart" data={data} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(
  mapStateToProps,
  null
)(WeeklyContribution);
