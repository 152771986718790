import React, { Component } from 'react';

class Notification extends Component {
  render() {
    return (
      <div id="offcanvas-notification" uk-offcanvas="flip: true; overlay: true">
        <div className="uk-offcanvas-bar">
          <button className="uk-offcanvas-close" type="button" uk-close />
          <div>
            <a href="#">
              <h5>
                TFP Request
                <br />
                lorem ipsum<i>...</i>
              </h5>
              <hr />
            </a>
          </div>
          <div>
            <a className="uk-button prog-button" href="messages.html">
              <span className="uk-position-relative uk-position-z-index">
                See All
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Notification;
