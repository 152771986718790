import React from 'react';

export default ({ input, label, type, meta: { touched, error }, children }) => (
  <div className="uk-form-controls">
    <select className="uk-select" {...input}>
      {children}
    </select>
    {touched && error && <span>{error}</span>}
  </div>
);
