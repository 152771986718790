import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import '../assets/css/main.css';
import DynaLogo from '../assets/img/aye-loader.gif';
import Sidebar from './Sidebar';
import RenderField from './RenderField';
import Validation from '../common/helpers/Validation';
import trustFundApiService from '../common/api/ApiService';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    };
  }

  handleSubmit = formValues => {
    if (formValues) {
      //let _this = this;
      let email = formValues.email;
      this.setState({ loader: true });
      trustFundApiService('forgotPassword', { email })
        .then(response => {
          if (response.data.status === 'Success') {
            this.props.initialize({ email: '' });
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
            this.props.initialize({ email: '' });
          }
        })
        .catch(err => {
          this.setState({ loader: false });
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <section id="bg">
        <div
          id="register-view"
          className="uk-width-1-1@m uk-child-width-1-2@m"
          data-uk-grid
        >
          <div
            id="signin"
            className="uk-flex-middle uk-flex-center uk-flex-last@m uk-margin-remove"
            data-uk-grid
          >
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="uk-width-1-1 uk-padding-remove">
              <form
                id="loginForm"
                onSubmit={handleSubmit(values =>
                  this.handleSubmit({
                    ...values,
                    email:
                      values && values.email ? values.email.toLowerCase() : ''
                  })
                )}
              >
                <div className="uk-height-1-1 loginReg uk-padding">
                  <div
                    id="register"
                    className="uk-child-width-1-1@m uk-flex-center"
                    data-uk-grid
                  >
                    <h2 className="uk-h3 theme-color">Forgot Password</h2>
                    <div id="signup1">
                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="email"
                            id="reg-email"
                            type="text"
                            autoComplete="username"
                            validate={[
                              Validation.emailRequired,
                              Validation.isEmail
                            ]}
                            placeholder="Enter your email"
                          />

                          <label className="uk-form-label" htmlFor="reg-email">
                            Enter Email
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin uk-width-1-1@m uk-flex uk-flex-center uk-padding-remove-left">
                      <div className="padding-right subby">
                        <button
                          className="uk-button prog-button"
                          type="submit"
                          disabled={submitting}
                        >
                          <span>Send Reset Link</span>
                        </button>
                      </div>
                      <div className="padding-left uk-hidden@m uk-flex uk-flex-middle">
                        <Link className="uk-link uk-link-reset" to="/login">
                          <span className="uk-position-relative uk-position-z-index">
                            Login
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Sidebar pageName="Signup" />
        </div>
      </section>
    );
  }
}

export default reduxForm({
  form: 'forgotPasswordForm',
  touchOnBlur: false,
  destroyOnUnmount: true
})(ForgotPassword);
