import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var today = new Date();
var lastDayOfMonth = new Date(today.getFullYear(), 10, 30);

class renderDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      endDate: lastDayOfMonth
    };
  }

  componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        selectedDate: moment(
          this.props.input.value,
          this.props.inputValueFormat
        )
      });
    }
  }

  handleChange = date => {
    this.setState({
      selectedDate: date
    });

    this.props.input.onChange(date);
  };

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  render() {
    const {
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <DatePicker
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          minDate={new Date()}
          //maxDate={this.state.endDate}
          //onChangeRaw={this.handleDateChangeRaw}
          readOnly={true}
        />
        {touched && error && <span className="datepicker__error">{error}</span>}
      </div>
    );
  }
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string
    }).isRequired,
    inputValueFormat: PropTypes.string
  };

  static defaultProps = {
    inputValueFormat: null
  };
}

export default renderDatePicker;
