import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  background-color: white;
  margin-top: 4rem;
  margin-bottom: 4rem;
  @media (min-width: 1020px) {
    margin-top: -4rem;
  }
`;

export const AboutTitle = styled.h3`
  text-align: center;
  font-size: 2rem;

  @media (min-width: 1020px) {
    font-size: 3rem;
  }
`;

export const Tagline = styled.p`
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 10rem;
  display: block !important;

  @media (min-width: 1020px) {
    padding: 6rem 0;
    margin-top: -5rem;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: -8rem;
`;
