import React, { Component } from 'react';

import Header2 from './common/Header2';

import './assets/css/main.css';

class ErrorPage extends Component {
  componentDidMount() {
    document.title = "Page Not Found - If You TRUST Me I'll TRUST You";
  }

  render() {
    return (
      <div>
        <section
          id="planTop"
          className="uk-width-1-1@m error"
          data-uk-height-viewport
        >
          <Header2 />
          <div className="uk-position-center">
            <p className="uk-margin-remove uk-text-lead uk-text-center">
              <b>WE'RE SORRY, PAGE NOT FOUND</b>
            </p>
            <p className="uk-margin-remove uk-text-center">
              The page you are looking for might have been removed or <br />
              had it's name changed or is temporarily unavailable.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <a href="/" className="uk-button dash-button uk-position-center">
              GO TO HOME
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default ErrorPage;
