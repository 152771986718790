import React, { Component } from 'react';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { showErrorToast } from './common/helpers/Toastr';
import DynaLogo from './assets/img/aye-loader.gif';

let minRequiredSTM = 0;

class ConfirmLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanamount: '',
      repaydate: '',
      minRequiredSTM: 0,
      trustmembers: [],
      req_tfps: {},
      STM: 0,
      GTM: 0,
      PTM: 0,
      disabled: false,
      loanData: [],
      loader: false,
      STMSelected: 0,
      GTMSelected: 0,
      PTMSelected: 0,
      STMCheckedArray: [],
      GTMCheckedArray: [],
      PTMCheckedArray: [],
      totalSTMLength: 0
    };
  }

  componentDidMount() {
    document.title = "Confirm Loan - If You TRUST Me I'll TRUST You";
    const { location } = this.props;
    if (location) {
      let data = location.state;
      if (data) {
        this.setState({
          loanData: data,
          loanamount: data.loan_amount,
          repaydate: data.loan_repayment_date,
          req_tfps: data.req_tfps,
          STMSelected: data.STMCheckedArray.length,
          GTMSelected: data.GTMCheckedArray.length,
          PTMSelected: data.PTMCheckedArray.length,
          STMCheckedArray: data.STMCheckedArray,
          GTMCheckedArray: data.GTMCheckedArray,
          PTMCheckedArray: data.PTMCheckedArray,
          totalSTMLength: data.totalSTMLength
        });
      }
      let minRequiredSTM = this.getMinForTrusters(
        data.req_tfps.STM,
        data.req_tfps.GTM,
        data.req_tfps.PTM
      );

      this.setState({ minRequiredSTM: minRequiredSTM });
    }
  }

  // 1 - apply loan , 2 - save and apply later
  handleSubmit = applicationType => {
    this.setState({
      loader: true
    });

    let trustMembers = this.state.STMCheckedArray.concat(
      this.state.GTMCheckedArray,
      this.state.PTMCheckedArray
    );

    if (applicationType === 2) {
      let data = {
        loan_process: applicationType,
        loan_amount: this.state.loanamount,
        loan_repayment_date: this.state.repaydate,
        req_tfps: this.state.req_tfps,
        trustmembers: trustMembers
      };

      trustFundApiService('applyForLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              loader: false
            });
            setTimeout(
              function() {
                this.props.history.push('/dashboard');
              }.bind(this),
              3000
            );
          } else {
            this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (applicationType === 1) {
      if (trustMembers.length > 0) {
        if (
          parseInt(this.state.totalSTMLength) >=
          parseInt(this.state.minRequiredSTM)
        ) {
          let data = {
            loan_process: applicationType,
            loan_amount: this.state.loanamount,
            loan_repayment_date: this.state.repaydate,
            req_tfps: this.state.req_tfps,
            trustmembers: trustMembers
          };
          trustFundApiService('applyForLoan', data)
            .then(response => {
              if (response.data.status === 'success') {
                this.setState({
                  disabled: false,
                  loader: false
                });
                setTimeout(
                  function() {
                    this.props.history.push('/dashboard');
                  }.bind(this),
                  3000
                );
              } else {
                this.setState({
                  loader: false
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          // showErrorToast('You have not selected minimum trust members');
          this.setState({
            loader: false
          });
        }
      } else {
        this.setState({
          loader: false
        });
        // showErrorToast('You have not selected minimum trust members');
      }
    }
  };

  getMinForTrusters(STM, GTM, PTM) {
    if (!(STM.includes('N') || STM.includes('Max'))) {
      // STM available
      minRequiredSTM = STM;
      return minRequiredSTM;
    }

    if (!(GTM.includes('N') || GTM.includes('Max'))) {
      // STM available
      minRequiredSTM = GTM;
      return minRequiredSTM;
    }
    if (!(PTM.includes('N') || PTM.includes('Max'))) {
      // STM available
      minRequiredSTM = PTM;
      return minRequiredSTM;
    }
    return minRequiredSTM;
  }

  render() {
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              {this.state.loader ? (
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <img src={DynaLogo} alt="" />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div
                  className="uk-child-width-1-1@m data-uk-grid-small data-uk-grid-match"
                  data-uk-grid
                >
                  <div>
                    <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                      <table class="uk-table uk-margin-left">
                        <caption className="uk-text-center">
                          <div className="divider" />
                          <h1 className="uk-h1 theme-color uk-margin-remove-top">
                            <span className="text-red">Loan Amount: </span>
                            {this.state.loanamount}
                          </h1>
                          <span className="uk-margin-right uk-margin-bottom">
                            Repayment Date:
                          </span>{' '}
                          {this.state.repaydate}
                          <br />
                          <br />
                          <div
                            className="uk-grid uk-grid-match uk-margin"
                            data-uk-grid
                          >
                            <div className="uk-margin">
                              <span
                                className="req uk-text-lead theme-color"
                                id="stm"
                              >
                                STM: {this.state.STMSelected}
                              </span>
                            </div>
                            <div>
                              <span className="uk-text-lead text-red">OR</span>
                            </div>
                            <div className="uk-margin">
                              <span
                                className="req uk-text-lead theme-color"
                                id="stm"
                              >
                                GTM: {this.state.GTMSelected}
                              </span>
                            </div>
                            <div>
                              <span className="uk-text-lead text-red">OR</span>
                            </div>
                            <div className="uk-margin">
                              <span
                                className="req uk-text-lead theme-color"
                                id="stm"
                              >
                                PTM: {this.state.PTMSelected}
                              </span>
                            </div>
                          </div>
                          <br />
                          <br />
                          <a
                            className="link text-red uk-align-right uk-margin-right"
                            onClick={() =>
                              this.props.history.push({
                                pathname: '/addtrusters',
                                state: {
                                  loanData: this.state.loanData,
                                  action: 'edit'
                                }
                              })
                            }
                          >
                            {' '}
                            Edit Selected Trusters{' '}
                          </a>
                          <br />
                          <br />
                          <br />
                          <div className="uk-text-center">
                            <button
                              onClick={
                                this.state.loader === false
                                  ? this.handleSubmit.bind(this, 1)
                                  : ''
                              }
                              className="uk-button dash-button uk-margin-bottom"
                              disabled={this.state.disabled}
                            >
                              Submit Loan Application
                              {this.state.loader ? (
                                <div className="spinner-wrapper">
                                  <div className="spinner">
                                    <img src={DynaLogo} alt="" />
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </button>
                            <br />
                            <a
                              className="uk-text-lead link"
                              onClick={
                                this.state.loader === false
                                  ? this.handleSubmit.bind(this, 2)
                                  : ''
                              }
                            >
                              Save and Continue Later
                            </a>
                          </div>
                          <br />
                        </caption>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ConfirmLoan;
