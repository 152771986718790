import React, { Component } from 'react';
import _ from 'lodash';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import CONSTANTS from './common/helpers/Constants';
import { showErrorToast } from './common/helpers/Toastr';
import ReactPaginate from 'react-paginate';
import { stat } from 'fs';

var maxRequiredSTM = 0;
var _1GTM_equalsto_STM = 5;
var _1PTM_equalsto_STM = 10;

class AddTrusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanamount: '',
      repaydate: '',
      STMRequired: 0,
      GTMRequired: 0,
      PTMRequired: 0,

      totalSTMLength: 0,

      minSTM: 0,
      maxSTM: 0,

      STMMembers: [],
      GTMMembers: [],
      PTMMembers: [],

      STMArray: [],
      GTMArray: [],
      PTMArray: [],

      STM: 0,
      GTM: 0,
      PTM: 0,

      STMSelected: 0,
      GTMSelected: 0,
      PTMSelected: 0,

      STMTotalRecords: 0,
      GTMTotalRecords: 0,
      PTMTotalRecords: 0,

      STMInitialPage: 0,
      GTMInitialPage: 0,
      PTMInitialPage: 0
    };
  }

  componentDidMount() {
    document.title = "Add Trusters - If You TRUST Me I'll TRUST You";
    const { location } = this.props;
    if (location) {
      let data = location.state;

      if (data) {
        if (data.action === 'add') {
          this.getTrustMembers('add');
          this.setState({
            loanamount: data.loanData.loan_amount,
            repaydate: data.loanData.loan_repayment_date,
            STMRequired: data.loanData.STMRequired,
            GTMRequired: data.loanData.GTMRequired,
            PTMRequired: data.loanData.PTMRequired
          });
        }

        if (data.action === 'edit') {
          this.getTrustMembers('edit');
          this.setState({
            loanamount: data.loanData.loan_amount,
            repaydate: data.loanData.loan_repayment_date,
            STMRequired: data.loanData.req_tfps.STM,
            GTMRequired: data.loanData.req_tfps.GTM,
            PTMRequired: data.loanData.req_tfps.PTM,
            STMSelected: data.loanData.STMCheckedArray
              ? data.loanData.STMCheckedArray.length
              : [],
            GTMSelected: data.loanData.GTMCheckedArray
              ? data.loanData.GTMCheckedArray.length
              : [],
            PTMSelected: data.loanData.PTMCheckedArray
              ? data.loanData.PTMCheckedArray.length
              : [],
            STMCheckedArray: data.loanData.STMCheckedArray
              ? data.loanData.STMCheckedArray
              : [],
            GTMCheckedArray: data.loanData.GTMCheckedArray
              ? data.loanData.GTMCheckedArray
              : [],
            PTMCheckedArray: data.loanData.PTMCheckedArray
              ? data.loanData.PTMCheckedArray
              : [],
            maxRequiredSTM: data.loanData.maxRequiredSTM
          });
        }
      }
    }
  }

  addTrusters = () => {
    let loanData = {
      loan_amount: this.state.loanamount,
      loan_repayment_date: this.state.repaydate,
      req_tfps: {
        STM: this.state.STMRequired,
        GTM: this.state.GTMRequired,
        PTM: this.state.PTMRequired
      },
      STMCheckedArray: this.state.STMArray,
      GTMCheckedArray: this.state.GTMArray,
      PTMCheckedArray: this.state.PTMArray,
      maxRequiredSTM: this.state.maxRequiredSTM,
      totalSTMLength: this.state.totalSTMLength
    };
    this.props.history.push({
      pathname: '/confirmloan',
      state: loanData
    });
  };

  getTrustMembers = action => {
    let data = {
      offset: '',
      count: ''
    };
    let STMArray = this.state.STMArray;
    let GTMArray = this.state.GTMArray;
    let PTMArray = this.state.PTMArray;

    trustFundApiService('trustMemberByType', data)
      .then(response => {
        if (response.data.status === 'success') {
          let maxRequiredSTM = this.getMaxForTrusters();
          let data = response.data.data.t_mem_list;
          if (action === 'add' && maxRequiredSTM > 0) {
            this.setState({
              STMMembers: data.STM,
              GTMMembers: data.GTM,
              PTMMembers: data.PTM,
              maxRequiredSTM
            });
          }

          if (action === 'save' || action === 'edit') {
            if (this.state.STMCheckedArray.length > 0) {
              data.STM = data.STM.map((STM, index) => {
                let STMIndex =
                  this.state.STMCheckedArray &&
                  this.state.STMCheckedArray.findIndex(
                    STMChecked =>
                      STMChecked.id === STM.id || STMChecked.u_id === STM.id
                  );
                if (STMIndex !== -1) {
                  STMArray.push({
                    tfppin: STM.u_tfp_pin,
                    type: STM.pl_prefix,
                    id: STM.id || STM.u_id
                  });
                  STM['checked'] = true;
                }
                return STM;
              });
              this.setState({
                STMArray,
                STMMembers: data.STM
              });
            } else {
              this.setState({
                STMMembers: data.STM
              });
            }

            if (this.state.GTMCheckedArray.length > 0) {
              data.GTM = data.GTM.map((GTM, index) => {
                let GTMIndex =
                  this.state.GTMCheckedArray &&
                  this.state.GTMCheckedArray.findIndex(
                    GTMChecked =>
                      GTMChecked.id === GTM.id || GTMChecked.u_id === GTM.id
                  );
                if (GTMIndex !== -1) {
                  GTMArray.push({
                    tfppin: GTM.u_tfp_pin,
                    type: GTM.pl_prefix,
                    id: GTM.id || GTM.u_id
                  });
                  GTM['checked'] = true;
                }
                return GTM;
              });
              this.setState({
                GTMArray,
                maxRequiredSTM,
                GTMMembers: data.GTM
              });
            } else {
              this.setState({
                GTMMembers: data.GTM
              });
            }
            if (this.state.PTMCheckedArray.length > 0) {
              data.PTM = data.PTM.map((PTM, index) => {
                let PTMIndex =
                  this.state.PTMCheckedArray &&
                  this.state.PTMCheckedArray.findIndex(
                    PTMChecked =>
                      PTMChecked.id === PTM.id || PTMChecked.u_id === PTM.id
                  );
                if (PTMIndex !== -1) {
                  PTMArray.push({
                    tfppin: PTM.u_tfp_pin,
                    type: PTM.pl_prefix,
                    id: PTM.id || PTM.u_id
                  });
                  PTM['checked'] = true;
                }
                return PTM;
              });
              this.setState({
                PTMArray,
                PTMMembers: data.PTM
              });
            } else {
              this.setState({
                PTMMembers: data.PTM
              });
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getMaxForTrusters() {
    if (
      !(
        this.state.PTMRequired.includes('N') ||
        this.state.PTMRequired.includes('Max')
      )
    ) {
      // STM available
      maxRequiredSTM = this.state.PTMRequired * 10 * 2;
      return maxRequiredSTM;
    }

    if (
      !(
        this.state.GTMRequired.includes('N') ||
        this.state.GTMRequired.includes('Max')
      )
    ) {
      // GTM available
      maxRequiredSTM = this.state.GTMRequired * 5 * 2;
      return maxRequiredSTM;
    }

    if (
      !(
        this.state.STMRequired.includes('N') ||
        this.state.STMRequired.includes('Max')
      )
    ) {
      // PTM available
      maxRequiredSTM = this.state.STMRequired * 2;
      return maxRequiredSTM;
    }
    return maxRequiredSTM;
  }

  checkMaxLimitForSTMSelection() {
    let maxRequiredSTM = this.state.maxRequiredSTM;
    let stmLength = this.state.STMArray.length;
    stmLength = stmLength + this.state.GTMArray.length * _1GTM_equalsto_STM;
    stmLength = stmLength + this.state.PTMArray.length * _1PTM_equalsto_STM;

    if (stmLength > maxRequiredSTM) {
      // Reached Max Limit
      return true;
    } else {
      this.setState({
        totalSTMLength: stmLength
      });
      return false;
    }
  }

  selectTrustMembers = (TFPPin, memberType, id, rowNum, event) => {
    let index;
    let STMMembers = this.state.STMMembers;
    let GTMMembers = this.state.GTMMembers;
    let PTMMembers = this.state.PTMMembers;
    let STMArray = this.state.STMArray;
    let GTMArray = this.state.GTMArray;
    let PTMArray = this.state.PTMArray;

    console.log(PTMArray);

    if (TFPPin && memberType) {
      if (memberType === CONSTANTS.PLAN_NAME.SILVER_PREFIX) {
        if (!this.checkMaxLimitForSTMSelection()) {
          if (event.target.checked) {
            STMMembers[rowNum].checked = true;
            STMArray.push({ tfppin: TFPPin, type: memberType, id: id });
          } else {
            STMMembers[rowNum].checked = false;
            index = STMArray.findIndex(x => x.tfppin === TFPPin);
            STMArray.splice(index, 1);
          }
        } else {
          if (!event.target.checked) {
            STMMembers[rowNum].checked = false;
            index = STMArray.findIndex(x => x.tfppin === TFPPin);
            STMArray.splice(index, 1);
          }
        }

        if (this.checkMaxLimitForSTMSelection()) {
          showErrorToast('You have exceeded your selection limit');
          STMMembers[rowNum].checked = false;
          index = STMArray.findIndex(x => x.tfppin === TFPPin);
          STMArray.splice(index, 1);
        }
        this.setState({
          STMArray,
          STMMembers,
          STMSelected: STMArray.length
        });
      }

      if (memberType === CONSTANTS.PLAN_NAME.GOLD_PREFIX) {
        if (!this.checkMaxLimitForSTMSelection()) {
          if (event.target.checked) {
            GTMMembers[rowNum].checked = true;
            GTMArray.push({ tfppin: TFPPin, type: memberType, id: id });
          } else {
            GTMMembers[rowNum].checked = false;
            index = GTMArray.findIndex(x => x.tfppin === TFPPin);
            GTMArray.splice(index, 1);
          }
        } else {
          if (!event.target.checked) {
            GTMMembers[rowNum].checked = false;
            index = GTMArray.findIndex(x => x.tfppin === TFPPin);
            GTMArray.splice(index, 1);
          }
        }

        if (this.checkMaxLimitForSTMSelection()) {
          showErrorToast('You have exceeded your selection limit');
          GTMMembers[rowNum].checked = false;
          index = GTMArray.findIndex(x => x.tfppin === TFPPin);
          GTMArray.splice(index, 1);
        }

        this.setState({
          GTMArray,
          GTMMembers,
          GTMSelected: GTMArray.length
        });
      }

      if (memberType === CONSTANTS.PLAN_NAME.PLATINUM_PREFIX) {
        if (!this.checkMaxLimitForSTMSelection()) {
          if (event.target.checked) {
            PTMMembers[rowNum].checked = true;
            PTMArray.push({ tfppin: TFPPin, type: memberType, id: id });
          } else {
            PTMMembers[rowNum].checked = false;
            index = PTMArray.findIndex(x => x.tfppin === TFPPin);
            PTMArray.splice(index, 1);
          }
        } else {
          if (!event.target.checked) {
            PTMMembers[rowNum].checked = false;
            index = PTMArray.findIndex(x => x.tfppin === TFPPin);
            PTMArray.splice(index, 1);
          }
        }

        if (this.checkMaxLimitForSTMSelection()) {
          showErrorToast('You have exceeded your selection limit');
          PTMMembers[rowNum].checked = false;
          index = PTMArray.findIndex(x => x.tfppin === TFPPin);
          PTMArray.splice(index, 1);
        }

        this.setState({
          PTMArray,
          PTMMembers,
          PTMSelected: PTMArray.length
        });
      }
    }
  };

  cancelSelection = () => {
    this.setState({
      STMMembers: [],
      GTMMembers: [],
      PTMMembers: [],
      STMArray: [],
      GTMArray: [],
      PTMArray: []
    });
    // STMArray = [];
    // GTMArray = [];
    // PTMArray = [];
    maxRequiredSTM = 0;
    this.props.history.push('/loan');
  };

  render() {
    const {
      loanamount,
      repaydate,
      STMRequired,
      GTMRequired,
      PTMRequired
    } = this.state;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <div
                id="left"
                className="uk-width-3-4@m uk-child-width-1-1@m uk-child-width-1-2@s uk-margin-remove dash-margin"
              >
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <table class="uk-table uk-margin-left">
                    <caption className="uk-text-center">
                      <div className="divider" />
                      <h1 className="uk-h1 theme-color uk-margin-remove-top">
                        <span className="text-red">Loan Amount: </span>
                        {loanamount}
                      </h1>
                      <span className="uk-margin-right uk-margin-bottom">
                        Repayment Date:
                      </span>{' '}
                      {repaydate}
                      <br />
                      <br />
                      <div
                        className="uk-grid uk-grid-match uk-margin"
                        data-uk-grid
                      >
                        <div className="uk-margin">
                          <span
                            className="req uk-text-lead theme-color"
                            id="stm"
                          >
                            STM: {STMRequired}
                          </span>
                        </div>
                        <div>
                          <span className="uk-text-lead text-red">OR</span>
                        </div>
                        <div className="uk-margin">
                          <span
                            className="req uk-text-lead theme-color"
                            id="stm"
                          >
                            GTM: {GTMRequired}
                          </span>
                        </div>
                        <div>
                          <span className="uk-text-lead text-red">OR</span>
                        </div>
                        <div className="uk-margin">
                          <span
                            className="req uk-text-lead theme-color"
                            id="stm"
                          >
                            PTM: {PTMRequired}
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                        <br />
                        <a
                          className="uk-text-small text-grey uk-inline"
                          onClick={this.cancelSelection}
                        >
                          Cancel Selection
                        </a>
                        <a
                          className="uk-button dash-button uk-margin-left uk-button-small"
                          onClick={this.addTrusters}
                          style={{ fontSize: '12px' }}
                        >
                          Add Selected Trusters
                        </a>
                      </div>
                    </caption>
                  </table>
                </div>
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <table class="uk-table uk-margin-left">
                    <ul data-uk-switcher="{connect:'#my-id'}" data-uk-tab>
                      <li>
                        <a href="#">Silver Trust Members (STM)</a>
                      </li>
                      <li>
                        <a href="#">Gold Trust Members (GTM)</a>
                      </li>
                      <li>
                        <a href="#">Platinum Trust Members (PTM)</a>
                      </li>
                    </ul>
                    <ul id="my-id" className="uk-switcher uk-margin">
                      <li className="uk-margin-right" data-uk-switcher-item="0">
                        <span className="uk-align-right uk-margin-right">
                          Selected : {this.state.STMSelected}
                        </span>
                        <table class="uk-table uk-margin-left">
                          <thead>
                            <tr>
                              <th className="" />
                              {/* <th className="theme-color">
                                <input class="uk-checkbox" type="checkbox" />
                              </th> */}
                              <th className="theme-color">TRUSTER</th>
                              <th className="theme-color">TFP</th>
                              <th className="theme-color">PLAN</th>
                              <th className="theme-color">TFP STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.STMMembers &&
                            this.state.STMMembers.length > 0 ? (
                              this.state.STMMembers.map((stm, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      className="uk-checkbox"
                                      type="checkbox"
                                      name="STM"
                                      onClick={this.selectTrustMembers.bind(
                                        this,
                                        stm.u_tfp_pin,
                                        stm.pl_prefix,
                                        stm.id,
                                        i
                                      )}
                                      disabled={
                                        stm.u_tfp_pin_status === 0
                                          ? false
                                          : true
                                      }
                                      checked={
                                        stm.checked && stm.checked === true
                                          ? stm.checked
                                          : false
                                      }
                                    />
                                  </td>
                                  <td>{stm.u_first_name || ''}</td>
                                  <td>{stm.u_tfp_pin}</td>
                                  <td>{stm.pl_prefix}</td>
                                  <td>
                                    {stm.u_tfp_pin_status == 0 ? (
                                      <span className="text-green">
                                        AVAILABLE
                                      </span>
                                    ) : stm.u_tfp_pin_status == 1 ? (
                                      <span className="text-red">IN USE</span>
                                    ) : stm.u_tfp_pin_status == 2 ? (
                                      <span className="text-red">
                                        NOT AVAILABLE
                                      </span>
                                    ) : stm.u_tfp_pin_status === 3 ? (
                                      <span>IN PROCESS</span>
                                    ) : null}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>No records available</tr>
                            )}
                          </tbody>
                        </table>
                      </li>
                      <li className="uk-margin-right" data-uk-switcher-item="1">
                        <span className="uk-align-right uk-margin-right">
                          Selected : {this.state.GTMSelected}
                        </span>
                        <table class="uk-table uk-margin-left">
                          <thead>
                            <tr>
                              <th className="" />
                              {/* <th className="">
                                <input
                                  className="data-uk-checkbox"
                                  type="checkbox"
                                />
                              </th> */}
                              <th className="theme-color">TRUSTER</th>
                              <th className="theme-color">TFP</th>
                              <th className="theme-color">PLAN</th>
                              <th className="theme-color">TFP STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.GTMMembers &&
                            this.state.GTMMembers.length > 0 ? (
                              this.state.GTMMembers.map((gtm, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      class="uk-checkbox"
                                      type="checkbox"
                                      onClick={this.selectTrustMembers.bind(
                                        this,
                                        gtm.u_tfp_pin,
                                        gtm.pl_prefix,
                                        gtm.id,
                                        i
                                      )}
                                      disabled={
                                        gtm.u_tfp_pin_status === 0
                                          ? false
                                          : true
                                      }
                                      checked={
                                        gtm.checked && gtm.checked === true
                                          ? gtm.checked
                                          : false
                                      }
                                    />
                                  </td>
                                  <td>{gtm.u_first_name || ''}</td>
                                  <td>{gtm.u_tfp_pin}</td>
                                  <td>{gtm.pl_prefix}</td>
                                  <td>
                                    {gtm.u_tfp_pin_status == 0 ? (
                                      <span className="text-green">
                                        AVAILABLE
                                      </span>
                                    ) : gtm.u_tfp_pin_status == 1 ? (
                                      <span className="text-red">IN USE</span>
                                    ) : gtm.u_tfp_pin_status == 2 ? (
                                      <span className="text-red">
                                        NOT AVAILABLE
                                      </span>
                                    ) : gtm.u_tfp_pin_status === 3 ? (
                                      <span>IN PROCESS</span>
                                    ) : null}
                                  </td>
                                  {/* <td>
                                    <span
                                      className={`${
                                        gtm.u_tfp_pin_status === 0
                                          ? 'text-green'
                                          : 'text-red'
                                      }`}
                                    >
                                      {gtm.u_tfp_pin_status === 0
                                        ? 'AVAILABLE'
                                        : 'IN USE'}
                                    </span>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>No records available</tr>
                            )}
                          </tbody>
                        </table>
                      </li>
                      <li className="uk-margin-right" data-uk-switcher-item="2">
                        <span className="uk-align-right uk-margin-right">
                          Selected : {this.state.PTMSelected}
                        </span>
                        <table class="uk-table uk-margin-left">
                          <thead>
                            <tr>
                              <th className="" />
                              {/* <th className="">
                                <input class="uk-checkbox" type="checkbox" />
                              </th> */}
                              <th className="theme-color">TRUSTER</th>
                              <th className="theme-color">TFP</th>
                              <th className="theme-color">PLAN</th>
                              <th className="theme-color">TFP STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.PTMMembers &&
                            this.state.PTMMembers.length > 0 ? (
                              this.state.PTMMembers.map((ptm, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      class="uk-checkbox"
                                      type="checkbox"
                                      onClick={this.selectTrustMembers.bind(
                                        this,
                                        ptm.u_tfp_pin,
                                        ptm.pl_prefix,
                                        ptm.id,
                                        i
                                      )}
                                      disabled={
                                        ptm.u_tfp_pin_status === 0
                                          ? false
                                          : true
                                      }
                                      checked={
                                        ptm.checked && ptm.checked === true
                                          ? ptm.checked
                                          : false
                                      }
                                    />
                                  </td>
                                  <td>{ptm.u_first_name || ''}</td>
                                  <td>{ptm.u_tfp_pin}</td>
                                  <td>{ptm.pl_prefix}</td>
                                  <td>
                                    {ptm.u_tfp_pin_status == 0 ? (
                                      <span className="text-green">
                                        AVAILABLE
                                      </span>
                                    ) : ptm.u_tfp_pin_status == 1 ? (
                                      <span className="text-red">IN USE</span>
                                    ) : ptm.u_tfp_pin_status == 2 ? (
                                      <span className="text-red">
                                        NOT AVAILABLE
                                      </span>
                                    ) : ptm.u_tfp_pin_status === 3 ? (
                                      <span>IN PROCESS</span>
                                    ) : null}
                                  </td>
                                  {/* <td>
                                    <span
                                      className={`${
                                        ptm.u_tfp_pin_status === 0
                                          ? 'text-green'
                                          : 'text-red'
                                      }`}
                                    >
                                      {ptm.u_tfp_pin_status === 0
                                        ? 'AVAILABLE'
                                        : 'IN USE'}
                                    </span>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>No records available</tr>
                            )}
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </table>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AddTrusters;
