import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import trustFundApiService from "./common/api/ApiService";
import Footer from "./common/Footer";
import Header2 from "./common/Header2";

import "./assets/css/main.css";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      loanid: "",
      uid: "",
      trustresponse: "",
    };
  }

  componentDidMount() {
    document.title = "Terms and Conditions - If You TRUST Me I'll TRUST You";
    // const { location } = this.props;
    // if (location) {
    //   const parsed = qs.parse(location.search);
    //   let loanid = parsed.loanid;
    //   let uid = parsed.uid;
    //   let trustresponse = parsed.trustresponse;
    //   this.setState(
    //     {
    //       loanid,
    //       uid,
    //       trustresponse
    //     },
    //     () => this.getTrustPartnerResponseOnLoan()
    //   );
    // }
  }

  getTrustPartnerResponseOnLoan = () => {
    let loanid = this.state.loanid;
    let uid = this.state.uid;
    let trustresponse = this.state.trustresponse;
    let termschecked;
    if (trustresponse == 0) {
      termschecked = 0;
    } else {
      termschecked = this.state.checked ? 1 : 0;
    }
    let data = {
      loanid,
      uid,
      trustresponse,
      termschecked,
    };

    trustFundApiService("getTrustPartnerResponseOnLoan", data)
      .then((response) => {
        if (response.data.status === "success") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  termsChecked = (event) => {
    if (event.target.checked) {
      this.setState({
        checked: true,
      });
    } else {
      this.setState({
        checked: false,
      });
    }
  };

  submitLoanConsent = () => {
    this.getTrustPartnerResponseOnLoan();
  };
  render() {
    return (
      <div className="remove-margin">
        <Header2 />
        <div className="container-main">
          <div className="uk-margin-left uk-margin-right">
            <h2 className="uk-h4 uk-text-center theme-color uk-margin-top">
              Terms and Conditions
            </h2>
            <p>
              Your use of the ENTREPRENEURS TRUST FUND Platform (hereinafter
              referred to as E-TRUSTFUND) an online platform under the Africa’s
              Young Entrepreneurs umbrella that gives you access to
              zero-interest loans without collateral or paper work in
              approximately 59 seconds is subject to the terms set therefore
              within this document.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND can be accessed by qualified users via
              our website and mobile application. By clicking on the ACCEPT
              button or checkbox on the website or mobile application, you
              accept and agree to the terms and conditions provided by
              ENTREPRENEURS TRUST FUND. In order to use the TRUST Fund service,
              you must first agree to the Terms and Conditions. You will not be
              permitted to go any further in the registration process if you do
              not ACCEPT the Terms and Conditions Provision of this service is
              by ENTREPRENEURS TRUST FUND.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND is the platform under the Africa’s Young
              Entrepreneurs’ umbrella that encourages its members to embrace a
              savings culture. It is an Online platform which is user friendly,
              easily accessible to all kinds of people in the society, offering
              members easy access to their contributions and funds in terms of
              credit to engage in their day to day business activities.
              <br />
              <br />
              I Understand and agree that the E-TrustFund is an annual
              membership based online cooperative that offers me boundless
              opportunities and benefits as a member.
              <br />
              <br />
              I understand and agree that I voluntarily registered as a member
              so as to develop a healthy savings culture, become trustworthy,
              build my trust network, promote the cooperative while I enjoy the
              several benefits of my membership.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND is a
              Non-Banking or non-Financial institution, and does not represent
              or warrant that it seeks to or is capable of providing services
              for banking and allied activities. You need to have an existing
              account with an institution capable of providing banking services
              in order to access the services.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND offers
              members who consecutively save for 12 months, interest on their
              contributions made on the platform; The interest rates vary
              according to the membership Plan from 0.5% to 1.5%. hence no
              interest will be paid on your contributions and your annual
              cooperative dues.
              <br />
              <br />
              You understand and agree that your annual TRUST cooperative dues
              is a non-refundable fee that grants you membership rights and
              access to several benefits on the platform.
              <br />
              <br />
              You understand and agree that your ENTREPRENEURS TRUST FUND
              account is automatically funded weekly from your registered bank
              account provided when signing up into the platform. Your weekly
              contributions can be paid through debit/credit cards, direct bank
              transfer, USSD Code or Bank deposit.
              <br />
              <br />
              You understand and agree that savings is a discipline and you
              further grant ENTREPRENEURS TRUST FUND the unequivocal permission
              to automatically debit your registered bank account to weekly fund
              your TRUST fund account.
              <br />
              <br />
              You understand and agree that loan requests are only received and
              processed from Mondays to Fridays, within the hours of 8am to 5pm.
              <br />
              You understand and agree that your ENTREPRENEURS TRUST FUND
              account should be placed in a dormant state should you fail to
              meet up with four (4) consecutive weekly contributions payments
              obligations.
              <br />
              <br />
              You understand and agree that no payment will be made to you from
              your ENTREPRENEURS TRUST FUND account before the cash-out date.
              You understand and agree that all contributions provided and loans
              advanced to you are both payable in local currency.
              <br />
              <br />
              You understand and agree that if you fail to pay back the loan
              advanced to you on the agreed payback date, your account will be
              blacklisted.
              <br />
              <br />
              You Trust Investment upon maturity will be paid into the bank
              account provided, in the event of termination before the
              investment maturity date, only the principal amount will be
              deposited into your bank account during the cash out window.
              <br />
              You understand and agree that when your account is blacklisted,
              you will lose all the contributions in your ENTREPRENEURS TRUST
              FUND account, including your total weekly contributions and annual
              cooperative dues and will no longer have access to all the
              benefits accessible to TRUST members.
              <br />
              You understand and agree that the cooperative dues will be
              deducted from your total contributions at the end of the Trust
              Cycle. This charges is to cater for: - Government Stamp duty.
              <br />
              - Payment gateway charge.
              <br />
              - Bank transaction charges.
              <br />
              - SMS/EMAIL notifications.
              <br />
              <br />
              <br />
              You understand and agree that cash-out monies and loans will only
              be paid to your registered bank account, which must be consistent
              with your TRUST FUND account.
              <br />
              <br />
              You understand and agree that you can continuously earn monetary
              rewards (TRUST Earnings) for being TRUST worthy on the platform.
              You further understand and agree that these earnings are payable
              on the last day of every week.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND only
              recognizes one registered bank account. However, you may elect to
              change your registered bank account subject to some verification
              exercises.
              <br />
              <br />
              You understand and agree that for your account protection, loan
              approvals may take longer than 59seconds when there is a
              reasonable belief of irregular activities on your profile.
              <br />
              <br />
              You understand and agree that you will only offer TRUST to members
              you are well acquainted with and who are not of questionable
              character.
              <br />
              <br />
              You understand and agree that long-term loans are subject to my
              Trusters agreed repayment dates. This means that I can now pick my
              preferred repayment dates e.g. 6, 12, 18 or even 24 months and
              further agree that loans should be paid back before the end of the
              Trust Cycle to be eligible for Cash-Out.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND has no
              obligation to certify or guarantee the credit worthiness of
              members of the TRUST fund and shall not be liable for
              failure/inability of its members to meet his/her loan repayment
              obligations.
              <br />
              You understand and agree that it is not within the purview of
              ENTREPRENEURS TRUST FUND to guarantee the moral standing,
              credibility, character, integrity of contracting TRUST members and
              that you, to the full exclusion of the ENTREPRENEURS TRUST FUND
              reserves the sole and indivisible right to determine the
              worthiness of contracting TRUST member and will be solely liable
              for any consequence, dispute, or event arising therefrom.
              <br />
              <br />
              You understand and agree that if you offer your TRUST to a member
              in order to borrow loan from ENTREPRENEURS TRUST FUND and the
              member fails to pay back the loan before the cash – out date, you
              will be unable to cash out your contributions on Cash -Out date
              and your TRUST fund account will be locked and blacklisted as you
              are solely responsible for the risk arising from your TRUST
              decision.
              <br />
              <br />
              You understand and agree that when your TFP (TRUST FUND PIN) is
              used to obtain a loan for another TRUST member, you are precluded
              from accessing loans, Emergency Travel Cover and Purchase Order
              Power until the previous loan is fully repaid.
              <br />
              <br />
              You understand and agree that you can terminate your ENTREPRENEURS
              TRUST FUND account at any time within the TRUST fund operation
              cycle. However, the balance on your ENTREPRENEURS TRUST FUND
              account will only be paid to you on the cash – out date.
              <br />
              <br />
              You understand and agree that you are only eligible for Purchase
              Order Power (POP) when you have consistently contributed on
              ENTREPRENEURS TRUST FUND for a period not less than 6 months. You
              understand and agree that the value of your Purchase Order Power
              (POP) is dependent on your category of membership.
              <br />
              You understand and agree that if ENTREPRENEURS TRUST FUND disables
              access to your account or login route, you may be prevented from
              accessing the service, your account details or any file(s) or
              other content which are contained in your TRUST fund account.
              <br />
              <br />
              You will not knowingly or negligently transmit any virus or other
              harmful material(s) to the ENTREPRENEURS TRUST FUND Website. You
              will not use any device, software or routine to interfere or
              attempt to interfere with the proper working of the Website nor
              will you attempt to decipher, de-compile, disassemble or reverse
              engineer any of the software comprising or in any way making up
              the Website or any part of it.
              <br />
              <br />
              <br />
              <br />
              You understand and agree that should your bank make a charge back
              on your account, ENTREPRENEURS TRUST FUND reserves the right to
              withdraw the sum so affected from your ENTREPRENEURS TRUST FUND
              dashboard.
              <br />
              <br />
              You understand and agree that all loan advanced should be fully
              liquidated, with no interest thereon.
              <br />
              <br />
              You understand and agree that half payment or part payment of loan
              advanced through the ENTREPRENEURS TRUST FUND will be disregarded
              and will not be accounted for.
              <br />
              <br />
              You understand and agree that while ENTREPRENEURS TRUST FUND may
              not currently limit your use of the service in any way, it may do
              so if your usage hinders the ability of ENTREPRENEURS TRUST FUND
              to carry on its operations or the ability of other TRUST members
              to use the service.
              <br />
              <br />
              The supply of the service will be subject to your weekly
              contributions at regular intervals. In the event that your access
              to the service is permanently or temporarily stopped,
              ENTREPRENEURS TRUST FUND’s liability shall extend only to making
              payment of the balance in your account to you on the cash – out
              day.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND shall not be responsible for any loss or
              damage caused by a modification of the features of services,
              limitation of use of the service or the discontinuation altogether
              thereof.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND shall not assume any responsibility to
              determine what, if any, taxes apply to the sale of Merchant’s
              goods and services and/or the payments the Merchant receives in
              connection with Merchant’s use of the ENTREPRENEURS TRUST FUND
              Service (“Taxes”). It is solely the Merchant’s responsibility to
              assess, collect, report, or remit the correct tax to the proper
              tax authority. ENTREPRENEURS TRUST FUND is not obligated to, nor
              will ENTREPRENEURS TRUST FUND determine whether Taxes apply, or
              calculate, collect, report, or remit any Taxes to any tax
              authority arising from any transaction.
              <br />
              <br />
              You understand and agree that whilst your loan is approved,
              payments may take a longer time due to delays from the financial
              institution. You understand and agree that ENTREPRENEURS TRUST
              FUND will accept no liability whatsoever for any transaction
              initiated and/or concluded outside the ENTREPRENEURS TRUST FUND
              platform.
              <br />
              <br />
              You understand and agree that and weekly contributions are to be
              exclusively paid directly to the platform through any of the
              various payment methods provided by the ENTREPRENEURS TRUST FUND.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND does not represent or authorize the
              employment of third parties for making such payments. <br />
              <br />
              ENTREPRENEURS TRUST FUND is exculpated from admitting any
              liability, damages, claims that may arise from same.
              <br />
              <br />
              You understand and agree that payments made by you be cleared by
              your bank before it reflects on your ENTREPRENEURS TRUST FUND
              dashboard.
              <br />
              <br />
              You further understand and agree that payments made on the banking
              platform could occasion protracted delay and ENTREPRENEURS TRUST
              FUND will not be held liable for such occurrences or be
              responsible for any loss of funds that arises thereon.
              <br />
              <br />
              You understand and agree to keep ENTREPRENEURS TRUST FUND
              indemnified at all times against all modes of actions, claims,
              proceedings, damages, costs (both before and after judgment) and
              charges, including solicitors fees which may be maintained against
              ENTREPRENEURS TRUST FUND in settling any dispute in connection
              with the members account, loss of funds, impaired banking
              operations and operation of the ENTREPRENEURS TRUST FUND.
              <br />
              <br />
              You understand and agree that the indemnity aforementioned shall
              continue to be in force notwithstanding the termination of your
              account.
              <br />
              <br />
              <h2> Use of the service by you</h2>
              <br />
              <br />
              In order to access certain services, you may be required to
              provide information about yourself as part of the registration
              process for the service, or for your continued use of the service.
              You agree that any registration information you provide to
              ENTREPRENEURS TRUST FUND will always be accurate, correct and up
              to date. You are solely responsible and ENTREPRENEURS TRUST FUND
              is not in any way liable for the information and data collected in
              order to process and authenticate a transaction performed.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND can share your information with the
              Government or law enforcement agencies (if officially requested or
              required by order, notification, statute or court order) and with
              anyone else, without your consent.
              <br />
              <br />
              You agree not to access (or attempt to access) the service by any
              means other than through the interface that is provided by
              ENTREPRENEURS TRUST FUND, unless you have been specifically
              permitted to do so in a separate agreement with ENTREPRENEURS
              TRUST FUND.
              <br />
              <br />
              You agree that you will not engage in any activity that
              interfere(s) with or disrupts the service (or the servers and
              networks which are connected to the service).
              <br />
              <br />
              Unless you have been specifically permitted to do so in a separate
              agreement with ENTREPRENEURS TRUST FUND, you agree that you will
              not reproduce, duplicate, copy, sell, trade or resell the service
              for any purpose.
              <br />
              <br />
              You agree that you are solely responsible for (and that
              ENTREPRENEURS TRUST FUND has no responsibility to you or to any
              third party for) any breach of your obligations under the Terms
              and Conditions and for the consequences (including any loss or
              damage which ENTREPRENEURS TRUST FUND may suffer) of any such
              breach.
              <br />
              <br />
              You understand and agree to observe and comply with all applicable
              laws in any relevant jurisdiction in relation to the establishment
              and operation of member’s account in the ENTREPRENEURS TRUST FUND
              and shall indemnify ENTREPRENEURS TRUST FUND at all times against
              all modes of actions, claims, proceedings, damages, costs (both
              before and after judgment) and charges, including solicitors fees
              which may be maintained against ENTREPRENEURS TRUST FUND in
              connection with any failure to observe and/or comply with the
              provisions of all extant and applicable laws.
              <br />
              <br />
              You agree by accepting the TAA (TRUST Advisory Agreement) form,
              you oblige your TRUST account to the intended user for the loan
              transaction. You are also liable to lose all your weekly
              contributions and TRUST benefits if the user defaults in repayment
              of the loan within the loan duration. You cannot hold
              ENTREPRENEURS TRUST FUND responsible in this event.
              <br />
              <br />
              You agree that the ENTREPRENEURS TRUST FUND gives members a grace
              period of one (1) month – 4 weeks to catch up with contributions,
              failure which means the TRUST member will lose his/her TRUST
              annual cooperative dues, but still have access to his/her weekly
              contributions.
              <br />
              <br />
              You understand and agree that if a TRUST member defaults once,
              he/she becomes ineligible for the annual Business Support fund.
              <br />
              <br />
              You understand and agree that a TRUST member can access loans on
              the platform only when he/she becomes a qualified TRUST member.
              <br />
              <br />
              You understand and agree that a TRUST member only becomes eligible
              for a Qualified TRUST Membership once he/she completes his/her 12
              weeks of contributions on the platform.
              <br />
              <br />
              You understand and agree that a TRUST member only becomes a
              Bonafide Member when he/she has consistently contributed on the
              ENTREPRENEURS TRUST FUND platform for a period not less than six
              (6) months.
              <br />
              <br />
              You understand and agree that to obtain a loan on the platform,
              the TFP’S (TRUST fund Pins) of other Qualified TRUST Members are
              required. You further understand and agree that the platform
              automatically calculates the number of TFP’S needed when there is
              a loan request.
              <br />
              <br />
              You understand and agree that there are no restriction(s) to the
              number of times a TRUST member can access loans on the platform
              within the TRUST cycle. You further understand and agree that a
              TRUST member is only eligible for another loan when he/she has
              fully liquidated the previous loan.
              <br />
              <br />
              You understand and agree that a TRUST member can borrow up to
              N10,000,000.00 (Ten Million Naira Only) from the ENTREPRENEURS
              TRUST FUND Platform depending on the TRUST member’s membership
              category.
              <br />
              <br />
              You understand and agree that the payback period for the loan
              advanced is determined by contracting members of the TRUST fund
              and further agree that loans should be paid back before the end of
              the TRUST fund cycle to be eligible for cash out.
              <br />
              <br />
              You understand and agree that the Trust Cycle is a 12-calendar
              month period calculated from the date you join. This means If you
              join in August 2021, you cash out in August, 2022. If you join in
              December 2021, you cash in December 2022 except you are a flexi
              member. You understand and agree that the Cash-Out date signifies
              the end of your Trust Cycle. The Cash-Out date is usually 7 days
              to the end of your 12 Calendar month period. You will be paid your
              total contribution directly to your registered bank account as
              seen in your profile on your dashboard after you have been charged
              the cooperative dues for the Trust Cycle.
              <br />
              <br />
              You understand and agree that a defaulter in the payment of a loan
              is expelled from the TRUST fund community and all the TFP accounts
              used to access the loan are blacklisted, meaning the defaulter’s
              TRUSTERs cannot access their contributions and benefits anymore.
              <br />
              <br />
              You understand and acknowledge that all other membership category
              cannot downgrade or upgrade to the flexi category.
              <br />
              You acknowledge that flexi members before who has registered
              before June 2022 are on a one-year duration. Only members from
              June 1st 2022 have the flexibility to cash out at the selected
              time upon new registration.
              <br />
              <br />
              <h2> Emails and Electronic Devices Security</h2>
              <br />
              <br />
              You understand and agree to ensure the security of your email
              address and electronic devices; and shall inform the ENTREPRENEURS
              TRUST FUND forthwith upon becoming reasonably aware of any
              circumstances likely to render the continued use of email
              instructions unsafe.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND shall endeavour to refrain from
              processing an email instruction upon receiving a notification
              pursuant to the above clause. Nonetheless, this shall however be
              without prejudice to the TRUST fund member’s covenants and
              obligations herein in respect of any transaction processed whether
              prior or subsequent to the notification.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND retains the sole discretion to
              process an email instruction, or any part thereof; and shall not
              be under any obligation to provide reasons for failing to do so.
              <br />
              <br />
              You are fully aware that email instructions are unsecure means of
              communication and are therefore susceptible to the risk of
              omissions, errors, miss-statements, non- receipts, fraud and/or
              other unauthorized interventions by third parties, all such risks
              and exposures which the TRUST member hereby fully assumes.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND may, but is not obliged to seek
              telephone confirmation or other form(s) of confirmation of an
              email instruction or any ENTREPRENEURS TRUST FUND account
              instruction; and shall exercise its discretion to proceed or
              refrain from acting upon such instructions if the ENTREPRENEURS
              TRUST FUND is unable to obtain satisfactory confirmation thereof,
              or has reasons to doubt the authenticity of an email or
              ENTREPRENEURS TRUST FUND account instruction or the confirmation
              received in respect thereof.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND has no obligation whatsoever to
              confirm or verify the identity of the person(s) sending any email
              instruction or ENTREPRENEURS TRUST FUND account instructions or
              the genuineness of any instruction. Any transaction processed
              pursuant to an email or ENTREPRENEURS TRUST FUND account
              instruction shall be binding on the TRUST member for all intent
              and purposes, whether made with or without the TRUST member’s
              authority, knowledge or consent.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND shall be held free and harmless from
              any responsibility, liability or adverse consequence (whether
              direct or indirect) about receiving, considering and/or processing
              the TRUST member’s email instruction(s). The TRUST fund member
              further indemnifies and keeps the ENTREPRENEURS TRUST FUND fully
              indemnified against all litigations, actions, claims, loss,
              damages, costs, and/or expenses which may be brought against the
              ENTREPRENEURS TRUST FUND or suffered or incurred by the
              ENTREPRENEURS TRUST FUND and which may have arisen either directly
              or indirectly out of or about the ENTREPRENEURS TRUST FUND’s
              receiving, consideration and/or processing the TRUST fund member’s
              mail instruction(s)
              <br />
              <br />
              This indemnity shall be a continuing obligation in respect of all
              matters connected to or arising from the ENTREPRENEURS TRUST
              FUND’s receipt of, consideration or processing of the TRUST fund
              member’s email instruction(s).
              <br />
              <br />
              <h2>Your passwords and account security</h2>
              <br />
              <br />
              You understand and agree that you are responsible to ENTREPRENEURS
              TRUST FUND and to third parties for maintaining the
              confidentiality of passwords associated with any account used to
              access the service.
              <br />
              <br />
              You are fully responsible for the security of data on the website
              or otherwise in your possession.
              <br />
              <br />
              You agree to assume full responsibility and ensure safe custody of
              all electronic correspondences and prints issued to/or by
              ENTREPRENEURS TRUST FUND in relation to the operation of the TRUST
              fund.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND in exercise
              of its sole discretion may elect not to honour certain
              instructions if there exist latent or apparent doubt of the
              authenticity of the instruction or does not consider it to be
              sufficiently clear.
              <br />
              <br />
              You understand, agree and authorize ENTREPRENEURS TRUST FUND,
              without the necessity of any other confirmation to honour all
              electronic transaction and/or account instructions issued on the
              ENTREPRENEURS TRUST FUND platform provided such orders are
              believed to emanate from you.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND shall not be responsible for any losses
              arising out of any loss(es) or theft of your user information or
              your mobile device or from unauthorized or fraudulent transactions
              associated with your bank account or your account.
              <br />
              <br />
              You understand and agree to comply with all applicable laws and
              rules in connection with your collection, security and
              dissemination of any personal, financial, card, or transaction
              information (defined as “Data”) on the website.
              <br />
              <br />
              You agree that at all times you shall be compliant with the
              Payment Card Industry Data Security Standards (PCI-DSS) and the
              Payment Application Data Security Standards (PA-DSS), as
              applicable.
              <br />
              <br />
              You also agree that you will use only PCI compliant service
              providers in connection with the storage or transmission of Card
              Data defined as a cardholder’s account number, expiration date,
              and CVV2.Information on the PCI DSS can be found on the PCI
              Council’s website https://www.pcisecuritystandards.org,
              https://www.pcisecuritystandards.org It is your responsibility to
              comply with these standards, as applicable. Without prejudice to
              the first paragraph under this section and the rest of the terms,
              you will be solely responsible for all activities that occur under
              your account. You shall not:
              <br />
              <br />
              1. Submit any card transaction for processing that does not arise
              from your usage of the ENTREPRENEURS TRUST FUND services.
              <br />
              <br />
              2. Act as a payment intermediary or aggregator or otherwise resell
              ENTREPRENEURS TRUST FUND’s services on behalf of any third party.
              <br />
              <br />
              3. Send what you believe to be potentially fraudulent
              authorizations or fraudulent card transactions.
              <br />
              <br />
              <br />
              4. Use of ENTREPRENEURS TRUST FUND Service in a manner that Visa,
              Master Card or any other payment provider reasonably believes to
              be an abuse of the Card Network or a violation of Card Network
              rules.
              <br />
              <br />
              You agree and undertake to notify ENTREPRENEURS TRUST FUND
              immediately whenever you reasonably have reasons to suspect a
              third party’s unauthorized use or access to any electronic
              correspondences and prints issued to/or by ENTREPRENEURS TRUST
              FUND.
              <br />
              <br />
              <h2> Content in the service</h2>
              <br />
              <br />
              You understand and agree that all information (such as data files,
              written text, computer software, or images) which you may have
              access to as part of, or through your use of the service are the
              sole responsibility of the person from which such content
              originated from.
              <br />
              <br />
              You should be aware that the contents presented to you as part of
              the service, including but not limited to advertisements and
              promotional material of ENTREPRENEURS TRUST FUND or its affiliated
              companies are protected by intellectual property rights which are
              owned by ENTREPRENEURS TRUST FUND and/or its affiliated companies,
              who provide their contents to ENTREPRENEURS TRUST FUND (or by
              other persons or companies on their behalf).
              <br />
              <br />
              You will not modify, rent, lease, loan, sell, distribute, copy or
              create derivative work based on this content (either in whole or
              in part) unless you have been specifically told that you may do so
              by ENTREPRENEURS TRUST FUND or by the owners of the corresponding
              content, in a separate agreement.
              <br />
              <br />
              Any content being disseminated using ENTREPRENEURS TRUST FUND’s
              network or the product, service or platform may be pre-screened,
              reviewed, flagged, filtered, modified or simply refused or
              removed. Any spam or pornographic material and / or any illegal
              content will be immediately deleted and we reserve the right to
              take appropriate legal action arising therefrom.
              <br />
              <br />
              You agree that you are solely responsible for (and that
              ENTREPRENEURS TRUST FUND has no responsibility to you or to any
              third party) any content that you create, transmit or display
              while using the service or for the consequences of your actions
              (including any loss or damage which ENTREPRENEURS TRUST FUND may
              suffer) by doing so.
              <br />
              <br />
              You understand that by using the service you may be exposed to
              content of other users that you may find offensive, indecent or
              objectionable and that, in this respect, you use the service at
              your own risk. ENTREPRENEURS TRUST FUND shall not be made
              responsible for any repugnant content circulated on its service
              platform by other users.
              <br />
              <br />
              On noticing any such content, it is your duty to bring it to the
              attention of ENTREPRENEURS TRUST FUND officials immediately, who
              will take due care to expunge it from the service.
              <br />
              <br />
              Exculpation of liability from bank related services
              <br />
              <br />
              You understand and agree that all cases or concerns touching on
              bank related services shall exclusively be referred to the related
              banks for resolution, and not within the purview or
              responsibilities of the ENTREPRENEURS TRUST FUND.
              <br />
              <br />
              You understand and agree that your annual cooperative dues is
              solely used to grant access to the benefits of the ENTREPRENEURS
              TRUST FUND and no deductions or lien will be charged thereon to
              settle bank related or third-party transactions cost.
              <br />
              <br />
              You understand and agree that your annual cooperative dues is
              automatically renewed by the ENTREPRENEURS TRUST FUND at the end
              of every TrustFund Cycle from your Total Contributions in order to
              maintain an active Trust Fund account
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND will not be
              liable for any third-party fees that may be incurred as a result
              of using the services whatsoever.
              <br />
              You understand and agree that at the end of the TRUST cycle, all
              third-party transaction cost, including, but not limited to bank
              fees will be deducted from your TRUST fund membership account.
              <br />
              Legal, Business and Medical Advisory
              <br />
              <br />
              You understand and agree that the services rendered herein do not
              suggest or establish any form of client relationship. You reserve
              the exclusive right and absolute discretion to seek advice from
              independent professionals. ENTREPRENEURS TRUST FUND is fully
              precluded from entertaining or admitting any liability arising
              thereof.
              <br />
              <br />
              Intellectual Property Right, Services Content, Software and
              Trademarks
              <br />
              <br />
              You acknowledge and agree that the Services may contain content or
              features (“Services Content”) that are protected by copyright,
              patent, trademark, trade secret or other proprietary rights and
              laws. Except as expressly authorized by ENTREPRENEURS TRUST FUND,
              you agree not to modify, copy, frame, scrape, rent, lease, loan,
              sell, distribute or create derivative work(s)based on the Services
              or the Services Content, in whole or in part.
              <br />
              <br />
              In connection with your use of the Services you will not engage in
              or use any data mining, robots, scraping or similar data gathering
              or extraction methods. If you are blocked by ENTREPRENEURS TRUST
              FUND from accessing the Services (including by blocking your IP
              address), you agree not to implement any measures to circumvent
              such blocking (e.g., by masking your IP address or using a proxy
              IP address). <br />
              <br />
              Any use of the Services or use of the Services Content other than
              as specifically authorized herein is strictly prohibited.
              <br />
              <br />
              The technology and software underlying the Services or distributed
              in connection therewith are the property of ENTREPRENEURS TRUST
              FUND, our affiliates and our partners (the “Software”). <br />
              <br />
              You agree not to copy, modify, create a derivative work of,
              reverse engineer, reverse assemble or otherwise attempt to
              discover any source code, sell, assign, sublicense, or otherwise
              transfer any right in the Software. Any rights not expressly
              granted herein are reserved by ENTREPRENEURS TRUST FUND.
              <br />
              <br />
              The ENTREPRENEURS TRUST FUND name and logos are trademarks and
              service marks of ENTREPRENEURS TRUST FUND (collectively the
              “ENTREPRENEURS TRUST FUND Trademarks”). Other company, TRUST
              member’s product, and service names and logos used and displayed
              on the Services may be trademarks or service marks of their
              respective owners, who may or may not endorse or be affiliated
              with or connected to ENTREPRENEURS TRUST FUND. Nothing in these
              Terms and Conditions or the Services should be construed as
              granting, by implication, estoppel, or otherwise, any license or
              right to use any of ENTREPRENEURS TRUST FUND Trademarks displayed
              on the Services or any third party’s trademarks, without obtaining
              prior written permission in each instance.
              <br />
              <br />
              <h2> Notification of Changes</h2>
              <br />
              <br />
              ENTREPRENEURS TRUST FUND reserves the rights to add to or
              terminate any of the services/features on the platform or amend
              these terms at any time, in its discretion without notifying you.
              Subject to applicable laws, we may bring to your notice, any
              updates or changes to existing terms or the addition of new terms
              by posting an updated version of these terms on the website.
              Whether or not you accept a revised version of these terms is
              solely at your discretion. If you do not agree to these terms or
              any revised version, your sole recourse is to terminate your
              access or use of the ENTREPRENEURS TRUST FUND services. Your
              continuous use of the service after the revised terms clearly
              constitutes your acceptance of, the revised terms in effect at the
              time of your access or use.
              <br />
              <br />
              <h2> Additional Terms</h2>
              <br />
              <br />
              In furtherance of your access or continual use of the service, you
              may be subject to additional terms, rules and conditions that are
              posted on the ENTREPRENEURS TRUST FUND website, including but not
              limited to terms and conditions, which are by reference,
              incorporated into these terms. In the event of a conflict between
              any additional terms and the terms and conditions, these terms
              will supersede.
              <br />
              <br />
              <h2> Assignment</h2>
              <br />
              <br />
              You will not transfer, assign, or delegate these terms or your
              rights or obligations hereunder or your account in any way (by
              operation of law or otherwise) without our prior written consent.
              To the extent permitted by applicable law, ENTREPRENEURS TRUST
              FUND may transfer, assign, or delegate these terms and our rights
              and obligations hereunder without your consent.
              <br />
              <br />
              <h2> Severability</h2>
              <br />
              <br />
              It is agreed and understood that if any provision of this terms
              becomes illegal, invalid, or unenforceable in any respect, the
              legality, validity and enforceability of the other provisions of
              this term shall not in any way be affected or impaired.
              <br />
              <br />
              <h2> Third-Party Material</h2>
              <br />
              <br />
              Under no circumstances will ENTREPRENEURS TRUST FUND be liable in
              any way for any content or materials of any third parties
              (including users and Campaign Organizers) or any User Content
              (defined below, including, but not limited to) for any errors or
              omissions in any content, or for any loss or damage of any kind
              incurred as a result of the use of any such content.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND does not
              pre-screen content, but that ENTREPRENEURS TRUST FUND and its
              designees will have the right (but not the obligation) in their
              sole discretion to refuse, remove, or allow any content that is
              available via the Services. Without limiting the foregoing,
              ENTREPRENEURS TRUST FUND and its designees will have the right to
              remove any content that violates these Terms and Conditions or is
              deemed by ENTREPRENEURS TRUST FUND, in its sole discretion, to be
              otherwise objectionable. User Content Transmitted Through the
              Services Regarding the use of personal content such as, photos,
              videos, image, trademarks, logos, brands or other materials you
              upload or post through the Services or share with other users or
              recipients (collectively, “User Content”), you represent and
              warrant that you own all right, title and interest in and to, or
              otherwise have all necessary rights and consents to (and to allow
              others to) fully exploit, such User Content, including, without
              limitation, as it concerns all copyrights, trademark rights and
              rights of publicity or privacy related thereto.
              <br />
              <br />
              By uploading, sharing, providing, or otherwise making available
              any User Content, or any portion thereof, in connection with the
              ENTREPRENEURS TRUST FUND Services, you hereby unconditionally
              grant and will grant ENTREPRENEURS TRUST FUND and its affiliated
              companies the use of your User Content in connection with the
              operation of the Services in any form, medium or technology now
              known or later developed.
              <br />
              <br />
              Without limiting the foregoing, if any User Content contains your
              name, image or likeness, you hereby undertake not to hold
              ENTREPRENEURS TRUST FUND and its contractors and employees
              culpable in respect of:
              <br />
              <br />
              I. All claims for invasion of privacy, publicity or libel,
              <br />
              <br />
              II. any liability or other claims by virtue of any blurring,
              distortion, alteration, optical illusion, or other use or
              exploitation of your name, image or likeness, and
              <br />
              <br />
              III. any liability for claims made by you (or any successor to any
              claim you might bring) in connection with your User Content, name,
              image or likeness.
              <br />
              <br />
              You waive any right to inspect or approve any intermediary
              version(s) or finished version(s) of the results of the use of
              your User Content (including your name, image or likeness).
              Furthermore, if any person (other than you) appears in your User
              Content, you represent and warrant that you have secured all
              necessary licenses, waivers and releases from such person(s) for
              the operation of ENTREPRENEURS TRUST FUND in a manner fully
              consistent with the licenses, waivers and releases.
              <br />
              <br />
              You further understand and agree that your participation in the
              Services and submission of User Content is voluntary and that you
              will not receive any monetary or financial compensation of any
              type associated with the licenses, waivers, and releases and that
              the sole consideration for subject matter of this agreement is the
              opportunity to use the Services.
              <br />
              <br />
              We do not guarantee that any Service Content will be made
              available through the Services.
              <br />
              <br />
              We reserve the right to, but do not have any obligation to,
              Remove, edit or modify any Services Content or User Content, in
              our sole discretion, at any time, without notice to you and for
              any reason (including, but not limited to, upon receipt of claims
              or allegations from third parties or authorities relating to such
              Services Content or User Content, or if we are concerned that you
              may have violated these Terms of Use), or for no reason at all and
              We reserve the right to remove or block any Services Content or
              User Content from the Services.
              <br />
              <br />
              You understand and agree that any questions, comments,
              suggestions, ideas, feedback or other information about the
              Services (“Submissions”), provided by you to ENTREPRENEURS TRUST
              FUND are non-confidential and ENTREPRENEURS TRUST FUND will be
              entitled to the unrestricted use and dissemination of these
              Submissions for any purpose, without acknowledgment or
              compensation to you.
              <br />
              <br />
              You understand and agree that ENTREPRENEURS TRUST FUND may
              preserve content and may also disclose content if required to do
              so by law or in the good-faith believing that such preservation or
              disclosure is reasonably necessary to:
              <br />
              <br />
              I. Comply with legal process, applicable laws or government
              requests;
              <br />
              <br />
              II. Enforce these Terms and Condition;
              <br />
              <br />
              III. Respond to claims that any content violates the rights of
              third parties; or
              <br />
              <br />
              IV. Protect the rights, property, or personal safety of
              ENTREPRENEURS TRUST FUND, its users or the public.
              <br />
              <br />
              <h2> REFUND POLICY</h2>
              <br />
              <br />
              Refunds will not be available for any membership category on the
              ENTREPRENEURS TRUST FUND. ENTREPRENEURS TRUST FUND will neither
              honour any refund request nor provide any part or full refund from
              the contributions of members before the CASH-OUT period.
              <br />
              You understand and agree that clicking on cash-out means that you
              are ending one Trust Fund Cycle and starting a new Cycle which
              means your savings continues.
              <br />
              I understand and agree that I can only withdraw my total
              contribution at the end of my Trust Cycle (12 months) and not when
              I like. All TRUST Fund members are paid their contributions
              directly to their registered bank account.
              <br />
              I understand and agree that I cannot defame or speak against the
              E-TrustFund Cooperative in any way either in words, deeds,
              writing, signs, comments, disposition or otherwise.
              <br />
              If I have any inquiry or query, I understand and agree that I can
              direct same through the Members Support Department whose Email,
              WhatsApp and other Contact details I have access to on my
              dashboard and not on social media or other public platforms.
              <br />
              I understand and agree that the Entrepreneurs Trust Fund is an
              online Cooperative which is built on technology as such there will
              be regular upgrades and maintenance to serve all members better.
              <br />
              I understand and agree that the E-TrustFund was established to
              make it easier for entrepreneurs to access free legal, medical and
              business advisory and loan with no interest rate, collateral,
              paperwork or credit check.
              <br />
              <br />
              <br />

              <h2> CASH-OUT POLICY</h2>
              <br />
             
              Cash-out disbursement is usually done in <strong>batches</strong>, but extended cases may apply due to the following;
              <p>
                <b>Incorrect Bank Account Detail:</b> When a member updates an incorrect bank account detail, the transaction would bounce back on the system, however upon reversal and settlement by the bank, the member will be asked for the “Correct Bank Account Detail”. 
                The transaction will then be Re-processed and payable into the new account provided by the member within 7 working days.
              </p>
              <p>
                <b>No Bank Account Updated in Profile:</b> If a member fails to provide their bank account detail in their profile, the system will be unable to recognise the account and the payment would be declined. If this may be the case you might be required to update your profile and apply during the next cash-out window. 
              </p>
              <p>
                <b>Poor Bank Network:</b>  When the bank experiences poor network or a down-time, it impedes banking transactions across electronic banking platforms. In this case, payments may lag however, be rest assured that it will be re-processed within 24hrs once the network is stable.
              </p>

              <p>
                <b>Reversed Payment Transactions:</b> Members are advised to wait for the bank to reverse the funds whenever payment fails or lags. This is common with online banking and electronic fund transfers. Please note that this process may take up to 21 working days which is subject to when the bank reverses the transaction. Be rest assured that the transaction will be reprocessed immediately the reversal is done.
              </p>


              <br />
              <br />
              <h2> TERMINATING YOUR ACCOUNT WITH ENTREPRENEURS TRUST FUND</h2>
              <br />
              <br />
              <br />
              The Terms will continue to apply until terminated by either you or
              ENTREPRENEURS TRUST FUND as set out below. It is further agreed
              that ENTREPRENEURS TRUST FUND shall terminate the operation of
              member’s account upon receipt of the member’s instruction. Either
              the member or ENTREPRENEURS TRUST FUND may terminate the operation
              of the account upon receipt of 72hours prior notice. Such notice
              shall be through the medium of email correspondence. TRUST members
              seeking termination of account shall send his/her instructions to
              support@etrustfund.org, whilst notice of termination from the
              ENTREPRENEURS TRUST FUND will be sent to the TRUST fund member’s
              registered email address.
              <br />
              <br />
              ENTREPRENEURS TRUST FUND may at any time, terminate its legal
              agreement with you if:
              <br />
              <br />
              You have breached any provision of the terms (or have acted in
              manner which clearly shows that you do not intend to, or are
              unable to comply with the provisions of the terms); or
              ENTREPRENEURS TRUST FUND is required to do so by law (for example,
              where the provision of the service to you is, or becomes unlawful
              by State, or Federal jurisdiction laws)
              <br />
              <br />
              When these terms come to an end, all of the legal rights,
              obligations and liabilities that you and ENTREPRENEURS TRUST FUND
              are subject to in relation to the obligation to maintain
              confidentiality or such other legal rights, obligations and
              liabilities which are expressed to continue indefinitely, shall be
              unaffected by this cessation.
              <br />
              <br />
              <h2> Exclusion of Warranties</h2>
              <br />
              You expressly understand and agree that your use of the service is
              at your sole risk and that the services are provided as is, and as
              available. In particular, ENTREPRENEURS TRUST FUND does not
              represent or warrant to you the following:
              <br />
              <br />
              I. Your use of the service will meet your requirements,
              <br />
              <br />
              II. Your use of the service will be uninterrupted, timely, secure
              or free from error, including specifically from server downtime.
              ENTREPRENEURS TRUST FUND does not represent, warrant or guarantee
              that you will be able to access or use the site at times or
              location of your choosing, or that ENTREPRENEURS TRUST FUND will
              have adequate capacity for the site as a whole or in any
              geographical location.
              <br />
              <br />
              III. Any information obtained by you as a result of your use of
              the service will be accurate or reliable, and
              <br />
              <br />
              IV. Any material downloaded or otherwise obtained through the use
              of the service is done at your own discretion and risk and
              ENTREPRENEURS TRUST FUND will not be responsible for any damage to
              your computer system or other device or loss of data that results
              from the download of any such material.
              <br />
              <br />
              V. No advice or information, whether oral or written, obtained by
              you from ENTREPRENEURS TRUST FUND or through or from the service
              shall create any warranty not expressly stated in the terms and
              conditions nor create any obligation on ENTREPRENEURS TRUST FUND
              to pursue or consummate any business or other relationship with
              you.
              <br />
              <br />
              VI. ENTREPRENEURS TRUST FUND further expressly disclaims all
              warranties and conditions of any kind, whether express or implied,
              including, but not limited to the implied warranties and
              conditions of merchantability, fitness for a particular purpose
              and non-infringement. Limitation of Liability
              <br />
              <br />
              Subject to overall provision in the stated clause above, you
              expressly understand and agree that ENTREPRENEURS TRUST FUND shall
              not be liable to you for:
              <br />
              <br />
              i. any direct, indirect, incidental, special, consequential or
              exemplary damages which may be incurred by you, however caused and
              under any theory of liability. this shall include, but not be
              limited to, any loss of profit (whether incurred directly or
              indirectly), any loss of goodwill or business reputation, any loss
              of data suffered, cost of procurement of substitute goods or
              service, or other intangible loss;
              <br />
              <br />
              ii. any loss or damage which may be incurred by you, including but
              not limited to loss or damage as a result of:
              <br />
              <br />
              A. any changes which ENTREPRENEURS TRUST FUND may make to the
              service, or for any permanent or temporary cessation in the
              provision of the service (or any features within the service);
              <br />
              B. the deletion of, corruption of, or failure to store, any
              content and other communications data maintained or transmitted by
              or through your use of the service;
              <br />
              C. your failure to provide ENTREPRENEURS TRUST FUND with accurate
              account information;
              <br />
              D. your failure to keep your password or account details secure
              and confidential; E. for any internet disruption, malfunction,
              strike, dispute or any other (intervening) circumstances adversely
              affecting the operation and use of the platform which is beyond
              the control of the ENTREPRENEURS TRUST FUND.
              <br />
              Other content to which references are made, links are provided,
              etc. The service may include hyperlinks to other web sites or
              content or resources. ENTREPRENEURS TRUST FUND may have no control
              over any web sites or resources which are provided by companies or
              persons other than ENTREPRENEURS TRUST FUND. You understand and
              agree that ENTREPRENEURS TRUST FUND is not responsible for the
              availability of any such external sites or resources, and does not
              endorse any advertising, products or other materials on or
              available from such web sites or resources. You understand and
              agree that ENTREPRENEURS TRUST FUND is not liable for any loss or
              damage which may be incurred by you as a result of the
              non-availability of those external sites or resources, or as a
              result of any reliance placed by you on the completeness, accuracy
              or existence of any advertising, products or other materials on or
              available from, such web sites or resources. You understand and
              agree that ENTREPRENEURS TRUST FUND reserves the rights and
              discretion to cause changes governing the transactions and
              operations on the ENTREPRENEURS TRUST FUND.
              <br />
              You understand and agree to be bound by any notification of
              change(s) governing the transactions and operations on the
              ENTREPRENEURS TRUST FUND. General legal terms
              <br />
              Sometimes when you use the service, you may (as a result of, or
              through your use of the service) use a service or download a piece
              of software, or purchase goods, which are provided by another
              person or company. Your use of these other service, software or
              goods may be subject to separate terms between you and the company
              or person concerned.
              <br />
              You understand and agree that the rights and liabilities arising
              from such agreement are outside the purview of the ENTREPRENEURS
              TRUST FUND. You agree that if ENTREPRENEURS TRUST FUND does not
              exercise or enforce any legal right or remedy which is contained
              in the terms and conditions (or which ENTREPRENEURS TRUST FUND has
              the benefit of under any applicable law), this will not be taken
              to be a formal waiver of ENTREPRENEURS TRUST FUND’s rights and
              that those rights or remedies will still be available to
              ENTREPRENEURS TRUST FUND.
              <br />
              You understand and agree that in addition to any general lien or
              similar right to which ENTREPRENEURS TRUST FUND may be entitled by
              law, ENTREPRENEURS TRUST FUND may at anytime and without prior
              notice to the TRUST fund member combine or consolidate all or any
              of the member’s account without liabilities to ENTREPRENEURS TRUST
              FUND or any other account or in any other respect whether such
              liabilities be actual or contingent, primary or collateral,
              severally or jointly.
              <br />
              If any court of law, having the statutory jurisdiction to decide
              on this matter rules that any provision of these terms is invalid,
              then that provision will be removed from the terms without
              affecting the rest of the terms. The remaining provisions of the
              terms will continue to be valid and enforceable.
              <br />
              You further agree that the terms, and your relationship with
              ENTREPRENEURS TRUST FUND under the terms, shall be governed by the
              enabling laws in your country of residence.
              <br />
              You and ENTREPRENEURS TRUST FUND agree that ALL disputes will be
              referred to arbitration, with the arbitral panel consisting of
              three arbitrators, two arbitrators being chosen by each party and
              a third arbitrator being jointly chosen by the parties all of whom
              shall have substantial experience in resolving commercial and
              contract disputes. If parties fail to nominate a third arbitrator
              within 14 days after the tribunal has been constituted, the Branch
              Chairman of the Chartered Institute of Arbitrators (United
              Kingdom) in country of dispute shall appoint for such party.
              Arbitrator’s awards are as enforceable as any court order. Any
              award given by the tribunal shall be binding on the parties.
              <br />
              You understand and agree that by submitting to these terms, you
              have waived your rights to approach the court to seek redress.
              THIRD-PARTY ENQUIRIES You understand and authorize ENTREPRENEURS
              TRUST FUND without reservations to make third-party enquiries
              about your person and business now or at any time in the future
              prior to considering your application for membership or access to
              enjoying benefits of its credit facilities. This Platform is not
              intended for children.
              <br />
              <br />
              DECLARATION I Declare that:
              <br />
              I am 18 years old or over. I understand and agree with the
              implications of all the above stated clauses and I undertake to
              abide by the obligations stated therein. I unequivocally certify
              that all funds and sources of such funds provided by me or by
              anyone or entity connected with me which are domiciled in the
              ENTREPRENEURS TRUST FUND platform are legitimate and not directly
              or indirectly the proceeds of any unlawful activity.
              <br />
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Terms;
