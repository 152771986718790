import {
  REGISTER,
  LOGIN,
  LOGOUT,
  GET_PLANS,
  PURCHASE_PLAN,
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_NOTIFICATION_COUNT,
  BUY_PLAN,
  BUY_PLAN_ORDINARY
} from '../types';
import trustFundApiService from '../../api/ApiService';

const actionRegister = data => async dispatch => {
  const res = await trustFundApiService('register', data);
  dispatch({ type: REGISTER, payload: res.data });
  return res;
};

const actionLogin = data => async dispatch => {
  const res = await trustFundApiService('login', data);
  dispatch({ type: LOGIN, payload: res.data });
  return res;
};

const actionLogout = data => async dispatch => {
  const res = await 'test';
  dispatch({ type: LOGOUT, payload: res.data });
  return res;
};

const actionGetPlans = () => async dispatch => {
  const res = await trustFundApiService('getPlans');
  dispatch({ type: GET_PLANS, payload: res.data });
  return res;
};

const actionPurchasePlan = data => async dispatch => {
  const res = await trustFundApiService('subscribePlan', data);
  dispatch({ type: PURCHASE_PLAN, payload: res.data });
  return res;
};

const actionPurchasePlanOTP = data => async dispatch => {
  const res = await trustFundApiService('subscribePlanByOTP', data);
  dispatch({ type: PURCHASE_PLAN, payload: res.data });
  return res;
};

const actionGetUserProfile = data => async dispatch => {
  const res = await trustFundApiService('getUserProfile', data);
  dispatch({ type: GET_PROFILE, payload: res.data });
  return res;
};

const actionUpdateProfile = data => async dispatch => {
  const res = await trustFundApiService('updateProfile', data);
  dispatch({ type: UPDATE_PROFILE, payload: res.data });
  return res;
};

const actionUpdateNotificationCount = notificationCount => async dispatch => {
  dispatch({ type: UPDATE_NOTIFICATION_COUNT, payload: notificationCount });
};

const actionBuyPlan = data => async dispatch => {
  const res = await trustFundApiService('paymentProcess3', data);
  dispatch({ type: BUY_PLAN, payload: res.data });
  return res;
};

const actionOrdinaryPlan = data => async dispatch => {
  dispatch({ type: BUY_PLAN_ORDINARY, payload: data });
};


export {
  actionLogin,
  actionRegister,
  actionLogout,
  actionGetPlans,
  actionPurchasePlan,
  actionPurchasePlanOTP,
  actionGetUserProfile,
  actionUpdateProfile,
  actionUpdateNotificationCount,
  actionBuyPlan,
  actionOrdinaryPlan
};
