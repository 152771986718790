export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const GET_PLANS = "GET_PLANS";
export const PURCHASE_PLAN = "PURCHASE_PLAN";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
export const BUY_PLAN = "BUY_PLAN";
export const BUY_PLAN_ORDINARY = "BUY_PLAN_ORDINARY";
