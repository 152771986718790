import React, { Component } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import Modal from 'react-modal';
import moment from 'moment';
import Header2 from './common/Header2';
import Footer from './common/Footer';
import BissiImg from './assets/img/chat-icon.png';
// import BissiImg from './assets/img/bissee.png';
import Avatar from './assets/img/ccicon.png';
import { Link } from "react-router-dom";

import trustFundApiService from './common/api/ApiService';
import CONSTANTS from './common/helpers/Constants';

class ChatCustomerCare extends Component {
  socket = {};
  constructor(props) {
    super(props);
    this.state = {
      onlineExpertId: '',
      expertIds: [],
      message: '',
      allMessages: []
    };
    this.socket = io.connect('https://etrustfund.org:5000');

    this.socket.on('connect', function() {
      console.log('inside connect socket');
    });

    this.socket.on('disconnect', function() {
      console.log('inside disconnect socket');
    });
  }

  // Solved the scroll to bottom issue
  componentDidUpdate() {
    document.title = "Chat with Members Support Team - If You TRUST Me I'll TRUST You";
    const objDiv = document.getElementById('scroller');
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      let name = location.state.username;
      let randomId = location.state.randomId;
      this.setState({
        name,
        randomId
      });
    }

    let _this = this;
    _this.getOnlineExperts();
    _this.socket.on('send_message', function(data) {
      if (
        (data && parseInt(data.receiver) === parseInt(_this.state.randomId)) ||
        (data.firstMessage == true &&
          data.receiver.findIndex(
            todo => todo == parseInt(_this.state.randomId)
          ) !== -1)
      ) {
        _this.updateChat(data);
      }

      if (data && data.sendexpertId) {
        _this.setState({
          onlineExpertId: data.sendexpertId
        });
      }
    });
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  getOnlineExperts = () => {
    let expertIds = [];
    let expertType = CONSTANTS.CHAT_TYPE.CUSTOMER_CARE;

    let data = {
      chat_expert: CONSTANTS.CHAT_TYPE.CUSTOMER_CARE
    };

    if (expertType != '') {
      trustFundApiService('customerCareChat', data)
        .then(response => {
          if (response.data.status === 'success') {
            let data = response.data.data;
            if (data.length > 0) {
              data.map((expert, index) => {
                expertIds.push(expert.u_id);
              });
              this.setState({ expertIds });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addMessage = e => {
    if (e.keyCode === 13 && e.shiftKey == false) {
      this.handleSendMessage();
      this.setState({
        message: ''
      });
    }
  };

  handleSendMessage = () => {
    let message = this.state.message ? this.state.message.trim() : '';
    if (message) {
      let senderId = parseInt(this.state.randomId);
      let expertIds =
        this.state.expertIds.length > 0 ? this.state.expertIds : [];

      let receiverId = '';
      let firstMessage = false;

      if (this.state.onlineExpertId !== '') {
        firstMessage = false;
        receiverId = this.state.onlineExpertId;
      } else {
        firstMessage = true;
        receiverId = expertIds;
      }

      let data = {
        sender: senderId,
        receiver: receiverId.length > 0 ? receiverId : parseInt(receiverId),
        message: message,
        firstMessage: firstMessage,
        datetime: new Date(),
        name: this.state.name
      };
      console.log('Emit', data);
      this.socket.emit('send_message', data);
      this.updateChat(data);
      this.setState({ message: '' });
    }
  };

  updateChat(data) {
    const allMessages = this.state.allMessages;
    allMessages.push(data);
    console.log(allMessages);
    this.setState({ allMessages });
  }

  render() {
    const { allMessages } = this.state;

    return (
      <div>
        <section
          id="planTop"
          className="uk-width-1-1@m"
          data-uk-height-viewport
        >
          <Header2 />

          <div className="uk-container">
            <div className="uk-margin-left uk-margin-right">
              <h2 className="uk-h4 uk-text-center theme-color uk-margin-top">
                Talk to our Members Support Team
              </h2>
              <p className="uk-text-center">
                Welcome to E-TrustFund, here you can speak directly with
                dedicated customer care agents.
              </p>
              <div data-uk-grid>
                <div class="uk-width-1-2 uk-width-expand uk-margin-top">
                  <div className="chat-area" id="scroller">
                    <div className="">
                      {/* Sender div */}
                      {allMessages && allMessages.length > 0
                        ? allMessages.map((message, i) =>
                            message.sender === this.state.randomId ? (
                              <div
                                className="me data-uk-grid-small uk-flex-bottom uk-flex-right uk-text-right"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small theme-bg uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {moment(message.datetime).format('HH:mm')}
                                  </p>
                                </div>

                                <div className="chat-img uk-width-auto">
                                  <img
                                    className="uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={
                                      this.props.userData.u_image
                                        ? this.props.userData.u_image
                                        : ''
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="guest data-uk-grid-small uk-flex-bottom uk-flex-left"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <img
                                    className="uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={Avatar}
                                  />
                                </div>
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small uk-card-default uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {' '}
                                    {moment(message.datetime).format('HH:mm')}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>

                  <div className="uk-card-footer uk-padding-remove">
                    <div
                      className="data-uk-grid-small uk-flex-middle"
                      data-uk-grid
                    >
                      <div className="uk-width-expand">
                        <div className="uk-padding-small uk-padding-remove-horizontal">
                          <textarea
                            className="uk-textarea uk-padding-remove uk-border-remove"
                            rows={1}
                            placeholder="Type Message Here..."
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={this.addMessage}
                          />
                        </div>
                      </div>
                      <div className="uk-width-auto">
                        <a
                          onClick={this.handleSendMessage}
                          className="uk-button dash-button"
                        >
                          Send
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-visible@m uk-width-1-2">
                  <img src={BissiImg} width={300} />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-text-center">
            <br/>
            <Link to="/">
              <span className="uk-button button-out">Return Home</span>
            </Link>
            <br/>
          </div>
        </section>
        <br/>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(ChatCustomerCare);
