import React, { useState } from "react";
import Plan from "./Plan";
import styles from "./Tplan.module.css";
import { formatNumber } from "../../../common/helpers/Utils";
import { setLocalStorage } from "../../../common/helpers/Utils";
import {
  NoMembership,
  RegisterButton,
  SvgWrapper,
  TrustplanHeaderBox,
} from "./Styles";
import { Link } from "react-router-dom";

export default function Trustplans({ planData, action, goto }) {
  const [flipit, setflipit] = useState(false);

  const plans = planData.filter((plan) => plan.pl_plan_name !== "FLEXI");
  const trustPlans = plans.map((plan) => (
    <Plan
      key={plan.pl_plan_id}
      planId={plan.pl_plan_id}
      planName={plan.pl_plan_name}
      weeklySaving={plan.pl_weekly_saving}
      loan={plan.pl_qualify_loan}
      action={action}
      planData={plan}
      goto={goto}
    />
  ));

  const registerPlan = (plan) => {
    setLocalStorage("planId", plan.pl_plan_id);
    window.location.href = "/register";
  };

  const flexContent = planData.filter((plan) => plan.pl_plan_name === "FLEXI");
  return (
    <>
      <section className={styles.tpSection}>
        <TrustplanHeaderBox>Trust Plans</TrustplanHeaderBox>
        <NoMembership>No membership fee</NoMembership>
        <div className={styles.plansContainer}>
          {trustPlans}
          <div className={styles.plan}>
            <input type="checkbox" checked={flipit} id={styles.flipcheck} />
            <div className={styles.flipcontainer}>
              <div className={styles.flipper}>
                <div className={styles.front}>
                  <div className={styles.frontbg}>
                    <div style={{ padding: "0.2rem 0" }}></div>
                    <div className={styles.planHeader}>
                      <h4>FLEXI MEMBER</h4>
                    </div>
                    <div className={styles.planPrice}>
                      <p>Weekly Savings <strong>Card only</strong></p>
                      <h2>{flexContent[0].pl_weekly_saving}</h2>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>Weekly Earnings</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>
                        Loan is {/*. " " */}
                        <span className="planBenefitcta">
                          {formatNumber(flexContent[0].pl_qualify_loan)}
                        </span>
                      </p>
                    </div>

                    <div className={styles.planBenefit}>
                      <p>
                        Trust bonus{" "}
                        <span className="planBenefitcta">
                          (0.5% - 1.5% On Total Savings)
                        </span>
                      </p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>
                        Trust Investment opportunities{" "}
                        <span className="planBenefitcta">(Up To 35% ROI)</span>
                      </p>
                    </div>
                    <div className={styles.moreBenefitBtnContainer}>
                      <RegisterButton onClick={() => registerPlan(planData[0])}>
                        Register Now
                      </RegisterButton>

                      <button onClick={() => setflipit(!flipit)}>
                        See more benefits
                      </button>
                    </div>
                  </div>
                </div>
                {/* back */}
                <div className={styles.back} onClick={() => setflipit(!flipit)}>
                  <div className={styles.backbg}>
                    <div
                      style={{
                        padding: "0.2rem 0",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <SvgWrapper>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </SvgWrapper>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[2].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[3].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[4].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[5].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[6].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[7].pb_text}</p>
                    </div>
                    <div className={styles.planBenefit}>
                      <p>{planData[0].planbenefits[8].pb_text}</p>
                    </div>

                    <div className={styles.moreBenefitBtnContainer}>
                      <button onClick={() => registerPlan(planData[0])}>
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* flippper */}
          </div>
        </div>
        <div>
          <div></div>
        </div>
      </section>
    </>
  );
}
