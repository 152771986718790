import React, { Component } from "react";
import axios from "axios";
import Footer from "./common/Footer";
import Header2 from "./common/Header2";
import swal from "sweetalert";

import Job from "./assets/img/jobs.png";

var _firstName,
  _surName,
  _email,
  _phone,
  _gender,
  _dob,
  _role,
  _qualification,
  _state,
  _lga,
  _address,
  _resume;

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Jobs - If You TRUST Me I'll TRUST You";
    document.desc = "Entrepreneurs TrustFund Jobs - If You TRUST Me I'll TRUST You";
    window.scrollTo(0, 0);
  }

  submitApplication = (e) => {

    swal("Submitting Application!", "Please Wait...", {
        button: false,
      });
    e.preventDefault();

    var formData = new FormData();
    formData.append("firstName", _firstName.value);
    formData.append("surName", _surName.value);
    formData.append("email", _email.value);
    formData.append("phone", _phone.value);
    formData.append("gender", _gender.value);
    formData.append("dob", _dob.value);
    formData.append("role", _role.value);
    formData.append("qualification", _qualification.value);
    formData.append("state", _state.value);
    formData.append("lga", _lga.value);
    formData.append("address", _address.value);
    formData.append("resume", _resume.value);

    console.log(formData);

    axios({
      method: "post",
      url: `${axios.defaults.baseURL}submit-job-application`,
      data: formData,
    })
      .then((response) => {
        console.log(response);
        swal("Application was successfully submitted!", "success", {
            button: false,
            timer: 3000,
          });
          setTimeout(
            function() {
              this.props.history.push('/');
            }.bind(this),
            3000
          );
        return response;
      })
    //   .then((json) => {
    //     if (json.data.success == true) {
        //   swal("Application was successfully submitted!", "success", {
        //     button: false,
        //     timer: 1500,
        //   });
    //     } else {
    //       swal("Application was not submitted!", "error");
    //     }
    //   })
    //   .catch((error) => {
    //     swal("Warning!", error, "warning");
    //   });
  };

  render() {
    return (
      <div>
        <Header2 />
        <div className="">
          <div
            id=""
            class="uk-width-1-1 uk-margin-small-left uk-margin-top"
            data-uk-grid
          >
            <div className="container container-main">
              <div className="uk-grid-item-match" data-uk-grid>
                <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                  <br />
                  <br />
                  <div
                    style={{
                      height: 200,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img src={Job} alt="Job" />
                  </div>
                  <h4 className="uk-text-bold uk-text-center">
                    EMPLOYMENT APPLICATION FORM
                  </h4>
                </div>
              </div>
              <div className="uk-grid-item-match" data-uk-grid>
                <div class="full-width uk-width-2-4 uk-background-default uk-padding">
                  Welcome to our RECRUITMENT portal, kindly see the below
                  information as a guide for the recruitment process. Please
                  provide accurate and up to date information in order to
                  continually receive our updates.
                  <br />
                  <ul>
                    <li>
                      Kindly use the form below to provide us basic information
                      about yourself
                    </li>
                    <li>
                      Step 1 of the application process is an online interview.{" "}
                    </li>
                    <li>
                      Check your email regularly to get information and updates
                      about this interview.
                    </li>
                    <li>
                      Also check other email folders such as spam, junk,
                      promotion and social for our emails.
                    </li>
                    <li>
                      Ensure to submit link to your most recent CV at the end of this
                      form.<br/><br/>

                      GO TO: <a href="https://ufile.io/" target="_blank">https://ufile.io/</a>, Upload your resume and copy the shareable link (Not More than 2MB)
                    </li>
                  </ul>
                  <i>We wish you the best of luck.</i>
                </div>
              </div>
              <div>
                <h5 className="uk-text-bold">Fill in your details below:</h5>
                <form
                  className="contact-form"
                  onSubmit={this.submitApplication}
                >
                  <div
                    className="uk-width-1-1@m uk-child-width-1-2@m"
                    data-uk-grid
                  >
                    <div>
                      <label>Enter your Firstname</label>
                      <input
                        ref={(input) => (_firstName = input)}
                        className="form-input"
                        placeholder="Fistname"
                        name="firstName"
                        type="text"
                        maxLength="100"
                        required
                      />
                    </div>
                    <div>
                      <label>Enter your Surname</label>
                      <input
                        ref={(input) => (_surName = input)}
                        className="form-input"
                        placeholder="Surname"
                        name="surName"
                        type="text"
                        maxLength="100"
                        required
                      />
                    </div>
                    <div>
                      <label>Enter your Email</label>
                      <input
                        ref={(input) => (_email = input)}
                        className="form-input"
                        placeholder="Email"
                        name="email"
                        type="text"
                        maxLength="100"
                        required
                      />
                    </div>
                    <div>
                      <label>Enter your Phone Number</label>
                      <input
                        ref={(input) => (_phone = input)}
                        className="form-input"
                        placeholder="Phone Number"
                        name="phone"
                        type="text"
                        maxLength="100"
                        required
                      />
                    </div>
                    <div>
                      <label>Select your Gender</label>
                      <select
                        ref={(input) => (_gender = input)}
                        className="form-input"
                        name="gender"
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    <div>
                      <label>Enter your Date of Birth</label>
                      <input
                        ref={(input) => (_dob = input)}
                        className="form-input"
                        placeholder="Date of Birth"
                        name="dob"
                        type="date"
                        required
                      />
                    </div>

                    <div>
                      <label>Select the Role your arew applying for</label>
                      <select
                        ref={(input) => (_role = input)}
                        className="form-input"
                        name="role"
                        required
                      >
                        <option value="">
                          What Role are you Applying for?
                        </option>
                        <option value="Representative">
                          Representative with N100,000 Monthly Salary
                        </option>
                        <option value="Manager">
                          Manager with N250,000 Monthly Salary
                        </option>
                      </select>
                    </div>

                    <div>
                      <label>Select Highest qualification obtained</label>
                      <select
                        ref={(input) => (_qualification = input)}
                        className="form-input"
                        name="qualification"
                        required
                      >
                        <option value="">Highest Qualification Obtained</option>
                        <option value="PhD">PhD</option>
                        <option value="MBBS">MBBS</option>
                        <option value="MBA">MBA</option>
                        <option value="M.Sc">M.Sc</option>
                        <option value="B.Sc">B.Sc</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Certificate">Certificate</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label>Enter your State of Residence</label>
                      <input
                        ref={(input) => (_state = input)}
                        className="form-input"
                        placeholder="State of Residence"
                        name="state"
                        type="text"
                        required
                      />
                    </div>

                    <div>
                      <label>Enter your LGA</label>
                      <input
                        ref={(input) => (_lga = input)}
                        className="form-input"
                        placeholder="Kindly Provide the LGA of your current place of Residence"
                        name="lga"
                        type="text"
                        required
                      />
                    </div>

                    <div>
                      <label>Enter your Address</label>
                      <input
                        ref={(input) => (_address = input)}
                        className="form-input"
                        placeholder="Enter residential address with the nearest Landmark"
                        name="address"
                        type="text"
                        required
                      />
                    </div>

                    <div>
                      <label>Paste resume Link from <a href="https://ufile.io/" target="_blank">https://ufile.io/</a></label>
                      <input
                        ref={(input) => (_resume = input)}
                        className="form-input"
                        placeholder="Paste resume Link from https://ufile.io/"
                        name="resume"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="uk-margin-right">
                    <button className="button btn-form" type="submit">
                      Submit Application
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Jobs;
