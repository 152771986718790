import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import '../assets/css/main.css';
import Logo from '../assets/img/nav.png';
import Sandwich from '../assets/img/sandwich-white.png';

class Header2 extends Component {
  render() {
    return (
      <div>
        {/* Topbar */}
        <div id="topbar" className="">
          <nav
            className="uk-navbar-container uk-vis@m container-main"
            data-uk-navbar
            style={{ position: 'relative', zIndex: 980 }}
          >
            <div className="uk-navbar-item etf-logo">
              <Link className="uk-logo" to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="uk-navbar-item">
              <ul
                className="uk-navbar-nav uk-vis@m"
                uk-scrollspy-nav="closest: li; scroll: true; cls: uk-active"
              >
                <li>
                  <Link to="/#howitworks">How It Works</Link>
                </li>
                <li>
                  <Link to="/#trustplans">Trust Plans</Link>
                </li>
                <li>
                  <Link to="/#faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/#videos">Videos</Link>
                </li>
              </ul>
            </div>
          </nav>
          {/*<nav className="global-nav global-nav--transparent">
            <div className="global-mobile-nav__menu">
              <ul>
                <li style={{ animationDelay: 0.5 }}>
                  <Link className="menu-txt-main" to="/login">
                    Login
                  </Link>
                </li>
                <li style={{ animationDelay: 0.7 }}>
                  <Link className="menu-txt-main" to="/register">
                    Register
                  </Link>
                </li>
              </ul>
              <ul className="small-menu" style={{ animationDelay: 1.05 }}>
                <li>
                  <Link className="menu-txt" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="menu-txt" to="/learn-more">
                    Help Center
                  </Link>
                </li>
                <li>
                  <Link className="menu-txt" to="/terms">
                    Terms
                  </Link>
                </li>
              </ul>
            </div>
            <div className="global-mobile-nav">
              <div className="global-mobile-nav__trigger">
                <span></span>
              </div>
            </div>
          </nav>*/}

          <nav>
            <div className="uk-hidden@l mobile-menu-bar uk-navbar-item">
              <a href="#">
                <img
                  className="nav-toggle mobile-nav-icon"
                  src={Sandwich}
                  alt="Sandwich"
                  data-uk-toggle="target: #offcanvas-overlay"
                />
              </a>
            </div>
          </nav>
            <div id="offcanvas-overlay" uk-offcanvas="overlay: true">
              <div class="uk-offcanvas-bar mobile-menu">
                  <a class="uk-offcanvas-close" type="button" data-uk-close></a>
                  <ul className="main-mobile-menu" uk-toggle="target: #offcanvas-overlay">
                    <li style={{ animationDelay: 0.5 }}>
                      <Link className="menu-txt-main" to="/login">
                        Login
                      </Link>
                      <a class="uk-offcanvas-close" type="button" data-uk-close></a>
                    </li>
                    <li style={{ animationDelay: 0.7 }}>
                      <Link className="menu-txt-main" to="/register">
                        Register
                      </Link>
                      <a class="uk-offcanvas-close" type="button" data-uk-close></a>
                    </li>
                  </ul>
                  <ul className="small-menu" style={{ animationDelay: 1.05 }}>
                    <li>
                      <a className="menu-txt" href="/terms">
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a className="menu-txt" href="/privacy">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a className="menu-txt" href="/learn-more">
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a className="menu-txt" href="/">
                        Home
                      </a>
                    </li>
                  </ul>
              </div>
          </div>
        </div>
        {/* END Topbar */}
      </div>
    );
  }
}
export default Header2;
