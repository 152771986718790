import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import MarketSidebar from './common/MarketSidebar';
import Notification from './common/Notification';
import GoogleAutocomplete from './common/helpers/GoogleAutocomplete';
import Validation from './common/helpers/Validation';
import trustFundApiService from './common/api/ApiService';
import Aisha from './assets/img/aisha.png';
import Arrow from './assets/img/arrows.png';
import Verified from './assets/img/verified.png';

class TrustMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  componentWillMount() {
    document.title = "Trust Market - If You TRUST Me I'll TRUST You";
    Modal.setAppElement('body');
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    const { } = this.props;

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>

              {/*Begin Trust Deal Modal Class*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-margin-right uk-margin-left uk-margin-top uk-margin-bottom">
                    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                        <div className="uk-width-expand">
                            <h4 className="uk-margin-remove theme-color">Nigerian Beweries Oil Supply <img width="15" src={Verified} /></h4>
                            <p className="uk-text-meta uk-margin-small-top">Aisha Ahmed - TFP0123456789</p>
                        </div>
                        <div className="uk-text-center">
                          <img src={Arrow} width="40" alt=""/> 15% <br/>
                        </div>
                    </div>
                    <div className="uk-grid-small uk-margin-bottom" data-uk-grid>
                      <div className="uk-width-1-2@s">
                          <input className="uk-input mrkt-comment" type="text" placeholder="Enter Stake..."/>
                          <span uk-icon="forward" className="uk-form-icon uk-form-icon-flip"></span>
                      </div>
                      <div className="uk-width-1-2@s">
                        <input className="uk-input mrkt-comment" type="text" placeholder="Input * %"/>
                          <span uk-icon="reply" className="uk-form-icon uk-form-icon-flip"></span>
                      </div>
                    </div>
                    <div className="uk-text-center">
                      <a onClick={this.toggleModal} className="uk-button uk-button-small dash-button uk-margin-bottom">Trust Deal</a><br/>
                      <a onClick={this.toggleModal} className="uk-position-relative link"> Close</a>
                    </div>
                  </div>
              </Modal>
              {/*Begin End Trust Modal Class*/}

              <div id="left" className="uk-width-3-4@m dash-margin">

                {/*Begin Post Deal Class*/}
                <div className="uk-card uk-card-loan uk-margin-bottom">
                    <div className="uk-card-body uk-margin-remove-top uk-margin-left uk-margin-bottom">
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-2@s"  uk-tooltip="title: Deal Title; pos: top">
                            <input className="uk-input mrkt-comment" type="text" placeholder="Post a new deal..."/>
                            <span uk-icon="bolt" className="uk-form-icon uk-form-icon-flip"></span>
                        </div>
                        <div className="uk-width-1-3@s"  uk-tooltip="title: Deal Target; pos: top">
                          <input className="uk-input mrkt-comment" type="text" placeholder="Enter Deal Target"/>
                            <span uk-icon="star" className="uk-form-icon uk-form-icon-flip"></span>
                        </div>
                        <div className="uk-width-1-6@s" uk-tooltip="title: Deal Return (%); pos: top">
                            <input className="uk-input mrkt-comment" type="text" placeholder="Return (%)"/>
                            <span uk-icon="tag" className="uk-form-icon uk-form-icon-flip"></span>
                        </div>
                      </div>
                      <div className="uk-margin">
                          <textarea className="uk-textarea uk-margin-bottom mrkt-comment" rows="2" placeholder="About deal..."></textarea>
                      </div>
                      <div className="uk-grid-small uk-margin-bottom" data-uk-grid>
                        <div className="uk-width-1-2@s">
                            <span className="uk-text-middle">Upload document for verification *</span>
                            <div uk-form-custom>
                                <input type="file" required/>
                            </div>
                        </div>
                        <div className="uk-width-1-4@s"  uk-tooltip="title: Deal Timeline; pos: top">
                            <input className="uk-input mrkt-comment" type="text" placeholder="Enter Deal Timeline"/>
                            <span uk-icon="clock" className="uk-form-icon uk-form-icon-flip link"></span>
                        </div>
                      </div>
                      <div className="uk-margin uk-text-right">
                          <a className="uk-button uk-button-small dash-button">Post Deal</a>
                      </div>
                    </div>
                </div>
              {/*End Post Deal Class*/}

              {/*Begin Deals Class*/}
                  <div className="uk-card uk-card-loan uk-margin-bottom">
                      <div className="uk-card-header">
                          <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                              <div className="uk-width-auto">
                                  <img className="uk-border-circle" width="60" src={Aisha} />
                              </div>
                              <div className="uk-width-expand">
                                  <h4 className="uk-margin-remove theme-color">Nigerian Beweries Oil Supply <img width="15" src={Verified} /></h4>
                                  <p className="uk-text-meta uk-margin-small-top">Aisha Ahmed - TFP0123456789</p>
                              </div>
                              <div className="uk-text-center">
                                <img src={Arrow} width="20" alt=""/> 15% <br/>
                                <a onClick={this.toggleModal} className="uk-button uk-button-small button-out">Trust Deal</a>
                              </div>
                          </div>
                      </div>
                      <div className="uk-card-body">
                          <p>I have been given a contract to supply 20 barrels of oil to the Nigerian beweries on or before the 30th of september 2019 and I want to use this medium to raise enough funds so I can get this contrsct done and also have the interest shared by those willing to stake their trust for this deal.</p>
                      </div>
                      <div className="uk-card-footer uk-margin-remove">
                        <div className="uk-flex uk-flex-middle uk-flex-between">
                          <div>
                            <a><span uk-icon="heart" uk-tooltip="title: like deal; pos: bottom" className="link"></span></a> 
                            <a><span uk-icon="refresh" uk-tooltip="title: reshare deal; pos: bottom" className="link uk-margin-left"></span></a>
                          </div>
                          <div>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="tag" uk-tooltip="title: Current Status; pos: bottom" className="link"></span></span> 500000</span>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="star" uk-tooltip="title: Deal Target; pos: bottom" className="link"></span></span> 1000000</span>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="future" uk-tooltip="title: Deal Deadline; pos: bottom" className="link"></span></span> 5 Days</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin-remove-top uk-margin-left uk-margin-bottom uk-margin-right">
                        <ul data-uk-accordion="collapsible: true">
                          <li>
                            <a className="uk-accordion-title" href="#">
                              <span className="theme-color">Comments <i className="fa fa-comments" aria-hidden="true" /></span><br/>
                            </a>
                            <div className="uk-accordion-content">
                                <div className="uk-margin">
                                    <textarea className="uk-textarea uk-margin-bottom mrkt-comment" rows="2" placeholder="Write Something..."></textarea>
                                    <div className="uk-text-right">
                                      <a className="uk-button uk-button-xsmall button-out">Add Comment</a>
                                    </div>
                                </div>
                                <p>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> How sure are you that this deal will work out? </i><a className="mrkt-user">John Doe - <i>Aug. 30th 2019</i></a><br/>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> You can see that my deal is verified and besides, you can reach out to me directly through DM for doubts. </i><a className="mrkt-user">Aisha Ahmed - <i>Aug. 30th 2019</i></a><br/>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> I think this is a good deal, I'm in. </i><a className="mrkt-user">Smith John - <i>Aug. 30th 2019</i></a><br/>
                                </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                  </div>
                {/*End Deals Class*/}

                {/*Begin Deals Class*/}
                  <div className="uk-card uk-card-loan uk-margin-bottom">
                      <div className="uk-card-header">
                          <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                              <div className="uk-width-auto">
                                  <img className="uk-border-circle" width="60" src={Aisha} />
                              </div>
                              <div className="uk-width-expand">
                                  <h4 className="uk-margin-remove theme-color">Nigerian Beweries Oil Supply <img width="15" src={Verified} /></h4>
                                  <p className="uk-text-meta uk-margin-small-top">Aisha Ahmed - TFP0123456789</p>
                              </div>
                              <div className="uk-text-center">
                                <img src={Arrow} width="20" alt=""/> 15% <br/>
                                <a onClick={this.toggleModal} className="uk-button uk-button-small button-out">Trust Deal</a>
                              </div>
                          </div>
                      </div>
                      <div className="uk-card-body">
                          <p>I have been given a contract to supply 20 barrels of oil to the Nigerian beweries on or before the 30th of september 2019 and I want to use this medium to raise enough funds so I can get this contrsct done and also have the interest shared by those willing to stake their trust for this deal.</p>
                      </div>
                      <div className="uk-card-footer uk-margin-remove">
                        <div className="uk-flex uk-flex-middle uk-flex-between">
                          <div>
                            <a><span uk-icon="heart" uk-tooltip="title: like deal; pos: bottom" className="link"></span></a> 
                            <a><span uk-icon="refresh" uk-tooltip="title: reshare deal; pos: bottom" className="link uk-margin-left"></span></a>
                          </div>
                          <div>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="tag" uk-tooltip="title: Current Status; pos: bottom" className="link"></span></span> 500000</span>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="star" uk-tooltip="title: Deal Target; pos: bottom" className="link"></span></span> 1000000</span>
                            <span className="uk-margin-right"><span className="theme-color"><span uk-icon="future" uk-tooltip="title: Deal Deadline; pos: bottom" className="link"></span></span> 5 Days</span>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin-remove-top uk-margin-left uk-margin-bottom uk-margin-right">
                        <ul data-uk-accordion="collapsible: true">
                          <li>
                            <a className="uk-accordion-title" href="#">
                              <span className="theme-color">Comments <i className="fa fa-comments" aria-hidden="true" /></span><br/>
                            </a>
                            <div className="uk-accordion-content">
                                <div className="uk-margin">
                                    <textarea className="uk-textarea uk-margin-bottom mrkt-comment" rows="2" placeholder="Write Something..."></textarea>
                                    <div className="uk-text-right">
                                      <a className="uk-button uk-button-xsmall button-out">Add Comment</a>
                                    </div>
                                </div>
                                <p>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> How sure are you that this deal will work out? </i><a className="mrkt-user">John Doe - <i>Aug. 30th 2019</i></a><br/>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> You can see that my deal is verified and besides, you can reach out to me directly through DM for doubts. </i><a className="mrkt-user">Aisha Ahmed - <i>Aug. 30th 2019</i></a><br/>
                                  <i><i className="fa fa-comment" aria-hidden="true" /> I think this is a good deal, I'm in. </i><a className="mrkt-user">Smith John - <i>Aug. 30th 2019</i></a><br/>
                                </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                  </div>
                {/*End Deals Class*/}

            </div>

            <MarketSidebar {...this.props} />
          </div>
        </div>
      </section>
    </div>
    );
  }
}

export default TrustMarket;
