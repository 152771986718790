import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import CONSTANTS from '../common/helpers/Constants';
import { capitalizeFirstLetter, formatNumber } from '../common/helpers/Utils';

const Contribution = props => {
  return (
    <tbody>
      {props.type === CONSTANTS.TRANSACTION_TYPE.CONTRIBUTION &&
      props.data.length > 0
        ? props.data.map((con, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{moment(con.trans_date).format('MM/DD/YYYY')}</td>
              <td>{formatNumber(con.trans_amount)}</td>
              <td>{capitalizeFirstLetter(con.status)}</td>
            </tr>
          ))
        : props.type === CONSTANTS.TRANSACTION_TYPE.LOAN &&
          props.data.length > 0
        ? props.data.map((loan, index) => (
            <tr key={index}>
              <td>{moment(loan.trans_date).format('MM/DD/YYYY')}</td>
              <td>{formatNumber(loan.trans_amount)}</td>
              <td>{capitalizeFirstLetter(loan.status)}</td>
              <td
                className="theme-color"
                style={{ cursor: 'pointer' }}
                // onClick={() => props.history.push('/viewloan/' + loan.trans_id)}
                onClick={() =>
                  props.history.push({
                    pathname: '/viewloan',
                    state: {
                      loanId: loan.trans_id
                    }
                  })
                }
              >
                View
              </td>
            </tr>
          ))
        : props.type === CONSTANTS.TRANSACTION_TYPE.EARNINGS &&
          props.data.length > 0
        ? props.data.map((loan, index) => (
            <tr key={index}>
              <td>{moment(loan.trans_date).format('MM/DD/YYYY')}</td>
              <td>{formatNumber(loan.trans_amount)}</td>
              <td>{capitalizeFirstLetter(loan.status)}</td>
            </tr>
          ))
        : props.type === CONSTANTS.TRANSACTION_TYPE.POP && props.data.length > 0
        ? props.data.map((pop, index) => (
            <tr key={index}>
              <td>{moment(pop.trans_date).format('MM/DD/YYYY')}</td>
              <td>{pop.pop_item_name}</td>
              <td>{formatNumber(pop.trans_amount)}</td>
              <td>{capitalizeFirstLetter(pop.status)}</td>
            </tr>
          ))
        : props.type === CONSTANTS.TRANSACTION_TYPE.ETC && props.data.length > 0
        ? props.data.map((etc, index) => (
            <tr key={index}>
              <td>{moment(etc.trans_date).format('MM/DD/YYYY')}</td>
              <td>{moment(etc.etc_from).format('MM/DD/YYYY')}</td>
              <td>
                {etc.etc_type === 'One Way'
                  ? 'N/A'
                  : moment(etc.etc_to).format('MM/DD/YYYY')}
              </td>
              <td>{capitalizeFirstLetter(etc.etc_type)}</td>
              <td>{capitalizeFirstLetter(etc.status)}</td>
            </tr>
          ))
        : null}
    </tbody>
  );
};

export default withRouter(Contribution);
