import React, { Component } from "react";

import { Field, reduxForm } from "redux-form";
import trustFundApiService from "../../../common/api/ApiService";
import RenderField from "../../../authorization/RenderField";
import Validation from "../../../common/helpers/Validation";

import PhoneInput from "../../../common/helpers/PhoneInput";
import { ContactContainer, Content } from "./Styles";

class ContactUsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (formValues) => {
    if (formValues) {
      // let _this = this;
      let data = {
        c_name: formValues.contactName,
        c_number: formValues.contactNumber,
        c_email: formValues.contactEmail,
        c_message: formValues.contactMessage,
      };
      trustFundApiService("contactUs", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.props.initialize({
              contactName: "",
              contactNumber: "",
              contactEmail: "",
              contactMessage: "",
            });
            this.setState({ loader: true });
          } else {
            this.setState({ loader: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <>
        <ContactContainer>
          <Content>
            For more information and Enquiries, Email us at:
            <b> contact@etrustfund.org</b> or tap the <b>whatsapp</b> icon
          </Content>
        </ContactContainer>
      </>

      // <div className="container container-main">
      //   <div className="uk-grid" uk-grid>
      //     <div className="uk-width-1-2 contact-mobile">
      //       <div className="message-margin">
      //         <h2 className="app-note uk-margin-large-left">
      //           For more information, Questions or Enquiries Email us at:
      //           support@etrustfund.org or tap the whatsapp icon for quick
      //           response
      //         </h2>
      //         {/* <p className="lead-margin uk-margin-large-left">
      //           Contact us now for more information, questions, etc.
      //         </p> */}
      //       </div>
      //     </div>
      //     <div className="uk-width-1-2 contact-mobile">
      //       <div className="container animated">
      //         <div className="grid between-md">
      //           <div className="col card message-card">
      //             <h5 className="uk-text-bold">Say Something</h5>
      //             <form
      //               className="contact-form"
      //               onSubmit={handleSubmit(this.handleSubmit)}
      //             >
      //               <Field
      //                 component={RenderField}
      //                 className="form-input"
      //                 placeholder="Name"
      //                 name="contactName"
      //                 id="reg-firstname"
      //                 type="text"
      //                 autoComplete="off"
      //                 maxLength="50"
      //                 validate={[
      //                   Validation.firstNameRequired,
      //                   Validation.fullNameAlphabetOnly,
      //                 ]}
      //               />
      //               <Field
      //                 component={RenderField}
      //                 className="form-input"
      //                 placeholder="Email"
      //                 name="contactEmail"
      //                 id="reg-email"
      //                 type="text"
      //                 autoComplete="useoffrname"
      //                 validate={[Validation.emailRequired, Validation.isEmail]}
      //               />
      //               <Field
      //                 component={RenderField}
      //                 className="form-input"
      //                 placeholder="Telephone"
      //                 name="contactNumber"
      //                 autoComplete="off"
      //                 maxLength="15"
      //                 validate={[
      //                   Validation.phoneNumberRequired,
      //                   Validation.phoneNumberLength,
      //                 ]}
      //                 normalize={Validation.normalizeNumber}
      //               />
      //               <Field
      //                 component={RenderField}
      //                 className="form-area"
      //                 placeholder="Your Message"
      //                 name="contactMessage"
      //                 id="message"
      //                 type="text"
      //                 autoComplete="off"
      //                 validate={[Validation.messageRequired]}
      //               />
      //               <button
      //                 href=""
      //                 className="button button--large btn-form"
      //                 type="submit"
      //               >
      //                 Send Message
      //               </button>
      //             </form>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default reduxForm({
  form: "contactUsForm",
  touchOnBlur: true,
  destroyOnUnmount: false,
})(ContactUsHome);
