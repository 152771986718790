import React, { Component } from 'react';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';

import '../assets/css/main.css';
import { getLocalStorage } from '../common/helpers/Utils';
import { userInfo } from 'os';

class Success extends Component {
  redirectDashboard = () => {
    setTimeout(
      function() {
        this.props.history.push('/dashboard');
      }.bind(this),
      500
    );
  };
  render() {
    console.log(this.props.userData);
    console.log(getLocalStorage('userInfo'));
    const u_first_name = this.props.userData.u_first_name || '';
    return (
      <section id="bg">
        <Confetti width="1800px;" height="900px;" />

        <div id="success">
          <div
            className="uk-grid-collapse uk-flex-center uk-flex-middle uk-height-viewport"
            data-uk-grid
          >
            <div className="message-smiley uk-container uk-margin-auto uk-grid-collapse wrap@s uk-flex-middle uk-flex">
              <div className="rotateInLeft uk-position-z-index smiley happy-state">
                <div className="smiley-eye smiley-left-eye" />
                <div className="smiley-eye smiley-right-eye" />
              </div>
              <div className="text-cont uk-width-4-5@m uk-text-center@s uk-text-center uk-text-left@m uk-animation-slide-left delaypnt5s uk-padding-medium">
                <h2 className="uk-text-huge uk-margin-small-bottom">
                  Congratulations {u_first_name},
                </h2>
                <h4 className="uk-margin-remove-top ">
                  You have successfully created your Entrepreneurs Trust Fund account,
                  your dashboard is being prepared.
                  <a
                    className="uk-margin-left uk-button prog-button"
                    onClick={this.redirectDashboard}
                  >
                    <span className="uk-position-relative uk-position-z-index">
                      Go to Dashboard
                    </span>{' '}
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps)(Success);
