import React, { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import Modal from "react-modal";
import moment from "moment";

import Header from "./common/Header";
import LeftSidebar from "./common/LeftSidebar";
import RightSidebar from "./common/RightSidebar";
import Notification from "./common/Notification";
import trustFundApiService from "./common/api/ApiService";
import Avatar from "./assets/img/ccicon.png";
import CONSTANTS from "./common/helpers/Constants";
import SocketContext from "./routes/SocketContext";
import { getLocalStorage } from "./common/helpers/Utils";

var typing = false;
var timeout = undefined;
var PCWidget = window.PCWidget;

class Chat extends Component {
  socket = {};
  static contextType = SocketContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      onlineExpertId: "",
      expertIds: [],
      message: "",
      allMessages: [],
      role_id: "",
      history: [],
      initial: true,
    };

    console.log('this.context', this.context);
    //this.socket = io.connect('https://ayetrustfund.com:5000');
    // this.socket = CONSTANTS.SOCKET_CONNECT;

    // this.socket.on('connect', function() {
    //   console.log('inside connect socket');
    // });

    // this.socket.on('disconnect', function() {
    //   console.log('inside disconnect socket');
    // });
  }

  // Solved the scroll to bottom issue
  componentDidUpdate() {
    const objDiv = document.getElementById("scroller");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentDidMount() {
    const { location } = this.props;
    let expertType = "";
    let expertTitle = "";
    if (location && location.state) {
      expertType = location.state.type;
      expertTitle = location.state.expertTitle;
      console.log(this.getOnlineExperts);

      this.getOnlineExperts(expertType, expertTitle);
    }

    //   const script = document.createElement('script');

    //   script.async = false;
    //   window.purechatApi = {
    //     l: [],
    //     t: [],
    //     on: function() {
    //       this.l.push(arguments);
    //     }
    //   };
    //   (function() {
    //     var done = false;
    //     var script = document.createElement('script');
    //     script.async = true;
    //     script.type = 'text/javascript';
    //     script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript';
    //     document
    //       .getElementsByTagName('HEAD')
    //       .item(0)
    //       .appendChild(script);
    //     script.onreadystatechange = script.onload = function(e) {
    //       if (
    //         !done &&
    //         (!this.readyState ||
    //           this.readyState === 'loaded' ||
    //           this.readyState === 'complete')
    //       ) {
    //         var w = new PCWidget({
    //           c: 'efc6d029-98e1-4aa0-9105-efddea61fb0a',
    //           f: true
    //         });
    //         done = true;
    //       }
    //     };
    //   })();
    //   document.body.appendChild(script);

    document.title = "Chat - If You TRUST Me I'll TRUST You";
    let _this = this;

    this.context.on("send_message", function (data) {
      console.log(data);
      if (
        (data &&
          parseInt(data.receiver) === parseInt(_this.props.userData.u_id)) ||
        (data.firstMessage == true &&
          data.receiver.findIndex(
            (todo) => todo == parseInt(_this.props.userData.u_id)
          ) !== -1)
      ) {
        _this.updateChat(data);
        // let messageData = {
        //   to_id: parseInt(data.receiver),
        //   role_id: data.roleid,
        //   message: data.message,
        // };
       
        console.log(data);
        
        // trustFundApiService("saveChatMessage", messageData);

        
      }

      if (data && data.sendexpertId) {
        _this.setState({
          onlineExpertId: data.sendexpertId,
        });
      }

      if (data.firstMessage === false) {
        setTimeout(function () {
          _this.getOnlineExperts(expertType, expertTitle);
        }, 2000);
      } else {
        _this.getOnlineExperts(expertType, expertTitle);
      }
    });

    // const { location } = this.props;
    // if (location && location.state) {
    //   let expertType = location.state.type;
    //   let expertTitle = location.state.expertTitle;
    //   this.getOnlineExperts(expertType, expertTitle);
    // }
  }

  componentWillReceiveProps(res) {
    const { location } = res;
    console.log(res);
    if (location && location.state) {
      let expertType = location.state.type;
      let expertTitle = location.state.expertTitle;
      this.getOnlineExperts(expertType, expertTitle);
    }
  }

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  getOnlineExperts = (expertType, expertTitle) => {
    let expertIds = [];
    let data = {
      chat_expert: expertType,
    };

    if (expertType != "") {
      trustFundApiService("getOnlineExperts", data)
        .then((response) => {
          if (response.data.status === "success") {
            let data = response.data.data;

            if (data.length > 0) {
              let role_id = data[0].u_role;
              data.map((expert, index) => {
                expertIds.push(expert.u_id);
              });
              this.setState(
                {
                  expertIds,
                  role_id: role_id,
                  // allMessages: [],
                  title: `Chat with ${expertTitle}`,
                },
                () => {
                  if (this.state.initial == true) {
                    this.getChatHistory(role_id);
                  }
                  console.log("caught");
                }
              );
            }
          } else {
            this.setState(
              {
                allMessages: [],
                title: `Chat with ${expertTitle}`,
                message1: response.data.message,
              },
              () => {
                this.toggleModal();
                console.log("caught");
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getChatHistory = (role_id) => {
    trustFundApiService("getChatHistory", {
      role_id,
    })
      .then((response) => {
        if (response.data.status === "success") {
          this.setState({
            initial: false,
          });
          let resultArray = response.data.data;
          console.log("resultArray", resultArray);

          for (var i in resultArray) {
            let messageObject = {
              sender: parseInt(resultArray[i].cb_fromid),
              receiver: resultArray[i].cb_toid,
              message: resultArray[i].cb_msg,
              datetime: resultArray[i].cb_datetime,
            };
            this.updateChat(messageObject);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  userTyping = () => {
    typing = true;
    this.context.emit("typing", {
      message: "typing...",
      username: this.props.userData.u_first_name,
    });
    clearTimeout(timeout);
    timeout = setTimeout(this.timeoutFunction, 2000);
  };

  timeoutFunction = () => {
    typing = false;
    this.context.emit("typing", { message: "", username: "" });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addMessage = (e) => {
    if (e.keyCode === 13 && e.shiftKey == false) {
      this.handleSendMessage();
      this.setState({
        message: "",
      });
    }
  };

  handleSendMessage = () => {
    let message = this.state.message ? this.state.message.trim() : "";
    if (message) {
      let senderId = this.props.userData.u_id;
      let expertIds =
        this.state.expertIds.length > 0 ? this.state.expertIds : [];

      let receiverId = "";
      let firstMessage = false;

      if (this.state.onlineExpertId !== "") {
        firstMessage = false;
        receiverId = this.state.onlineExpertId;
      } else {
        firstMessage = true;
        receiverId = expertIds;
      }

      let data = {
        sender: parseInt(senderId),
        receiver: receiverId.length > 0 ? receiverId : parseInt(receiverId),
        message: message,
        firstMessage: firstMessage,
        datetime: new Date(),
        name: this.props.userData.u_first_name,
      };
      console.log('firstMessage' , firstMessage === false);

      if (firstMessage === false) {
        let messageData = {
          to_id: parseInt(receiverId),
          role_id: this.state.role_id,
          msg: message,
        };
        trustFundApiService("saveChatMessage", messageData);
      }
      this.context.emit("send_message", data);
      // setTimeout(() => {
      this.updateChat(data);
      // }, 100);
      this.setState({ message: "" });
    }
  };

  updateChat(data) {
    let allMessages = this.state.allMessages;
    console.log(allMessages);

    allMessages.push(data);
    this.setState({ allMessages }, () => {
      console.log(this.state.allMessages);
    });
  }

  render() {
    const { allMessages } = this.state;

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.title}
                  </h3>
                  <p>{this.state.message1} </p>

                  <a
                    onClick={this.toggleModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Cancel
                  </a>
                </div>
              </Modal>
              <div
                id="left"
                className="uk-width-3-4@m uk-child-width-1-1@m uk-child-width-1-3@s uk-margin-remove"
                data-uk-grid
              >
                <div className="uk-padding-remove-left uk-margin-remove-top single uk-width-1-1@m uk-width-1-2@s">
                  <div className="uk-text-center  uk-margin">
                    <h2 className="uk-text-large-theme theme-color uk-text-center">
                      {this.state.title}
                    </h2>
                  </div>

                  <div className="chat-area dash-chat-margin" id="scroller">
                    <div className="">
                      {/* Sender div */}
                      {allMessages && allMessages.length > 0
                        ? allMessages.map((message, i) =>
                            message.sender === this.props.userData.u_id ? (
                              <div
                                className="me data-uk-grid-small uk-flex-bottom uk-flex-right uk-text-right"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small theme-bg uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {moment(message.datetime).format("HH:mm")}
                                  </p>
                                </div>

                                <div className="chat-img uk-width-auto">
                                  <img
                                    className="userimg uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={
                                      this.props.userData.u_image
                                        ? this.props.userData.u_image
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="guest data-uk-grid-small uk-flex-bottom uk-flex-left"
                                data-uk-grid
                                key={i}
                              >
                                <div className="uk-width-auto">
                                  <img
                                    className="uk-border-circle"
                                    width={32}
                                    height={32}
                                    src={Avatar}
                                  />
                                </div>
                                <div className="uk-width-auto">
                                  <div className="chat-msg uk-card uk-card-body uk-card-small uk-card-default uk-border-rounded">
                                    <p className="uk-margin-remove chat-text">
                                      {message.message}
                                    </p>
                                  </div>
                                  <p className="chat-time">
                                    {" "}
                                    {moment(message.datetime).format("HH:mm")}
                                  </p>
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>

                  <div className="chat-input uk-card-footer uk-padding-remove">
                    <div
                      className="data-uk-grid-small uk-flex-middle"
                      data-uk-grid
                    >
                      <div className="chat-type-width">
                        <div className="uk-padding-remove-horizontal">
                          <textarea
                            className="uk-textarea uk-padding-remove uk-border-remove"
                            rows={1}
                            placeholder="Type Here..."
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={this.addMessage}
                            onKeyUp={this.userTyping.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="uk-width-auto">
                        <a
                          onClick={this.handleSendMessage}
                          className="uk-button dash-button"
                        >
                          Send
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, null)(Chat);
