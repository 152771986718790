import React, { Component } from 'react';
import { Field, reduxForm, change, untouch } from 'redux-form';
import moment from 'moment';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

import DynaLogo from './assets/img/aye-loader.gif';
import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import Validation from './common/helpers/Validation';
import trustFundApiService from './common/api/ApiService';
import RenderField from './authorization/RenderField';
import { showErrorToast } from './common/helpers/Toastr';
import renderDatePicker from './common/helpers/Datepicker';
import SelectRenderField from './authorization/SelectRenderField';
import DateRenderField from './authorization/DatePickerRenderField';

class Etc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      startDate: '',
      endDate: '',
      endDateShow: false,
      cityList: []
    };
  }

  componentDidMount() {
    document.title = "Emergency Travel Cover - If You TRUST Me I'll TRUST You";
    this.getCities();
  }

  getCities = () => {
    trustFundApiService('ETCCityList')
      .then(response => {
        this.setState({ cityList: response.data.data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubmit = formValues => {
    let startDate = moment(this.state.startDate).format('MM/DD/YYYY');
    let endDate = moment(this.state.endDate).format('MM/DD/YYYY');
    let repaymentDate = moment(this.state.repaymentDate).format('MM/DD/YYYY');

    if (formValues.cityFrom !== formValues.cityTo) {
      if (formValues) {
        this.setState({
          loader: true
        });
        let data = {
          ec_from: formValues.cityFrom,
          ec_to: formValues.cityTo,
          ec_type: formValues.typeOfCover,
          ec_amount: formValues.amount,
          ec_reason: formValues.reason,
          ec_from_travel_date: startDate,
          ec_to_travel_date: endDate,
          ec_repayment_date: repaymentDate
        };

        trustFundApiService('emergencyTravelCover', data)
          .then(response => {
            if (response.data.status === 'success') {
              this.setState({
                loader: false
              });
              this.props.initialize({
                cityFrom: '',
                cityTo: '',
                typeOfCover: '',
                amount: '',
                reason: '',
                startDate: '',
                endDate: '',
                repaymentDate: ''
              });
              setTimeout(
                function() {
                  this.props.history.push('/dashboard');
                }.bind(this),
                3000
              );
            } else {
              this.setState({
                loader: false
              });
            }
          })
          .catch(err => {
            this.setState({
              loader: false
            });
            console.log(err);
          });
      }
    } else {
      showErrorToast("Two cities can't be same");
    }
  };

  handleChangeStart = startDate => {
    this.handleDateChange({ startDate });
  };

  handleChangeEndDate = endDate => {
    this.handleDateChange({ endDate });
  };

  handleDateChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (startDate && endDate) {
      if (startDate.valueOf() > endDate.valueOf()) {
        endDate = startDate;
      }
    }
    this.props.dispatch(change('etcForm', 'startDate', startDate));
    this.props.dispatch(change('etcForm', 'endDate', endDate));
    this.props.dispatch(untouch('etcForm', 'startDate'));
    this.props.dispatch(untouch('etcForm', 'endDate'));
    this.setState({ startDate, endDate });
  };

  handleShowField = e => {
    if (e.target.value === 'Round Trip') {
      this.setState({ endDateShow: true });
    } else {
      this.props.dispatch(change('ETCForm', 'endDate', ''));
      this.props.dispatch(untouch('ETCForm', 'endDate'));
      this.setState({ endDateShow: false });
    }
  };
  render() {
    const { handleSubmit, submitting } = this.props;

    let cityShow = this.state.cityList;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              {this.state.loader ? (
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <img src={DynaLogo} alt="" />
                  </div>
                </div>
              ) : null}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <h2 className="uk-text-large-theme theme-color uk-text-center">
                    Emergency Travel Cover
                    <br />
                  </h2>
                  <br />
                  <form
                    className="block-datePicker"
                    onSubmit={handleSubmit(this.handleSubmit)}
                    name="etcForm"
                  >
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">
                          ETC Limit (max)
                        </label>
                        <input
                          className="uk-input"
                          name="amount"
                          type="text"
                          value={this.props.userData.u_pl_etc || ''}
                          readOnly
                        />
                      </div>
                    </div>
                    <div data-uk-grid>
                      <div class="uk-width-1-2">
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">From:</label>
                            <Field
                              name="cityFrom"
                              id="city-from"
                              component={SelectRenderField}
                              className="uk-select"
                              validate={[Validation.cityRequired]}
                            >
                              <option value="">Select a city</option>
                              {cityShow &&
                                cityShow.map((cityOption, index) => (
                                  <option
                                    value={cityOption.etc_city_name}
                                    key={index}
                                  >
                                    {cityOption.etc_city_name}
                                  </option>
                                ))}
                              ))}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div class="uk-width-1-2">
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label className="uk-form-label-dash">To:</label>

                            <Field
                              name="cityTo"
                              id="city-to"
                              component={SelectRenderField}
                              className="uk-select"
                              validate={[
                                Validation.cityRequired,
                                Validation.equalTo
                              ]}
                            >
                              <option value="">Select a city</option>
                              {cityShow &&
                                cityShow.map((cityOption, index) => (
                                  <option
                                    value={cityOption.etc_city_name}
                                    key={index}
                                  >
                                    {cityOption.etc_city_name}
                                  </option>
                                ))}
                              ))}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label-dash">
                        Type of Cover
                      </label>
                      <Field
                        name="typeOfCover"
                        id="type-cover"
                        component={SelectRenderField}
                        className="uk-select"
                        validate={[Validation.typeRequired]}
                        onChange={this.handleShowField}
                      >
                        <option value="">Select</option>
                        <option value="One Way">One Way</option>
                        <option value="Round Trip">Round Trip</option>
                        ))}
                      </Field>
                    </div>
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">
                          Reason for Travel
                        </label>

                        <Field
                          component={RenderField}
                          name="reason"
                          className="uk-textarea"
                          type="textarea"
                          autoComplete="off"
                          maxLength="300"
                          validate={[Validation.reasonRequired]}
                        />
                      </div>
                    </div>
                    <div className="uk-margin uk-form-controls">
                      <label className="uk-form-label-dash" htmlFor="reg-name">
                        Repayment Date
                      </label>

                      <Field
                        name="repaydate"
                        component={renderDatePicker}
                        className="uk-input"
                        readOnly
                        validate={[Validation.repaydateReuired]}
                        normalize={value =>
                          value ? moment(value).format('MM/DD/YYYY') : null
                        }
                      />
                    </div>
                    <div data-uk-grid>
                      <div class="uk-width-1-2">
                        <div className="uk-margin">
                          <div className="uk-form-controls">
                            <label
                              className="uk-form-label-dash"
                              htmlFor="reg-name"
                            >
                              Travel Date
                            </label>

                            <Field
                              name="startDate"
                              component={DateRenderField}
                              type="text"
                              selected={this.state.startDate}
                              onChange={this.handleChangeStart}
                              minDate={new Date()}
                              endDate={this.state.endDate}
                              startDate={this.state.startDate}
                              validate={[
                                Validation.dateReuired
                                // Validation.lessThanStartDate
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="uk-width-1-2">
                        {this.state.endDateShow ? (
                          <div className="uk-margin">
                            <div className="uk-form-controls">
                              <label
                                className="uk-form-label-dash"
                                htmlFor="reg-name"
                              >
                                Return Date
                              </label>

                              <Field
                                name="endDate"
                                component={DateRenderField}
                                type="text"
                                minDate={
                                  this.state.startDate
                                    ? this.state.startDate
                                    : new Date()
                                }
                                selected={this.state.endDate}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                validate={[
                                  Validation.dateReuired
                                  //Validation.lessThanEndDate
                                ]}
                              />
                            </div>{' '}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="uk-text-center uk-margin-bottom uk-margin-large-top">
                      <button
                        className="uk-button dash-button"
                        type="submit"
                        disabled={submitting}
                      >
                        Apply for E.T.C
                      </button>
                    </div>

                    <div className="uk-text-center">
                      <Link to="/dashboard" className="uk-text-lead link">
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
              </div>

              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

Etc = connect(mapStateToProps, null)(Etc);

export default reduxForm({
  form: 'etcForm',
  touchOnBlur: true,
  destroyOnUnmount: true
})(Etc);
