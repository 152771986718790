import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import LeftSidebar from "./common/LeftSidebar";
import RightSidebar from "./common/RightSidebar";
import Notification from "./common/Notification";
import Modal from "react-modal";
import trustFundApiService from "./common/api/ApiService";
import DynaLogo from "./assets/img/aye-loader.gif";
import PlanBlk from "./assets/img/plan-blk.png";
import Close from "./assets/img/close.png";
import Smiley from "./assets/img/emoticons/smiley.png";
import Verified from "./assets/img/verified.png";
import { showErrorToast } from "./common/helpers/Toastr";
import {
  actionGetPlans,
  actionLogout,
  actionBuyPlan,
} from "./common/redux/actions/";

import {
  formatNumber,
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./common/helpers/Utils";

class TransferEarnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      counter: 0,
      wc: 1000,
      loader: false,
      earnings: "",
    };
  }
  componentWillMount() {
    Modal.setAppElement("body");
  }
  // componentDidMount() {
  //   console.log('fhhhhhhhhg');

  //   this.currentPlanInfo();
  // }

  currentPlanInfo = () => {
    console.log("hello");

    trustFundApiService("profileCompleteness")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.setState(
            {
              tfp_pin_status: data.u_tfp_pin_status,
              loan_pop_etc_status: data.loan_pop_etc_status,
              loan_pop_etc_rating: data.rating.loan_pop_etc_rating,
              pl_name: data.plan_info.pl_name,
              pl_id: data.plan_info.pl_id,
              pl_image: data.plan_info.pl_image,
              cashoutStatus: data.cashout_status,
              trustLink: response.data.data.u_trust_link,
              earnings: data.earnings,
              earnings_number: data.earning_number,
            },
            () => {
              console.log(this.state.pl_name);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  contributionModal = () => {
    this.setState({
      isContribute: !this.state.isContribute,
    });
  };

  toggleModal1 = (name) => {
    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
    });

    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen1: !this.state.isChosen1,
    });
  };
  toggleModal2 = (name) => {
    console.log(name);

    let selectedPlanId;
    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        isConfirm: false,
        isChosen2: !this.state.isChosen2,
      },
      () => {
        console.log(this.state.selectedPlanId);
      }
    );
  };
  toggleModal3 = (name) => {
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState({
      isConfirm: false,
      selectedPlanId,
      isChosen3: !this.state.isChosen3,
    });
  };

  toggleModal5 = (name) => {
    console.log(name);
    console.log(this.state.planData);
    let selectedPlanId;

    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        selectedPlanId = plan.pl_plan_id;
      }
      console.log(selectedPlanId);
    });
    this.setState(
      {
        selectedPlanId,
        con_amount: "",

        isChosen5: !this.state.isChosen5,
        isConfirm: false,
      },
      () => console.log(this.state.selectedPlanId)
    );
  };

  getPlans = () => {
    this.props
      .actionGetPlans()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          console.log(data);

          this.setState(
            {
              planData: data.map((plan) => {
                return {
                  pl_plan_id: plan.pl_plan_id,
                  pl_plan_name: plan.pl_plan_name,
                };
              }),
            },
            () => console.log(this.state.planData)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  selectPlan = (type, val) => {
    setLocalStorage("planId", this.state.selectedPlanId);
    let data;
    if (type == "subscribeFlexiPlan") {
      data = {
        flexi_type: val,
        con_amount: this.state.con_amount,
        pf_pl_text: this.state.pf_pl_text,
        planid: this.state.selectedPlanId,
        page_response: "1",
      };
    } else {
      data = {
        planid: this.state.selectedPlanId.toString(),
        page_response: "1",
      };
    }
    if (this.state.selectedPlanId) {
      console.log(data);
      if (val !== 1) {
        this.setState({
          loader: true,
        });
      }
      trustFundApiService(type, data)
        .then((response) => {
          if (response.data.status === "success") {
            let result = response.data.data;
            console.log(result);

            if (val == 1) {
              console.log(response);
              this.setState({
                membership_fee: response.data.data.membership_fee,
                loader: false,
              });
            } else {
              if (result.authurl) {
                let authURL = result.authurl;
                window.location.href = authURL;
              }
            }
          } else {
            this.setState({
              loader: false,
              con_amount: Math.floor(this.state.con_amount / 10),
            });
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    }
  };

  getFlexiFrequencies() {
    this.setState({
      loader: true,
    });
    trustFundApiService("getFlexiFrequencies").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          loader: false,
          flexiFrequencies: response.data.data,
          pf_pl_text: response.data.data[0].pf_pl_text,
        });
      } else {
        this.setState({
          loader: false,
        });
      }
    });
  }
  handleFlexiChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);

    var isnum =
      e.target.name == "con_amount" ? /^\d+$/.test(e.target.value) : true;

    this.setState(
      {
        [e.target.name]:
          e.target.name == "con_amount"
            ? e.target.value.replace(/[^0-9]/g, "")
            : e.target.value,
      },
      () => {
        if (isnum && this.state.con_amount > 199) {
          this.selectPlan("subscribeFlexiPlan", 1);
        }
      }
    );
  };

  planModal = () => {
    this.setState({
      buyPlan: !this.state.buyPlan,
    });
  };

  bankTransfer = (planId) => {
    setLocalStorage("planId", planId);
    this.props.history.push("/transfer");
  };

  startPlan = (name) => {
    let data = {
      planid: "",
      page_response: 1,
    };
    this.state.planData.map((plan) => {
      console.log(plan.pl_plan_name);
      if (name == plan.pl_plan_name) {
        data.planid = plan.pl_plan_id;
      }
    });
    this.setState({
      buyPlan: false,
      loader: true,
    });

    trustFundApiService("usernonftmplanpaymentbyearning", data)
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.currentPlanInfo();
          this.setState({
            loader: false,
          });
        }
        else {
          this.setState({
            loader: false,    
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
      });
  };

  startContribution = (name) => {
    this.setState({
      loader: true,
      isContribute:false
    });
    console.log('startContribution');
    

    trustFundApiService("usermakeupfrontcontributionbyearning")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          this.currentPlanInfo();
          this.setState({
            loader: false,    
          });
        } else {
          this.setState({
            loader: false,    
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
      });
  };

  getContribution = () => {
    trustFundApiService("getContribution")
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.data;
          let wc = response.data.data.base_unit;
          this.setState({
            wc,
            earnings: data.earnings,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  redeemEarnings = () => {
    let totalEarnings = this.state.earnings;
    let data = {
      redeem_amount: totalEarnings,
    };
    if (parseInt(totalEarnings) >= 5000) {
      this.setState({
        loader: true,
      });
      trustFundApiService("redeemEarningsDB", data)
        .then((response) => {
          if (response.data.status === "success") {
            this.getContribution();
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          } else {
            this.setState(
              {
                loader: false,
                message: response.data.message,
                title: "Redeem Earnings",
              },
              () => this.toggleModal()
            );
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log(err);
        });
    } else {
      this.setState(
        {
          loader: false,
          message:
            "You can only redeem your earnings if you have N5000 or above",
          title: "Redeem Earnings",
        },
        () => this.toggleModal()
      );
    }
  };

  incrementWeek = () => {
    this.setState({
      counter: this.state.counter + 1,
    });
  };

  decrementWeek = () => {
    if (this.state.counter >= 1) {
      this.setState({
        counter: this.state.counter - 1,
      });
    }
  };

  // makePayment = () => {
  //   let con_week = this.state.counter;
  //   let con_amount = this.state.wc * con_week;
  //   let con_currency = "";

  //   let data = {
  //     con_week,
  //     con_amount,
  //     con_currency,
  //   };

  //   if (con_week > 0 && con_amount > 0) {
  //     this.setState({
  //       loader: true,
  //       isActive: false,
  //     });
  //     trustFundApiService("makeContribution", data)
  //       .then((response) => {
  //         if (response.data.status === "success") {
  //           this.setState({
  //             counter: 0,
  //             isActive: false,
  //             loader: false,
  //           });
  //           setTimeout(
  //             function () {
  //               this.props.history.push("/dashboard");
  //             }.bind(this),
  //             3000
  //           );
  //         } else {
  //           this.setState({
  //             loader: false,
  //             isActive: false,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           loader: false,
  //         });
  //         console.log(err);
  //       });
  //   } else {
  //     showErrorToast("Contribution amount should be greater than zero");
  //   }
  // };

  componentDidMount() {
    document.title = "Weekly Earnings - If You TRUST Me I'll TRUST You";
    // this.getContribution();
    this.currentPlanInfo();
    this.getFlexiFrequencies();
    this.getPlans();
  }

  render() {
    const cur_symbol = this.props.userData.cur_symbol || "";
    const cur_name = this.props.userData.cur_name || "";
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />
          {this.state.loader ? (
            <div className="spinner-wrapper">
              <div className="spinner">
                <img src={DynaLogo} alt="" />
              </div>
            </div>
          ) : null}

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isContribute}
                onRequestClose={this.contributionModal}
              >
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    Confirm Earnings Transfer
                  </h3>
                  <p>
                    Are you sure you want to convert your earnings to
                    contributions?
                  </p>
                  <div className="uk-margin-bottom">
                    <a
                      className="uk-button dash-button"
                      onClick={this.state.loader ? "" : this.startContribution}
                    >
                      Yes, I want to!
                    </a>
                  </div>
                  <a
                    onClick={this.contributionModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    No, Cancel
                  </a>
                </div>
              </Modal>
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.isActive}
                onRequestClose={this.toggleModal}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <img src={Smiley} width={100} alt="" />
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.title}
                  </h3>
                  <p>{this.state.message} </p>

                  <a
                    onClick={this.toggleModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    Close
                  </a>
                </div>
              </Modal>

              {/*Buy Plan*/}
              <Modal
                className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
                isOpen={this.state.buyPlan}
                onRequestClose={this.planModal}
              >
                {" "}
                <div className="uk-text-center uk-margin-top">
                  <h3 className="uk-text-lead theme-color">
                    {this.state.earnings >= 1000
                      ? "Available Plan based on Earnings"
                      : "Insufficient Earnings"}
                  </h3>

                  <div class="">
                    {this.state.earnings >= 30000 ? (
                      <p>
                        <img src={PlanBlk} width={100} alt="" />
                        <br />
                        <span className="theme-color">
                          PLATINUM
                          <br />
                        </span>
                        <Link
                          onClick={() => this.startPlan("PLATINUM")}
                          // REQUEST TARANJEET FOR DIRECT ENDPOINTS TO CONVERT EARNINGS TO PLAN AND LEAVE BALANCE
                          className="uk-button uk-button-small buy-button"
                        >
                          Start Plan
                        </Link>
                      </p>
                    ) : null}
                    {this.state.earnings >= 15000 ? (
                      <p>
                        <img src={PlanBlk} width={100} alt="" />
                        <br />
                        <span className="theme-color">
                          GOLD
                          <br />
                        </span>
                        <Link
                          onClick={() => this.startPlan("GOLD")}
                          // REQUEST TARANJEET FOR DIRECT ENDPOINTS TO CONVERT EARNINGS TO PLAN AND LEAVE BALANCE
                          className="uk-button uk-button-small buy-button"
                        >
                          Start Plan
                        </Link>
                      </p>
                    ) : null}
                    {this.state.earnings >= 3000 ? (
                      <p>
                        <img src={PlanBlk} width={100} alt="" />
                        <br />
                        <span className="theme-color">
                          SILVER
                          <br />
                        </span>
                        <Link
                          onClick={() => this.startPlan("SILVER")}
                          // REQUEST TARANJEET FOR DIRECT ENDPOINTS TO CONVERT EARNINGS TO PLAN AND LEAVE BALANCE
                          className="uk-button uk-button-small buy-button"
                        >
                          Start Plan
                        </Link>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="uk-text-center uk-margin-right uk-margin-left">
                  <a
                    onClick={this.planModal}
                    className="uk-position-relative link uk-position-z-index"
                  >
                    <img src={Close} className="close-icon" alt="close" />
                  </a>
                </div>
              </Modal>

              {/*Buy Plan*/}
              <div id="left" className="uk-width-3-4@m  dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  <div className="uk-width-2-1 uk-text-center">
                    <h2 className="uk-text-large-theme theme-color uk-text-center">
                      Weekly Earnings
                      <br />
                    </h2>
                    <br />
                    <br />
                    {/* COUNTER */}
                    <div id="week" className="uk-text-center up-text">
                      <span className="uk-text-center uk-heading-secondary theme-color uk-margin-right">
                        Number of Earnings:
                      </span>
                      {(this.state.earnings_number) || "0"}
                    </div>
                    <div id="week" className="uk-text-center up-text">
                      <span className="uk-text-center uk-heading-secondary theme-color uk-margin-right">
                        Total Earnings({cur_symbol}):
                      </span>
                      {formatNumber(this.state.earnings) || "0.00"}
                      {/* {this.state.earnings} */}
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="uk-text-center">
                      <p>
                        {" "}
                        {this.state.pl_id === 14 ? (
                          <p>
                            <Link
                              className="uk-button button-out"
                              type="button"
                              uk-tooltip="title: Send Cashout Request; pos: bottom"
                              // onClick={this.planModal}
                              onClick={
                                this.state.loader ? "" : this.redeemEarnings
                              }
                            >
                              Redeem Earnings
                            </Link>
                            <br />
                            <Link
                              className="uk-button dash-button uk-margin-top"
                              onClick={this.planModal}
                            >
                              Upgrade Plan with Earnings
                            </Link>
                          </p>
                        ) : (
                          <p>
                            <Link
                              className="uk-button button-out"
                              onClick={
                                this.state.loader ? "" : this.redeemEarnings
                              }
                              uk-tooltip="title: Send Redeem Earnings Request; pos: bottom"
                            >
                              Redeem Earnings{" "}
                            </Link>
                            <br />
                            <Link
                              className="uk-button dash-button uk-margin-top"
                              onClick={this.contributionModal}
                            >
                              Make Contributions
                            </Link>
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>

        {/* Toogle Modal */}

        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen5}
          onRequestClose={this.toggleModal5}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">
              Set Flexi Amount & Frequency
            </h3>

            <div data-uk-grid>
              <div class="uk-width-1-2">
                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <label className="uk-form-label-dash">
                      Contribution Amount (₦)
                    </label>
                    <input
                      className="uk-input"
                      name="con_amount"
                      type="text"
                      value={this.state.con_amount}
                      onChange={(e) => this.handleFlexiChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div className="uk-margin">
                  <div className="uk-form-controls">
                    <label className="uk-form-label-dash">
                      Select Frequency
                    </label>
                    <select
                      className="uk-select"
                      name="pf_pl_text"
                      type="text"
                      onChange={(e) => this.handleFlexiChange(e)}
                    >
                      {this.state.flexiFrequencies &&
                        this.state.flexiFrequencies.map((resp, index) => {
                          return (
                            <option
                              key={index}
                              value={resp.pf_pl_text}
                              id={resp.pf_id}
                            >
                              {resp.pf_pl_text}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <h4>
              Membership Fee: <span className="theme-color"></span>₦
              {this.state.membership_fee}
            </h4>

            <a
              onClick={this.selectPlan.bind(this, "subscribeFlexiPlan", 2)}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>Your Dashboard will be created in few minutes</i>
            </span>
            <br />
            <br />

            <a
              onClick={this.toggleModal5}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>
        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen1}
          onRequestClose={this.toggleModal1}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                1
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal1}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>
        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen2}
          onRequestClose={this.toggleModal2}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                2
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal2}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>

        <Modal
          className="uk-card uk-card-default uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn"
          isOpen={this.state.isChosen3}
          onRequestClose={this.toggleModal3}
        >
          <div className="uk-text-center uk-margin-right uk-margin-left">
            <h3 className="uk-text-lead theme-color">Select Payment Method</h3>
            <a
              onClick={this.selectPlan.bind(this, "paymentProcess1")}
              className="uk-button dash-button"
            >
              Pay with Card
            </a>
            <br />
            <span className="modal-text">
              <img width="10" src={Verified} />{" "}
              <i>
                To access Dashboard instantly and for seamless weekly
                contributions
              </i>
            </span>
            <br />
            <br />
            OR
            <br />
            <br />
            <a
              onClick={this.bankTransfer.bind(
                this,
                // plan.pl_plan_id
                3
              )}
              className="uk-button button-out"
            >
              Pay via Bank Transfer
            </a>
            <br />
            <span className="modal-text">
              <i>
                This method may take up to 24hrs to access dashboard
                <br /> and a proof of paymemt may be required.
              </i>
            </span>
            <br />
            <br />
            <a
              onClick={this.toggleModal3}
              className="uk-position-relative link uk-position-z-index theme-color"
            >
              Close
            </a>
          </div>
        </Modal>
        {/* End Toggle Modal */}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

export default connect(mapStateToProps, {
  actionLogout,
  actionGetPlans,
  actionBuyPlan,
})(TransferEarnings);
