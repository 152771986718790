import React from 'react';

export default ({
  input,
  label,
  type,
  className,
  autoComplete,
  maxLength,
  placeholder,
  readOnly,
  style,
  onPaste,
  onCopy,
  meta: { error, touched }
}) => (
  <React.Fragment>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      className={className}
      autoComplete={autoComplete}
      maxLength={maxLength}
      onPaste={onPaste}
      onCopy={onCopy}
      readOnly={readOnly}
      style={style}
    />
    {touched && (error && <span>{error}</span>)}
  </React.Fragment>
);
