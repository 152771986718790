import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/svglogo.svg";
import Sandwich from "../../assets/img/sandwich-white.png";

export default function TopBar({ isLoggedIn }) {
  return (
    <>
      <div id="topbar" className="uk-position-fixed uk-position-z-index">
        <nav
          className="uk-navbar-container uk-vis@m container-main"
          data-uk-navbar
          style={{ position: "relative", zIndex: 980 }}
        >
          <div className="uk-navbar-item etf-logo">
            <Link className="uk-logo" to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="uk-navbar-item">
            <ul
              className="uk-navbar-nav uk-vis@m"
              uk-scrollspy-nav="closest: li; scroll: true; cls: uk-active"
            >
              <li>
                <a href="#howitworks">How It Works</a>
              </li>
              <li>
                <a href="#trustplans">Trust Plans</a>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
              <li>
                <a href="#videos">Videos</a>
              </li>
              <li>
                <a href="#testimonies">Testimonies</a>
              </li>
            </ul>
          </div>
          <div className="uk-navbar-item uk-vis@m">
            <ul className="uk-navbar-nav">
              {isLoggedIn ? (
                <li>
                  <span>
                    <Link className="uk-button button-out" to="/login">
                      <span className="uk-position-relative uk-position-z-index">
                        Login
                      </span>
                    </Link>
                  </span>
                </li>
              ) : (
                <li>
                  <span>
                    <Link className="uk-button button-out" to="/login">
                      <span className="uk-position-relative uk-position-z-index">
                        Login
                      </span>
                    </Link>
                  </span>
                </li>
              )}
              <li>
                {isLoggedIn ? (
                  <span>
                    <a
                      className="uk-button button-out"
                      onClick={() => this.props.history.push("/dashboard")}
                    >
                      <span className="uk-position-relative uk-position-z-index">
                        Go To Dashboard
                      </span>
                    </a>
                  </span>
                ) : (
                  <span>
                    <Link to="/register">
                      <span className="uk-button prog-button">Register</span>
                    </Link>
                  </span>
                )}
              </li>
            </ul>
          </div>
        </nav>
        <nav>
          <div className="uk-hidden@l mobile-menu-bar uk-navbar-item">
            <a href="#">
              <img
                className="nav-toggle mobile-nav-icon"
                src={Sandwich}
                alt="Sandwich"
                data-uk-toggle="target: #offcanvas-overlay"
              />
            </a>
          </div>
          <div id="offcanvas-overlay" uk-offcanvas="overlay: true">
            <div className="uk-offcanvas-bar mobile-menu">
              <a className="uk-offcanvas-close" type="button" data-uk-close></a>
              <ul
                className="main-mobile-menu"
                uk-toggle="target: #offcanvas-overlay"
              >
                <li style={{ animationDelay: 0.5 }}>
                  <Link className="menu-txt-main" to="/login">
                    Login
                  </Link>
                  <a
                    className="uk-offcanvas-close"
                    type="button"
                    data-uk-close
                  ></a>
                </li>
                <li style={{ animationDelay: 0.7 }}>
                  <Link className="menu-txt-main" to="/register">
                    Register
                  </Link>
                  <a
                    className="uk-offcanvas-close"
                    type="button"
                    data-uk-close
                  ></a>
                </li>
              </ul>
              <ul className="small-menu" style={{ animationDelay: 1.05 }}>
                <li>
                  <a className="menu-txt" href="/terms">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a className="menu-txt" href="/privacy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a className="menu-txt" href="/learn-more">
                    Learn More
                  </a>
                </li>
                <li>
                  <a className="menu-txt" href="/">
                    Home
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
