import React, { Component } from 'react';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { formatNumber } from './common/helpers/Utils';
import DynaLogo from './assets/img/aye-loader.gif';

class LoanView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanTrustMembers: [],
      loanAmount: '',
      loanRepayDate: '',
      loanId: '',
      loader: false,
      loanStatus: 0
    };
  }

  componentDidMount() {
    document.title = "View Loan - If You TRUST Me I'll TRUST You";
    const { location } = this.props;
    if (location && location.state) {
      let loanId = location.state.loanId;
      this.getLoanDetail(loanId);
    }
  }

  getLoanDetail = loan_id => {
    let data = {
      loan_id
    };
    trustFundApiService('getLoanDetail', data)
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;

          this.setState({
            loanAmount: data.loan_detail.loan_req_amount,
            loanRepayDate: data.loan_detail.loan_req_repayment_date,
            loanTrustMembers: data.loan_trsut_members,
            loanStatus: data.loan_detail.loan_status
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  cancelLoan = () => {
    let data = {
      loan_action: 3
    };
    if (data) {
      this.setState({
        loader: true
      });
      trustFundApiService('refundOrCancelLoan', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.getContribution();
            this.setState({
              loader: false
            });
          } else {
            this.setState({
              loader: false
            });
          }
        })
        .catch(err => {
          this.setState({
            loader: false
          });
          console.log(err);
        });
    }
  };

  render() {
    const { loanAmount, loanRepayDate, loanTrustMembers } = this.state;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                  <table class="uk-table uk-margin-left">
                    <caption className="uk-text-center">
                      <div className="divider" />
                      <h1 className="uk-h1 theme-color uk-margin-remove-top">
                        <span className="text-red">Loan Amount: </span>
                        {loanAmount || 0}
                      </h1>
                      <span className="uk-margin-right uk-margin-bottom">
                        Repayment Date:
                      </span>{' '}
                      {loanRepayDate || 'N/A'}
                      <div className="divider" />
                    </caption>
                    <thead>
                      <tr>
                        <th className="theme-color">TRUSTER</th>
                        <th className="theme-color">TFP</th>
                        <th className="theme-color">PLAN</th>
                        <th className="theme-color">REPONSE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanTrustMembers && loanTrustMembers.length > 0
                        ? loanTrustMembers.map((member, index) => (
                            <tr key={index}>
                              <td>{member.u_first_name}</td>
                              <td>{member.u_tfp_pin}</td>
                              <td>{member.pl_prefix}</td>
                              {member.status === 'DECLINED' ? (
                                <td className="text-red">DECLINED</td>
                              ) : (
                                <td>{member.status}</td>
                              )}
                              {/* {member.status === 'TRUSTED' ? (
                                <td className="theme-color">TRUSTED</td>
                              ) : member.status === 'DECLINED' ? (
                                <td className="text-red">DECLINED</td>
                              ) : member.status === 'PENDING' ? (
                                <td>PENDING</td>
                              ) : null} */}
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <div className="uk-text-center">
                    {this.state.loanStatus === 2 ? (
                      <button
                        className="uk-button dash-button uk-margin-bottom"
                        onClick={this.cancelLoan.bind(this)}
                      >
                        Cancel Loan Application
                        {this.state.loader ? (
                          <div className="spinner-wrapper">
                            <div className="spinner">
                              <img src={DynaLogo} alt="" />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LoanView;
