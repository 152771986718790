import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import trustFundApiService from './common/api/ApiService';
import { Field, reduxForm } from 'redux-form';
import RenderField from './authorization/RenderField';
import Validation from './common/helpers/Validation';
import Allen from './assets/img/allen.png';
import DynaLogo from './assets/img/aye-loader.gif';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    };
  }

  componentWillMount() {
    document.title = "Change Password - If You TRUST Me I'll TRUST You";
  }

  handleSubmit = formValues => {
    if (formValues) {
      let old_password = formValues.oldpassword;
      let new_password = formValues.newpassword;

      let data = {
        old_password,
        new_password
      };
      this.setState({ loader: true });

      trustFundApiService('changePassword', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({ loader: false });
            setTimeout(
              function() {
                this.props.history.push('/profile');
              }.bind(this),
              3000
            );
          } else {
            this.setState({ loader: false });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    console.log(this.props.userData);
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              <div
                id="left"
                className="uk-width-3-4@m uk-child-width-1-1@m uk-child-width-1-2@s uk-margin-remove dash-margin"
              >
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <div class="uk-text-center" data-uk-grid>
                    <div class="uk-width-1-2">
                      <div className="uk-text-center">
                        {this.props.userData && this.props.userData.u_image ? (
                          <img
                            className="uk-border-pill uk-margin-top"
                            src={this.props.userData.u_image}
                            width={150}
                            height={150}
                            alt="User Image"
                          />
                        ) : (
                          <img
                            className="uk-border-pill uk-margin-top"
                            src={Allen}
                            width={150}
                            height={150}
                            alt="User Image"
                          />
                        )}
                      </div>
                    </div>
                    <div class="uk-width-1-2">
                      <br />
                      <br />
                      <div className="uk-text-center">
                        <h2 className="uk-card-title theme-color uk-margin-remove">
                          {this.props.userData.u_first_name || ''}
                        </h2>
                        <h5 className="uk-text-default theme-color uk-margin-top">
                          {this.props.userData.u_email || ''}
                        </h5>
                        <h3 className="uk-card-title text-green uk-margin-remove">
                          {this.props.userData.u_tfp_pin || ''}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <div className="uk-flex-center  uk-margin">
                    <h2 className="uk-text-large-theme theme-color uk-text-center  uk-margin-top">
                      Change Account Password
                      <br />
                    </h2>
                    <br />
                    <form onSubmit={handleSubmit(this.handleSubmit)}>
                      {/* <div className="uk-margin uk-margin-top">
                        <label className="uk-form-label-dash-dash">
                          Old Password:
                        </label>
                        <input
                          className="uk-input"
                          type="password"
                          name="old-pass"
                          id="old-pass"
                        />
                      </div> */}
                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <label className="uk-form-label-dash" htmlFor="reg-email">
                            Old Password
                          </label>
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="oldpassword"
                            id="old-password"
                            type="password"
                            maxLength="40"
                            placeholder="Enter Old Password"
                            autoComplete="off"
                            validate={[
                              Validation.oldPasswordRequired,
                              Validation.noSpace
                            ]}
                          />
                        </div>
                      </div>
                      {/* <div className="uk-margin uk-margin-top">
                        <label className="uk-form-label-dash-dash uk-margin-top">
                          New Password:
                        </label>
                        <input
                          className="uk-input"
                          type="password"
                          name="new-pass"
                          id="new-pass"
                        />
                      </div> */}

                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <label className="uk-form-label-dash" htmlFor="reg-email">
                            New Password
                          </label>
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="newpassword"
                            id="new-password"
                            type="password"
                            placeholder="Enter New Password"
                            maxLength="40"
                            autoComplete="off"
                            validate={[
                              Validation.newPasswordRequired,
                              Validation.passwordPolicy,
                              Validation.noSpace
                            ]}
                          />
                        </div>
                      </div>

                      {/* <div className="uk-margin uk-margin-top">
                        <label className="uk-form-label-dash-dash uk-margin-top">
                          Confirm New Password:
                        </label>
                        <input
                          className="uk-input"
                          type="password"
                          name="new-pass"
                          id="new-pass"
                        />
                      </div> */}
                      <div className="uk-margin-medium-top">
                        <div className="uk-form-controls">
                          <label className="uk-form-label-dash" htmlFor="reg-email">
                            Confirm Password
                          </label>
                          <Field
                            component={RenderField}
                            className="uk-input"
                            name="confirmpassword"
                            id="confirm-password"
                            placeholder="Confirm New Password"
                            type="password"
                            maxLength="40"
                            autoComplete="off"
                            validate={[
                              Validation.confirmPasswordRequired,
                              Validation.changePasswordsMatch,
                              Validation.noSpace
                            ]}
                          />
                        </div>
                      </div>

                      <div className="uk-text-center uk-margin">
                        {/* <Link
                          to="/dashboard"
                          className="uk-button dash-button uk-margin-bottom uk-margin-top"
                        >
                          Update Password
                        </Link> */}
                        <button
                          className="uk-button dash-button"
                          type="submit"
                          disabled={submitting}
                        >
                          <span>Update Password</span>
                        </button>

                        <br />
                        <br />
                        <Link to="/profile" className="uk-text-lead link ">
                          Cancel
                        </Link>
                        <br />
                      </div>
                    </form>
                  </div>
                </div>
                {this.state.loader ? (
                  <div className="spinner-wrapper">
                    <div className="spinner">
                      <img src={DynaLogo} alt="" />
                    </div>
                  </div>
                ) : null}
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

ChangePassword = reduxForm({
  form: 'changePasswordForm',
  touchOnBlur: false,
  destroyOnUnmount: true
})(ChangePassword);

export default connect(mapStateToProps, null)(ChangePassword);
