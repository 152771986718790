import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";

import Footer from './common/Footer';
import Header2 from './common/Header2';
import BissiImg from './assets/img/chat-icon.png';
// import BissiImg from './assets/img/bissee.png';
import Validation from './common/helpers/Validation';
import RenderField from './authorization/RenderField';

class Bissi extends Component {
  componentDidMount() {
    document.title = "Talk to Members Support Team - If You TRUST Me I'll TRUST You";
    window.scrollTo(0, 0);
  }

  handleSubmit = formValues => {
    console.log(formValues);
    if (formValues) {
      this.props.history.push({
        pathname: '/support',
        state: {
          username: formValues.firstname,
          randomId: Math.floor(1000 + Math.random() * 9000)
        }
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <section
          id="planTop"
          className="uk-width-1-1@m"
          data-uk-height-viewport
        >
          <Header2 />

          <div className="uk-container">
            <div className="uk-margin-left uk-margin-right">
              <h2 className="uk-h4 uk-text-center theme-color uk-margin-top">
                Start Conversation
              </h2>
              <p className="uk-text-center">
                Welcome to E-TrustFund, here you can speak directly with
                dedicated members support team.
              </p>
              <div data-uk-grid>
                <div class="uk-width-1-2 uk-width-expand uk-margin-top">
                  <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <fieldset class="uk-fieldset">
                      <div class="uk-margin uk-margin-large-top">
                        <label className="uk-form-label">Name</label>
                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="firstname"
                          id="reg-firstname"
                          type="text"
                          autoComplete="off"
                          validate={[
                            Validation.firstNameRequired,
                            Validation.fullNameAlphabetOnly
                          ]}
                          maxLength="50"
                        />
                      </div>

                      <div class="uk-margin">
                        <label className="uk-form-label">Email</label>
                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="email"
                          id="reg-email"
                          type="text"
                          autoComplete="username"
                          validate={[
                            Validation.emailRequired,
                            Validation.isEmail
                          ]}
                        />
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="uk-button dash-button uk-align-right"
                        >
                          Start Chat
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div class="uk-visible@m uk-width-1-2">
                  <img src={BissiImg} width={300} />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-text-center">
            <br/>
              <Link to="/">
                <span className="uk-button button-out">Return Home</span>
              </Link>
            <br/>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default reduxForm({
  form: 'signupForm',
  touchOnBlur: true,
  destroyOnUnmount: true
})(Bissi);
