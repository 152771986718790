import {
  LOGIN,
  REGISTER,
  LOGOUT,
  GET_PLANS,
  PURCHASE_PLAN,
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_NOTIFICATION_COUNT,
  BUY_PLAN,
  BUY_PLAN_ORDINARY,
} from "../types";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
} from "../../helpers/Utils";

const INITIAL_STATE = {
  userData: getLocalStorage("userInfo") || {},
  planData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      if (action.payload && action.payload.data) {
        setLocalStorage("userInfo", action.payload.data);
        const userInfo = action.payload.data;
        state.userData = userInfo;
      }
      return { ...state };

    case REGISTER:
      if (action.payload && action.payload.data) {
        setLocalStorage("userInfo", action.payload.data);
        const userInfo = action.payload.data;
        state.userData = userInfo;
      }
      return { ...state };

    case GET_PLANS:
      if (action.payload && action.payload.data) {
        const planInfo = action.payload.data;
        state.planData = planInfo;
      }
      return { ...state };

    case PURCHASE_PLAN:
      if (action.payload && action.payload.data) {
        const TFPData = action.payload.data;
        state.userData.u_tfp_pin = TFPData.u_tfp_pin;
        setLocalStorage("userInfo", state.userData);
      }
      return { ...state };

    case BUY_PLAN:
      if (action.payload && action.payload.data) {
        const TFPData = action.payload.data;
        state.userData.u_tfp_pin = TFPData.u_tfp_pin;
        console.log("BUY_PLAN", state.userData);
        setLocalStorage("userInfo", state.userData);
      }
      return { ...state };

    case BUY_PLAN_ORDINARY:
      if (action.payload) {
        const TFPData = action.payload;
        state.userData.u_tfp_pin = TFPData.u_tfp_pin;
        console.log(state.userData);
        setLocalStorage("userInfo", state.userData);
      }
      return { ...state };

    case LOGOUT:
      setLocalStorage("userInfo", {});
      clearLocalStorage();
      const userInfo = {};
      const planInfo = {};
      state.userData = userInfo;
      state.planData = planInfo;
      return { ...state };

    case GET_PROFILE:
      if (action.payload && action.payload.data) {
        const object = action.payload.data;
        let userInfo = getLocalStorage("userInfo");

        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            const element = object[key];
            userInfo[key] = element;
            state[key] = element;
          }
        }
        state.userData = userInfo;
        setLocalStorage("userInfo", userInfo);
      }
      return Object.assign({}, state);

    case UPDATE_PROFILE:
      if (action.payload && action.payload.data) {
        const object = action.payload.data;
        let userInfo = getLocalStorage("userInfo");

        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            const element = object[key];
            userInfo[key] = element;
            state[key] = element;
          }
        }
        state.userData = userInfo;
        setLocalStorage("userInfo", userInfo);
      }
      return Object.assign({}, state);

    case UPDATE_NOTIFICATION_COUNT:
      if (action.payload) {
        const object = action.payload;
        let userInfo = getLocalStorage("userInfo");

        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            const element = object[key];
            userInfo[key] = element;
            state[key] = element;
          }
        }
        state.userData = userInfo;
        setLocalStorage("userInfo", userInfo);
      }
      return Object.assign({}, state);
    default:
      return state;
  }
};
