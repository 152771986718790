import React,{useEffect, useLayoutEffect} from "react";

export default function Loans() {



    useLayoutEffect(() => {
      window.location.replace('https://forms.gle/XMWPUUdQZa1Kbtsq5');
    }, []);
    
    
  return <div>A moment please...</div>;
}
