import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Link } from 'react-router-dom';

import DynaLogo from './assets/img/aye-loader.gif';
import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import GoogleAutocomplete from './common/helpers/GoogleAutocomplete';
import Validation from './common/helpers/Validation';
import trustFundApiService from './common/api/ApiService';
import SelectRenderField from './authorization/SelectRenderField';
import renderDatePicker from './common/helpers/Datepicker';
import RenderField from './authorization/RenderField';

class Pop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false
    };
  }

  componentDidMount() {
    document.title = "Purchase Order Power - If You TRUST Me I'll TRUST You";
  }

  handleSubmit = formValues => {
    if (formValues) {
      //let _this = this;

      let data = {
        pr_item_id: formValues.itemId,
        pr_item_name: formValues.itemName,
        pr_item_amount: formValues.amount,
        pr_repayment_date: formValues.repaydate,
        pr_reason_item_purchase: formValues.reasonFor,
        pr_delivery_location: formValues.location
      };

      if (data) {
        this.setState({
          loader: true
        });
        trustFundApiService('purchaseOrderPower', data)
          .then(response => {
            if (response.data.status === 'success') {
              this.setState({
                loader: false
              });
              this.props.initialize({
                itemId: '',
                itemName: '',
                amount: '',
                repaydate: '',
                reasonFor: '',
                location: ''
              });
              setTimeout(
                function() {
                  this.props.history.push('/dashboard');
                }.bind(this),
                3000
              );
            } else {
              this.setState({
                loader: false
              });
            }
          })
          .catch(err => {
            this.setState({
              loader: false
            });
            console.log(err);
          });
      }
    }
  };
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />

          <div className="content-wrapper">
            <div id="dash-content" className="uk-margin-remove" data-uk-grid>
              {this.state.loader ? (
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <img src={DynaLogo} alt="" />
                  </div>
                </div>
              ) : null}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="uk-card uk-card-loan uk-card-body animated fadeIn uk-margin-bottom">
                  <h2 className="uk-text-large-theme theme-color uk-text-center">
                    Purchase Order Power
                    <br />
                  </h2>
                  <br />
                  <form
                    className="block-datePicker"
                    onSubmit={handleSubmit(this.handleSubmit)}
                  >
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">
                          POP Limit (max)
                        </label>
                        <input
                          className="uk-input"
                          name="amount"
                          type="text"
                          value={this.props.userData.u_pl_pop || ''}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">Item name</label>
                        <Field
                          component={RenderField}
                          className="uk-input"
                          name="itemName"
                          id="city-from"
                          type="text"
                          autoComplete="new-password"
                          maxLength="50"
                          validate={[Validation.itemRequired]}
                        />

                        {/* <Field
                          name="itemName"
                          id="city-from"
                          component={SelectRenderField}
                          className="uk-select"
                          validate={[Validation.itemRequired]}
                        >
                          <option value="">Select a item</option>
                          <option value="item1">item1</option>
                          <option value="item2">item2</option>
                          <option value="item3">item3</option>
                          <option value="item4">item4</option>
                          <option value="item5">item5</option>
                          <option value="item6">item6</option>
                          <option value="item7">item7</option>
                          <option value="item8">item8</option>
                          <option value="item9">item9</option>
                          <option value="item10">item10</option>
                          <option value="item11">item11</option>
                          <option value="item12">item12</option>
                        </Field> */}
                      </div>
                    </div>
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">
                          Reason for Purchase
                        </label>
                        <Field
                          component={RenderField}
                          name="reasonFor"
                          className="uk-textarea"
                          type="textarea"
                          autoComplete="off"
                          validate={[Validation.reasonPurchaseRequired]}
                          maxLength="300"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <label
                          className="uk-form-label-dash"
                          htmlFor="reg-name"
                        >
                          Repayment Date
                        </label>

                        <Field
                          name="repaydate"
                          component={renderDatePicker}
                          type="text"
                          className="uk-input"
                          validate={[Validation.repaydateReuired]}
                          normalize={value =>
                            value ? moment(value).format('MM/DD/YYYY') : null
                          }
                        />
                      </div>
                    </div>
                    <div className="uk-margin higher-index">
                      <div className="uk-form-controls">
                        <label className="uk-form-label-dash">
                          Delivery Location
                        </label>
                        <Field
                          component={GoogleAutocomplete}
                          name="location"
                          className="uk-input"
                          type="text"
                          autoComplete="off"
                          validate={[Validation.locationRequired]}
                        />
                      </div>
                    </div>

                    <div className="uk-text-center uk-margin-bottom uk-margin-large-top">
                      <button
                        className="uk-button dash-button"
                        type="submit"
                        disabled={submitting}
                      >
                        Apply for P.O.P
                      </button>
                    </div>
                    <div className="uk-text-center">
                      <Link to="/dashboard" className="uk-text-lead link">
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
              </div>

              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return { userData: user.userData };
};

Pop = connect(mapStateToProps, null)(Pop);

export default reduxForm({
  form: 'etcForm',
  touchOnBlur: true,
  destroyOnUnmount: true
})(Pop);
