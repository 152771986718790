import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

import Header from './common/Header';
import LeftSidebar from './common/LeftSidebar';
import RightSidebar from './common/RightSidebar';
import Notification from './common/Notification';
import RenderField from './authorization/RenderField';
import Validation from './common/helpers/Validation';
import trustFundApiService from './common/api/ApiService';
import CONSTANTS from './common/helpers/Constants';
import DateRenderField from './authorization/DatePickerRenderField';
import { showErrorToast } from './common/helpers/Toastr';
import People from './assets/img/pple.png';

var maxRequiredSTM = 0;

class Loan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pins: [],
      disabled: true,
      STMRequired: 0,
      GTMRequired: 0,
      PTMRequired: 0,
      isActive: true,
      error: false,
      showContinueLoanBtn: false
    };
  }

  componentWillMount() {
    document.title = "Loan - If You TRUST Me I'll TRUST You";
    Modal.setAppElement('body');
  }

  componentDidMount() {
    this.checkSavedLoanApplication();
  }

  toggleModal = event => {
    if (event && event.keyCode === 27) {
    } else {
      this.setState({
        isActive: !this.state.isActive
      });
    }
  };

  checkLoanRequirement = event => {
    let loan_amount = event.target.value;
    if (loan_amount) {
      //if (loan_amount >= 10000) {
      let data = {
        loan_amount
      };

      trustFundApiService('checkLoanRequirement', data)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              disabled: false,
              error: false,
              errorMessage: ''
            });
            if (response.data.data.length > 0) {
              this.setState({
                pins: response.data.data,
                STMRequired: response.data.data[0].pl_value,
                GTMRequired: response.data.data[1].pl_value,
                PTMRequired: response.data.data[2].pl_value
              });
            }
          } else if (response.data.status === 'error') {
            this.setState({
              pins: response.data.data,
              error: true,
              errorMessage: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({
        pins: [],
        STMRequired: 0,
        GTMRequired: 0,
        PTMRequired: 0
      });
    }
  };

  // checkLoanRequirement = formValues => {
  //   let loan_amount = formValues.loanamount;
  //   let _this = this;
  //   if (formValues.loanamount >= 10000) {
  //     let data = {
  //       loan_amount
  //     };

  //     trustFundApiService('checkLoanRequirement', data)
  //       .then(response => {
  //         if (response.data.status === 'success') {
  //           if (response.data.data.length > 0) {
  //             this.setState({
  //               pins: response.data.data,
  //               STMRequired: response.data.data[0].pl_value,
  //               GTMRequired: response.data.data[1].pl_value,
  //               PTMRequired: response.data.data[2].pl_value
  //             });
  //             if (formValues.loanamount && formValues.repaydate) {
  //               let loanData = {
  //                 loan_amount: formValues.loanamount,
  //                 loan_repayment_date: moment(formValues.repaydate).format(
  //                   'MM/DD/YYYY'
  //                 ),
  //                 STMRequired: response.data.data[0].pl_value,
  //                 GTMRequired: response.data.data[1].pl_value,
  //                 PTMRequired: response.data.data[2].pl_value
  //               };

  //               setTimeout(function() {
  //                 _this.props.history.push({
  //                   pathname: '/addtrusters',
  //                   state: {
  //                     action: 'add',
  //                     loanData: loanData
  //                   }
  //                 });
  //               }, 3000);
  //             }
  //           }
  //         } else if (response.data.status === 'error') {
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   } else {
  //     this.setState({
  //       pins: [],
  //       STMRequired: 0,
  //       GTMRequired: 0,
  //       PTMRequired: 0
  //     });
  //   }
  // };

  handleSubmit = formValues => {
    if (!this.state.error) {
      if (formValues.loanamount && formValues.repaydate) {
        let loanData = {
          loan_amount: formValues.loanamount,
          loan_repayment_date: moment(formValues.repaydate).format(
            'MM/DD/YYYY'
          ),
          STMRequired: this.state.STMRequired,
          GTMRequired: this.state.GTMRequired,
          PTMRequired: this.state.PTMRequired
        };
        this.props.history.push({
          pathname: '/addtrusters',
          state: {
            action: 'add',
            loanData: loanData
          }
        });
      }
    } else {
      showErrorToast(this.state.errorMessage);
    }
  };

  // handleSubmit = formValues => {
  //   if (formValues.loanamount) {
  //     this.checkLoanRequirement(formValues);
  //   }
  // };

  checkSavedLoanApplication = () => {
    let data = {
      loan_id: ''
    };
    trustFundApiService('getUserSavedLoanrequest', data)
      .then(response => {
        if (response.data.status === 'success') {
          this.setState({
            showContinueLoanBtn: true
          });
        } else if (response.data.status === 'error') {
          this.setState({
            showContinueLoanBtn: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getSavedLoanApplication = () => {
    let data = {
      loan_id: ''
    };
    trustFundApiService('getUserSavedLoanrequest', data)
      .then(response => {
        if (response.data.status === 'success') {
          let data = response.data.data;
          this.setState({
            showContinueLoanBtn: true
          });
          let loanData = {
            loan_amount: data.loan_amount,
            loan_repayment_date: moment(data.repaydate).format('MM/DD/YYYY'),
            req_tfps: {
              STM: data.req_tfps.STM,
              GTM: data.req_tfps.GTM,
              PTM: data.req_tfps.PTM
            },
            STMCheckedArray:
              data.trustmembers.STM.length > 0 ? data.trustmembers.STM : [],
            GTMCheckedArray:
              data.trustmembers.GTM.length > 0 ? data.trustmembers.GTM : [],
            PTMCheckedArray:
              data.trustmembers.PTM.length > 0 ? data.trustmembers.PTM : [],
            maxRequiredSTM: this.getMaxForTrusters(
              data.req_tfps.STM,
              data.req_tfps.GTM,
              data.req_tfps.PTM
            )
          };

          this.props.history.push({
            pathname: '/addtrusters',
            state: {
              action: 'edit',
              loanData: loanData
            }
          });
        } else if (response.data.status === 'error') {
          this.setState({
            showContinueLoanBtn: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getMaxForTrusters(STM, GTM, PTM) {
    if (!(PTM.includes('N') || PTM.includes('Max'))) {
      // STM available
      maxRequiredSTM = PTM * 10 * 2;
      return maxRequiredSTM;
    }

    if (!(GTM.includes('N') || GTM.includes('Max'))) {
      // GTM available
      maxRequiredSTM = GTM * 5 * 2;
      return maxRequiredSTM;
    }

    if (!(STM.includes('N') || STM.includes('Max'))) {
      // PTM available
      maxRequiredSTM = STM * 2;
      return maxRequiredSTM;
    }
    return maxRequiredSTM;
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <section id="dashboard">
          <Header {...this.props} />
          <LeftSidebar {...this.props} />
          <Notification {...this.props} />
          <div className="content-wrapper">
            <div
              id="dash-content"
              className="uk-margin-remove uk-margin-top"
              data-uk-grid
            >
              {this.state.showContinueLoanBtn ? (
                <Modal
                  className="uk-card uk-card-loan uk-margin uk-width-1-2 u-modal uk-position-center animated fadeIn ReactModal__Overlay--after-open"
                  isOpen={this.state.isActive}
                  onRequestClose={this.toggleModal}
                  shouldCloseOnOverlayClick={false}
                >
                  {' '}
                  <div className="uk-text-center uk-margin-top">
                    <img src={People} width={100} alt="" />
                  </div>
                  <div className="uk-text-center uk-margin-right uk-margin-left">
                    <h3 className="uk-heading-primary theme-color">
                      Get a Loan in 59s
                    </h3>
                    <div className="uk-margin-bottom">
                      <a
                        className="uk-button dash-button"
                        onClick={this.toggleModal}
                      >
                        Start New Loan Application
                      </a>
                      <br />
                      <br />

                      <a
                        className="uk-text-lead link uk-text-center"
                        onClick={this.getSavedLoanApplication}
                      >
                        <span className="uk-position-relative uk-position-z-index">
                          Continue Last Saved
                        </span>
                      </a>
                    </div>
                    <a
                      onClick={() => this.props.history.push('/dashboard')}
                      className="uk-position-relative link uk-position-z-index"
                    >
                      Close
                    </a>
                  </div>
                </Modal>
              ) : null}
              <div id="left" className="uk-width-3-4@m dash-margin">
                <div className="">
                  <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <div>
                      <div className="uk-card uk-card-loan uk-card-body animated fadeIn">
                        <div className="uk-width-2-1 uk-text-center">
                          <h2 className="uk-text-large-theme theme-color uk-margin-large-bottom">
                            Apply for a Loan
                            <br />
                          </h2>
                          <div class="uk-child-width-1-1@s uk-grid-collapse uk-text-center">
                            <div className="field-data">
                              <label className="uk-form-label-dash">
                                Loan Amount:
                              </label>
                              <Field
                                name="loanamount"
                                id="loanamount"
                                component={RenderField}
                                className="uk-input input-width"
                                maxLength="10"
                                validate={[
                                  Validation.loanAmountReuired
                                  //Validation.minLoanAmount
                                ]}
                                normalize={Validation.normalizeNumber}
                                onChange={this.checkLoanRequirement}
                                autoComplete="off"
                              />
                            </div>

                            <div className="">
                              <br />
                              <br />
                              <br />
                            </div>
                            <div className="field-data">
                              <label className="uk-form-label-dash">
                                Repayment Date:
                              </label>
                              <Field
                                name="repaydate"
                                component={DateRenderField}
                                className="uk-input input-width"
                                minDate={new Date()}
                                validate={[Validation.repaydateReuired]}
                                normalize={value =>
                                  value
                                    ? moment(value).format('MM/DD/YYYY')
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-width-1-1 uk-child-width-1-6@s uk-text-center">
                          {this.state.pins && this.state.pins.length > 0
                            ? this.state.pins.map((pin, i) =>
                                pin.pl_name ===
                                CONSTANTS.PLAN_NAME.SILVER_PREFIX ? (
                                  <React.Fragment>
                                    <div className="uk-inline" key={i}>
                                      <div className=" uk-margin-top">
                                        <h2 className="req theme-color uk-text-center">
                                          STM:
                                          {pin.pl_value.includes('N') ||
                                          pin.pl_value.includes('Max') ? (
                                            <span id="stm" />
                                          ) : (
                                            <span id="stm">
                                              {pin.pl_value || 0}
                                            </span>
                                          )}
                                          <br />
                                        </h2>
                                        <br />
                                        <div className="uk-form-controls">
                                          {pin.pl_value.includes('N') ||
                                          pin.pl_value.includes('Max') ? (
                                            <label className="uk-form-label-dash text-red max-limit">
                                              {pin.pl_value || 0}
                                            </label>
                                          ) : (
                                            <label className="uk-form-label-dash">
                                              10 STM = 1 PTM
                                            </label>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="uk-inline">
                                      <h2 className="uk-text-lead text-red">
                                        OR
                                      </h2>
                                    </div>
                                  </React.Fragment>
                                ) : pin.pl_name ===
                                  CONSTANTS.PLAN_NAME.GOLD_PREFIX ? (
                                  <React.Fragment>
                                    <div className="uk-inline">
                                      <div className=" uk-margin-top">
                                        <h2 className="req theme-color uk-text-center">
                                          GTM:
                                          {pin.pl_value.includes('N') ||
                                          pin.pl_value.includes('Max') ? (
                                            <span id="gtm" />
                                          ) : (
                                            <span id="gtm">
                                              {pin.pl_value || 0}
                                            </span>
                                          )}
                                          <br />
                                        </h2>
                                        <br />
                                        <div className="uk-form-controls">
                                          {pin.pl_value.includes('N') ||
                                          pin.pl_value.includes('Max') ? (
                                            <label className="uk-form-label-dash text-red max-limit">
                                              {pin.pl_value || 0}
                                            </label>
                                          ) : (
                                            <label className="uk-form-label-dash">
                                              5 STM = 1 GTM
                                            </label>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-inline">
                                      <h2 className="uk-text-lead text-red">
                                        OR
                                      </h2>
                                    </div>
                                  </React.Fragment>
                                ) : pin.pl_name ===
                                  CONSTANTS.PLAN_NAME.PLATINUM_PREFIX ? (
                                  <div className="uk-inline">
                                    <div className=" uk-margin-top">
                                      <h2 className="req theme-color uk-text-center">
                                        PTM:
                                        {pin.pl_value.includes('N') ||
                                        pin.pl_value.includes('Max') ? (
                                          <span id="ptm" />
                                        ) : (
                                          <span id="ptm">
                                            {pin.pl_value || 0}
                                          </span>
                                        )}
                                        <br />
                                      </h2>
                                      <br />
                                      <div className="uk-form-controls">
                                        {pin.pl_value.includes('N') ||
                                        pin.pl_value.includes('Max') ? (
                                          <label className="uk-form-label-dash text-red max-limit">
                                            {pin.pl_value || 0}
                                          </label>
                                        ) : (
                                          <label className="uk-form-label-dash">
                                            2 GTM = 1 PTM
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null
                              )
                            : ''}
                        </div>
                        <div className="uk-text-center uk-margin-large-top uk-margin-bottom">
                          <button
                            type="submit"
                            className="uk-button button-out"
                            //disabled={this.state.disabled}
                          >
                            <span uk-icon="icon: plus" /> Add Trusters
                          </button>
                          <br />
                        </div>
                        <div className="uk-text-center uk-margin-bottom">
                          <Link className="uk-text-lead link" to="/dashboard">
                            Cancel
                          </Link>
                          <br />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <RightSidebar {...this.props} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default reduxForm({
  form: 'loanForm',
  touchOnBlur: true,
  destroyOnUnmount: true
})(Loan);
