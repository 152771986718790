import React from 'react';
import DatePicker from 'react-datepicker';

var today = new Date();
var lastDayOfMonth = new Date(today.getFullYear(), 10, 30);

export default ({
  input,
  label,
  type,
  className,
  selected,
  endDate,
  minDate,
  startDate,
  maxDate,
  meta: { touched, error }
}) => (
  <React.Fragment>
    <DatePicker
      {...input}
      selected={selected}
      type={type}
      className={className}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      // maxDate={lastDayOfMonth}
      autoComplete="off"
      readOnly={true}
    />
    {touched && error && <span className="error_field">{error}</span>}
  </React.Fragment>
);
