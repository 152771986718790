import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Header2 from "../../common/Header2";
import { connect } from "react-redux";
import { actionGetPlans } from "../../common/redux/actions";
import { clearLocalStorage } from "../../common/helpers/Utils";
import { actionLogout } from "../../common/redux/actions/";
import _ from "lodash";
import * as qs from "query-string";
import OTP from "./OTP";

import "../../assets/css/main.css";
import SilverPlan from "../../assets/img/silver.png";
import GoldPlan from "../../assets/img/gold.png";
import PlatinumPlan from "../../assets/img/Plat.png";
import PlanBlk from "../../assets/img/plan-blk.png";
import Lock from "../../assets/img/padlock.png";
import gtb from "../../assets/img/bank-codes/gtb.svg";
import zenith from "../../assets/img/bank-codes/zenith.svg";
import access from "../../assets/img/bank-codes/access.svg";
import fbn from "../../assets/img/bank-codes/fbn.svg";
import uba from "../../assets/img/bank-codes/uba.png";
import {
  capitalizeFirstLetter,
  formatExpirationDate,
} from "../../common/helpers/Utils";
import CONSTANTS from "../../common/helpers/Constants";
import RenderField from "../RenderField";
import Validation from "../../common/helpers/Validation";
import trustFundApiService from "../../common/api/ApiService";
import { actionPurchasePlan } from "../../common/redux/actions";
import { showErrorToast } from "../../common/helpers/Toastr";
import DynaLogo from "../../assets/img/aye-loader.gif";
import { getLocalStorage } from "../../common/helpers/Utils";

class BankTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planData: [],
      loader: false,
      isActive: false,
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    this.toggleModal();

    let planId = getLocalStorage("planId");
    if (planId) {
      this.getPlans(planId);
    }

    console.log(this.props.history);
    console.log(getLocalStorage("userInfo"));

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function(event) {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }

  logout = () => {
    const _this = this;
    clearLocalStorage(function () {
      _this.props.history.push("/");
      _this.props.actionLogout();
    });
  };

  toggleModal = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  getPlans = (planId) => {
    this.props
      .actionGetPlans()
      .then((result) => {
        if (result && result.data && result.data.status === "success") {
          let data = result.data.data;
          let planData = _.filter(data, { pl_plan_id: planId });
          this.setState({
            planData: planData[0],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    const cur_name = this.props.userData.cur_name || "";
    let { planData } = this.state;
    let planImage = "";
    let planAmount = "";
    if (planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.SILVER_PREFIX) {
      planImage = SilverPlan;
      planAmount =
        parseInt(planData.pl_registration) +
        parseInt(planData.pl_weekly_saving);
    } else if (planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.GOLD_PREFIX) {
      planImage = GoldPlan;
      planAmount =
        parseInt(planData.pl_registration) +
        parseInt(planData.pl_weekly_saving);
    } else if (
      planData.pl_plan_prefix === CONSTANTS.PLAN_NAME.PLATINUM_PREFIX
    ) {
      planImage = PlatinumPlan;
      planAmount =
        parseInt(planData.pl_registration) +
        parseInt(planData.pl_weekly_saving);
    } else {
      planImage = SilverPlan;
      planAmount =
        parseInt(planData.pl_registration) +
        parseInt(planData.pl_weekly_saving);
    }

    return (
      <div>
        <section
          id="planTop"
          className="uk-width-1-1@m"
          data-uk-height-viewport
        >
          <Header2 />
          <div className="container-main">
            {this.state.loader ? (
              <div className="spinner-wrapper">
                <div className="spinner">
                  <img src={DynaLogo} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="plan-container uk-margin-remove-large-top">
              <div className="grid center-xs">
                <h3 className="uk-text-center">Payment via Bank Transfer</h3>
              </div>
              <div className="uk-margin-left uk-margin-right">
                <p className="set-top uk-margin-left">
                  Please transfer to the bank details below or use any of the
                  USSD codes listed below to pay for your{" "}
                  <span className="uk-text-bold ">
                    Membership Fee and First Weekly Contribution
                  </span>{" "}
                  and once confirmed, your trustfund account will be activated
                  within 24Hours.{" "}
                  <i>
                    Kindly note you will be required to send your{" "}
                    <b>Proof of Payment</b> and the{" "}
                    <b>TFP of your Trust Partner</b>(if available) to{" "}
                    <b>proof@etrustfund.org</b>
                  </i>
                </p>
              </div>

              <div
                class="uk-child-width-1-2@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <div className="">
                      <h2 className="theme-color uk-margin-remove-top">
                        Selected Plan
                      </h2>
                      <img
                        // src={planImage}
                        src={PlanBlk}
                        style={{ width: "100px" }}
                        alt="Platinum"
                      />
                      <div className="uk-inline-clip uk-margin-left">
                        <p className="uk-text-lead">
                          {capitalizeFirstLetter(planData.pl_plan_name) || ""}{" "}
                          Trust Plan
                        </p>
                        <p>
                          <b>Membership Fee: </b>
                          {cur_name}
                          {planData.pl_registration}
                          <br />
                          <b>Weekly Contribution: </b>
                          {cur_name}
                          {planData.pl_weekly_saving}
                          <br />
                          <b>Total: </b>
                          {cur_name}
                          {planAmount || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <div className="">
                      <h2 className="theme-color uk-margin-remove-top">
                        Transfer Details
                      </h2>
                      <div className="uk-inline-clip uk-margin-left">
                        <p className="uk-text-lead">
                          Guarantee Trust Bank (GTB)
                        </p>
                        <p>
                          <b>Account Name: </b>A.Y.E Trust Fund
                          <br />
                          <b>Account Number: </b>0454179544
                          <br />
                          <b>Narration: </b>Name or Email used to Register
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-margin-top uk-margin-bottom uk-text-center">
                <span className="theme-color">
                  <i>-- You may see below for USSD short codes --</i>
                </span>
                <br />
                <br />
              </div>
              <div
                class="uk-child-width-1-3@m uk-grid-small uk-grid-match"
                data-uk-grid
              >
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">
                      <img src={gtb} />
                    </h3>
                    <p>
                      <b>*737*1*{planAmount || 0}*0454179544#</b>
                    </p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">
                      <img src={fbn} />
                    </h3>
                    <p>
                      <b>*894*2*{planAmount || 0}*0454179544#</b>
                    </p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">
                      <img src={zenith} />
                    </h3>
                    <p>
                      <b>*966*2*{planAmount || 0}*0454179544#</b>
                    </p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">
                      <img src={access} />
                    </h3>
                    <p>
                      <b>*901*2*{planAmount || 0}*0454179544#</b>
                    </p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">
                      <img src={uba} width={90} />
                    </h3>
                    <p>
                      <b>*919*2*{planAmount || 0}*0454179544#</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ Section */}
        <section id="faq">
          <div className="uk-text-center uk-margin-large-top">
            <Link to="/learn-more">
              <span className="uk-button button-out">Learn More</span>
            </Link>
            <Link
              onClick={
                // getLocalStorage("userInfo")
                //   ? this.props.history.goBack()
                  // :
                   this.logout
              }
            >
              <span className="uk-button button-out">Return Home</span>
            </Link>
          </div>
          <br />
        </section>
        {/* End FAQ Section */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { planData, userData } = state.user;
  return { planData, userData };
};

BankTransfer = connect(mapStateToProps, { actionPurchasePlan, actionGetPlans })(
  BankTransfer
);

export default reduxForm({
  form: "addCardForm",
  touchOnBlur: true,
  destroyOnUnmount: false,
})(BankTransfer);
